/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Value } from "./google/protobuf/struct";
import { BoolValue, Int32Value, StringValue, UInt32Value } from "./google/protobuf/wrappers";

export const protobufPackage = "";

export enum AvailabilityStatus {
  UNCHANGED = 0,
  ONLINE = 1,
  SILENT = 2,
  AWAY = 3,
  JITSI = 4,
  BBB = 5,
  DENY_PROXIMITY_MEETING = 6,
  SPEAKER = 7,
  UNRECOGNIZED = -1,
}

export function availabilityStatusFromJSON(object: any): AvailabilityStatus {
  switch (object) {
    case 0:
    case "UNCHANGED":
      return AvailabilityStatus.UNCHANGED;
    case 1:
    case "ONLINE":
      return AvailabilityStatus.ONLINE;
    case 2:
    case "SILENT":
      return AvailabilityStatus.SILENT;
    case 3:
    case "AWAY":
      return AvailabilityStatus.AWAY;
    case 4:
    case "JITSI":
      return AvailabilityStatus.JITSI;
    case 5:
    case "BBB":
      return AvailabilityStatus.BBB;
    case 6:
    case "DENY_PROXIMITY_MEETING":
      return AvailabilityStatus.DENY_PROXIMITY_MEETING;
    case 7:
    case "SPEAKER":
      return AvailabilityStatus.SPEAKER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AvailabilityStatus.UNRECOGNIZED;
  }
}

export function availabilityStatusToJSON(object: AvailabilityStatus): string {
  switch (object) {
    case AvailabilityStatus.UNCHANGED:
      return "UNCHANGED";
    case AvailabilityStatus.ONLINE:
      return "ONLINE";
    case AvailabilityStatus.SILENT:
      return "SILENT";
    case AvailabilityStatus.AWAY:
      return "AWAY";
    case AvailabilityStatus.JITSI:
      return "JITSI";
    case AvailabilityStatus.BBB:
      return "BBB";
    case AvailabilityStatus.DENY_PROXIMITY_MEETING:
      return "DENY_PROXIMITY_MEETING";
    case AvailabilityStatus.SPEAKER:
      return "SPEAKER";
    case AvailabilityStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PositionMessage {
  x: number;
  y: number;
  direction: PositionMessage_Direction;
  moving: boolean;
}

export enum PositionMessage_Direction {
  UP = 0,
  RIGHT = 1,
  DOWN = 2,
  LEFT = 3,
  UNRECOGNIZED = -1,
}

export function positionMessage_DirectionFromJSON(object: any): PositionMessage_Direction {
  switch (object) {
    case 0:
    case "UP":
      return PositionMessage_Direction.UP;
    case 1:
    case "RIGHT":
      return PositionMessage_Direction.RIGHT;
    case 2:
    case "DOWN":
      return PositionMessage_Direction.DOWN;
    case 3:
    case "LEFT":
      return PositionMessage_Direction.LEFT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PositionMessage_Direction.UNRECOGNIZED;
  }
}

export function positionMessage_DirectionToJSON(object: PositionMessage_Direction): string {
  switch (object) {
    case PositionMessage_Direction.UP:
      return "UP";
    case PositionMessage_Direction.RIGHT:
      return "RIGHT";
    case PositionMessage_Direction.DOWN:
      return "DOWN";
    case PositionMessage_Direction.LEFT:
      return "LEFT";
    case PositionMessage_Direction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PointMessage {
  x: number;
  y: number;
}

export interface ViewportMessage {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export interface CharacterTextureMessage {
  url: string;
  id: string;
}

export interface ApplicationMessage {
  name: string;
  script: string;
}

export interface CompanionTextureMessage {
  url: string;
  id: string;
}

export interface PingMessage {
}

export interface UpdateMapToNewestMessage {
  commandId?: string | undefined;
}

export interface UpdateMapToNewestWithKeyMessage {
  mapKey: string;
  updateMapToNewestMessage: UpdateMapToNewestMessage | undefined;
}

export interface SetPlayerDetailsMessage {
  outlineColor: number | undefined;
  removeOutlineColor: boolean | undefined;
  showVoiceIndicator: boolean | undefined;
  availabilityStatus: AvailabilityStatus;
  setVariable: SetPlayerVariableMessage | undefined;
}

export interface UserMovesMessage {
  position: PositionMessage | undefined;
  viewport: ViewportMessage | undefined;
}

export interface WebRtcSignalToServerMessage {
  receiverId: number;
  signal: string;
}

export interface ReportPlayerMessage {
  reportedUserUuid: string;
  reportComment: string;
}

export interface BanPlayerMessage {
  banUserUuid: string;
  banUserName: string;
}

export interface EmotePromptMessage {
  emote: string;
}

export interface EmoteEventMessage {
  actorUserId: number;
  emote: string;
}

export interface FollowRequestMessage {
  leader: number;
}

export interface FollowConfirmationMessage {
  leader: number;
  follower: number;
}

export interface FollowAbortMessage {
  leader: number;
  follower: number;
}

export interface LockGroupPromptMessage {
  lock: boolean;
}

export interface KickOffUserMessage {
  userId: string;
  spaceName: string;
}

export interface EditMapMessage {
  message?:
    | { $case: "modifyAreaMessage"; modifyAreaMessage: ModifyAreaMessage }
    | { $case: "createAreaMessage"; createAreaMessage: CreateAreaMessage }
    | { $case: "deleteAreaMessage"; deleteAreaMessage: DeleteAreaMessage }
    | { $case: "modifyEntityMessage"; modifyEntityMessage: ModifyEntityMessage }
    | { $case: "createEntityMessage"; createEntityMessage: CreateEntityMessage }
    | { $case: "deleteEntityMessage"; deleteEntityMessage: DeleteEntityMessage }
    | { $case: "updateWAMSettingsMessage"; updateWAMSettingsMessage: UpdateWAMSettingsMessage }
    | { $case: "errorCommandMessage"; errorCommandMessage: ErrorCommandMessage }
    | { $case: "modifiyWAMMetadataMessage"; modifiyWAMMetadataMessage: ModifiyWAMMetadataMessage }
    | undefined;
}

export interface ErrorCommandMessage {
  reason: string;
}

export interface UpdateWAMSettingsMessage {
  message?:
    | { $case: "updateMegaphoneSettingMessage"; updateMegaphoneSettingMessage: UpdateMegaphoneSettingMessage }
    | undefined;
}

export interface UpdateMegaphoneSettingMessage {
  enabled: boolean;
  title?: string | undefined;
  scope?: string | undefined;
  rights: string[];
}

export interface EditMapCommandMessage {
  id: string;
  editMapMessage: EditMapMessage | undefined;
}

export interface EditMapCommandsArrayMessage {
  editMapCommands: EditMapCommandMessage[];
}

export interface EditMapCommandWithKeyMessage {
  mapKey: string;
  editMapCommandMessage: EditMapCommandMessage | undefined;
}

export interface ModifyAreaMessage {
  id: string;
  name?: string | undefined;
  x?: number | undefined;
  y?: number | undefined;
  width?: number | undefined;
  height?: number | undefined;
  properties: any[];
  modifyProperties?: boolean | undefined;
}

export interface DeleteAreaMessage {
  id: string;
}

export interface CreateAreaMessage {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  name: string;
  properties: any[];
}

export interface CreateEntityMessage {
  id: string;
  x: number;
  y: number;
  collectionName: string;
  prefabId: string;
  properties: any[];
  name?: string | undefined;
}

export interface DeleteEntityMessage {
  id: string;
}

export interface ModifyEntityMessage {
  id: string;
  x?: number | undefined;
  y?: number | undefined;
  properties: any[];
  modifyProperties?: boolean | undefined;
  name?: string | undefined;
}

export interface ClientToServerMessage {
  message?:
    | { $case: "userMovesMessage"; userMovesMessage: UserMovesMessage }
    | { $case: "viewportMessage"; viewportMessage: ViewportMessage }
    | { $case: "itemEventMessage"; itemEventMessage: ItemEventMessage }
    | { $case: "setPlayerDetailsMessage"; setPlayerDetailsMessage: SetPlayerDetailsMessage }
    | { $case: "webRtcSignalToServerMessage"; webRtcSignalToServerMessage: WebRtcSignalToServerMessage }
    | {
      $case: "webRtcScreenSharingSignalToServerMessage";
      webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage;
    }
    | { $case: "playGlobalMessage"; playGlobalMessage: PlayGlobalMessage }
    | { $case: "reportPlayerMessage"; reportPlayerMessage: ReportPlayerMessage }
    | { $case: "emotePromptMessage"; emotePromptMessage: EmotePromptMessage }
    | { $case: "variableMessage"; variableMessage: VariableMessage }
    | { $case: "followRequestMessage"; followRequestMessage: FollowRequestMessage }
    | { $case: "followConfirmationMessage"; followConfirmationMessage: FollowConfirmationMessage }
    | { $case: "followAbortMessage"; followAbortMessage: FollowAbortMessage }
    | { $case: "lockGroupPromptMessage"; lockGroupPromptMessage: LockGroupPromptMessage }
    | { $case: "queryMessage"; queryMessage: QueryMessage }
    | { $case: "pingMessage"; pingMessage: PingMessage }
    | { $case: "askPositionMessage"; askPositionMessage: AskPositionMessage }
    | { $case: "editMapCommandMessage"; editMapCommandMessage: EditMapCommandMessage }
    | { $case: "addSpaceFilterMessage"; addSpaceFilterMessage: AddSpaceFilterMessage }
    | { $case: "removeSpaceFilterMessage"; removeSpaceFilterMessage: RemoveSpaceFilterMessage }
    | { $case: "updateSpaceFilterMessage"; updateSpaceFilterMessage: UpdateSpaceFilterMessage }
    | { $case: "watchSpaceMessage"; watchSpaceMessage: WatchSpaceMessage }
    | { $case: "unwatchSpaceMessage"; unwatchSpaceMessage: UnwatchSpaceMessage }
    | { $case: "cameraStateMessage"; cameraStateMessage: CameraStateMessage }
    | { $case: "microphoneStateMessage"; microphoneStateMessage: MicrophoneStateMessage }
    | { $case: "megaphoneStateMessage"; megaphoneStateMessage: MegaphoneStateMessage }
    | { $case: "screenSharingStateMessage"; screenSharingStateMessage: ScreenSharingStateMessage }
    | { $case: "jitsiParticipantIdSpaceMessage"; jitsiParticipantIdSpaceMessage: JitsiParticipantIdSpaceMessage }
    | { $case: "updateSpaceMetadataMessage"; updateSpaceMetadataMessage: UpdateSpaceMetadataMessage }
    | { $case: "kickOffUserMessage"; kickOffUserMessage: KickOffUserMessage }
    | { $case: "muteParticipantIdMessage"; muteParticipantIdMessage: MuteParticipantIdMessage }
    | { $case: "muteEveryBodyParticipantMessage"; muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage }
    | { $case: "muteVideoParticipantIdMessage"; muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage }
    | {
      $case: "muteVideoEveryBodyParticipantMessage";
      muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage;
    }
    | { $case: "banPlayerMessage"; banPlayerMessage: BanPlayerMessage }
    | undefined;
}

export interface ReceivedEventMessage {
  name: string;
  data: any | undefined;
  senderId?: number | undefined;
}

export interface ModifiyWAMMetadataMessage {
  name: string;
  description?: string | undefined;
  copyright?: string | undefined;
  thumbnail?: string | undefined;
  tags?: string | undefined;
}

export interface ItemEventMessage {
  itemId: number;
  event: string;
  stateJson: string;
  parametersJson: string;
}

export interface VariableMessage {
  name: string;
  value: string;
}

export interface SetPlayerVariableMessage {
  name: string;
  value: string;
  public: boolean;
  persist: boolean;
  ttl: number | undefined;
  scope: SetPlayerVariableMessage_Scope;
}

export enum SetPlayerVariableMessage_Scope {
  ROOM = 0,
  WORLD = 1,
  UNRECOGNIZED = -1,
}

export function setPlayerVariableMessage_ScopeFromJSON(object: any): SetPlayerVariableMessage_Scope {
  switch (object) {
    case 0:
    case "ROOM":
      return SetPlayerVariableMessage_Scope.ROOM;
    case 1:
    case "WORLD":
      return SetPlayerVariableMessage_Scope.WORLD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SetPlayerVariableMessage_Scope.UNRECOGNIZED;
  }
}

export function setPlayerVariableMessage_ScopeToJSON(object: SetPlayerVariableMessage_Scope): string {
  switch (object) {
    case SetPlayerVariableMessage_Scope.ROOM:
      return "ROOM";
    case SetPlayerVariableMessage_Scope.WORLD:
      return "WORLD";
    case SetPlayerVariableMessage_Scope.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** A variable, along the tag describing who it is targeted at */
export interface VariableWithTagMessage {
  name: string;
  value: string;
  readableBy: string;
}

export interface PlayGlobalMessage {
  type: string;
  content: string;
  broadcastToWorld: boolean;
}

export interface QueryMessage {
  id: number;
  query?:
    | { $case: "jitsiJwtQuery"; jitsiJwtQuery: JitsiJwtQuery }
    | { $case: "joinBBBMeetingQuery"; joinBBBMeetingQuery: JoinBBBMeetingQuery }
    | { $case: "roomTagsQuery"; roomTagsQuery: RoomTagsQuery }
    | { $case: "embeddableWebsiteQuery"; embeddableWebsiteQuery: EmbeddableWebsiteQuery }
    | { $case: "roomsFromSameWorldQuery"; roomsFromSameWorldQuery: RoomsFromSameWorldQuery }
    | { $case: "sendEventQuery"; sendEventQuery: SendEventQuery }
    | undefined;
}

export interface JitsiJwtQuery {
  jitsiRoom: string;
}

export interface JoinBBBMeetingQuery {
  /** a hash of domain, instance and localMeetingId */
  meetingId: string;
  /** bbbMeeting field from the map */
  localMeetingId: string;
  /**
   * Fix me Pusher linter fails because eslint-plugin version < 3.0
   * map<string, string> userdata = 3;
   */
  meetingName: string;
}

export interface RoomTagsQuery {
}

export interface EmbeddableWebsiteQuery {
  url: string;
}

export interface RoomsFromSameWorldQuery {
}

export interface SendEventQuery {
  name: string;
  data: any | undefined;
  targetUserIds: number[];
}

export interface AnswerMessage {
  id: number;
  answer?:
    | { $case: "error"; error: ErrorMessage }
    | { $case: "jitsiJwtAnswer"; jitsiJwtAnswer: JitsiJwtAnswer }
    | { $case: "joinBBBMeetingAnswer"; joinBBBMeetingAnswer: JoinBBBMeetingAnswer }
    | { $case: "roomTagsAnswer"; roomTagsAnswer: RoomTagsAnswer }
    | { $case: "embeddableWebsiteAnswer"; embeddableWebsiteAnswer: EmbeddableWebsiteAnswer }
    | { $case: "roomsFromSameWorldAnswer"; roomsFromSameWorldAnswer: RoomsFromSameWorldAnswer }
    | { $case: "sendEventAnswer"; sendEventAnswer: SendEventAnswer }
    | undefined;
}

export interface JitsiJwtAnswer {
  jwt: string;
  url: string;
}

export interface JoinBBBMeetingAnswer {
  meetingId: string;
  clientURL: string;
}

export interface RoomTagsAnswer {
  tags: string[];
}

export interface EmbeddableWebsiteAnswer {
  url: string;
  state: boolean;
  embeddable: boolean;
  message?: string | undefined;
}

export interface RoomShortDescription {
  name: string;
  roomUrl: string;
  wamUrl?: string | undefined;
  description?: string | undefined;
  copyright?: string | undefined;
  thumbnail?: string | undefined;
  areasSearchable?: number | undefined;
  entitiesSearchable?: number | undefined;
}

export interface RoomsFromSameWorldAnswer {
  roomDescriptions: RoomShortDescription[];
}

export interface SendEventAnswer {
}

export interface UserMovedMessage {
  userId: number;
  position: PositionMessage | undefined;
}

export interface MoveToPositionMessage {
  position: PositionMessage | undefined;
}

export interface SubMessage {
  message?:
    | { $case: "userMovedMessage"; userMovedMessage: UserMovedMessage }
    | { $case: "groupUpdateMessage"; groupUpdateMessage: GroupUpdateMessage }
    | { $case: "groupDeleteMessage"; groupDeleteMessage: GroupDeleteMessage }
    | { $case: "userJoinedMessage"; userJoinedMessage: UserJoinedMessage }
    | { $case: "userLeftMessage"; userLeftMessage: UserLeftMessage }
    | { $case: "itemEventMessage"; itemEventMessage: ItemEventMessage }
    | { $case: "emoteEventMessage"; emoteEventMessage: EmoteEventMessage }
    | { $case: "variableMessage"; variableMessage: VariableMessage }
    | { $case: "errorMessage"; errorMessage: ErrorMessage }
    | { $case: "playerDetailsUpdatedMessage"; playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage }
    | { $case: "pingMessage"; pingMessage: PingMessage }
    | { $case: "editMapCommandMessage"; editMapCommandMessage: EditMapCommandMessage }
    | { $case: "joinMucRoomMessage"; joinMucRoomMessage: JoinMucRoomMessage }
    | { $case: "leaveMucRoomMessage"; leaveMucRoomMessage: LeaveMucRoomMessage }
    | { $case: "addSpaceUserMessage"; addSpaceUserMessage: AddSpaceUserMessage }
    | { $case: "updateSpaceUserMessage"; updateSpaceUserMessage: UpdateSpaceUserMessage }
    | { $case: "removeSpaceUserMessage"; removeSpaceUserMessage: RemoveSpaceUserMessage }
    | { $case: "megaphoneSettingsMessage"; megaphoneSettingsMessage: MegaphoneSettings }
    | { $case: "receivedEventMessage"; receivedEventMessage: ReceivedEventMessage }
    | { $case: "updateSpaceMetadataMessage"; updateSpaceMetadataMessage: UpdateSpaceMetadataMessage }
    | { $case: "kickOffMessage"; kickOffMessage: KickOffMessage }
    | { $case: "muteMicrophoneMessage"; muteMicrophoneMessage: MuteMicrophoneMessage }
    | { $case: "muteVideoMessage"; muteVideoMessage: MuteVideoMessage }
    | { $case: "muteMicrophoneEverybodyMessage"; muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage }
    | { $case: "muteVideoEverybodyMessage"; muteVideoEverybodyMessage: MuteVideoEverybodyMessage }
    | { $case: "askMuteMicrophoneMessage"; askMuteMicrophoneMessage: AskMuteMicrophoneMessage }
    | { $case: "askMuteVideoMessage"; askMuteVideoMessage: AskMuteVideoMessage }
    | undefined;
}

export interface BatchMessage {
  /** FIXME: event seems to be useless */
  event: string;
  payload: SubMessage[];
}

export interface GroupUpdateMessage {
  groupId: number;
  position: PointMessage | undefined;
  groupSize: number | undefined;
  locked: boolean | undefined;
}

export interface GroupDeleteMessage {
  groupId: number;
}

export interface UserJoinedMessage {
  userId: number;
  userJid: string;
  name: string;
  characterTextures: CharacterTextureMessage[];
  position: PositionMessage | undefined;
  companionTexture: CompanionTextureMessage | undefined;
  visitCardUrl: string;
  userUuid: string;
  outlineColor: number;
  hasOutline: boolean;
  availabilityStatus: AvailabilityStatus;
  variables: { [key: string]: string };
}

export interface UserJoinedMessage_VariablesEntry {
  key: string;
  value: string;
}

export interface UserLeftMessage {
  userId: number;
}

/** ErrorMessage is only used to console.error the message in the front */
export interface ErrorMessage {
  message: string;
}

/** ErrorScreenMessage is used to show the ErrorScreen in the front */
export interface ErrorScreenMessage {
  type: string;
  code: string | undefined;
  title: string | undefined;
  subtitle: string | undefined;
  details: string | undefined;
  timeToRetry: number | undefined;
  canRetryManual: boolean | undefined;
  urlToRedirect: string | undefined;
  buttonTitle: string | undefined;
  image: string | undefined;
}

export interface ItemStateMessage {
  itemId: number;
  stateJson: string;
}

export interface GroupUsersUpdateMessage {
  groupId: number;
  userIds: number[];
}

export interface RoomJoinedMessage {
  /**
   * repeated UserJoinedMessage user = 1;
   * repeated GroupUpdateMessage group = 2;
   */
  item: ItemStateMessage[];
  currentUserId: number;
  tag: string[];
  variable: VariableMessage[];
  userRoomToken: string;
  /** We send the current skin of the current player. */
  characterTextures: CharacterTextureMessage[];
  activatedInviteUser: boolean;
  playerVariable: VariableMessage[];
  applications: ApplicationMessage[];
  userJid: string;
  editMapCommandsArrayMessage: EditMapCommandsArrayMessage | undefined;
  webrtcUserName: string;
  webrtcPassword: string;
  canEdit: boolean;
  megaphoneSettings: MegaphoneSettings | undefined;
  companionTexture: CompanionTextureMessage | undefined;
}

export interface MegaphoneSettings {
  enabled: boolean;
  url?: string | undefined;
}

export interface WebRtcStartMessage {
  userId: number;
  initiator: boolean;
  webrtcUserName: string;
  webrtcPassword: string;
}

export interface WebRtcDisconnectMessage {
  userId: number;
}

export interface WebRtcSignalToClientMessage {
  userId: number;
  signal: string;
  webrtcUserName: string;
  webrtcPassword: string;
}

export interface TeleportMessageMessage {
  map: string;
}

export interface SendUserMessage {
  type: string;
  message: string;
}

export interface WorldFullWarningMessage {
}

export interface WorldFullWarningToRoomMessage {
  roomId: string;
}

export interface RefreshRoomPromptMessage {
  roomId: string;
}

export interface RefreshRoomMessage {
  roomId: string;
  versionNumber: number;
  timeToRefresh?: number | undefined;
}

export interface WorldFullMessage {
}

export interface TokenExpiredMessage {
}

export interface InvalidCharacterTextureMessage {
  message: string;
}

export interface InvalidCompanionTextureMessage {
  message: string;
}

export interface WorldConnectionMessage {
  message: string;
}

export interface BanUserMessage {
  type: string;
  message: string;
}

export interface AskPositionMessage {
  userIdentifier: string;
  playUri: string;
}

export interface CameraStateMessage {
  value: boolean;
}

export interface MicrophoneStateMessage {
  value: boolean;
}

export interface ScreenSharingStateMessage {
  value: boolean;
}

export interface MegaphoneStateMessage {
  value: boolean;
  spaceName?: string | undefined;
}

export interface JitsiParticipantIdSpaceMessage {
  spaceName: string;
  value: string;
}

export interface MegaphoneEnabledMessage {
  value: boolean;
}

export interface MuteParticipantIdMessage {
  spaceName: string;
  mutedUserUuid: string;
}

export interface MuteEveryBodyParticipantMessage {
  spaceName: string;
  senderUserId: string;
}

export interface MutedMessage {
  userUuid: string;
  message: string;
}

export interface MuteVideoParticipantIdMessage {
  spaceName: string;
  mutedUserUuid: string;
}

export interface MuteVideoEveryBodyParticipantMessage {
  spaceName: string;
  userId: string;
}

export interface MutedVideoMessage {
  userUuid: string;
  message: string;
}

export interface AskMutedMessage {
  userUuid: string;
  message: string;
}

export interface AskMutedVideoMessage {
  userUuid: string;
  message: string;
}

/** Messages going from back and pusher to the front */
export interface ServerToClientMessage {
  message?:
    | { $case: "batchMessage"; batchMessage: BatchMessage }
    | { $case: "errorMessage"; errorMessage: ErrorMessage }
    | { $case: "roomJoinedMessage"; roomJoinedMessage: RoomJoinedMessage }
    | { $case: "webRtcStartMessage"; webRtcStartMessage: WebRtcStartMessage }
    | { $case: "webRtcSignalToClientMessage"; webRtcSignalToClientMessage: WebRtcSignalToClientMessage }
    | {
      $case: "webRtcScreenSharingSignalToClientMessage";
      webRtcScreenSharingSignalToClientMessage: WebRtcSignalToClientMessage;
    }
    | { $case: "webRtcDisconnectMessage"; webRtcDisconnectMessage: WebRtcDisconnectMessage }
    | { $case: "teleportMessageMessage"; teleportMessageMessage: TeleportMessageMessage }
    | { $case: "sendUserMessage"; sendUserMessage: SendUserMessage }
    | { $case: "banUserMessage"; banUserMessage: BanUserMessage }
    | { $case: "worldFullWarningMessage"; worldFullWarningMessage: WorldFullWarningMessage }
    | { $case: "worldFullMessage"; worldFullMessage: WorldFullMessage }
    | { $case: "refreshRoomMessage"; refreshRoomMessage: RefreshRoomMessage }
    | { $case: "worldConnectionMessage"; worldConnectionMessage: WorldConnectionMessage }
    | { $case: "tokenExpiredMessage"; tokenExpiredMessage: TokenExpiredMessage }
    | { $case: "followRequestMessage"; followRequestMessage: FollowRequestMessage }
    | { $case: "followConfirmationMessage"; followConfirmationMessage: FollowConfirmationMessage }
    | { $case: "followAbortMessage"; followAbortMessage: FollowAbortMessage }
    | { $case: "invalidCharacterTextureMessage"; invalidCharacterTextureMessage: InvalidCharacterTextureMessage }
    | { $case: "groupUsersUpdateMessage"; groupUsersUpdateMessage: GroupUsersUpdateMessage }
    | { $case: "errorScreenMessage"; errorScreenMessage: ErrorScreenMessage }
    | { $case: "answerMessage"; answerMessage: AnswerMessage }
    | { $case: "xmppSettingsMessage"; xmppSettingsMessage: XmppSettingsMessage }
    | { $case: "moveToPositionMessage"; moveToPositionMessage: MoveToPositionMessage }
    | { $case: "invalidCompanionTextureMessage"; invalidCompanionTextureMessage: InvalidCompanionTextureMessage }
    | { $case: "mutedMessage"; mutedMessage: MutedMessage }
    | { $case: "mutedVideoMessage"; mutedVideoMessage: MutedVideoMessage }
    | { $case: "askMutedMessage"; askMutedMessage: AskMutedMessage }
    | { $case: "askMutedVideoMessage"; askMutedVideoMessage: AskMutedVideoMessage }
    | undefined;
}

export interface JoinRoomMessage {
  positionMessage: PositionMessage | undefined;
  name: string;
  characterTextures: CharacterTextureMessage[];
  userUuid: string;
  roomId: string;
  tag: string[];
  IPAddress: string;
  companionTexture: CompanionTextureMessage | undefined;
  visitCardUrl: string | undefined;
  userRoomToken: string;
  availabilityStatus: AvailabilityStatus;
  activatedInviteUser: boolean;
  isLogged: boolean;
  applications: ApplicationMessage[];
  userJid: string;
  lastCommandId: string | undefined;
  canEdit: boolean;
}

export interface UserJoinedZoneMessage {
  userId: number;
  userJid: string;
  name: string;
  characterTextures: CharacterTextureMessage[];
  position: PositionMessage | undefined;
  fromZone: Zone | undefined;
  companionTexture: CompanionTextureMessage | undefined;
  visitCardUrl: string;
  userUuid: string;
  outlineColor: number;
  hasOutline: boolean;
  availabilityStatus: AvailabilityStatus;
  variables: { [key: string]: string };
}

export interface UserJoinedZoneMessage_VariablesEntry {
  key: string;
  value: string;
}

export interface UserLeftZoneMessage {
  userId: number;
  toZone: Zone | undefined;
}

export interface GroupUpdateZoneMessage {
  groupId: number;
  position: PointMessage | undefined;
  groupSize: number;
  fromZone: Zone | undefined;
  locked: boolean;
}

export interface GroupLeftZoneMessage {
  groupId: number;
  toZone: Zone | undefined;
}

export interface PlayerDetailsUpdatedMessage {
  userId: number;
  details: SetPlayerDetailsMessage | undefined;
}

export interface Zone {
  x: number;
  y: number;
}

export interface ZoneMessage {
  roomId: string;
  x: number;
  y: number;
}

export interface RoomMessage {
  roomId: string;
}

export interface PusherToBackMessage {
  message?:
    | { $case: "joinRoomMessage"; joinRoomMessage: JoinRoomMessage }
    | { $case: "userMovesMessage"; userMovesMessage: UserMovesMessage }
    | { $case: "itemEventMessage"; itemEventMessage: ItemEventMessage }
    | { $case: "setPlayerDetailsMessage"; setPlayerDetailsMessage: SetPlayerDetailsMessage }
    | { $case: "webRtcSignalToServerMessage"; webRtcSignalToServerMessage: WebRtcSignalToServerMessage }
    | {
      $case: "webRtcScreenSharingSignalToServerMessage";
      webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage;
    }
    | { $case: "sendUserMessage"; sendUserMessage: SendUserMessage }
    | { $case: "banUserMessage"; banUserMessage: BanUserMessage }
    | { $case: "emotePromptMessage"; emotePromptMessage: EmotePromptMessage }
    | { $case: "variableMessage"; variableMessage: VariableMessage }
    | { $case: "followRequestMessage"; followRequestMessage: FollowRequestMessage }
    | { $case: "followConfirmationMessage"; followConfirmationMessage: FollowConfirmationMessage }
    | { $case: "followAbortMessage"; followAbortMessage: FollowAbortMessage }
    | { $case: "lockGroupPromptMessage"; lockGroupPromptMessage: LockGroupPromptMessage }
    | { $case: "queryMessage"; queryMessage: QueryMessage }
    | { $case: "askPositionMessage"; askPositionMessage: AskPositionMessage }
    | { $case: "editMapCommandMessage"; editMapCommandMessage: EditMapCommandMessage }
    | { $case: "pingMessage"; pingMessage: PingMessage }
    | { $case: "kickOffUserMessage"; kickOffUserMessage: KickOffUserMessage }
    | { $case: "muteParticipantIdMessage"; muteParticipantIdMessage: MuteParticipantIdMessage }
    | { $case: "muteEveryBodyParticipantMessage"; muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage }
    | { $case: "muteVideoParticipantIdMessage"; muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage }
    | {
      $case: "muteVideoEveryBodyParticipantMessage";
      muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage;
    }
    | undefined;
}

export interface BatchToPusherMessage {
  payload: SubToPusherMessage[];
}

export interface SubToPusherMessage {
  message?:
    | { $case: "userJoinedZoneMessage"; userJoinedZoneMessage: UserJoinedZoneMessage }
    | { $case: "groupUpdateZoneMessage"; groupUpdateZoneMessage: GroupUpdateZoneMessage }
    | { $case: "userMovedMessage"; userMovedMessage: UserMovedMessage }
    | { $case: "groupLeftZoneMessage"; groupLeftZoneMessage: GroupLeftZoneMessage }
    | { $case: "userLeftZoneMessage"; userLeftZoneMessage: UserLeftZoneMessage }
    | { $case: "itemEventMessage"; itemEventMessage: ItemEventMessage }
    | { $case: "sendUserMessage"; sendUserMessage: SendUserMessage }
    | { $case: "banUserMessage"; banUserMessage: BanUserMessage }
    | { $case: "emoteEventMessage"; emoteEventMessage: EmoteEventMessage }
    | { $case: "errorMessage"; errorMessage: ErrorMessage }
    | { $case: "playerDetailsUpdatedMessage"; playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage }
    | { $case: "editMapCommandMessage"; editMapCommandMessage: EditMapCommandMessage }
    | undefined;
}

export interface BatchToPusherRoomMessage {
  payload: SubToPusherRoomMessage[];
}

export interface SubToPusherRoomMessage {
  message?:
    | { $case: "variableMessage"; variableMessage: VariableWithTagMessage }
    | { $case: "errorMessage"; errorMessage: ErrorMessage }
    | { $case: "editMapCommandMessage"; editMapCommandMessage: EditMapCommandMessage }
    | { $case: "joinMucRoomMessage"; joinMucRoomMessage: JoinMucRoomMessage }
    | { $case: "leaveMucRoomMessage"; leaveMucRoomMessage: LeaveMucRoomMessage }
    | { $case: "receivedEventMessage"; receivedEventMessage: ReceivedEventMessage }
    | undefined;
}

export interface UserJoinedRoomMessage {
  uuid: string;
  ipAddress: string;
  name: string;
}

export interface UserLeftRoomMessage {
  uuid: string;
}

export interface ServerToAdminClientMessage {
  message?:
    | { $case: "userJoinedRoom"; userJoinedRoom: UserJoinedRoomMessage }
    | { $case: "userLeftRoom"; userLeftRoom: UserLeftRoomMessage }
    | { $case: "errorMessage"; errorMessage: ErrorMessage }
    | undefined;
}

export interface AdminPusherToBackMessage {
  message?: { $case: "subscribeToRoom"; subscribeToRoom: string } | undefined;
}

export interface MapStorageRefreshMessage {
  comment: string;
}

export interface MapStorageToBackMessage {
  message?: { $case: "mapStorageRefreshMessage"; mapStorageRefreshMessage: MapStorageRefreshMessage } | undefined;
}

export interface MapStorageUrlMessage {
  mapUrl: string;
  roomId: string;
}

export interface MapStorageClearAfterUploadMessage {
  wamUrl: string;
}

/** A message sent by an administrator to a recipient */
export interface AdminMessage {
  message: string;
  recipientUuid: string;
  roomId: string;
  type: string;
}

/** A message sent by an administrator to everyone in a specific room */
export interface AdminRoomMessage {
  message: string;
  roomId: string;
  type: string;
}

/** A message sent by an administrator to absolutely everybody */
export interface AdminGlobalMessage {
  message: string;
}

export interface BanMessage {
  recipientUuid: string;
  roomId: string;
  type: string;
  message: string;
}

export interface RoomDescription {
  roomId: string;
  nbUsers: number;
}

export interface RoomsList {
  roomDescription: RoomDescription[];
}

export interface DispatchGlobalEventRequest {
  name: string;
  value: any | undefined;
}

/**
 * ******************************************************************************
 * Start Chat Messages
 * ******************************************************************************
 */
export interface ChatMessagePrompt {
  roomId: string;
  message?: { $case: "joinMucRoomMessage"; joinMucRoomMessage: JoinMucRoomMessage } | {
    $case: "leaveMucRoomMessage";
    leaveMucRoomMessage: LeaveMucRoomMessage;
  } | undefined;
}

export interface JoinMucRoomMessage {
  mucRoomDefinitionMessage: MucRoomDefinitionMessage | undefined;
}

export interface LeaveMucRoomMessage {
  url: string;
}

export interface MucRoomDefinitionMessage {
  url: string;
  name: string;
  type: string;
  subscribe: boolean;
}

export interface XmppSettingsMessage {
  conferenceDomain: string;
  rooms: MucRoomDefinitionMessage[];
  jabberId: string;
  jabberPassword: string;
}

/**
 * ******************************************************************************
 * Start Spaces messages
 * ******************************************************************************
 */
export interface PusherToBackSpaceMessage {
  message?:
    | { $case: "watchSpaceMessage"; watchSpaceMessage: WatchSpaceMessage }
    | { $case: "unwatchSpaceMessage"; unwatchSpaceMessage: UnwatchSpaceMessage }
    | { $case: "addSpaceUserMessage"; addSpaceUserMessage: AddSpaceUserMessage }
    | { $case: "updateSpaceUserMessage"; updateSpaceUserMessage: UpdateSpaceUserMessage }
    | { $case: "removeSpaceUserMessage"; removeSpaceUserMessage: RemoveSpaceUserMessage }
    | { $case: "pongMessage"; pongMessage: PingMessage }
    | { $case: "updateSpaceMetadataMessage"; updateSpaceMetadataMessage: UpdateSpaceMetadataMessage }
    | { $case: "kickOffMessage"; kickOffMessage: KickOffMessage }
    | { $case: "muteMicrophoneMessage"; muteMicrophoneMessage: MuteMicrophoneMessage }
    | { $case: "muteVideoMessage"; muteVideoMessage: MuteVideoMessage }
    | { $case: "muteMicrophoneEverybodyMessage"; muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage }
    | { $case: "muteVideoEverybodyMessage"; muteVideoEverybodyMessage: MuteVideoEverybodyMessage }
    | { $case: "askMuteMicrophoneMessage"; askMuteMicrophoneMessage: AskMuteMicrophoneMessage }
    | { $case: "askMuteVideoMessage"; askMuteVideoMessage: AskMuteVideoMessage }
    | undefined;
}

export interface BackToPusherSpaceMessage {
  message?:
    | { $case: "addSpaceUserMessage"; addSpaceUserMessage: AddSpaceUserMessage }
    | { $case: "updateSpaceUserMessage"; updateSpaceUserMessage: UpdateSpaceUserMessage }
    | { $case: "removeSpaceUserMessage"; removeSpaceUserMessage: RemoveSpaceUserMessage }
    | { $case: "pingMessage"; pingMessage: PingMessage }
    | { $case: "updateSpaceMetadataMessage"; updateSpaceMetadataMessage: UpdateSpaceMetadataMessage }
    | { $case: "kickOffMessage"; kickOffMessage: KickOffMessage }
    | { $case: "muteMicrophoneMessage"; muteMicrophoneMessage: MuteMicrophoneMessage }
    | { $case: "muteVideoMessage"; muteVideoMessage: MuteVideoMessage }
    | { $case: "muteMicrophoneEverybodyMessage"; muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage }
    | { $case: "muteVideoEverybodyMessage"; muteVideoEverybodyMessage: MuteVideoEverybodyMessage }
    | { $case: "askMuteMicrophoneMessage"; askMuteMicrophoneMessage: AskMuteMicrophoneMessage }
    | { $case: "askMuteVideoMessage"; askMuteVideoMessage: AskMuteVideoMessage }
    | undefined;
}

export interface WatchSpaceMessage {
  spaceName: string;
  user: SpaceUser | undefined;
  spaceFilter: SpaceFilterMessage | undefined;
}

export interface UnwatchSpaceMessage {
  spaceName: string;
  userId: number;
}

export interface SpaceUser {
  /** used */
  id: number;
  /** used */
  name: string;
  playUri: string;
  /** TO DELETE */
  color: string;
  characterTextures: CharacterTextureMessage[];
  isLogged: boolean;
  availabilityStatus: number;
  roomName: string | undefined;
  visitCardUrl: string | undefined;
  tags: string[];
  /** used */
  cameraState: boolean;
  /** used */
  microphoneState: boolean;
  /** used */
  screenSharingState: boolean;
  /** used */
  megaphoneState: boolean;
  /** used */
  jitsiParticipantId:
    | string
    | undefined;
  /** used */
  uuid: string;
}

export interface PartialSpaceUser {
  id: number;
  name: string | undefined;
  playUri: string | undefined;
  color: string | undefined;
  characterTextures: CharacterTextureMessage[];
  isLogged: boolean | undefined;
  availabilityStatus: number | undefined;
  roomName: string | undefined;
  visitCardUrl: string | undefined;
  tags: string[];
  cameraState: boolean | undefined;
  microphoneState: boolean | undefined;
  screenSharingState: boolean | undefined;
  megaphoneState: boolean | undefined;
  jitsiParticipantId: string | undefined;
  uuid: string;
}

export interface AddSpaceUserMessage {
  spaceName: string;
  user: SpaceUser | undefined;
  filterName: string | undefined;
}

export interface UpdateSpaceUserMessage {
  spaceName: string;
  user: PartialSpaceUser | undefined;
  filterName: string | undefined;
}

export interface RemoveSpaceUserMessage {
  spaceName: string;
  userId: number;
  filterName: string | undefined;
}

export interface UpdateSpaceMetadataMessage {
  spaceName: string;
  metadata: string;
  filterName: string | undefined;
}

export interface KickOffMessage {
  spaceName: string;
  userId: string;
  filterName: string | undefined;
}

export interface MuteMicrophoneMessage {
  spaceName: string;
  userId: string;
  filterName: string | undefined;
}

export interface MuteVideoMessage {
  spaceName: string;
  userId: string;
  filterName: string | undefined;
}

export interface MuteMicrophoneEverybodyMessage {
  spaceName: string;
  userId: string;
  filterName: string | undefined;
}

export interface MuteVideoEverybodyMessage {
  spaceName: string;
  userId: string;
  filterName: string | undefined;
}

export interface AskMuteMicrophoneMessage {
  spaceName: string;
  userId: string;
  filterName: string | undefined;
}

export interface AskMuteVideoMessage {
  spaceName: string;
  userId: string;
  filterName: string | undefined;
}

/** Filter * */
export interface AddSpaceFilterMessage {
  spaceFilterMessage: SpaceFilterMessage | undefined;
}

export interface UpdateSpaceFilterMessage {
  spaceFilterMessage: SpaceFilterMessage | undefined;
}

export interface RemoveSpaceFilterMessage {
  spaceFilterMessage: SpaceFilterMessage | undefined;
}

export interface SpaceFilterMessage {
  filterName: string;
  spaceName: string;
  filter?:
    | { $case: "spaceFilterEverybody"; spaceFilterEverybody: SpaceFilterEverybody }
    | { $case: "spaceFilterContainName"; spaceFilterContainName: SpaceFilterContainName }
    | { $case: "spaceFilterLiveStreaming"; spaceFilterLiveStreaming: SpaceFilterLiveStreaming }
    | undefined;
}

export interface SpaceFilterContainName {
  value: string;
}

export interface SpaceFilterLiveStreaming {
}

export interface SpaceFilterEverybody {
}

export interface VariableRequest {
  room: string;
  name: string;
}

export interface SaveVariableRequest {
  room: string;
  name: string;
  value: any | undefined;
}

export interface EventRequest {
  room: string;
  name: string;
}

export interface EventResponse {
  data: any | undefined;
  senderId?: number | undefined;
}

export interface DispatchEventRequest {
  room: string;
  name: string;
  data:
    | any
    | undefined;
  /** If targetUserIds is empty, the event is broadcast to all users in the room. Otherwise, it targets the users with the given ids. */
  targetUserIds: number[];
}

function createBasePositionMessage(): PositionMessage {
  return { x: 0, y: 0, direction: 0, moving: false };
}

export const PositionMessage = {
  encode(message: PositionMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(8).int32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(16).int32(message.y);
    }
    if (message.direction !== 0) {
      writer.uint32(24).int32(message.direction);
    }
    if (message.moving === true) {
      writer.uint32(32).bool(message.moving);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.y = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.moving = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionMessage {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      direction: isSet(object.direction) ? positionMessage_DirectionFromJSON(object.direction) : 0,
      moving: isSet(object.moving) ? globalThis.Boolean(object.moving) : false,
    };
  },

  toJSON(message: PositionMessage): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    if (message.direction !== 0) {
      obj.direction = positionMessage_DirectionToJSON(message.direction);
    }
    if (message.moving === true) {
      obj.moving = message.moving;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionMessage>, I>>(base?: I): PositionMessage {
    return PositionMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionMessage>, I>>(object: I): PositionMessage {
    const message = createBasePositionMessage();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.direction = object.direction ?? 0;
    message.moving = object.moving ?? false;
    return message;
  },
};

function createBasePointMessage(): PointMessage {
  return { x: 0, y: 0 };
}

export const PointMessage = {
  encode(message: PointMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(8).int32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(16).int32(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PointMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePointMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.y = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PointMessage {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: PointMessage): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PointMessage>, I>>(base?: I): PointMessage {
    return PointMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PointMessage>, I>>(object: I): PointMessage {
    const message = createBasePointMessage();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseViewportMessage(): ViewportMessage {
  return { left: 0, top: 0, right: 0, bottom: 0 };
}

export const ViewportMessage = {
  encode(message: ViewportMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.left !== 0) {
      writer.uint32(8).int32(message.left);
    }
    if (message.top !== 0) {
      writer.uint32(16).int32(message.top);
    }
    if (message.right !== 0) {
      writer.uint32(24).int32(message.right);
    }
    if (message.bottom !== 0) {
      writer.uint32(32).int32(message.bottom);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ViewportMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseViewportMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.left = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.top = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.right = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bottom = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ViewportMessage {
    return {
      left: isSet(object.left) ? globalThis.Number(object.left) : 0,
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
      right: isSet(object.right) ? globalThis.Number(object.right) : 0,
      bottom: isSet(object.bottom) ? globalThis.Number(object.bottom) : 0,
    };
  },

  toJSON(message: ViewportMessage): unknown {
    const obj: any = {};
    if (message.left !== 0) {
      obj.left = Math.round(message.left);
    }
    if (message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    if (message.right !== 0) {
      obj.right = Math.round(message.right);
    }
    if (message.bottom !== 0) {
      obj.bottom = Math.round(message.bottom);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ViewportMessage>, I>>(base?: I): ViewportMessage {
    return ViewportMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ViewportMessage>, I>>(object: I): ViewportMessage {
    const message = createBaseViewportMessage();
    message.left = object.left ?? 0;
    message.top = object.top ?? 0;
    message.right = object.right ?? 0;
    message.bottom = object.bottom ?? 0;
    return message;
  },
};

function createBaseCharacterTextureMessage(): CharacterTextureMessage {
  return { url: "", id: "" };
}

export const CharacterTextureMessage = {
  encode(message: CharacterTextureMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CharacterTextureMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCharacterTextureMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CharacterTextureMessage {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: CharacterTextureMessage): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CharacterTextureMessage>, I>>(base?: I): CharacterTextureMessage {
    return CharacterTextureMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CharacterTextureMessage>, I>>(object: I): CharacterTextureMessage {
    const message = createBaseCharacterTextureMessage();
    message.url = object.url ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseApplicationMessage(): ApplicationMessage {
  return { name: "", script: "" };
}

export const ApplicationMessage = {
  encode(message: ApplicationMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.script !== "") {
      writer.uint32(18).string(message.script);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicationMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicationMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.script = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicationMessage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      script: isSet(object.script) ? globalThis.String(object.script) : "",
    };
  },

  toJSON(message: ApplicationMessage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.script !== "") {
      obj.script = message.script;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicationMessage>, I>>(base?: I): ApplicationMessage {
    return ApplicationMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplicationMessage>, I>>(object: I): ApplicationMessage {
    const message = createBaseApplicationMessage();
    message.name = object.name ?? "";
    message.script = object.script ?? "";
    return message;
  },
};

function createBaseCompanionTextureMessage(): CompanionTextureMessage {
  return { url: "", id: "" };
}

export const CompanionTextureMessage = {
  encode(message: CompanionTextureMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompanionTextureMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompanionTextureMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompanionTextureMessage {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: CompanionTextureMessage): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompanionTextureMessage>, I>>(base?: I): CompanionTextureMessage {
    return CompanionTextureMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompanionTextureMessage>, I>>(object: I): CompanionTextureMessage {
    const message = createBaseCompanionTextureMessage();
    message.url = object.url ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

function createBasePingMessage(): PingMessage {
  return {};
}

export const PingMessage = {
  encode(_: PingMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PingMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingMessage {
    return {};
  },

  toJSON(_: PingMessage): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PingMessage>, I>>(base?: I): PingMessage {
    return PingMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PingMessage>, I>>(_: I): PingMessage {
    const message = createBasePingMessage();
    return message;
  },
};

function createBaseUpdateMapToNewestMessage(): UpdateMapToNewestMessage {
  return { commandId: undefined };
}

export const UpdateMapToNewestMessage = {
  encode(message: UpdateMapToNewestMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.commandId !== undefined) {
      writer.uint32(10).string(message.commandId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMapToNewestMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMapToNewestMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.commandId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMapToNewestMessage {
    return { commandId: isSet(object.commandId) ? globalThis.String(object.commandId) : undefined };
  },

  toJSON(message: UpdateMapToNewestMessage): unknown {
    const obj: any = {};
    if (message.commandId !== undefined) {
      obj.commandId = message.commandId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMapToNewestMessage>, I>>(base?: I): UpdateMapToNewestMessage {
    return UpdateMapToNewestMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMapToNewestMessage>, I>>(object: I): UpdateMapToNewestMessage {
    const message = createBaseUpdateMapToNewestMessage();
    message.commandId = object.commandId ?? undefined;
    return message;
  },
};

function createBaseUpdateMapToNewestWithKeyMessage(): UpdateMapToNewestWithKeyMessage {
  return { mapKey: "", updateMapToNewestMessage: undefined };
}

export const UpdateMapToNewestWithKeyMessage = {
  encode(message: UpdateMapToNewestWithKeyMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mapKey !== "") {
      writer.uint32(10).string(message.mapKey);
    }
    if (message.updateMapToNewestMessage !== undefined) {
      UpdateMapToNewestMessage.encode(message.updateMapToNewestMessage, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMapToNewestWithKeyMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMapToNewestWithKeyMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mapKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updateMapToNewestMessage = UpdateMapToNewestMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMapToNewestWithKeyMessage {
    return {
      mapKey: isSet(object.mapKey) ? globalThis.String(object.mapKey) : "",
      updateMapToNewestMessage: isSet(object.updateMapToNewestMessage)
        ? UpdateMapToNewestMessage.fromJSON(object.updateMapToNewestMessage)
        : undefined,
    };
  },

  toJSON(message: UpdateMapToNewestWithKeyMessage): unknown {
    const obj: any = {};
    if (message.mapKey !== "") {
      obj.mapKey = message.mapKey;
    }
    if (message.updateMapToNewestMessage !== undefined) {
      obj.updateMapToNewestMessage = UpdateMapToNewestMessage.toJSON(message.updateMapToNewestMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMapToNewestWithKeyMessage>, I>>(base?: I): UpdateMapToNewestWithKeyMessage {
    return UpdateMapToNewestWithKeyMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMapToNewestWithKeyMessage>, I>>(
    object: I,
  ): UpdateMapToNewestWithKeyMessage {
    const message = createBaseUpdateMapToNewestWithKeyMessage();
    message.mapKey = object.mapKey ?? "";
    message.updateMapToNewestMessage =
      (object.updateMapToNewestMessage !== undefined && object.updateMapToNewestMessage !== null)
        ? UpdateMapToNewestMessage.fromPartial(object.updateMapToNewestMessage)
        : undefined;
    return message;
  },
};

function createBaseSetPlayerDetailsMessage(): SetPlayerDetailsMessage {
  return {
    outlineColor: undefined,
    removeOutlineColor: undefined,
    showVoiceIndicator: undefined,
    availabilityStatus: 0,
    setVariable: undefined,
  };
}

export const SetPlayerDetailsMessage = {
  encode(message: SetPlayerDetailsMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.outlineColor !== undefined) {
      UInt32Value.encode({ value: message.outlineColor! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.removeOutlineColor !== undefined) {
      BoolValue.encode({ value: message.removeOutlineColor! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.showVoiceIndicator !== undefined) {
      BoolValue.encode({ value: message.showVoiceIndicator! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.availabilityStatus !== 0) {
      writer.uint32(48).int32(message.availabilityStatus);
    }
    if (message.setVariable !== undefined) {
      SetPlayerVariableMessage.encode(message.setVariable, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPlayerDetailsMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPlayerDetailsMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.outlineColor = UInt32Value.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.removeOutlineColor = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.showVoiceIndicator = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.availabilityStatus = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.setVariable = SetPlayerVariableMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPlayerDetailsMessage {
    return {
      outlineColor: isSet(object.outlineColor) ? Number(object.outlineColor) : undefined,
      removeOutlineColor: isSet(object.removeOutlineColor) ? Boolean(object.removeOutlineColor) : undefined,
      showVoiceIndicator: isSet(object.showVoiceIndicator) ? Boolean(object.showVoiceIndicator) : undefined,
      availabilityStatus: isSet(object.availabilityStatus) ? availabilityStatusFromJSON(object.availabilityStatus) : 0,
      setVariable: isSet(object.setVariable) ? SetPlayerVariableMessage.fromJSON(object.setVariable) : undefined,
    };
  },

  toJSON(message: SetPlayerDetailsMessage): unknown {
    const obj: any = {};
    if (message.outlineColor !== undefined) {
      obj.outlineColor = message.outlineColor;
    }
    if (message.removeOutlineColor !== undefined) {
      obj.removeOutlineColor = message.removeOutlineColor;
    }
    if (message.showVoiceIndicator !== undefined) {
      obj.showVoiceIndicator = message.showVoiceIndicator;
    }
    if (message.availabilityStatus !== 0) {
      obj.availabilityStatus = availabilityStatusToJSON(message.availabilityStatus);
    }
    if (message.setVariable !== undefined) {
      obj.setVariable = SetPlayerVariableMessage.toJSON(message.setVariable);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetPlayerDetailsMessage>, I>>(base?: I): SetPlayerDetailsMessage {
    return SetPlayerDetailsMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetPlayerDetailsMessage>, I>>(object: I): SetPlayerDetailsMessage {
    const message = createBaseSetPlayerDetailsMessage();
    message.outlineColor = object.outlineColor ?? undefined;
    message.removeOutlineColor = object.removeOutlineColor ?? undefined;
    message.showVoiceIndicator = object.showVoiceIndicator ?? undefined;
    message.availabilityStatus = object.availabilityStatus ?? 0;
    message.setVariable = (object.setVariable !== undefined && object.setVariable !== null)
      ? SetPlayerVariableMessage.fromPartial(object.setVariable)
      : undefined;
    return message;
  },
};

function createBaseUserMovesMessage(): UserMovesMessage {
  return { position: undefined, viewport: undefined };
}

export const UserMovesMessage = {
  encode(message: UserMovesMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.position !== undefined) {
      PositionMessage.encode(message.position, writer.uint32(10).fork()).ldelim();
    }
    if (message.viewport !== undefined) {
      ViewportMessage.encode(message.viewport, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMovesMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMovesMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.position = PositionMessage.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.viewport = ViewportMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMovesMessage {
    return {
      position: isSet(object.position) ? PositionMessage.fromJSON(object.position) : undefined,
      viewport: isSet(object.viewport) ? ViewportMessage.fromJSON(object.viewport) : undefined,
    };
  },

  toJSON(message: UserMovesMessage): unknown {
    const obj: any = {};
    if (message.position !== undefined) {
      obj.position = PositionMessage.toJSON(message.position);
    }
    if (message.viewport !== undefined) {
      obj.viewport = ViewportMessage.toJSON(message.viewport);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMovesMessage>, I>>(base?: I): UserMovesMessage {
    return UserMovesMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMovesMessage>, I>>(object: I): UserMovesMessage {
    const message = createBaseUserMovesMessage();
    message.position = (object.position !== undefined && object.position !== null)
      ? PositionMessage.fromPartial(object.position)
      : undefined;
    message.viewport = (object.viewport !== undefined && object.viewport !== null)
      ? ViewportMessage.fromPartial(object.viewport)
      : undefined;
    return message;
  },
};

function createBaseWebRtcSignalToServerMessage(): WebRtcSignalToServerMessage {
  return { receiverId: 0, signal: "" };
}

export const WebRtcSignalToServerMessage = {
  encode(message: WebRtcSignalToServerMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.receiverId !== 0) {
      writer.uint32(8).int32(message.receiverId);
    }
    if (message.signal !== "") {
      writer.uint32(18).string(message.signal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebRtcSignalToServerMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebRtcSignalToServerMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.receiverId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signal = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebRtcSignalToServerMessage {
    return {
      receiverId: isSet(object.receiverId) ? globalThis.Number(object.receiverId) : 0,
      signal: isSet(object.signal) ? globalThis.String(object.signal) : "",
    };
  },

  toJSON(message: WebRtcSignalToServerMessage): unknown {
    const obj: any = {};
    if (message.receiverId !== 0) {
      obj.receiverId = Math.round(message.receiverId);
    }
    if (message.signal !== "") {
      obj.signal = message.signal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebRtcSignalToServerMessage>, I>>(base?: I): WebRtcSignalToServerMessage {
    return WebRtcSignalToServerMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebRtcSignalToServerMessage>, I>>(object: I): WebRtcSignalToServerMessage {
    const message = createBaseWebRtcSignalToServerMessage();
    message.receiverId = object.receiverId ?? 0;
    message.signal = object.signal ?? "";
    return message;
  },
};

function createBaseReportPlayerMessage(): ReportPlayerMessage {
  return { reportedUserUuid: "", reportComment: "" };
}

export const ReportPlayerMessage = {
  encode(message: ReportPlayerMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reportedUserUuid !== "") {
      writer.uint32(10).string(message.reportedUserUuid);
    }
    if (message.reportComment !== "") {
      writer.uint32(18).string(message.reportComment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportPlayerMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportPlayerMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reportedUserUuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reportComment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportPlayerMessage {
    return {
      reportedUserUuid: isSet(object.reportedUserUuid) ? globalThis.String(object.reportedUserUuid) : "",
      reportComment: isSet(object.reportComment) ? globalThis.String(object.reportComment) : "",
    };
  },

  toJSON(message: ReportPlayerMessage): unknown {
    const obj: any = {};
    if (message.reportedUserUuid !== "") {
      obj.reportedUserUuid = message.reportedUserUuid;
    }
    if (message.reportComment !== "") {
      obj.reportComment = message.reportComment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportPlayerMessage>, I>>(base?: I): ReportPlayerMessage {
    return ReportPlayerMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportPlayerMessage>, I>>(object: I): ReportPlayerMessage {
    const message = createBaseReportPlayerMessage();
    message.reportedUserUuid = object.reportedUserUuid ?? "";
    message.reportComment = object.reportComment ?? "";
    return message;
  },
};

function createBaseBanPlayerMessage(): BanPlayerMessage {
  return { banUserUuid: "", banUserName: "" };
}

export const BanPlayerMessage = {
  encode(message: BanPlayerMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.banUserUuid !== "") {
      writer.uint32(10).string(message.banUserUuid);
    }
    if (message.banUserName !== "") {
      writer.uint32(18).string(message.banUserName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BanPlayerMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBanPlayerMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.banUserUuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.banUserName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BanPlayerMessage {
    return {
      banUserUuid: isSet(object.banUserUuid) ? globalThis.String(object.banUserUuid) : "",
      banUserName: isSet(object.banUserName) ? globalThis.String(object.banUserName) : "",
    };
  },

  toJSON(message: BanPlayerMessage): unknown {
    const obj: any = {};
    if (message.banUserUuid !== "") {
      obj.banUserUuid = message.banUserUuid;
    }
    if (message.banUserName !== "") {
      obj.banUserName = message.banUserName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BanPlayerMessage>, I>>(base?: I): BanPlayerMessage {
    return BanPlayerMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BanPlayerMessage>, I>>(object: I): BanPlayerMessage {
    const message = createBaseBanPlayerMessage();
    message.banUserUuid = object.banUserUuid ?? "";
    message.banUserName = object.banUserName ?? "";
    return message;
  },
};

function createBaseEmotePromptMessage(): EmotePromptMessage {
  return { emote: "" };
}

export const EmotePromptMessage = {
  encode(message: EmotePromptMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.emote !== "") {
      writer.uint32(18).string(message.emote);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmotePromptMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmotePromptMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.emote = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmotePromptMessage {
    return { emote: isSet(object.emote) ? globalThis.String(object.emote) : "" };
  },

  toJSON(message: EmotePromptMessage): unknown {
    const obj: any = {};
    if (message.emote !== "") {
      obj.emote = message.emote;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmotePromptMessage>, I>>(base?: I): EmotePromptMessage {
    return EmotePromptMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmotePromptMessage>, I>>(object: I): EmotePromptMessage {
    const message = createBaseEmotePromptMessage();
    message.emote = object.emote ?? "";
    return message;
  },
};

function createBaseEmoteEventMessage(): EmoteEventMessage {
  return { actorUserId: 0, emote: "" };
}

export const EmoteEventMessage = {
  encode(message: EmoteEventMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.actorUserId !== 0) {
      writer.uint32(8).int32(message.actorUserId);
    }
    if (message.emote !== "") {
      writer.uint32(18).string(message.emote);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmoteEventMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmoteEventMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.actorUserId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.emote = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmoteEventMessage {
    return {
      actorUserId: isSet(object.actorUserId) ? globalThis.Number(object.actorUserId) : 0,
      emote: isSet(object.emote) ? globalThis.String(object.emote) : "",
    };
  },

  toJSON(message: EmoteEventMessage): unknown {
    const obj: any = {};
    if (message.actorUserId !== 0) {
      obj.actorUserId = Math.round(message.actorUserId);
    }
    if (message.emote !== "") {
      obj.emote = message.emote;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmoteEventMessage>, I>>(base?: I): EmoteEventMessage {
    return EmoteEventMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmoteEventMessage>, I>>(object: I): EmoteEventMessage {
    const message = createBaseEmoteEventMessage();
    message.actorUserId = object.actorUserId ?? 0;
    message.emote = object.emote ?? "";
    return message;
  },
};

function createBaseFollowRequestMessage(): FollowRequestMessage {
  return { leader: 0 };
}

export const FollowRequestMessage = {
  encode(message: FollowRequestMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.leader !== 0) {
      writer.uint32(8).int32(message.leader);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FollowRequestMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFollowRequestMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.leader = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FollowRequestMessage {
    return { leader: isSet(object.leader) ? globalThis.Number(object.leader) : 0 };
  },

  toJSON(message: FollowRequestMessage): unknown {
    const obj: any = {};
    if (message.leader !== 0) {
      obj.leader = Math.round(message.leader);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FollowRequestMessage>, I>>(base?: I): FollowRequestMessage {
    return FollowRequestMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FollowRequestMessage>, I>>(object: I): FollowRequestMessage {
    const message = createBaseFollowRequestMessage();
    message.leader = object.leader ?? 0;
    return message;
  },
};

function createBaseFollowConfirmationMessage(): FollowConfirmationMessage {
  return { leader: 0, follower: 0 };
}

export const FollowConfirmationMessage = {
  encode(message: FollowConfirmationMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.leader !== 0) {
      writer.uint32(8).int32(message.leader);
    }
    if (message.follower !== 0) {
      writer.uint32(16).int32(message.follower);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FollowConfirmationMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFollowConfirmationMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.leader = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.follower = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FollowConfirmationMessage {
    return {
      leader: isSet(object.leader) ? globalThis.Number(object.leader) : 0,
      follower: isSet(object.follower) ? globalThis.Number(object.follower) : 0,
    };
  },

  toJSON(message: FollowConfirmationMessage): unknown {
    const obj: any = {};
    if (message.leader !== 0) {
      obj.leader = Math.round(message.leader);
    }
    if (message.follower !== 0) {
      obj.follower = Math.round(message.follower);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FollowConfirmationMessage>, I>>(base?: I): FollowConfirmationMessage {
    return FollowConfirmationMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FollowConfirmationMessage>, I>>(object: I): FollowConfirmationMessage {
    const message = createBaseFollowConfirmationMessage();
    message.leader = object.leader ?? 0;
    message.follower = object.follower ?? 0;
    return message;
  },
};

function createBaseFollowAbortMessage(): FollowAbortMessage {
  return { leader: 0, follower: 0 };
}

export const FollowAbortMessage = {
  encode(message: FollowAbortMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.leader !== 0) {
      writer.uint32(8).int32(message.leader);
    }
    if (message.follower !== 0) {
      writer.uint32(16).int32(message.follower);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FollowAbortMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFollowAbortMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.leader = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.follower = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FollowAbortMessage {
    return {
      leader: isSet(object.leader) ? globalThis.Number(object.leader) : 0,
      follower: isSet(object.follower) ? globalThis.Number(object.follower) : 0,
    };
  },

  toJSON(message: FollowAbortMessage): unknown {
    const obj: any = {};
    if (message.leader !== 0) {
      obj.leader = Math.round(message.leader);
    }
    if (message.follower !== 0) {
      obj.follower = Math.round(message.follower);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FollowAbortMessage>, I>>(base?: I): FollowAbortMessage {
    return FollowAbortMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FollowAbortMessage>, I>>(object: I): FollowAbortMessage {
    const message = createBaseFollowAbortMessage();
    message.leader = object.leader ?? 0;
    message.follower = object.follower ?? 0;
    return message;
  },
};

function createBaseLockGroupPromptMessage(): LockGroupPromptMessage {
  return { lock: false };
}

export const LockGroupPromptMessage = {
  encode(message: LockGroupPromptMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lock === true) {
      writer.uint32(8).bool(message.lock);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LockGroupPromptMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLockGroupPromptMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lock = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LockGroupPromptMessage {
    return { lock: isSet(object.lock) ? globalThis.Boolean(object.lock) : false };
  },

  toJSON(message: LockGroupPromptMessage): unknown {
    const obj: any = {};
    if (message.lock === true) {
      obj.lock = message.lock;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LockGroupPromptMessage>, I>>(base?: I): LockGroupPromptMessage {
    return LockGroupPromptMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LockGroupPromptMessage>, I>>(object: I): LockGroupPromptMessage {
    const message = createBaseLockGroupPromptMessage();
    message.lock = object.lock ?? false;
    return message;
  },
};

function createBaseKickOffUserMessage(): KickOffUserMessage {
  return { userId: "", spaceName: "" };
}

export const KickOffUserMessage = {
  encode(message: KickOffUserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.spaceName !== "") {
      writer.uint32(18).string(message.spaceName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KickOffUserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKickOffUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spaceName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KickOffUserMessage {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
    };
  },

  toJSON(message: KickOffUserMessage): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KickOffUserMessage>, I>>(base?: I): KickOffUserMessage {
    return KickOffUserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KickOffUserMessage>, I>>(object: I): KickOffUserMessage {
    const message = createBaseKickOffUserMessage();
    message.userId = object.userId ?? "";
    message.spaceName = object.spaceName ?? "";
    return message;
  },
};

function createBaseEditMapMessage(): EditMapMessage {
  return { message: undefined };
}

export const EditMapMessage = {
  encode(message: EditMapMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "modifyAreaMessage":
        ModifyAreaMessage.encode(message.message.modifyAreaMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "createAreaMessage":
        CreateAreaMessage.encode(message.message.createAreaMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "deleteAreaMessage":
        DeleteAreaMessage.encode(message.message.deleteAreaMessage, writer.uint32(26).fork()).ldelim();
        break;
      case "modifyEntityMessage":
        ModifyEntityMessage.encode(message.message.modifyEntityMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "createEntityMessage":
        CreateEntityMessage.encode(message.message.createEntityMessage, writer.uint32(42).fork()).ldelim();
        break;
      case "deleteEntityMessage":
        DeleteEntityMessage.encode(message.message.deleteEntityMessage, writer.uint32(50).fork()).ldelim();
        break;
      case "updateWAMSettingsMessage":
        UpdateWAMSettingsMessage.encode(message.message.updateWAMSettingsMessage, writer.uint32(58).fork()).ldelim();
        break;
      case "errorCommandMessage":
        ErrorCommandMessage.encode(message.message.errorCommandMessage, writer.uint32(66).fork()).ldelim();
        break;
      case "modifiyWAMMetadataMessage":
        ModifiyWAMMetadataMessage.encode(message.message.modifiyWAMMetadataMessage, writer.uint32(74).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditMapMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditMapMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "modifyAreaMessage",
            modifyAreaMessage: ModifyAreaMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "createAreaMessage",
            createAreaMessage: CreateAreaMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "deleteAreaMessage",
            deleteAreaMessage: DeleteAreaMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "modifyEntityMessage",
            modifyEntityMessage: ModifyEntityMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "createEntityMessage",
            createEntityMessage: CreateEntityMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = {
            $case: "deleteEntityMessage",
            deleteEntityMessage: DeleteEntityMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "updateWAMSettingsMessage",
            updateWAMSettingsMessage: UpdateWAMSettingsMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.message = {
            $case: "errorCommandMessage",
            errorCommandMessage: ErrorCommandMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.message = {
            $case: "modifiyWAMMetadataMessage",
            modifiyWAMMetadataMessage: ModifiyWAMMetadataMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditMapMessage {
    return {
      message: isSet(object.modifyAreaMessage)
        ? { $case: "modifyAreaMessage", modifyAreaMessage: ModifyAreaMessage.fromJSON(object.modifyAreaMessage) }
        : isSet(object.createAreaMessage)
        ? { $case: "createAreaMessage", createAreaMessage: CreateAreaMessage.fromJSON(object.createAreaMessage) }
        : isSet(object.deleteAreaMessage)
        ? { $case: "deleteAreaMessage", deleteAreaMessage: DeleteAreaMessage.fromJSON(object.deleteAreaMessage) }
        : isSet(object.modifyEntityMessage)
        ? {
          $case: "modifyEntityMessage",
          modifyEntityMessage: ModifyEntityMessage.fromJSON(object.modifyEntityMessage),
        }
        : isSet(object.createEntityMessage)
        ? {
          $case: "createEntityMessage",
          createEntityMessage: CreateEntityMessage.fromJSON(object.createEntityMessage),
        }
        : isSet(object.deleteEntityMessage)
        ? {
          $case: "deleteEntityMessage",
          deleteEntityMessage: DeleteEntityMessage.fromJSON(object.deleteEntityMessage),
        }
        : isSet(object.updateWAMSettingsMessage)
        ? {
          $case: "updateWAMSettingsMessage",
          updateWAMSettingsMessage: UpdateWAMSettingsMessage.fromJSON(object.updateWAMSettingsMessage),
        }
        : isSet(object.errorCommandMessage)
        ? {
          $case: "errorCommandMessage",
          errorCommandMessage: ErrorCommandMessage.fromJSON(object.errorCommandMessage),
        }
        : isSet(object.modifiyWAMMetadataMessage)
        ? {
          $case: "modifiyWAMMetadataMessage",
          modifiyWAMMetadataMessage: ModifiyWAMMetadataMessage.fromJSON(object.modifiyWAMMetadataMessage),
        }
        : undefined,
    };
  },

  toJSON(message: EditMapMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "modifyAreaMessage") {
      obj.modifyAreaMessage = ModifyAreaMessage.toJSON(message.message.modifyAreaMessage);
    }
    if (message.message?.$case === "createAreaMessage") {
      obj.createAreaMessage = CreateAreaMessage.toJSON(message.message.createAreaMessage);
    }
    if (message.message?.$case === "deleteAreaMessage") {
      obj.deleteAreaMessage = DeleteAreaMessage.toJSON(message.message.deleteAreaMessage);
    }
    if (message.message?.$case === "modifyEntityMessage") {
      obj.modifyEntityMessage = ModifyEntityMessage.toJSON(message.message.modifyEntityMessage);
    }
    if (message.message?.$case === "createEntityMessage") {
      obj.createEntityMessage = CreateEntityMessage.toJSON(message.message.createEntityMessage);
    }
    if (message.message?.$case === "deleteEntityMessage") {
      obj.deleteEntityMessage = DeleteEntityMessage.toJSON(message.message.deleteEntityMessage);
    }
    if (message.message?.$case === "updateWAMSettingsMessage") {
      obj.updateWAMSettingsMessage = UpdateWAMSettingsMessage.toJSON(message.message.updateWAMSettingsMessage);
    }
    if (message.message?.$case === "errorCommandMessage") {
      obj.errorCommandMessage = ErrorCommandMessage.toJSON(message.message.errorCommandMessage);
    }
    if (message.message?.$case === "modifiyWAMMetadataMessage") {
      obj.modifiyWAMMetadataMessage = ModifiyWAMMetadataMessage.toJSON(message.message.modifiyWAMMetadataMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EditMapMessage>, I>>(base?: I): EditMapMessage {
    return EditMapMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EditMapMessage>, I>>(object: I): EditMapMessage {
    const message = createBaseEditMapMessage();
    if (
      object.message?.$case === "modifyAreaMessage" &&
      object.message?.modifyAreaMessage !== undefined &&
      object.message?.modifyAreaMessage !== null
    ) {
      message.message = {
        $case: "modifyAreaMessage",
        modifyAreaMessage: ModifyAreaMessage.fromPartial(object.message.modifyAreaMessage),
      };
    }
    if (
      object.message?.$case === "createAreaMessage" &&
      object.message?.createAreaMessage !== undefined &&
      object.message?.createAreaMessage !== null
    ) {
      message.message = {
        $case: "createAreaMessage",
        createAreaMessage: CreateAreaMessage.fromPartial(object.message.createAreaMessage),
      };
    }
    if (
      object.message?.$case === "deleteAreaMessage" &&
      object.message?.deleteAreaMessage !== undefined &&
      object.message?.deleteAreaMessage !== null
    ) {
      message.message = {
        $case: "deleteAreaMessage",
        deleteAreaMessage: DeleteAreaMessage.fromPartial(object.message.deleteAreaMessage),
      };
    }
    if (
      object.message?.$case === "modifyEntityMessage" &&
      object.message?.modifyEntityMessage !== undefined &&
      object.message?.modifyEntityMessage !== null
    ) {
      message.message = {
        $case: "modifyEntityMessage",
        modifyEntityMessage: ModifyEntityMessage.fromPartial(object.message.modifyEntityMessage),
      };
    }
    if (
      object.message?.$case === "createEntityMessage" &&
      object.message?.createEntityMessage !== undefined &&
      object.message?.createEntityMessage !== null
    ) {
      message.message = {
        $case: "createEntityMessage",
        createEntityMessage: CreateEntityMessage.fromPartial(object.message.createEntityMessage),
      };
    }
    if (
      object.message?.$case === "deleteEntityMessage" &&
      object.message?.deleteEntityMessage !== undefined &&
      object.message?.deleteEntityMessage !== null
    ) {
      message.message = {
        $case: "deleteEntityMessage",
        deleteEntityMessage: DeleteEntityMessage.fromPartial(object.message.deleteEntityMessage),
      };
    }
    if (
      object.message?.$case === "updateWAMSettingsMessage" &&
      object.message?.updateWAMSettingsMessage !== undefined &&
      object.message?.updateWAMSettingsMessage !== null
    ) {
      message.message = {
        $case: "updateWAMSettingsMessage",
        updateWAMSettingsMessage: UpdateWAMSettingsMessage.fromPartial(object.message.updateWAMSettingsMessage),
      };
    }
    if (
      object.message?.$case === "errorCommandMessage" &&
      object.message?.errorCommandMessage !== undefined &&
      object.message?.errorCommandMessage !== null
    ) {
      message.message = {
        $case: "errorCommandMessage",
        errorCommandMessage: ErrorCommandMessage.fromPartial(object.message.errorCommandMessage),
      };
    }
    if (
      object.message?.$case === "modifiyWAMMetadataMessage" &&
      object.message?.modifiyWAMMetadataMessage !== undefined &&
      object.message?.modifiyWAMMetadataMessage !== null
    ) {
      message.message = {
        $case: "modifiyWAMMetadataMessage",
        modifiyWAMMetadataMessage: ModifiyWAMMetadataMessage.fromPartial(object.message.modifiyWAMMetadataMessage),
      };
    }
    return message;
  },
};

function createBaseErrorCommandMessage(): ErrorCommandMessage {
  return { reason: "" };
}

export const ErrorCommandMessage = {
  encode(message: ErrorCommandMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reason !== "") {
      writer.uint32(10).string(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ErrorCommandMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorCommandMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ErrorCommandMessage {
    return { reason: isSet(object.reason) ? globalThis.String(object.reason) : "" };
  },

  toJSON(message: ErrorCommandMessage): unknown {
    const obj: any = {};
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ErrorCommandMessage>, I>>(base?: I): ErrorCommandMessage {
    return ErrorCommandMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ErrorCommandMessage>, I>>(object: I): ErrorCommandMessage {
    const message = createBaseErrorCommandMessage();
    message.reason = object.reason ?? "";
    return message;
  },
};

function createBaseUpdateWAMSettingsMessage(): UpdateWAMSettingsMessage {
  return { message: undefined };
}

export const UpdateWAMSettingsMessage = {
  encode(message: UpdateWAMSettingsMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "updateMegaphoneSettingMessage":
        UpdateMegaphoneSettingMessage.encode(message.message.updateMegaphoneSettingMessage, writer.uint32(10).fork())
          .ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWAMSettingsMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWAMSettingsMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "updateMegaphoneSettingMessage",
            updateMegaphoneSettingMessage: UpdateMegaphoneSettingMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateWAMSettingsMessage {
    return {
      message: isSet(object.updateMegaphoneSettingMessage)
        ? {
          $case: "updateMegaphoneSettingMessage",
          updateMegaphoneSettingMessage: UpdateMegaphoneSettingMessage.fromJSON(object.updateMegaphoneSettingMessage),
        }
        : undefined,
    };
  },

  toJSON(message: UpdateWAMSettingsMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "updateMegaphoneSettingMessage") {
      obj.updateMegaphoneSettingMessage = UpdateMegaphoneSettingMessage.toJSON(
        message.message.updateMegaphoneSettingMessage,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateWAMSettingsMessage>, I>>(base?: I): UpdateWAMSettingsMessage {
    return UpdateWAMSettingsMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateWAMSettingsMessage>, I>>(object: I): UpdateWAMSettingsMessage {
    const message = createBaseUpdateWAMSettingsMessage();
    if (
      object.message?.$case === "updateMegaphoneSettingMessage" &&
      object.message?.updateMegaphoneSettingMessage !== undefined &&
      object.message?.updateMegaphoneSettingMessage !== null
    ) {
      message.message = {
        $case: "updateMegaphoneSettingMessage",
        updateMegaphoneSettingMessage: UpdateMegaphoneSettingMessage.fromPartial(
          object.message.updateMegaphoneSettingMessage,
        ),
      };
    }
    return message;
  },
};

function createBaseUpdateMegaphoneSettingMessage(): UpdateMegaphoneSettingMessage {
  return { enabled: false, title: undefined, scope: undefined, rights: [] };
}

export const UpdateMegaphoneSettingMessage = {
  encode(message: UpdateMegaphoneSettingMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled === true) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.title !== undefined) {
      writer.uint32(18).string(message.title);
    }
    if (message.scope !== undefined) {
      writer.uint32(26).string(message.scope);
    }
    for (const v of message.rights) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMegaphoneSettingMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMegaphoneSettingMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scope = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rights.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMegaphoneSettingMessage {
    return {
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      title: isSet(object.title) ? globalThis.String(object.title) : undefined,
      scope: isSet(object.scope) ? globalThis.String(object.scope) : undefined,
      rights: globalThis.Array.isArray(object?.rights) ? object.rights.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: UpdateMegaphoneSettingMessage): unknown {
    const obj: any = {};
    if (message.enabled === true) {
      obj.enabled = message.enabled;
    }
    if (message.title !== undefined) {
      obj.title = message.title;
    }
    if (message.scope !== undefined) {
      obj.scope = message.scope;
    }
    if (message.rights?.length) {
      obj.rights = message.rights;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMegaphoneSettingMessage>, I>>(base?: I): UpdateMegaphoneSettingMessage {
    return UpdateMegaphoneSettingMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMegaphoneSettingMessage>, I>>(
    object: I,
  ): UpdateMegaphoneSettingMessage {
    const message = createBaseUpdateMegaphoneSettingMessage();
    message.enabled = object.enabled ?? false;
    message.title = object.title ?? undefined;
    message.scope = object.scope ?? undefined;
    message.rights = object.rights?.map((e) => e) || [];
    return message;
  },
};

function createBaseEditMapCommandMessage(): EditMapCommandMessage {
  return { id: "", editMapMessage: undefined };
}

export const EditMapCommandMessage = {
  encode(message: EditMapCommandMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.editMapMessage !== undefined) {
      EditMapMessage.encode(message.editMapMessage, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditMapCommandMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditMapCommandMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.editMapMessage = EditMapMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditMapCommandMessage {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      editMapMessage: isSet(object.editMapMessage) ? EditMapMessage.fromJSON(object.editMapMessage) : undefined,
    };
  },

  toJSON(message: EditMapCommandMessage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.editMapMessage !== undefined) {
      obj.editMapMessage = EditMapMessage.toJSON(message.editMapMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EditMapCommandMessage>, I>>(base?: I): EditMapCommandMessage {
    return EditMapCommandMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EditMapCommandMessage>, I>>(object: I): EditMapCommandMessage {
    const message = createBaseEditMapCommandMessage();
    message.id = object.id ?? "";
    message.editMapMessage = (object.editMapMessage !== undefined && object.editMapMessage !== null)
      ? EditMapMessage.fromPartial(object.editMapMessage)
      : undefined;
    return message;
  },
};

function createBaseEditMapCommandsArrayMessage(): EditMapCommandsArrayMessage {
  return { editMapCommands: [] };
}

export const EditMapCommandsArrayMessage = {
  encode(message: EditMapCommandsArrayMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.editMapCommands) {
      EditMapCommandMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditMapCommandsArrayMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditMapCommandsArrayMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.editMapCommands.push(EditMapCommandMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditMapCommandsArrayMessage {
    return {
      editMapCommands: globalThis.Array.isArray(object?.editMapCommands)
        ? object.editMapCommands.map((e: any) => EditMapCommandMessage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EditMapCommandsArrayMessage): unknown {
    const obj: any = {};
    if (message.editMapCommands?.length) {
      obj.editMapCommands = message.editMapCommands.map((e) => EditMapCommandMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EditMapCommandsArrayMessage>, I>>(base?: I): EditMapCommandsArrayMessage {
    return EditMapCommandsArrayMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EditMapCommandsArrayMessage>, I>>(object: I): EditMapCommandsArrayMessage {
    const message = createBaseEditMapCommandsArrayMessage();
    message.editMapCommands = object.editMapCommands?.map((e) => EditMapCommandMessage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEditMapCommandWithKeyMessage(): EditMapCommandWithKeyMessage {
  return { mapKey: "", editMapCommandMessage: undefined };
}

export const EditMapCommandWithKeyMessage = {
  encode(message: EditMapCommandWithKeyMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mapKey !== "") {
      writer.uint32(10).string(message.mapKey);
    }
    if (message.editMapCommandMessage !== undefined) {
      EditMapCommandMessage.encode(message.editMapCommandMessage, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditMapCommandWithKeyMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditMapCommandWithKeyMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mapKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.editMapCommandMessage = EditMapCommandMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditMapCommandWithKeyMessage {
    return {
      mapKey: isSet(object.mapKey) ? globalThis.String(object.mapKey) : "",
      editMapCommandMessage: isSet(object.editMapCommandMessage)
        ? EditMapCommandMessage.fromJSON(object.editMapCommandMessage)
        : undefined,
    };
  },

  toJSON(message: EditMapCommandWithKeyMessage): unknown {
    const obj: any = {};
    if (message.mapKey !== "") {
      obj.mapKey = message.mapKey;
    }
    if (message.editMapCommandMessage !== undefined) {
      obj.editMapCommandMessage = EditMapCommandMessage.toJSON(message.editMapCommandMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EditMapCommandWithKeyMessage>, I>>(base?: I): EditMapCommandWithKeyMessage {
    return EditMapCommandWithKeyMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EditMapCommandWithKeyMessage>, I>>(object: I): EditMapCommandWithKeyMessage {
    const message = createBaseEditMapCommandWithKeyMessage();
    message.mapKey = object.mapKey ?? "";
    message.editMapCommandMessage =
      (object.editMapCommandMessage !== undefined && object.editMapCommandMessage !== null)
        ? EditMapCommandMessage.fromPartial(object.editMapCommandMessage)
        : undefined;
    return message;
  },
};

function createBaseModifyAreaMessage(): ModifyAreaMessage {
  return {
    id: "",
    name: undefined,
    x: undefined,
    y: undefined,
    width: undefined,
    height: undefined,
    properties: [],
    modifyProperties: undefined,
  };
}

export const ModifyAreaMessage = {
  encode(message: ModifyAreaMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.x !== undefined) {
      writer.uint32(24).uint32(message.x);
    }
    if (message.y !== undefined) {
      writer.uint32(32).uint32(message.y);
    }
    if (message.width !== undefined) {
      writer.uint32(40).uint32(message.width);
    }
    if (message.height !== undefined) {
      writer.uint32(48).uint32(message.height);
    }
    for (const v of message.properties) {
      Value.encode(Value.wrap(v!), writer.uint32(58).fork()).ldelim();
    }
    if (message.modifyProperties !== undefined) {
      writer.uint32(64).bool(message.modifyProperties);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyAreaMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyAreaMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.x = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.y = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.width = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.height = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.properties.push(Value.unwrap(Value.decode(reader, reader.uint32())));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.modifyProperties = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModifyAreaMessage {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      x: isSet(object.x) ? globalThis.Number(object.x) : undefined,
      y: isSet(object.y) ? globalThis.Number(object.y) : undefined,
      width: isSet(object.width) ? globalThis.Number(object.width) : undefined,
      height: isSet(object.height) ? globalThis.Number(object.height) : undefined,
      properties: globalThis.Array.isArray(object?.properties) ? [...object.properties] : [],
      modifyProperties: isSet(object.modifyProperties) ? globalThis.Boolean(object.modifyProperties) : undefined,
    };
  },

  toJSON(message: ModifyAreaMessage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.x !== undefined) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== undefined) {
      obj.y = Math.round(message.y);
    }
    if (message.width !== undefined) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== undefined) {
      obj.height = Math.round(message.height);
    }
    if (message.properties?.length) {
      obj.properties = message.properties;
    }
    if (message.modifyProperties !== undefined) {
      obj.modifyProperties = message.modifyProperties;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyAreaMessage>, I>>(base?: I): ModifyAreaMessage {
    return ModifyAreaMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModifyAreaMessage>, I>>(object: I): ModifyAreaMessage {
    const message = createBaseModifyAreaMessage();
    message.id = object.id ?? "";
    message.name = object.name ?? undefined;
    message.x = object.x ?? undefined;
    message.y = object.y ?? undefined;
    message.width = object.width ?? undefined;
    message.height = object.height ?? undefined;
    message.properties = object.properties?.map((e) => e) || [];
    message.modifyProperties = object.modifyProperties ?? undefined;
    return message;
  },
};

function createBaseDeleteAreaMessage(): DeleteAreaMessage {
  return { id: "" };
}

export const DeleteAreaMessage = {
  encode(message: DeleteAreaMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAreaMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAreaMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteAreaMessage {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: DeleteAreaMessage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAreaMessage>, I>>(base?: I): DeleteAreaMessage {
    return DeleteAreaMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAreaMessage>, I>>(object: I): DeleteAreaMessage {
    const message = createBaseDeleteAreaMessage();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCreateAreaMessage(): CreateAreaMessage {
  return { id: "", x: 0, y: 0, width: 0, height: 0, name: "", properties: [] };
}

export const CreateAreaMessage = {
  encode(message: CreateAreaMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.x !== 0) {
      writer.uint32(16).uint32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(24).uint32(message.y);
    }
    if (message.width !== 0) {
      writer.uint32(32).uint32(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(40).uint32(message.height);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    for (const v of message.properties) {
      Value.encode(Value.wrap(v!), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAreaMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAreaMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.x = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.y = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.width = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.height = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.properties.push(Value.unwrap(Value.decode(reader, reader.uint32())));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAreaMessage {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      properties: globalThis.Array.isArray(object?.properties) ? [...object.properties] : [],
    };
  },

  toJSON(message: CreateAreaMessage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.properties?.length) {
      obj.properties = message.properties;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAreaMessage>, I>>(base?: I): CreateAreaMessage {
    return CreateAreaMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAreaMessage>, I>>(object: I): CreateAreaMessage {
    const message = createBaseCreateAreaMessage();
    message.id = object.id ?? "";
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    message.name = object.name ?? "";
    message.properties = object.properties?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateEntityMessage(): CreateEntityMessage {
  return { id: "", x: 0, y: 0, collectionName: "", prefabId: "", properties: [], name: undefined };
}

export const CreateEntityMessage = {
  encode(message: CreateEntityMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.x !== 0) {
      writer.uint32(16).uint32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(24).uint32(message.y);
    }
    if (message.collectionName !== "") {
      writer.uint32(34).string(message.collectionName);
    }
    if (message.prefabId !== "") {
      writer.uint32(42).string(message.prefabId);
    }
    for (const v of message.properties) {
      Value.encode(Value.wrap(v!), writer.uint32(50).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(58).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateEntityMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateEntityMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.x = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.y = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.collectionName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.prefabId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.properties.push(Value.unwrap(Value.decode(reader, reader.uint32())));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateEntityMessage {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      collectionName: isSet(object.collectionName) ? globalThis.String(object.collectionName) : "",
      prefabId: isSet(object.prefabId) ? globalThis.String(object.prefabId) : "",
      properties: globalThis.Array.isArray(object?.properties) ? [...object.properties] : [],
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
    };
  },

  toJSON(message: CreateEntityMessage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    if (message.collectionName !== "") {
      obj.collectionName = message.collectionName;
    }
    if (message.prefabId !== "") {
      obj.prefabId = message.prefabId;
    }
    if (message.properties?.length) {
      obj.properties = message.properties;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateEntityMessage>, I>>(base?: I): CreateEntityMessage {
    return CreateEntityMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateEntityMessage>, I>>(object: I): CreateEntityMessage {
    const message = createBaseCreateEntityMessage();
    message.id = object.id ?? "";
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.collectionName = object.collectionName ?? "";
    message.prefabId = object.prefabId ?? "";
    message.properties = object.properties?.map((e) => e) || [];
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseDeleteEntityMessage(): DeleteEntityMessage {
  return { id: "" };
}

export const DeleteEntityMessage = {
  encode(message: DeleteEntityMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteEntityMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteEntityMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteEntityMessage {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: DeleteEntityMessage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteEntityMessage>, I>>(base?: I): DeleteEntityMessage {
    return DeleteEntityMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteEntityMessage>, I>>(object: I): DeleteEntityMessage {
    const message = createBaseDeleteEntityMessage();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseModifyEntityMessage(): ModifyEntityMessage {
  return { id: "", x: undefined, y: undefined, properties: [], modifyProperties: undefined, name: undefined };
}

export const ModifyEntityMessage = {
  encode(message: ModifyEntityMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.x !== undefined) {
      writer.uint32(16).uint32(message.x);
    }
    if (message.y !== undefined) {
      writer.uint32(24).uint32(message.y);
    }
    for (const v of message.properties) {
      Value.encode(Value.wrap(v!), writer.uint32(34).fork()).ldelim();
    }
    if (message.modifyProperties !== undefined) {
      writer.uint32(40).bool(message.modifyProperties);
    }
    if (message.name !== undefined) {
      writer.uint32(50).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyEntityMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyEntityMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.x = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.y = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.properties.push(Value.unwrap(Value.decode(reader, reader.uint32())));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.modifyProperties = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModifyEntityMessage {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      x: isSet(object.x) ? globalThis.Number(object.x) : undefined,
      y: isSet(object.y) ? globalThis.Number(object.y) : undefined,
      properties: globalThis.Array.isArray(object?.properties) ? [...object.properties] : [],
      modifyProperties: isSet(object.modifyProperties) ? globalThis.Boolean(object.modifyProperties) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
    };
  },

  toJSON(message: ModifyEntityMessage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.x !== undefined) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== undefined) {
      obj.y = Math.round(message.y);
    }
    if (message.properties?.length) {
      obj.properties = message.properties;
    }
    if (message.modifyProperties !== undefined) {
      obj.modifyProperties = message.modifyProperties;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyEntityMessage>, I>>(base?: I): ModifyEntityMessage {
    return ModifyEntityMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModifyEntityMessage>, I>>(object: I): ModifyEntityMessage {
    const message = createBaseModifyEntityMessage();
    message.id = object.id ?? "";
    message.x = object.x ?? undefined;
    message.y = object.y ?? undefined;
    message.properties = object.properties?.map((e) => e) || [];
    message.modifyProperties = object.modifyProperties ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseClientToServerMessage(): ClientToServerMessage {
  return { message: undefined };
}

export const ClientToServerMessage = {
  encode(message: ClientToServerMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "userMovesMessage":
        UserMovesMessage.encode(message.message.userMovesMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "viewportMessage":
        ViewportMessage.encode(message.message.viewportMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "itemEventMessage":
        ItemEventMessage.encode(message.message.itemEventMessage, writer.uint32(42).fork()).ldelim();
        break;
      case "setPlayerDetailsMessage":
        SetPlayerDetailsMessage.encode(message.message.setPlayerDetailsMessage, writer.uint32(50).fork()).ldelim();
        break;
      case "webRtcSignalToServerMessage":
        WebRtcSignalToServerMessage.encode(message.message.webRtcSignalToServerMessage, writer.uint32(58).fork())
          .ldelim();
        break;
      case "webRtcScreenSharingSignalToServerMessage":
        WebRtcSignalToServerMessage.encode(
          message.message.webRtcScreenSharingSignalToServerMessage,
          writer.uint32(66).fork(),
        ).ldelim();
        break;
      case "playGlobalMessage":
        PlayGlobalMessage.encode(message.message.playGlobalMessage, writer.uint32(74).fork()).ldelim();
        break;
      case "reportPlayerMessage":
        ReportPlayerMessage.encode(message.message.reportPlayerMessage, writer.uint32(90).fork()).ldelim();
        break;
      case "emotePromptMessage":
        EmotePromptMessage.encode(message.message.emotePromptMessage, writer.uint32(106).fork()).ldelim();
        break;
      case "variableMessage":
        VariableMessage.encode(message.message.variableMessage, writer.uint32(114).fork()).ldelim();
        break;
      case "followRequestMessage":
        FollowRequestMessage.encode(message.message.followRequestMessage, writer.uint32(122).fork()).ldelim();
        break;
      case "followConfirmationMessage":
        FollowConfirmationMessage.encode(message.message.followConfirmationMessage, writer.uint32(130).fork()).ldelim();
        break;
      case "followAbortMessage":
        FollowAbortMessage.encode(message.message.followAbortMessage, writer.uint32(138).fork()).ldelim();
        break;
      case "lockGroupPromptMessage":
        LockGroupPromptMessage.encode(message.message.lockGroupPromptMessage, writer.uint32(146).fork()).ldelim();
        break;
      case "queryMessage":
        QueryMessage.encode(message.message.queryMessage, writer.uint32(162).fork()).ldelim();
        break;
      case "pingMessage":
        PingMessage.encode(message.message.pingMessage, writer.uint32(170).fork()).ldelim();
        break;
      case "askPositionMessage":
        AskPositionMessage.encode(message.message.askPositionMessage, writer.uint32(186).fork()).ldelim();
        break;
      case "editMapCommandMessage":
        EditMapCommandMessage.encode(message.message.editMapCommandMessage, writer.uint32(194).fork()).ldelim();
        break;
      case "addSpaceFilterMessage":
        AddSpaceFilterMessage.encode(message.message.addSpaceFilterMessage, writer.uint32(202).fork()).ldelim();
        break;
      case "removeSpaceFilterMessage":
        RemoveSpaceFilterMessage.encode(message.message.removeSpaceFilterMessage, writer.uint32(210).fork()).ldelim();
        break;
      case "updateSpaceFilterMessage":
        UpdateSpaceFilterMessage.encode(message.message.updateSpaceFilterMessage, writer.uint32(218).fork()).ldelim();
        break;
      case "watchSpaceMessage":
        WatchSpaceMessage.encode(message.message.watchSpaceMessage, writer.uint32(226).fork()).ldelim();
        break;
      case "unwatchSpaceMessage":
        UnwatchSpaceMessage.encode(message.message.unwatchSpaceMessage, writer.uint32(234).fork()).ldelim();
        break;
      case "cameraStateMessage":
        CameraStateMessage.encode(message.message.cameraStateMessage, writer.uint32(242).fork()).ldelim();
        break;
      case "microphoneStateMessage":
        MicrophoneStateMessage.encode(message.message.microphoneStateMessage, writer.uint32(250).fork()).ldelim();
        break;
      case "megaphoneStateMessage":
        MegaphoneStateMessage.encode(message.message.megaphoneStateMessage, writer.uint32(258).fork()).ldelim();
        break;
      case "screenSharingStateMessage":
        ScreenSharingStateMessage.encode(message.message.screenSharingStateMessage, writer.uint32(266).fork()).ldelim();
        break;
      case "jitsiParticipantIdSpaceMessage":
        JitsiParticipantIdSpaceMessage.encode(message.message.jitsiParticipantIdSpaceMessage, writer.uint32(274).fork())
          .ldelim();
        break;
      case "updateSpaceMetadataMessage":
        UpdateSpaceMetadataMessage.encode(message.message.updateSpaceMetadataMessage, writer.uint32(282).fork())
          .ldelim();
        break;
      case "kickOffUserMessage":
        KickOffUserMessage.encode(message.message.kickOffUserMessage, writer.uint32(290).fork()).ldelim();
        break;
      case "muteParticipantIdMessage":
        MuteParticipantIdMessage.encode(message.message.muteParticipantIdMessage, writer.uint32(298).fork()).ldelim();
        break;
      case "muteEveryBodyParticipantMessage":
        MuteEveryBodyParticipantMessage.encode(
          message.message.muteEveryBodyParticipantMessage,
          writer.uint32(306).fork(),
        ).ldelim();
        break;
      case "muteVideoParticipantIdMessage":
        MuteVideoParticipantIdMessage.encode(message.message.muteVideoParticipantIdMessage, writer.uint32(314).fork())
          .ldelim();
        break;
      case "muteVideoEveryBodyParticipantMessage":
        MuteVideoEveryBodyParticipantMessage.encode(
          message.message.muteVideoEveryBodyParticipantMessage,
          writer.uint32(322).fork(),
        ).ldelim();
        break;
      case "banPlayerMessage":
        BanPlayerMessage.encode(message.message.banPlayerMessage, writer.uint32(330).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientToServerMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientToServerMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "userMovesMessage",
            userMovesMessage: UserMovesMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "viewportMessage",
            viewportMessage: ViewportMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "itemEventMessage",
            itemEventMessage: ItemEventMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = {
            $case: "setPlayerDetailsMessage",
            setPlayerDetailsMessage: SetPlayerDetailsMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "webRtcSignalToServerMessage",
            webRtcSignalToServerMessage: WebRtcSignalToServerMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.message = {
            $case: "webRtcScreenSharingSignalToServerMessage",
            webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.message = {
            $case: "playGlobalMessage",
            playGlobalMessage: PlayGlobalMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.message = {
            $case: "reportPlayerMessage",
            reportPlayerMessage: ReportPlayerMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.message = {
            $case: "emotePromptMessage",
            emotePromptMessage: EmotePromptMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.message = {
            $case: "variableMessage",
            variableMessage: VariableMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.message = {
            $case: "followRequestMessage",
            followRequestMessage: FollowRequestMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.message = {
            $case: "followConfirmationMessage",
            followConfirmationMessage: FollowConfirmationMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.message = {
            $case: "followAbortMessage",
            followAbortMessage: FollowAbortMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.message = {
            $case: "lockGroupPromptMessage",
            lockGroupPromptMessage: LockGroupPromptMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.message = { $case: "queryMessage", queryMessage: QueryMessage.decode(reader, reader.uint32()) };
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.message = { $case: "pingMessage", pingMessage: PingMessage.decode(reader, reader.uint32()) };
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.message = {
            $case: "askPositionMessage",
            askPositionMessage: AskPositionMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.message = {
            $case: "editMapCommandMessage",
            editMapCommandMessage: EditMapCommandMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.message = {
            $case: "addSpaceFilterMessage",
            addSpaceFilterMessage: AddSpaceFilterMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.message = {
            $case: "removeSpaceFilterMessage",
            removeSpaceFilterMessage: RemoveSpaceFilterMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.message = {
            $case: "updateSpaceFilterMessage",
            updateSpaceFilterMessage: UpdateSpaceFilterMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.message = {
            $case: "watchSpaceMessage",
            watchSpaceMessage: WatchSpaceMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.message = {
            $case: "unwatchSpaceMessage",
            unwatchSpaceMessage: UnwatchSpaceMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.message = {
            $case: "cameraStateMessage",
            cameraStateMessage: CameraStateMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.message = {
            $case: "microphoneStateMessage",
            microphoneStateMessage: MicrophoneStateMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.message = {
            $case: "megaphoneStateMessage",
            megaphoneStateMessage: MegaphoneStateMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.message = {
            $case: "screenSharingStateMessage",
            screenSharingStateMessage: ScreenSharingStateMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.message = {
            $case: "jitsiParticipantIdSpaceMessage",
            jitsiParticipantIdSpaceMessage: JitsiParticipantIdSpaceMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.message = {
            $case: "updateSpaceMetadataMessage",
            updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.message = {
            $case: "kickOffUserMessage",
            kickOffUserMessage: KickOffUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.message = {
            $case: "muteParticipantIdMessage",
            muteParticipantIdMessage: MuteParticipantIdMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.message = {
            $case: "muteEveryBodyParticipantMessage",
            muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.message = {
            $case: "muteVideoParticipantIdMessage",
            muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.message = {
            $case: "muteVideoEveryBodyParticipantMessage",
            muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.message = {
            $case: "banPlayerMessage",
            banPlayerMessage: BanPlayerMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientToServerMessage {
    return {
      message: isSet(object.userMovesMessage)
        ? { $case: "userMovesMessage", userMovesMessage: UserMovesMessage.fromJSON(object.userMovesMessage) }
        : isSet(object.viewportMessage)
        ? { $case: "viewportMessage", viewportMessage: ViewportMessage.fromJSON(object.viewportMessage) }
        : isSet(object.itemEventMessage)
        ? { $case: "itemEventMessage", itemEventMessage: ItemEventMessage.fromJSON(object.itemEventMessage) }
        : isSet(object.setPlayerDetailsMessage)
        ? {
          $case: "setPlayerDetailsMessage",
          setPlayerDetailsMessage: SetPlayerDetailsMessage.fromJSON(object.setPlayerDetailsMessage),
        }
        : isSet(object.webRtcSignalToServerMessage)
        ? {
          $case: "webRtcSignalToServerMessage",
          webRtcSignalToServerMessage: WebRtcSignalToServerMessage.fromJSON(object.webRtcSignalToServerMessage),
        }
        : isSet(object.webRtcScreenSharingSignalToServerMessage)
        ? {
          $case: "webRtcScreenSharingSignalToServerMessage",
          webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage.fromJSON(
            object.webRtcScreenSharingSignalToServerMessage,
          ),
        }
        : isSet(object.playGlobalMessage)
        ? { $case: "playGlobalMessage", playGlobalMessage: PlayGlobalMessage.fromJSON(object.playGlobalMessage) }
        : isSet(object.reportPlayerMessage)
        ? {
          $case: "reportPlayerMessage",
          reportPlayerMessage: ReportPlayerMessage.fromJSON(object.reportPlayerMessage),
        }
        : isSet(object.emotePromptMessage)
        ? { $case: "emotePromptMessage", emotePromptMessage: EmotePromptMessage.fromJSON(object.emotePromptMessage) }
        : isSet(object.variableMessage)
        ? { $case: "variableMessage", variableMessage: VariableMessage.fromJSON(object.variableMessage) }
        : isSet(object.followRequestMessage)
        ? {
          $case: "followRequestMessage",
          followRequestMessage: FollowRequestMessage.fromJSON(object.followRequestMessage),
        }
        : isSet(object.followConfirmationMessage)
        ? {
          $case: "followConfirmationMessage",
          followConfirmationMessage: FollowConfirmationMessage.fromJSON(object.followConfirmationMessage),
        }
        : isSet(object.followAbortMessage)
        ? { $case: "followAbortMessage", followAbortMessage: FollowAbortMessage.fromJSON(object.followAbortMessage) }
        : isSet(object.lockGroupPromptMessage)
        ? {
          $case: "lockGroupPromptMessage",
          lockGroupPromptMessage: LockGroupPromptMessage.fromJSON(object.lockGroupPromptMessage),
        }
        : isSet(object.queryMessage)
        ? { $case: "queryMessage", queryMessage: QueryMessage.fromJSON(object.queryMessage) }
        : isSet(object.pingMessage)
        ? { $case: "pingMessage", pingMessage: PingMessage.fromJSON(object.pingMessage) }
        : isSet(object.askPositionMessage)
        ? { $case: "askPositionMessage", askPositionMessage: AskPositionMessage.fromJSON(object.askPositionMessage) }
        : isSet(object.editMapCommandMessage)
        ? {
          $case: "editMapCommandMessage",
          editMapCommandMessage: EditMapCommandMessage.fromJSON(object.editMapCommandMessage),
        }
        : isSet(object.addSpaceFilterMessage)
        ? {
          $case: "addSpaceFilterMessage",
          addSpaceFilterMessage: AddSpaceFilterMessage.fromJSON(object.addSpaceFilterMessage),
        }
        : isSet(object.removeSpaceFilterMessage)
        ? {
          $case: "removeSpaceFilterMessage",
          removeSpaceFilterMessage: RemoveSpaceFilterMessage.fromJSON(object.removeSpaceFilterMessage),
        }
        : isSet(object.updateSpaceFilterMessage)
        ? {
          $case: "updateSpaceFilterMessage",
          updateSpaceFilterMessage: UpdateSpaceFilterMessage.fromJSON(object.updateSpaceFilterMessage),
        }
        : isSet(object.watchSpaceMessage)
        ? { $case: "watchSpaceMessage", watchSpaceMessage: WatchSpaceMessage.fromJSON(object.watchSpaceMessage) }
        : isSet(object.unwatchSpaceMessage)
        ? {
          $case: "unwatchSpaceMessage",
          unwatchSpaceMessage: UnwatchSpaceMessage.fromJSON(object.unwatchSpaceMessage),
        }
        : isSet(object.cameraStateMessage)
        ? { $case: "cameraStateMessage", cameraStateMessage: CameraStateMessage.fromJSON(object.cameraStateMessage) }
        : isSet(object.microphoneStateMessage)
        ? {
          $case: "microphoneStateMessage",
          microphoneStateMessage: MicrophoneStateMessage.fromJSON(object.microphoneStateMessage),
        }
        : isSet(object.megaphoneStateMessage)
        ? {
          $case: "megaphoneStateMessage",
          megaphoneStateMessage: MegaphoneStateMessage.fromJSON(object.megaphoneStateMessage),
        }
        : isSet(object.screenSharingStateMessage)
        ? {
          $case: "screenSharingStateMessage",
          screenSharingStateMessage: ScreenSharingStateMessage.fromJSON(object.screenSharingStateMessage),
        }
        : isSet(object.jitsiParticipantIdSpaceMessage)
        ? {
          $case: "jitsiParticipantIdSpaceMessage",
          jitsiParticipantIdSpaceMessage: JitsiParticipantIdSpaceMessage.fromJSON(
            object.jitsiParticipantIdSpaceMessage,
          ),
        }
        : isSet(object.updateSpaceMetadataMessage)
        ? {
          $case: "updateSpaceMetadataMessage",
          updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromJSON(object.updateSpaceMetadataMessage),
        }
        : isSet(object.kickOffUserMessage)
        ? { $case: "kickOffUserMessage", kickOffUserMessage: KickOffUserMessage.fromJSON(object.kickOffUserMessage) }
        : isSet(object.muteParticipantIdMessage)
        ? {
          $case: "muteParticipantIdMessage",
          muteParticipantIdMessage: MuteParticipantIdMessage.fromJSON(object.muteParticipantIdMessage),
        }
        : isSet(object.muteEveryBodyParticipantMessage)
        ? {
          $case: "muteEveryBodyParticipantMessage",
          muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage.fromJSON(
            object.muteEveryBodyParticipantMessage,
          ),
        }
        : isSet(object.muteVideoParticipantIdMessage)
        ? {
          $case: "muteVideoParticipantIdMessage",
          muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage.fromJSON(object.muteVideoParticipantIdMessage),
        }
        : isSet(object.muteVideoEveryBodyParticipantMessage)
        ? {
          $case: "muteVideoEveryBodyParticipantMessage",
          muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage.fromJSON(
            object.muteVideoEveryBodyParticipantMessage,
          ),
        }
        : isSet(object.banPlayerMessage)
        ? { $case: "banPlayerMessage", banPlayerMessage: BanPlayerMessage.fromJSON(object.banPlayerMessage) }
        : undefined,
    };
  },

  toJSON(message: ClientToServerMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "userMovesMessage") {
      obj.userMovesMessage = UserMovesMessage.toJSON(message.message.userMovesMessage);
    }
    if (message.message?.$case === "viewportMessage") {
      obj.viewportMessage = ViewportMessage.toJSON(message.message.viewportMessage);
    }
    if (message.message?.$case === "itemEventMessage") {
      obj.itemEventMessage = ItemEventMessage.toJSON(message.message.itemEventMessage);
    }
    if (message.message?.$case === "setPlayerDetailsMessage") {
      obj.setPlayerDetailsMessage = SetPlayerDetailsMessage.toJSON(message.message.setPlayerDetailsMessage);
    }
    if (message.message?.$case === "webRtcSignalToServerMessage") {
      obj.webRtcSignalToServerMessage = WebRtcSignalToServerMessage.toJSON(message.message.webRtcSignalToServerMessage);
    }
    if (message.message?.$case === "webRtcScreenSharingSignalToServerMessage") {
      obj.webRtcScreenSharingSignalToServerMessage = WebRtcSignalToServerMessage.toJSON(
        message.message.webRtcScreenSharingSignalToServerMessage,
      );
    }
    if (message.message?.$case === "playGlobalMessage") {
      obj.playGlobalMessage = PlayGlobalMessage.toJSON(message.message.playGlobalMessage);
    }
    if (message.message?.$case === "reportPlayerMessage") {
      obj.reportPlayerMessage = ReportPlayerMessage.toJSON(message.message.reportPlayerMessage);
    }
    if (message.message?.$case === "emotePromptMessage") {
      obj.emotePromptMessage = EmotePromptMessage.toJSON(message.message.emotePromptMessage);
    }
    if (message.message?.$case === "variableMessage") {
      obj.variableMessage = VariableMessage.toJSON(message.message.variableMessage);
    }
    if (message.message?.$case === "followRequestMessage") {
      obj.followRequestMessage = FollowRequestMessage.toJSON(message.message.followRequestMessage);
    }
    if (message.message?.$case === "followConfirmationMessage") {
      obj.followConfirmationMessage = FollowConfirmationMessage.toJSON(message.message.followConfirmationMessage);
    }
    if (message.message?.$case === "followAbortMessage") {
      obj.followAbortMessage = FollowAbortMessage.toJSON(message.message.followAbortMessage);
    }
    if (message.message?.$case === "lockGroupPromptMessage") {
      obj.lockGroupPromptMessage = LockGroupPromptMessage.toJSON(message.message.lockGroupPromptMessage);
    }
    if (message.message?.$case === "queryMessage") {
      obj.queryMessage = QueryMessage.toJSON(message.message.queryMessage);
    }
    if (message.message?.$case === "pingMessage") {
      obj.pingMessage = PingMessage.toJSON(message.message.pingMessage);
    }
    if (message.message?.$case === "askPositionMessage") {
      obj.askPositionMessage = AskPositionMessage.toJSON(message.message.askPositionMessage);
    }
    if (message.message?.$case === "editMapCommandMessage") {
      obj.editMapCommandMessage = EditMapCommandMessage.toJSON(message.message.editMapCommandMessage);
    }
    if (message.message?.$case === "addSpaceFilterMessage") {
      obj.addSpaceFilterMessage = AddSpaceFilterMessage.toJSON(message.message.addSpaceFilterMessage);
    }
    if (message.message?.$case === "removeSpaceFilterMessage") {
      obj.removeSpaceFilterMessage = RemoveSpaceFilterMessage.toJSON(message.message.removeSpaceFilterMessage);
    }
    if (message.message?.$case === "updateSpaceFilterMessage") {
      obj.updateSpaceFilterMessage = UpdateSpaceFilterMessage.toJSON(message.message.updateSpaceFilterMessage);
    }
    if (message.message?.$case === "watchSpaceMessage") {
      obj.watchSpaceMessage = WatchSpaceMessage.toJSON(message.message.watchSpaceMessage);
    }
    if (message.message?.$case === "unwatchSpaceMessage") {
      obj.unwatchSpaceMessage = UnwatchSpaceMessage.toJSON(message.message.unwatchSpaceMessage);
    }
    if (message.message?.$case === "cameraStateMessage") {
      obj.cameraStateMessage = CameraStateMessage.toJSON(message.message.cameraStateMessage);
    }
    if (message.message?.$case === "microphoneStateMessage") {
      obj.microphoneStateMessage = MicrophoneStateMessage.toJSON(message.message.microphoneStateMessage);
    }
    if (message.message?.$case === "megaphoneStateMessage") {
      obj.megaphoneStateMessage = MegaphoneStateMessage.toJSON(message.message.megaphoneStateMessage);
    }
    if (message.message?.$case === "screenSharingStateMessage") {
      obj.screenSharingStateMessage = ScreenSharingStateMessage.toJSON(message.message.screenSharingStateMessage);
    }
    if (message.message?.$case === "jitsiParticipantIdSpaceMessage") {
      obj.jitsiParticipantIdSpaceMessage = JitsiParticipantIdSpaceMessage.toJSON(
        message.message.jitsiParticipantIdSpaceMessage,
      );
    }
    if (message.message?.$case === "updateSpaceMetadataMessage") {
      obj.updateSpaceMetadataMessage = UpdateSpaceMetadataMessage.toJSON(message.message.updateSpaceMetadataMessage);
    }
    if (message.message?.$case === "kickOffUserMessage") {
      obj.kickOffUserMessage = KickOffUserMessage.toJSON(message.message.kickOffUserMessage);
    }
    if (message.message?.$case === "muteParticipantIdMessage") {
      obj.muteParticipantIdMessage = MuteParticipantIdMessage.toJSON(message.message.muteParticipantIdMessage);
    }
    if (message.message?.$case === "muteEveryBodyParticipantMessage") {
      obj.muteEveryBodyParticipantMessage = MuteEveryBodyParticipantMessage.toJSON(
        message.message.muteEveryBodyParticipantMessage,
      );
    }
    if (message.message?.$case === "muteVideoParticipantIdMessage") {
      obj.muteVideoParticipantIdMessage = MuteVideoParticipantIdMessage.toJSON(
        message.message.muteVideoParticipantIdMessage,
      );
    }
    if (message.message?.$case === "muteVideoEveryBodyParticipantMessage") {
      obj.muteVideoEveryBodyParticipantMessage = MuteVideoEveryBodyParticipantMessage.toJSON(
        message.message.muteVideoEveryBodyParticipantMessage,
      );
    }
    if (message.message?.$case === "banPlayerMessage") {
      obj.banPlayerMessage = BanPlayerMessage.toJSON(message.message.banPlayerMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientToServerMessage>, I>>(base?: I): ClientToServerMessage {
    return ClientToServerMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientToServerMessage>, I>>(object: I): ClientToServerMessage {
    const message = createBaseClientToServerMessage();
    if (
      object.message?.$case === "userMovesMessage" &&
      object.message?.userMovesMessage !== undefined &&
      object.message?.userMovesMessage !== null
    ) {
      message.message = {
        $case: "userMovesMessage",
        userMovesMessage: UserMovesMessage.fromPartial(object.message.userMovesMessage),
      };
    }
    if (
      object.message?.$case === "viewportMessage" &&
      object.message?.viewportMessage !== undefined &&
      object.message?.viewportMessage !== null
    ) {
      message.message = {
        $case: "viewportMessage",
        viewportMessage: ViewportMessage.fromPartial(object.message.viewportMessage),
      };
    }
    if (
      object.message?.$case === "itemEventMessage" &&
      object.message?.itemEventMessage !== undefined &&
      object.message?.itemEventMessage !== null
    ) {
      message.message = {
        $case: "itemEventMessage",
        itemEventMessage: ItemEventMessage.fromPartial(object.message.itemEventMessage),
      };
    }
    if (
      object.message?.$case === "setPlayerDetailsMessage" &&
      object.message?.setPlayerDetailsMessage !== undefined &&
      object.message?.setPlayerDetailsMessage !== null
    ) {
      message.message = {
        $case: "setPlayerDetailsMessage",
        setPlayerDetailsMessage: SetPlayerDetailsMessage.fromPartial(object.message.setPlayerDetailsMessage),
      };
    }
    if (
      object.message?.$case === "webRtcSignalToServerMessage" &&
      object.message?.webRtcSignalToServerMessage !== undefined &&
      object.message?.webRtcSignalToServerMessage !== null
    ) {
      message.message = {
        $case: "webRtcSignalToServerMessage",
        webRtcSignalToServerMessage: WebRtcSignalToServerMessage.fromPartial(
          object.message.webRtcSignalToServerMessage,
        ),
      };
    }
    if (
      object.message?.$case === "webRtcScreenSharingSignalToServerMessage" &&
      object.message?.webRtcScreenSharingSignalToServerMessage !== undefined &&
      object.message?.webRtcScreenSharingSignalToServerMessage !== null
    ) {
      message.message = {
        $case: "webRtcScreenSharingSignalToServerMessage",
        webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage.fromPartial(
          object.message.webRtcScreenSharingSignalToServerMessage,
        ),
      };
    }
    if (
      object.message?.$case === "playGlobalMessage" &&
      object.message?.playGlobalMessage !== undefined &&
      object.message?.playGlobalMessage !== null
    ) {
      message.message = {
        $case: "playGlobalMessage",
        playGlobalMessage: PlayGlobalMessage.fromPartial(object.message.playGlobalMessage),
      };
    }
    if (
      object.message?.$case === "reportPlayerMessage" &&
      object.message?.reportPlayerMessage !== undefined &&
      object.message?.reportPlayerMessage !== null
    ) {
      message.message = {
        $case: "reportPlayerMessage",
        reportPlayerMessage: ReportPlayerMessage.fromPartial(object.message.reportPlayerMessage),
      };
    }
    if (
      object.message?.$case === "emotePromptMessage" &&
      object.message?.emotePromptMessage !== undefined &&
      object.message?.emotePromptMessage !== null
    ) {
      message.message = {
        $case: "emotePromptMessage",
        emotePromptMessage: EmotePromptMessage.fromPartial(object.message.emotePromptMessage),
      };
    }
    if (
      object.message?.$case === "variableMessage" &&
      object.message?.variableMessage !== undefined &&
      object.message?.variableMessage !== null
    ) {
      message.message = {
        $case: "variableMessage",
        variableMessage: VariableMessage.fromPartial(object.message.variableMessage),
      };
    }
    if (
      object.message?.$case === "followRequestMessage" &&
      object.message?.followRequestMessage !== undefined &&
      object.message?.followRequestMessage !== null
    ) {
      message.message = {
        $case: "followRequestMessage",
        followRequestMessage: FollowRequestMessage.fromPartial(object.message.followRequestMessage),
      };
    }
    if (
      object.message?.$case === "followConfirmationMessage" &&
      object.message?.followConfirmationMessage !== undefined &&
      object.message?.followConfirmationMessage !== null
    ) {
      message.message = {
        $case: "followConfirmationMessage",
        followConfirmationMessage: FollowConfirmationMessage.fromPartial(object.message.followConfirmationMessage),
      };
    }
    if (
      object.message?.$case === "followAbortMessage" &&
      object.message?.followAbortMessage !== undefined &&
      object.message?.followAbortMessage !== null
    ) {
      message.message = {
        $case: "followAbortMessage",
        followAbortMessage: FollowAbortMessage.fromPartial(object.message.followAbortMessage),
      };
    }
    if (
      object.message?.$case === "lockGroupPromptMessage" &&
      object.message?.lockGroupPromptMessage !== undefined &&
      object.message?.lockGroupPromptMessage !== null
    ) {
      message.message = {
        $case: "lockGroupPromptMessage",
        lockGroupPromptMessage: LockGroupPromptMessage.fromPartial(object.message.lockGroupPromptMessage),
      };
    }
    if (
      object.message?.$case === "queryMessage" &&
      object.message?.queryMessage !== undefined &&
      object.message?.queryMessage !== null
    ) {
      message.message = { $case: "queryMessage", queryMessage: QueryMessage.fromPartial(object.message.queryMessage) };
    }
    if (
      object.message?.$case === "pingMessage" &&
      object.message?.pingMessage !== undefined &&
      object.message?.pingMessage !== null
    ) {
      message.message = { $case: "pingMessage", pingMessage: PingMessage.fromPartial(object.message.pingMessage) };
    }
    if (
      object.message?.$case === "askPositionMessage" &&
      object.message?.askPositionMessage !== undefined &&
      object.message?.askPositionMessage !== null
    ) {
      message.message = {
        $case: "askPositionMessage",
        askPositionMessage: AskPositionMessage.fromPartial(object.message.askPositionMessage),
      };
    }
    if (
      object.message?.$case === "editMapCommandMessage" &&
      object.message?.editMapCommandMessage !== undefined &&
      object.message?.editMapCommandMessage !== null
    ) {
      message.message = {
        $case: "editMapCommandMessage",
        editMapCommandMessage: EditMapCommandMessage.fromPartial(object.message.editMapCommandMessage),
      };
    }
    if (
      object.message?.$case === "addSpaceFilterMessage" &&
      object.message?.addSpaceFilterMessage !== undefined &&
      object.message?.addSpaceFilterMessage !== null
    ) {
      message.message = {
        $case: "addSpaceFilterMessage",
        addSpaceFilterMessage: AddSpaceFilterMessage.fromPartial(object.message.addSpaceFilterMessage),
      };
    }
    if (
      object.message?.$case === "removeSpaceFilterMessage" &&
      object.message?.removeSpaceFilterMessage !== undefined &&
      object.message?.removeSpaceFilterMessage !== null
    ) {
      message.message = {
        $case: "removeSpaceFilterMessage",
        removeSpaceFilterMessage: RemoveSpaceFilterMessage.fromPartial(object.message.removeSpaceFilterMessage),
      };
    }
    if (
      object.message?.$case === "updateSpaceFilterMessage" &&
      object.message?.updateSpaceFilterMessage !== undefined &&
      object.message?.updateSpaceFilterMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceFilterMessage",
        updateSpaceFilterMessage: UpdateSpaceFilterMessage.fromPartial(object.message.updateSpaceFilterMessage),
      };
    }
    if (
      object.message?.$case === "watchSpaceMessage" &&
      object.message?.watchSpaceMessage !== undefined &&
      object.message?.watchSpaceMessage !== null
    ) {
      message.message = {
        $case: "watchSpaceMessage",
        watchSpaceMessage: WatchSpaceMessage.fromPartial(object.message.watchSpaceMessage),
      };
    }
    if (
      object.message?.$case === "unwatchSpaceMessage" &&
      object.message?.unwatchSpaceMessage !== undefined &&
      object.message?.unwatchSpaceMessage !== null
    ) {
      message.message = {
        $case: "unwatchSpaceMessage",
        unwatchSpaceMessage: UnwatchSpaceMessage.fromPartial(object.message.unwatchSpaceMessage),
      };
    }
    if (
      object.message?.$case === "cameraStateMessage" &&
      object.message?.cameraStateMessage !== undefined &&
      object.message?.cameraStateMessage !== null
    ) {
      message.message = {
        $case: "cameraStateMessage",
        cameraStateMessage: CameraStateMessage.fromPartial(object.message.cameraStateMessage),
      };
    }
    if (
      object.message?.$case === "microphoneStateMessage" &&
      object.message?.microphoneStateMessage !== undefined &&
      object.message?.microphoneStateMessage !== null
    ) {
      message.message = {
        $case: "microphoneStateMessage",
        microphoneStateMessage: MicrophoneStateMessage.fromPartial(object.message.microphoneStateMessage),
      };
    }
    if (
      object.message?.$case === "megaphoneStateMessage" &&
      object.message?.megaphoneStateMessage !== undefined &&
      object.message?.megaphoneStateMessage !== null
    ) {
      message.message = {
        $case: "megaphoneStateMessage",
        megaphoneStateMessage: MegaphoneStateMessage.fromPartial(object.message.megaphoneStateMessage),
      };
    }
    if (
      object.message?.$case === "screenSharingStateMessage" &&
      object.message?.screenSharingStateMessage !== undefined &&
      object.message?.screenSharingStateMessage !== null
    ) {
      message.message = {
        $case: "screenSharingStateMessage",
        screenSharingStateMessage: ScreenSharingStateMessage.fromPartial(object.message.screenSharingStateMessage),
      };
    }
    if (
      object.message?.$case === "jitsiParticipantIdSpaceMessage" &&
      object.message?.jitsiParticipantIdSpaceMessage !== undefined &&
      object.message?.jitsiParticipantIdSpaceMessage !== null
    ) {
      message.message = {
        $case: "jitsiParticipantIdSpaceMessage",
        jitsiParticipantIdSpaceMessage: JitsiParticipantIdSpaceMessage.fromPartial(
          object.message.jitsiParticipantIdSpaceMessage,
        ),
      };
    }
    if (
      object.message?.$case === "updateSpaceMetadataMessage" &&
      object.message?.updateSpaceMetadataMessage !== undefined &&
      object.message?.updateSpaceMetadataMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceMetadataMessage",
        updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromPartial(object.message.updateSpaceMetadataMessage),
      };
    }
    if (
      object.message?.$case === "kickOffUserMessage" &&
      object.message?.kickOffUserMessage !== undefined &&
      object.message?.kickOffUserMessage !== null
    ) {
      message.message = {
        $case: "kickOffUserMessage",
        kickOffUserMessage: KickOffUserMessage.fromPartial(object.message.kickOffUserMessage),
      };
    }
    if (
      object.message?.$case === "muteParticipantIdMessage" &&
      object.message?.muteParticipantIdMessage !== undefined &&
      object.message?.muteParticipantIdMessage !== null
    ) {
      message.message = {
        $case: "muteParticipantIdMessage",
        muteParticipantIdMessage: MuteParticipantIdMessage.fromPartial(object.message.muteParticipantIdMessage),
      };
    }
    if (
      object.message?.$case === "muteEveryBodyParticipantMessage" &&
      object.message?.muteEveryBodyParticipantMessage !== undefined &&
      object.message?.muteEveryBodyParticipantMessage !== null
    ) {
      message.message = {
        $case: "muteEveryBodyParticipantMessage",
        muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage.fromPartial(
          object.message.muteEveryBodyParticipantMessage,
        ),
      };
    }
    if (
      object.message?.$case === "muteVideoParticipantIdMessage" &&
      object.message?.muteVideoParticipantIdMessage !== undefined &&
      object.message?.muteVideoParticipantIdMessage !== null
    ) {
      message.message = {
        $case: "muteVideoParticipantIdMessage",
        muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage.fromPartial(
          object.message.muteVideoParticipantIdMessage,
        ),
      };
    }
    if (
      object.message?.$case === "muteVideoEveryBodyParticipantMessage" &&
      object.message?.muteVideoEveryBodyParticipantMessage !== undefined &&
      object.message?.muteVideoEveryBodyParticipantMessage !== null
    ) {
      message.message = {
        $case: "muteVideoEveryBodyParticipantMessage",
        muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage.fromPartial(
          object.message.muteVideoEveryBodyParticipantMessage,
        ),
      };
    }
    if (
      object.message?.$case === "banPlayerMessage" &&
      object.message?.banPlayerMessage !== undefined &&
      object.message?.banPlayerMessage !== null
    ) {
      message.message = {
        $case: "banPlayerMessage",
        banPlayerMessage: BanPlayerMessage.fromPartial(object.message.banPlayerMessage),
      };
    }
    return message;
  },
};

function createBaseReceivedEventMessage(): ReceivedEventMessage {
  return { name: "", data: undefined, senderId: undefined };
}

export const ReceivedEventMessage = {
  encode(message: ReceivedEventMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.data !== undefined) {
      Value.encode(Value.wrap(message.data), writer.uint32(18).fork()).ldelim();
    }
    if (message.senderId !== undefined) {
      writer.uint32(24).int32(message.senderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReceivedEventMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReceivedEventMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.senderId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReceivedEventMessage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      data: isSet(object?.data) ? object.data : undefined,
      senderId: isSet(object.senderId) ? globalThis.Number(object.senderId) : undefined,
    };
  },

  toJSON(message: ReceivedEventMessage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.data !== undefined) {
      obj.data = message.data;
    }
    if (message.senderId !== undefined) {
      obj.senderId = Math.round(message.senderId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReceivedEventMessage>, I>>(base?: I): ReceivedEventMessage {
    return ReceivedEventMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReceivedEventMessage>, I>>(object: I): ReceivedEventMessage {
    const message = createBaseReceivedEventMessage();
    message.name = object.name ?? "";
    message.data = object.data ?? undefined;
    message.senderId = object.senderId ?? undefined;
    return message;
  },
};

function createBaseModifiyWAMMetadataMessage(): ModifiyWAMMetadataMessage {
  return { name: "", description: undefined, copyright: undefined, thumbnail: undefined, tags: undefined };
}

export const ModifiyWAMMetadataMessage = {
  encode(message: ModifiyWAMMetadataMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.copyright !== undefined) {
      writer.uint32(26).string(message.copyright);
    }
    if (message.thumbnail !== undefined) {
      writer.uint32(34).string(message.thumbnail);
    }
    if (message.tags !== undefined) {
      writer.uint32(42).string(message.tags);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifiyWAMMetadataMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifiyWAMMetadataMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.copyright = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.thumbnail = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModifiyWAMMetadataMessage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      copyright: isSet(object.copyright) ? globalThis.String(object.copyright) : undefined,
      thumbnail: isSet(object.thumbnail) ? globalThis.String(object.thumbnail) : undefined,
      tags: isSet(object.tags) ? globalThis.String(object.tags) : undefined,
    };
  },

  toJSON(message: ModifiyWAMMetadataMessage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.copyright !== undefined) {
      obj.copyright = message.copyright;
    }
    if (message.thumbnail !== undefined) {
      obj.thumbnail = message.thumbnail;
    }
    if (message.tags !== undefined) {
      obj.tags = message.tags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifiyWAMMetadataMessage>, I>>(base?: I): ModifiyWAMMetadataMessage {
    return ModifiyWAMMetadataMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModifiyWAMMetadataMessage>, I>>(object: I): ModifiyWAMMetadataMessage {
    const message = createBaseModifiyWAMMetadataMessage();
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    message.copyright = object.copyright ?? undefined;
    message.thumbnail = object.thumbnail ?? undefined;
    message.tags = object.tags ?? undefined;
    return message;
  },
};

function createBaseItemEventMessage(): ItemEventMessage {
  return { itemId: 0, event: "", stateJson: "", parametersJson: "" };
}

export const ItemEventMessage = {
  encode(message: ItemEventMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.itemId !== 0) {
      writer.uint32(8).int32(message.itemId);
    }
    if (message.event !== "") {
      writer.uint32(18).string(message.event);
    }
    if (message.stateJson !== "") {
      writer.uint32(26).string(message.stateJson);
    }
    if (message.parametersJson !== "") {
      writer.uint32(34).string(message.parametersJson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ItemEventMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseItemEventMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.itemId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.event = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.stateJson = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.parametersJson = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ItemEventMessage {
    return {
      itemId: isSet(object.itemId) ? globalThis.Number(object.itemId) : 0,
      event: isSet(object.event) ? globalThis.String(object.event) : "",
      stateJson: isSet(object.stateJson) ? globalThis.String(object.stateJson) : "",
      parametersJson: isSet(object.parametersJson) ? globalThis.String(object.parametersJson) : "",
    };
  },

  toJSON(message: ItemEventMessage): unknown {
    const obj: any = {};
    if (message.itemId !== 0) {
      obj.itemId = Math.round(message.itemId);
    }
    if (message.event !== "") {
      obj.event = message.event;
    }
    if (message.stateJson !== "") {
      obj.stateJson = message.stateJson;
    }
    if (message.parametersJson !== "") {
      obj.parametersJson = message.parametersJson;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ItemEventMessage>, I>>(base?: I): ItemEventMessage {
    return ItemEventMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ItemEventMessage>, I>>(object: I): ItemEventMessage {
    const message = createBaseItemEventMessage();
    message.itemId = object.itemId ?? 0;
    message.event = object.event ?? "";
    message.stateJson = object.stateJson ?? "";
    message.parametersJson = object.parametersJson ?? "";
    return message;
  },
};

function createBaseVariableMessage(): VariableMessage {
  return { name: "", value: "" };
}

export const VariableMessage = {
  encode(message: VariableMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariableMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVariableMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VariableMessage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: VariableMessage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VariableMessage>, I>>(base?: I): VariableMessage {
    return VariableMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VariableMessage>, I>>(object: I): VariableMessage {
    const message = createBaseVariableMessage();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseSetPlayerVariableMessage(): SetPlayerVariableMessage {
  return { name: "", value: "", public: false, persist: false, ttl: undefined, scope: 0 };
}

export const SetPlayerVariableMessage = {
  encode(message: SetPlayerVariableMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.public === true) {
      writer.uint32(24).bool(message.public);
    }
    if (message.persist === true) {
      writer.uint32(32).bool(message.persist);
    }
    if (message.ttl !== undefined) {
      Int32Value.encode({ value: message.ttl! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.scope !== 0) {
      writer.uint32(48).int32(message.scope);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPlayerVariableMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPlayerVariableMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.public = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.persist = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ttl = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.scope = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPlayerVariableMessage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      public: isSet(object.public) ? globalThis.Boolean(object.public) : false,
      persist: isSet(object.persist) ? globalThis.Boolean(object.persist) : false,
      ttl: isSet(object.ttl) ? Number(object.ttl) : undefined,
      scope: isSet(object.scope) ? setPlayerVariableMessage_ScopeFromJSON(object.scope) : 0,
    };
  },

  toJSON(message: SetPlayerVariableMessage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.public === true) {
      obj.public = message.public;
    }
    if (message.persist === true) {
      obj.persist = message.persist;
    }
    if (message.ttl !== undefined) {
      obj.ttl = message.ttl;
    }
    if (message.scope !== 0) {
      obj.scope = setPlayerVariableMessage_ScopeToJSON(message.scope);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetPlayerVariableMessage>, I>>(base?: I): SetPlayerVariableMessage {
    return SetPlayerVariableMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetPlayerVariableMessage>, I>>(object: I): SetPlayerVariableMessage {
    const message = createBaseSetPlayerVariableMessage();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    message.public = object.public ?? false;
    message.persist = object.persist ?? false;
    message.ttl = object.ttl ?? undefined;
    message.scope = object.scope ?? 0;
    return message;
  },
};

function createBaseVariableWithTagMessage(): VariableWithTagMessage {
  return { name: "", value: "", readableBy: "" };
}

export const VariableWithTagMessage = {
  encode(message: VariableWithTagMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.readableBy !== "") {
      writer.uint32(26).string(message.readableBy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariableWithTagMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVariableWithTagMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.readableBy = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VariableWithTagMessage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      readableBy: isSet(object.readableBy) ? globalThis.String(object.readableBy) : "",
    };
  },

  toJSON(message: VariableWithTagMessage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.readableBy !== "") {
      obj.readableBy = message.readableBy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VariableWithTagMessage>, I>>(base?: I): VariableWithTagMessage {
    return VariableWithTagMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VariableWithTagMessage>, I>>(object: I): VariableWithTagMessage {
    const message = createBaseVariableWithTagMessage();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    message.readableBy = object.readableBy ?? "";
    return message;
  },
};

function createBasePlayGlobalMessage(): PlayGlobalMessage {
  return { type: "", content: "", broadcastToWorld: false };
}

export const PlayGlobalMessage = {
  encode(message: PlayGlobalMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.content !== "") {
      writer.uint32(18).string(message.content);
    }
    if (message.broadcastToWorld === true) {
      writer.uint32(24).bool(message.broadcastToWorld);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PlayGlobalMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlayGlobalMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.content = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.broadcastToWorld = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlayGlobalMessage {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      broadcastToWorld: isSet(object.broadcastToWorld) ? globalThis.Boolean(object.broadcastToWorld) : false,
    };
  },

  toJSON(message: PlayGlobalMessage): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.broadcastToWorld === true) {
      obj.broadcastToWorld = message.broadcastToWorld;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlayGlobalMessage>, I>>(base?: I): PlayGlobalMessage {
    return PlayGlobalMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlayGlobalMessage>, I>>(object: I): PlayGlobalMessage {
    const message = createBasePlayGlobalMessage();
    message.type = object.type ?? "";
    message.content = object.content ?? "";
    message.broadcastToWorld = object.broadcastToWorld ?? false;
    return message;
  },
};

function createBaseQueryMessage(): QueryMessage {
  return { id: 0, query: undefined };
}

export const QueryMessage = {
  encode(message: QueryMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    switch (message.query?.$case) {
      case "jitsiJwtQuery":
        JitsiJwtQuery.encode(message.query.jitsiJwtQuery, writer.uint32(18).fork()).ldelim();
        break;
      case "joinBBBMeetingQuery":
        JoinBBBMeetingQuery.encode(message.query.joinBBBMeetingQuery, writer.uint32(162).fork()).ldelim();
        break;
      case "roomTagsQuery":
        RoomTagsQuery.encode(message.query.roomTagsQuery, writer.uint32(26).fork()).ldelim();
        break;
      case "embeddableWebsiteQuery":
        EmbeddableWebsiteQuery.encode(message.query.embeddableWebsiteQuery, writer.uint32(34).fork()).ldelim();
        break;
      case "roomsFromSameWorldQuery":
        RoomsFromSameWorldQuery.encode(message.query.roomsFromSameWorldQuery, writer.uint32(42).fork()).ldelim();
        break;
      case "sendEventQuery":
        SendEventQuery.encode(message.query.sendEventQuery, writer.uint32(50).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query = { $case: "jitsiJwtQuery", jitsiJwtQuery: JitsiJwtQuery.decode(reader, reader.uint32()) };
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.query = {
            $case: "joinBBBMeetingQuery",
            joinBBBMeetingQuery: JoinBBBMeetingQuery.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.query = { $case: "roomTagsQuery", roomTagsQuery: RoomTagsQuery.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.query = {
            $case: "embeddableWebsiteQuery",
            embeddableWebsiteQuery: EmbeddableWebsiteQuery.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.query = {
            $case: "roomsFromSameWorldQuery",
            roomsFromSameWorldQuery: RoomsFromSameWorldQuery.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.query = { $case: "sendEventQuery", sendEventQuery: SendEventQuery.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryMessage {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      query: isSet(object.jitsiJwtQuery)
        ? { $case: "jitsiJwtQuery", jitsiJwtQuery: JitsiJwtQuery.fromJSON(object.jitsiJwtQuery) }
        : isSet(object.joinBBBMeetingQuery)
        ? {
          $case: "joinBBBMeetingQuery",
          joinBBBMeetingQuery: JoinBBBMeetingQuery.fromJSON(object.joinBBBMeetingQuery),
        }
        : isSet(object.roomTagsQuery)
        ? { $case: "roomTagsQuery", roomTagsQuery: RoomTagsQuery.fromJSON(object.roomTagsQuery) }
        : isSet(object.embeddableWebsiteQuery)
        ? {
          $case: "embeddableWebsiteQuery",
          embeddableWebsiteQuery: EmbeddableWebsiteQuery.fromJSON(object.embeddableWebsiteQuery),
        }
        : isSet(object.roomsFromSameWorldQuery)
        ? {
          $case: "roomsFromSameWorldQuery",
          roomsFromSameWorldQuery: RoomsFromSameWorldQuery.fromJSON(object.roomsFromSameWorldQuery),
        }
        : isSet(object.sendEventQuery)
        ? { $case: "sendEventQuery", sendEventQuery: SendEventQuery.fromJSON(object.sendEventQuery) }
        : undefined,
    };
  },

  toJSON(message: QueryMessage): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.query?.$case === "jitsiJwtQuery") {
      obj.jitsiJwtQuery = JitsiJwtQuery.toJSON(message.query.jitsiJwtQuery);
    }
    if (message.query?.$case === "joinBBBMeetingQuery") {
      obj.joinBBBMeetingQuery = JoinBBBMeetingQuery.toJSON(message.query.joinBBBMeetingQuery);
    }
    if (message.query?.$case === "roomTagsQuery") {
      obj.roomTagsQuery = RoomTagsQuery.toJSON(message.query.roomTagsQuery);
    }
    if (message.query?.$case === "embeddableWebsiteQuery") {
      obj.embeddableWebsiteQuery = EmbeddableWebsiteQuery.toJSON(message.query.embeddableWebsiteQuery);
    }
    if (message.query?.$case === "roomsFromSameWorldQuery") {
      obj.roomsFromSameWorldQuery = RoomsFromSameWorldQuery.toJSON(message.query.roomsFromSameWorldQuery);
    }
    if (message.query?.$case === "sendEventQuery") {
      obj.sendEventQuery = SendEventQuery.toJSON(message.query.sendEventQuery);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryMessage>, I>>(base?: I): QueryMessage {
    return QueryMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryMessage>, I>>(object: I): QueryMessage {
    const message = createBaseQueryMessage();
    message.id = object.id ?? 0;
    if (
      object.query?.$case === "jitsiJwtQuery" &&
      object.query?.jitsiJwtQuery !== undefined &&
      object.query?.jitsiJwtQuery !== null
    ) {
      message.query = { $case: "jitsiJwtQuery", jitsiJwtQuery: JitsiJwtQuery.fromPartial(object.query.jitsiJwtQuery) };
    }
    if (
      object.query?.$case === "joinBBBMeetingQuery" &&
      object.query?.joinBBBMeetingQuery !== undefined &&
      object.query?.joinBBBMeetingQuery !== null
    ) {
      message.query = {
        $case: "joinBBBMeetingQuery",
        joinBBBMeetingQuery: JoinBBBMeetingQuery.fromPartial(object.query.joinBBBMeetingQuery),
      };
    }
    if (
      object.query?.$case === "roomTagsQuery" &&
      object.query?.roomTagsQuery !== undefined &&
      object.query?.roomTagsQuery !== null
    ) {
      message.query = { $case: "roomTagsQuery", roomTagsQuery: RoomTagsQuery.fromPartial(object.query.roomTagsQuery) };
    }
    if (
      object.query?.$case === "embeddableWebsiteQuery" &&
      object.query?.embeddableWebsiteQuery !== undefined &&
      object.query?.embeddableWebsiteQuery !== null
    ) {
      message.query = {
        $case: "embeddableWebsiteQuery",
        embeddableWebsiteQuery: EmbeddableWebsiteQuery.fromPartial(object.query.embeddableWebsiteQuery),
      };
    }
    if (
      object.query?.$case === "roomsFromSameWorldQuery" &&
      object.query?.roomsFromSameWorldQuery !== undefined &&
      object.query?.roomsFromSameWorldQuery !== null
    ) {
      message.query = {
        $case: "roomsFromSameWorldQuery",
        roomsFromSameWorldQuery: RoomsFromSameWorldQuery.fromPartial(object.query.roomsFromSameWorldQuery),
      };
    }
    if (
      object.query?.$case === "sendEventQuery" &&
      object.query?.sendEventQuery !== undefined &&
      object.query?.sendEventQuery !== null
    ) {
      message.query = {
        $case: "sendEventQuery",
        sendEventQuery: SendEventQuery.fromPartial(object.query.sendEventQuery),
      };
    }
    return message;
  },
};

function createBaseJitsiJwtQuery(): JitsiJwtQuery {
  return { jitsiRoom: "" };
}

export const JitsiJwtQuery = {
  encode(message: JitsiJwtQuery, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jitsiRoom !== "") {
      writer.uint32(10).string(message.jitsiRoom);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JitsiJwtQuery {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJitsiJwtQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jitsiRoom = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JitsiJwtQuery {
    return { jitsiRoom: isSet(object.jitsiRoom) ? globalThis.String(object.jitsiRoom) : "" };
  },

  toJSON(message: JitsiJwtQuery): unknown {
    const obj: any = {};
    if (message.jitsiRoom !== "") {
      obj.jitsiRoom = message.jitsiRoom;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JitsiJwtQuery>, I>>(base?: I): JitsiJwtQuery {
    return JitsiJwtQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JitsiJwtQuery>, I>>(object: I): JitsiJwtQuery {
    const message = createBaseJitsiJwtQuery();
    message.jitsiRoom = object.jitsiRoom ?? "";
    return message;
  },
};

function createBaseJoinBBBMeetingQuery(): JoinBBBMeetingQuery {
  return { meetingId: "", localMeetingId: "", meetingName: "" };
}

export const JoinBBBMeetingQuery = {
  encode(message: JoinBBBMeetingQuery, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.meetingId !== "") {
      writer.uint32(10).string(message.meetingId);
    }
    if (message.localMeetingId !== "") {
      writer.uint32(18).string(message.localMeetingId);
    }
    if (message.meetingName !== "") {
      writer.uint32(26).string(message.meetingName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinBBBMeetingQuery {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinBBBMeetingQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meetingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.localMeetingId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.meetingName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinBBBMeetingQuery {
    return {
      meetingId: isSet(object.meetingId) ? globalThis.String(object.meetingId) : "",
      localMeetingId: isSet(object.localMeetingId) ? globalThis.String(object.localMeetingId) : "",
      meetingName: isSet(object.meetingName) ? globalThis.String(object.meetingName) : "",
    };
  },

  toJSON(message: JoinBBBMeetingQuery): unknown {
    const obj: any = {};
    if (message.meetingId !== "") {
      obj.meetingId = message.meetingId;
    }
    if (message.localMeetingId !== "") {
      obj.localMeetingId = message.localMeetingId;
    }
    if (message.meetingName !== "") {
      obj.meetingName = message.meetingName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinBBBMeetingQuery>, I>>(base?: I): JoinBBBMeetingQuery {
    return JoinBBBMeetingQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinBBBMeetingQuery>, I>>(object: I): JoinBBBMeetingQuery {
    const message = createBaseJoinBBBMeetingQuery();
    message.meetingId = object.meetingId ?? "";
    message.localMeetingId = object.localMeetingId ?? "";
    message.meetingName = object.meetingName ?? "";
    return message;
  },
};

function createBaseRoomTagsQuery(): RoomTagsQuery {
  return {};
}

export const RoomTagsQuery = {
  encode(_: RoomTagsQuery, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomTagsQuery {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomTagsQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RoomTagsQuery {
    return {};
  },

  toJSON(_: RoomTagsQuery): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomTagsQuery>, I>>(base?: I): RoomTagsQuery {
    return RoomTagsQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomTagsQuery>, I>>(_: I): RoomTagsQuery {
    const message = createBaseRoomTagsQuery();
    return message;
  },
};

function createBaseEmbeddableWebsiteQuery(): EmbeddableWebsiteQuery {
  return { url: "" };
}

export const EmbeddableWebsiteQuery = {
  encode(message: EmbeddableWebsiteQuery, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmbeddableWebsiteQuery {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmbeddableWebsiteQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmbeddableWebsiteQuery {
    return { url: isSet(object.url) ? globalThis.String(object.url) : "" };
  },

  toJSON(message: EmbeddableWebsiteQuery): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmbeddableWebsiteQuery>, I>>(base?: I): EmbeddableWebsiteQuery {
    return EmbeddableWebsiteQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmbeddableWebsiteQuery>, I>>(object: I): EmbeddableWebsiteQuery {
    const message = createBaseEmbeddableWebsiteQuery();
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseRoomsFromSameWorldQuery(): RoomsFromSameWorldQuery {
  return {};
}

export const RoomsFromSameWorldQuery = {
  encode(_: RoomsFromSameWorldQuery, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomsFromSameWorldQuery {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomsFromSameWorldQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RoomsFromSameWorldQuery {
    return {};
  },

  toJSON(_: RoomsFromSameWorldQuery): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomsFromSameWorldQuery>, I>>(base?: I): RoomsFromSameWorldQuery {
    return RoomsFromSameWorldQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomsFromSameWorldQuery>, I>>(_: I): RoomsFromSameWorldQuery {
    const message = createBaseRoomsFromSameWorldQuery();
    return message;
  },
};

function createBaseSendEventQuery(): SendEventQuery {
  return { name: "", data: undefined, targetUserIds: [] };
}

export const SendEventQuery = {
  encode(message: SendEventQuery, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.data !== undefined) {
      Value.encode(Value.wrap(message.data), writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.targetUserIds) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendEventQuery {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendEventQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag === 24) {
            message.targetUserIds.push(reader.int32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.targetUserIds.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendEventQuery {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      data: isSet(object?.data) ? object.data : undefined,
      targetUserIds: globalThis.Array.isArray(object?.targetUserIds)
        ? object.targetUserIds.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: SendEventQuery): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.data !== undefined) {
      obj.data = message.data;
    }
    if (message.targetUserIds?.length) {
      obj.targetUserIds = message.targetUserIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendEventQuery>, I>>(base?: I): SendEventQuery {
    return SendEventQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendEventQuery>, I>>(object: I): SendEventQuery {
    const message = createBaseSendEventQuery();
    message.name = object.name ?? "";
    message.data = object.data ?? undefined;
    message.targetUserIds = object.targetUserIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseAnswerMessage(): AnswerMessage {
  return { id: 0, answer: undefined };
}

export const AnswerMessage = {
  encode(message: AnswerMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    switch (message.answer?.$case) {
      case "error":
        ErrorMessage.encode(message.answer.error, writer.uint32(18).fork()).ldelim();
        break;
      case "jitsiJwtAnswer":
        JitsiJwtAnswer.encode(message.answer.jitsiJwtAnswer, writer.uint32(26).fork()).ldelim();
        break;
      case "joinBBBMeetingAnswer":
        JoinBBBMeetingAnswer.encode(message.answer.joinBBBMeetingAnswer, writer.uint32(34).fork()).ldelim();
        break;
      case "roomTagsAnswer":
        RoomTagsAnswer.encode(message.answer.roomTagsAnswer, writer.uint32(42).fork()).ldelim();
        break;
      case "embeddableWebsiteAnswer":
        EmbeddableWebsiteAnswer.encode(message.answer.embeddableWebsiteAnswer, writer.uint32(50).fork()).ldelim();
        break;
      case "roomsFromSameWorldAnswer":
        RoomsFromSameWorldAnswer.encode(message.answer.roomsFromSameWorldAnswer, writer.uint32(58).fork()).ldelim();
        break;
      case "sendEventAnswer":
        SendEventAnswer.encode(message.answer.sendEventAnswer, writer.uint32(66).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnswerMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnswerMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.answer = { $case: "error", error: ErrorMessage.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.answer = { $case: "jitsiJwtAnswer", jitsiJwtAnswer: JitsiJwtAnswer.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.answer = {
            $case: "joinBBBMeetingAnswer",
            joinBBBMeetingAnswer: JoinBBBMeetingAnswer.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.answer = { $case: "roomTagsAnswer", roomTagsAnswer: RoomTagsAnswer.decode(reader, reader.uint32()) };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.answer = {
            $case: "embeddableWebsiteAnswer",
            embeddableWebsiteAnswer: EmbeddableWebsiteAnswer.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.answer = {
            $case: "roomsFromSameWorldAnswer",
            roomsFromSameWorldAnswer: RoomsFromSameWorldAnswer.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.answer = {
            $case: "sendEventAnswer",
            sendEventAnswer: SendEventAnswer.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnswerMessage {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      answer: isSet(object.error)
        ? { $case: "error", error: ErrorMessage.fromJSON(object.error) }
        : isSet(object.jitsiJwtAnswer)
        ? { $case: "jitsiJwtAnswer", jitsiJwtAnswer: JitsiJwtAnswer.fromJSON(object.jitsiJwtAnswer) }
        : isSet(object.joinBBBMeetingAnswer)
        ? {
          $case: "joinBBBMeetingAnswer",
          joinBBBMeetingAnswer: JoinBBBMeetingAnswer.fromJSON(object.joinBBBMeetingAnswer),
        }
        : isSet(object.roomTagsAnswer)
        ? { $case: "roomTagsAnswer", roomTagsAnswer: RoomTagsAnswer.fromJSON(object.roomTagsAnswer) }
        : isSet(object.embeddableWebsiteAnswer)
        ? {
          $case: "embeddableWebsiteAnswer",
          embeddableWebsiteAnswer: EmbeddableWebsiteAnswer.fromJSON(object.embeddableWebsiteAnswer),
        }
        : isSet(object.roomsFromSameWorldAnswer)
        ? {
          $case: "roomsFromSameWorldAnswer",
          roomsFromSameWorldAnswer: RoomsFromSameWorldAnswer.fromJSON(object.roomsFromSameWorldAnswer),
        }
        : isSet(object.sendEventAnswer)
        ? { $case: "sendEventAnswer", sendEventAnswer: SendEventAnswer.fromJSON(object.sendEventAnswer) }
        : undefined,
    };
  },

  toJSON(message: AnswerMessage): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.answer?.$case === "error") {
      obj.error = ErrorMessage.toJSON(message.answer.error);
    }
    if (message.answer?.$case === "jitsiJwtAnswer") {
      obj.jitsiJwtAnswer = JitsiJwtAnswer.toJSON(message.answer.jitsiJwtAnswer);
    }
    if (message.answer?.$case === "joinBBBMeetingAnswer") {
      obj.joinBBBMeetingAnswer = JoinBBBMeetingAnswer.toJSON(message.answer.joinBBBMeetingAnswer);
    }
    if (message.answer?.$case === "roomTagsAnswer") {
      obj.roomTagsAnswer = RoomTagsAnswer.toJSON(message.answer.roomTagsAnswer);
    }
    if (message.answer?.$case === "embeddableWebsiteAnswer") {
      obj.embeddableWebsiteAnswer = EmbeddableWebsiteAnswer.toJSON(message.answer.embeddableWebsiteAnswer);
    }
    if (message.answer?.$case === "roomsFromSameWorldAnswer") {
      obj.roomsFromSameWorldAnswer = RoomsFromSameWorldAnswer.toJSON(message.answer.roomsFromSameWorldAnswer);
    }
    if (message.answer?.$case === "sendEventAnswer") {
      obj.sendEventAnswer = SendEventAnswer.toJSON(message.answer.sendEventAnswer);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnswerMessage>, I>>(base?: I): AnswerMessage {
    return AnswerMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AnswerMessage>, I>>(object: I): AnswerMessage {
    const message = createBaseAnswerMessage();
    message.id = object.id ?? 0;
    if (object.answer?.$case === "error" && object.answer?.error !== undefined && object.answer?.error !== null) {
      message.answer = { $case: "error", error: ErrorMessage.fromPartial(object.answer.error) };
    }
    if (
      object.answer?.$case === "jitsiJwtAnswer" &&
      object.answer?.jitsiJwtAnswer !== undefined &&
      object.answer?.jitsiJwtAnswer !== null
    ) {
      message.answer = {
        $case: "jitsiJwtAnswer",
        jitsiJwtAnswer: JitsiJwtAnswer.fromPartial(object.answer.jitsiJwtAnswer),
      };
    }
    if (
      object.answer?.$case === "joinBBBMeetingAnswer" &&
      object.answer?.joinBBBMeetingAnswer !== undefined &&
      object.answer?.joinBBBMeetingAnswer !== null
    ) {
      message.answer = {
        $case: "joinBBBMeetingAnswer",
        joinBBBMeetingAnswer: JoinBBBMeetingAnswer.fromPartial(object.answer.joinBBBMeetingAnswer),
      };
    }
    if (
      object.answer?.$case === "roomTagsAnswer" &&
      object.answer?.roomTagsAnswer !== undefined &&
      object.answer?.roomTagsAnswer !== null
    ) {
      message.answer = {
        $case: "roomTagsAnswer",
        roomTagsAnswer: RoomTagsAnswer.fromPartial(object.answer.roomTagsAnswer),
      };
    }
    if (
      object.answer?.$case === "embeddableWebsiteAnswer" &&
      object.answer?.embeddableWebsiteAnswer !== undefined &&
      object.answer?.embeddableWebsiteAnswer !== null
    ) {
      message.answer = {
        $case: "embeddableWebsiteAnswer",
        embeddableWebsiteAnswer: EmbeddableWebsiteAnswer.fromPartial(object.answer.embeddableWebsiteAnswer),
      };
    }
    if (
      object.answer?.$case === "roomsFromSameWorldAnswer" &&
      object.answer?.roomsFromSameWorldAnswer !== undefined &&
      object.answer?.roomsFromSameWorldAnswer !== null
    ) {
      message.answer = {
        $case: "roomsFromSameWorldAnswer",
        roomsFromSameWorldAnswer: RoomsFromSameWorldAnswer.fromPartial(object.answer.roomsFromSameWorldAnswer),
      };
    }
    if (
      object.answer?.$case === "sendEventAnswer" &&
      object.answer?.sendEventAnswer !== undefined &&
      object.answer?.sendEventAnswer !== null
    ) {
      message.answer = {
        $case: "sendEventAnswer",
        sendEventAnswer: SendEventAnswer.fromPartial(object.answer.sendEventAnswer),
      };
    }
    return message;
  },
};

function createBaseJitsiJwtAnswer(): JitsiJwtAnswer {
  return { jwt: "", url: "" };
}

export const JitsiJwtAnswer = {
  encode(message: JitsiJwtAnswer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jwt !== "") {
      writer.uint32(10).string(message.jwt);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JitsiJwtAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJitsiJwtAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jwt = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JitsiJwtAnswer {
    return {
      jwt: isSet(object.jwt) ? globalThis.String(object.jwt) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: JitsiJwtAnswer): unknown {
    const obj: any = {};
    if (message.jwt !== "") {
      obj.jwt = message.jwt;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JitsiJwtAnswer>, I>>(base?: I): JitsiJwtAnswer {
    return JitsiJwtAnswer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JitsiJwtAnswer>, I>>(object: I): JitsiJwtAnswer {
    const message = createBaseJitsiJwtAnswer();
    message.jwt = object.jwt ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseJoinBBBMeetingAnswer(): JoinBBBMeetingAnswer {
  return { meetingId: "", clientURL: "" };
}

export const JoinBBBMeetingAnswer = {
  encode(message: JoinBBBMeetingAnswer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.meetingId !== "") {
      writer.uint32(10).string(message.meetingId);
    }
    if (message.clientURL !== "") {
      writer.uint32(18).string(message.clientURL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinBBBMeetingAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinBBBMeetingAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.meetingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientURL = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinBBBMeetingAnswer {
    return {
      meetingId: isSet(object.meetingId) ? globalThis.String(object.meetingId) : "",
      clientURL: isSet(object.clientURL) ? globalThis.String(object.clientURL) : "",
    };
  },

  toJSON(message: JoinBBBMeetingAnswer): unknown {
    const obj: any = {};
    if (message.meetingId !== "") {
      obj.meetingId = message.meetingId;
    }
    if (message.clientURL !== "") {
      obj.clientURL = message.clientURL;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinBBBMeetingAnswer>, I>>(base?: I): JoinBBBMeetingAnswer {
    return JoinBBBMeetingAnswer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinBBBMeetingAnswer>, I>>(object: I): JoinBBBMeetingAnswer {
    const message = createBaseJoinBBBMeetingAnswer();
    message.meetingId = object.meetingId ?? "";
    message.clientURL = object.clientURL ?? "";
    return message;
  },
};

function createBaseRoomTagsAnswer(): RoomTagsAnswer {
  return { tags: [] };
}

export const RoomTagsAnswer = {
  encode(message: RoomTagsAnswer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tags) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomTagsAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomTagsAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomTagsAnswer {
    return { tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: RoomTagsAnswer): unknown {
    const obj: any = {};
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomTagsAnswer>, I>>(base?: I): RoomTagsAnswer {
    return RoomTagsAnswer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomTagsAnswer>, I>>(object: I): RoomTagsAnswer {
    const message = createBaseRoomTagsAnswer();
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBaseEmbeddableWebsiteAnswer(): EmbeddableWebsiteAnswer {
  return { url: "", state: false, embeddable: false, message: undefined };
}

export const EmbeddableWebsiteAnswer = {
  encode(message: EmbeddableWebsiteAnswer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.state === true) {
      writer.uint32(16).bool(message.state);
    }
    if (message.embeddable === true) {
      writer.uint32(24).bool(message.embeddable);
    }
    if (message.message !== undefined) {
      writer.uint32(34).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmbeddableWebsiteAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmbeddableWebsiteAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.embeddable = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmbeddableWebsiteAnswer {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      state: isSet(object.state) ? globalThis.Boolean(object.state) : false,
      embeddable: isSet(object.embeddable) ? globalThis.Boolean(object.embeddable) : false,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
    };
  },

  toJSON(message: EmbeddableWebsiteAnswer): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.state === true) {
      obj.state = message.state;
    }
    if (message.embeddable === true) {
      obj.embeddable = message.embeddable;
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmbeddableWebsiteAnswer>, I>>(base?: I): EmbeddableWebsiteAnswer {
    return EmbeddableWebsiteAnswer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmbeddableWebsiteAnswer>, I>>(object: I): EmbeddableWebsiteAnswer {
    const message = createBaseEmbeddableWebsiteAnswer();
    message.url = object.url ?? "";
    message.state = object.state ?? false;
    message.embeddable = object.embeddable ?? false;
    message.message = object.message ?? undefined;
    return message;
  },
};

function createBaseRoomShortDescription(): RoomShortDescription {
  return {
    name: "",
    roomUrl: "",
    wamUrl: undefined,
    description: undefined,
    copyright: undefined,
    thumbnail: undefined,
    areasSearchable: undefined,
    entitiesSearchable: undefined,
  };
}

export const RoomShortDescription = {
  encode(message: RoomShortDescription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.roomUrl !== "") {
      writer.uint32(18).string(message.roomUrl);
    }
    if (message.wamUrl !== undefined) {
      writer.uint32(26).string(message.wamUrl);
    }
    if (message.description !== undefined) {
      writer.uint32(34).string(message.description);
    }
    if (message.copyright !== undefined) {
      writer.uint32(42).string(message.copyright);
    }
    if (message.thumbnail !== undefined) {
      writer.uint32(50).string(message.thumbnail);
    }
    if (message.areasSearchable !== undefined) {
      writer.uint32(56).int32(message.areasSearchable);
    }
    if (message.entitiesSearchable !== undefined) {
      writer.uint32(64).int32(message.entitiesSearchable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomShortDescription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomShortDescription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.wamUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.copyright = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.thumbnail = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.areasSearchable = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.entitiesSearchable = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomShortDescription {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      roomUrl: isSet(object.roomUrl) ? globalThis.String(object.roomUrl) : "",
      wamUrl: isSet(object.wamUrl) ? globalThis.String(object.wamUrl) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      copyright: isSet(object.copyright) ? globalThis.String(object.copyright) : undefined,
      thumbnail: isSet(object.thumbnail) ? globalThis.String(object.thumbnail) : undefined,
      areasSearchable: isSet(object.areasSearchable) ? globalThis.Number(object.areasSearchable) : undefined,
      entitiesSearchable: isSet(object.entitiesSearchable) ? globalThis.Number(object.entitiesSearchable) : undefined,
    };
  },

  toJSON(message: RoomShortDescription): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.roomUrl !== "") {
      obj.roomUrl = message.roomUrl;
    }
    if (message.wamUrl !== undefined) {
      obj.wamUrl = message.wamUrl;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.copyright !== undefined) {
      obj.copyright = message.copyright;
    }
    if (message.thumbnail !== undefined) {
      obj.thumbnail = message.thumbnail;
    }
    if (message.areasSearchable !== undefined) {
      obj.areasSearchable = Math.round(message.areasSearchable);
    }
    if (message.entitiesSearchable !== undefined) {
      obj.entitiesSearchable = Math.round(message.entitiesSearchable);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomShortDescription>, I>>(base?: I): RoomShortDescription {
    return RoomShortDescription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomShortDescription>, I>>(object: I): RoomShortDescription {
    const message = createBaseRoomShortDescription();
    message.name = object.name ?? "";
    message.roomUrl = object.roomUrl ?? "";
    message.wamUrl = object.wamUrl ?? undefined;
    message.description = object.description ?? undefined;
    message.copyright = object.copyright ?? undefined;
    message.thumbnail = object.thumbnail ?? undefined;
    message.areasSearchable = object.areasSearchable ?? undefined;
    message.entitiesSearchable = object.entitiesSearchable ?? undefined;
    return message;
  },
};

function createBaseRoomsFromSameWorldAnswer(): RoomsFromSameWorldAnswer {
  return { roomDescriptions: [] };
}

export const RoomsFromSameWorldAnswer = {
  encode(message: RoomsFromSameWorldAnswer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roomDescriptions) {
      RoomShortDescription.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomsFromSameWorldAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomsFromSameWorldAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomDescriptions.push(RoomShortDescription.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomsFromSameWorldAnswer {
    return {
      roomDescriptions: globalThis.Array.isArray(object?.roomDescriptions)
        ? object.roomDescriptions.map((e: any) => RoomShortDescription.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RoomsFromSameWorldAnswer): unknown {
    const obj: any = {};
    if (message.roomDescriptions?.length) {
      obj.roomDescriptions = message.roomDescriptions.map((e) => RoomShortDescription.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomsFromSameWorldAnswer>, I>>(base?: I): RoomsFromSameWorldAnswer {
    return RoomsFromSameWorldAnswer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomsFromSameWorldAnswer>, I>>(object: I): RoomsFromSameWorldAnswer {
    const message = createBaseRoomsFromSameWorldAnswer();
    message.roomDescriptions = object.roomDescriptions?.map((e) => RoomShortDescription.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSendEventAnswer(): SendEventAnswer {
  return {};
}

export const SendEventAnswer = {
  encode(_: SendEventAnswer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendEventAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendEventAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SendEventAnswer {
    return {};
  },

  toJSON(_: SendEventAnswer): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SendEventAnswer>, I>>(base?: I): SendEventAnswer {
    return SendEventAnswer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendEventAnswer>, I>>(_: I): SendEventAnswer {
    const message = createBaseSendEventAnswer();
    return message;
  },
};

function createBaseUserMovedMessage(): UserMovedMessage {
  return { userId: 0, position: undefined };
}

export const UserMovedMessage = {
  encode(message: UserMovedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.position !== undefined) {
      PositionMessage.encode(message.position, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMovedMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMovedMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.position = PositionMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMovedMessage {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      position: isSet(object.position) ? PositionMessage.fromJSON(object.position) : undefined,
    };
  },

  toJSON(message: UserMovedMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.position !== undefined) {
      obj.position = PositionMessage.toJSON(message.position);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMovedMessage>, I>>(base?: I): UserMovedMessage {
    return UserMovedMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMovedMessage>, I>>(object: I): UserMovedMessage {
    const message = createBaseUserMovedMessage();
    message.userId = object.userId ?? 0;
    message.position = (object.position !== undefined && object.position !== null)
      ? PositionMessage.fromPartial(object.position)
      : undefined;
    return message;
  },
};

function createBaseMoveToPositionMessage(): MoveToPositionMessage {
  return { position: undefined };
}

export const MoveToPositionMessage = {
  encode(message: MoveToPositionMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.position !== undefined) {
      PositionMessage.encode(message.position, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MoveToPositionMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMoveToPositionMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.position = PositionMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MoveToPositionMessage {
    return { position: isSet(object.position) ? PositionMessage.fromJSON(object.position) : undefined };
  },

  toJSON(message: MoveToPositionMessage): unknown {
    const obj: any = {};
    if (message.position !== undefined) {
      obj.position = PositionMessage.toJSON(message.position);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MoveToPositionMessage>, I>>(base?: I): MoveToPositionMessage {
    return MoveToPositionMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MoveToPositionMessage>, I>>(object: I): MoveToPositionMessage {
    const message = createBaseMoveToPositionMessage();
    message.position = (object.position !== undefined && object.position !== null)
      ? PositionMessage.fromPartial(object.position)
      : undefined;
    return message;
  },
};

function createBaseSubMessage(): SubMessage {
  return { message: undefined };
}

export const SubMessage = {
  encode(message: SubMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "userMovedMessage":
        UserMovedMessage.encode(message.message.userMovedMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "groupUpdateMessage":
        GroupUpdateMessage.encode(message.message.groupUpdateMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "groupDeleteMessage":
        GroupDeleteMessage.encode(message.message.groupDeleteMessage, writer.uint32(26).fork()).ldelim();
        break;
      case "userJoinedMessage":
        UserJoinedMessage.encode(message.message.userJoinedMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "userLeftMessage":
        UserLeftMessage.encode(message.message.userLeftMessage, writer.uint32(42).fork()).ldelim();
        break;
      case "itemEventMessage":
        ItemEventMessage.encode(message.message.itemEventMessage, writer.uint32(50).fork()).ldelim();
        break;
      case "emoteEventMessage":
        EmoteEventMessage.encode(message.message.emoteEventMessage, writer.uint32(58).fork()).ldelim();
        break;
      case "variableMessage":
        VariableMessage.encode(message.message.variableMessage, writer.uint32(66).fork()).ldelim();
        break;
      case "errorMessage":
        ErrorMessage.encode(message.message.errorMessage, writer.uint32(74).fork()).ldelim();
        break;
      case "playerDetailsUpdatedMessage":
        PlayerDetailsUpdatedMessage.encode(message.message.playerDetailsUpdatedMessage, writer.uint32(82).fork())
          .ldelim();
        break;
      case "pingMessage":
        PingMessage.encode(message.message.pingMessage, writer.uint32(90).fork()).ldelim();
        break;
      case "editMapCommandMessage":
        EditMapCommandMessage.encode(message.message.editMapCommandMessage, writer.uint32(106).fork()).ldelim();
        break;
      case "joinMucRoomMessage":
        JoinMucRoomMessage.encode(message.message.joinMucRoomMessage, writer.uint32(114).fork()).ldelim();
        break;
      case "leaveMucRoomMessage":
        LeaveMucRoomMessage.encode(message.message.leaveMucRoomMessage, writer.uint32(122).fork()).ldelim();
        break;
      case "addSpaceUserMessage":
        AddSpaceUserMessage.encode(message.message.addSpaceUserMessage, writer.uint32(130).fork()).ldelim();
        break;
      case "updateSpaceUserMessage":
        UpdateSpaceUserMessage.encode(message.message.updateSpaceUserMessage, writer.uint32(138).fork()).ldelim();
        break;
      case "removeSpaceUserMessage":
        RemoveSpaceUserMessage.encode(message.message.removeSpaceUserMessage, writer.uint32(146).fork()).ldelim();
        break;
      case "megaphoneSettingsMessage":
        MegaphoneSettings.encode(message.message.megaphoneSettingsMessage, writer.uint32(154).fork()).ldelim();
        break;
      case "receivedEventMessage":
        ReceivedEventMessage.encode(message.message.receivedEventMessage, writer.uint32(162).fork()).ldelim();
        break;
      case "updateSpaceMetadataMessage":
        UpdateSpaceMetadataMessage.encode(message.message.updateSpaceMetadataMessage, writer.uint32(170).fork())
          .ldelim();
        break;
      case "kickOffMessage":
        KickOffMessage.encode(message.message.kickOffMessage, writer.uint32(178).fork()).ldelim();
        break;
      case "muteMicrophoneMessage":
        MuteMicrophoneMessage.encode(message.message.muteMicrophoneMessage, writer.uint32(186).fork()).ldelim();
        break;
      case "muteVideoMessage":
        MuteVideoMessage.encode(message.message.muteVideoMessage, writer.uint32(194).fork()).ldelim();
        break;
      case "muteMicrophoneEverybodyMessage":
        MuteMicrophoneEverybodyMessage.encode(message.message.muteMicrophoneEverybodyMessage, writer.uint32(202).fork())
          .ldelim();
        break;
      case "muteVideoEverybodyMessage":
        MuteVideoEverybodyMessage.encode(message.message.muteVideoEverybodyMessage, writer.uint32(210).fork()).ldelim();
        break;
      case "askMuteMicrophoneMessage":
        AskMuteMicrophoneMessage.encode(message.message.askMuteMicrophoneMessage, writer.uint32(218).fork()).ldelim();
        break;
      case "askMuteVideoMessage":
        AskMuteVideoMessage.encode(message.message.askMuteVideoMessage, writer.uint32(226).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "userMovedMessage",
            userMovedMessage: UserMovedMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "groupUpdateMessage",
            groupUpdateMessage: GroupUpdateMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "groupDeleteMessage",
            groupDeleteMessage: GroupDeleteMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "userJoinedMessage",
            userJoinedMessage: UserJoinedMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "userLeftMessage",
            userLeftMessage: UserLeftMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = {
            $case: "itemEventMessage",
            itemEventMessage: ItemEventMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "emoteEventMessage",
            emoteEventMessage: EmoteEventMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.message = {
            $case: "variableMessage",
            variableMessage: VariableMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.message = { $case: "errorMessage", errorMessage: ErrorMessage.decode(reader, reader.uint32()) };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.message = {
            $case: "playerDetailsUpdatedMessage",
            playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.message = { $case: "pingMessage", pingMessage: PingMessage.decode(reader, reader.uint32()) };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.message = {
            $case: "editMapCommandMessage",
            editMapCommandMessage: EditMapCommandMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.message = {
            $case: "joinMucRoomMessage",
            joinMucRoomMessage: JoinMucRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.message = {
            $case: "leaveMucRoomMessage",
            leaveMucRoomMessage: LeaveMucRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.message = {
            $case: "addSpaceUserMessage",
            addSpaceUserMessage: AddSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.message = {
            $case: "updateSpaceUserMessage",
            updateSpaceUserMessage: UpdateSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.message = {
            $case: "removeSpaceUserMessage",
            removeSpaceUserMessage: RemoveSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.message = {
            $case: "megaphoneSettingsMessage",
            megaphoneSettingsMessage: MegaphoneSettings.decode(reader, reader.uint32()),
          };
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.message = {
            $case: "receivedEventMessage",
            receivedEventMessage: ReceivedEventMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.message = {
            $case: "updateSpaceMetadataMessage",
            updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.message = { $case: "kickOffMessage", kickOffMessage: KickOffMessage.decode(reader, reader.uint32()) };
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.message = {
            $case: "muteMicrophoneMessage",
            muteMicrophoneMessage: MuteMicrophoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.message = {
            $case: "muteVideoMessage",
            muteVideoMessage: MuteVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.message = {
            $case: "muteMicrophoneEverybodyMessage",
            muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.message = {
            $case: "muteVideoEverybodyMessage",
            muteVideoEverybodyMessage: MuteVideoEverybodyMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.message = {
            $case: "askMuteMicrophoneMessage",
            askMuteMicrophoneMessage: AskMuteMicrophoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.message = {
            $case: "askMuteVideoMessage",
            askMuteVideoMessage: AskMuteVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubMessage {
    return {
      message: isSet(object.userMovedMessage)
        ? { $case: "userMovedMessage", userMovedMessage: UserMovedMessage.fromJSON(object.userMovedMessage) }
        : isSet(object.groupUpdateMessage)
        ? { $case: "groupUpdateMessage", groupUpdateMessage: GroupUpdateMessage.fromJSON(object.groupUpdateMessage) }
        : isSet(object.groupDeleteMessage)
        ? { $case: "groupDeleteMessage", groupDeleteMessage: GroupDeleteMessage.fromJSON(object.groupDeleteMessage) }
        : isSet(object.userJoinedMessage)
        ? { $case: "userJoinedMessage", userJoinedMessage: UserJoinedMessage.fromJSON(object.userJoinedMessage) }
        : isSet(object.userLeftMessage)
        ? { $case: "userLeftMessage", userLeftMessage: UserLeftMessage.fromJSON(object.userLeftMessage) }
        : isSet(object.itemEventMessage)
        ? { $case: "itemEventMessage", itemEventMessage: ItemEventMessage.fromJSON(object.itemEventMessage) }
        : isSet(object.emoteEventMessage)
        ? { $case: "emoteEventMessage", emoteEventMessage: EmoteEventMessage.fromJSON(object.emoteEventMessage) }
        : isSet(object.variableMessage)
        ? { $case: "variableMessage", variableMessage: VariableMessage.fromJSON(object.variableMessage) }
        : isSet(object.errorMessage)
        ? { $case: "errorMessage", errorMessage: ErrorMessage.fromJSON(object.errorMessage) }
        : isSet(object.playerDetailsUpdatedMessage)
        ? {
          $case: "playerDetailsUpdatedMessage",
          playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage.fromJSON(object.playerDetailsUpdatedMessage),
        }
        : isSet(object.pingMessage)
        ? { $case: "pingMessage", pingMessage: PingMessage.fromJSON(object.pingMessage) }
        : isSet(object.editMapCommandMessage)
        ? {
          $case: "editMapCommandMessage",
          editMapCommandMessage: EditMapCommandMessage.fromJSON(object.editMapCommandMessage),
        }
        : isSet(object.joinMucRoomMessage)
        ? { $case: "joinMucRoomMessage", joinMucRoomMessage: JoinMucRoomMessage.fromJSON(object.joinMucRoomMessage) }
        : isSet(object.leaveMucRoomMessage)
        ? {
          $case: "leaveMucRoomMessage",
          leaveMucRoomMessage: LeaveMucRoomMessage.fromJSON(object.leaveMucRoomMessage),
        }
        : isSet(object.addSpaceUserMessage)
        ? {
          $case: "addSpaceUserMessage",
          addSpaceUserMessage: AddSpaceUserMessage.fromJSON(object.addSpaceUserMessage),
        }
        : isSet(object.updateSpaceUserMessage)
        ? {
          $case: "updateSpaceUserMessage",
          updateSpaceUserMessage: UpdateSpaceUserMessage.fromJSON(object.updateSpaceUserMessage),
        }
        : isSet(object.removeSpaceUserMessage)
        ? {
          $case: "removeSpaceUserMessage",
          removeSpaceUserMessage: RemoveSpaceUserMessage.fromJSON(object.removeSpaceUserMessage),
        }
        : isSet(object.megaphoneSettingsMessage)
        ? {
          $case: "megaphoneSettingsMessage",
          megaphoneSettingsMessage: MegaphoneSettings.fromJSON(object.megaphoneSettingsMessage),
        }
        : isSet(object.receivedEventMessage)
        ? {
          $case: "receivedEventMessage",
          receivedEventMessage: ReceivedEventMessage.fromJSON(object.receivedEventMessage),
        }
        : isSet(object.updateSpaceMetadataMessage)
        ? {
          $case: "updateSpaceMetadataMessage",
          updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromJSON(object.updateSpaceMetadataMessage),
        }
        : isSet(object.kickOffMessage)
        ? { $case: "kickOffMessage", kickOffMessage: KickOffMessage.fromJSON(object.kickOffMessage) }
        : isSet(object.muteMicrophoneMessage)
        ? {
          $case: "muteMicrophoneMessage",
          muteMicrophoneMessage: MuteMicrophoneMessage.fromJSON(object.muteMicrophoneMessage),
        }
        : isSet(object.muteVideoMessage)
        ? { $case: "muteVideoMessage", muteVideoMessage: MuteVideoMessage.fromJSON(object.muteVideoMessage) }
        : isSet(object.muteMicrophoneEverybodyMessage)
        ? {
          $case: "muteMicrophoneEverybodyMessage",
          muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.fromJSON(
            object.muteMicrophoneEverybodyMessage,
          ),
        }
        : isSet(object.muteVideoEverybodyMessage)
        ? {
          $case: "muteVideoEverybodyMessage",
          muteVideoEverybodyMessage: MuteVideoEverybodyMessage.fromJSON(object.muteVideoEverybodyMessage),
        }
        : isSet(object.askMuteMicrophoneMessage)
        ? {
          $case: "askMuteMicrophoneMessage",
          askMuteMicrophoneMessage: AskMuteMicrophoneMessage.fromJSON(object.askMuteMicrophoneMessage),
        }
        : isSet(object.askMuteVideoMessage)
        ? {
          $case: "askMuteVideoMessage",
          askMuteVideoMessage: AskMuteVideoMessage.fromJSON(object.askMuteVideoMessage),
        }
        : undefined,
    };
  },

  toJSON(message: SubMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "userMovedMessage") {
      obj.userMovedMessage = UserMovedMessage.toJSON(message.message.userMovedMessage);
    }
    if (message.message?.$case === "groupUpdateMessage") {
      obj.groupUpdateMessage = GroupUpdateMessage.toJSON(message.message.groupUpdateMessage);
    }
    if (message.message?.$case === "groupDeleteMessage") {
      obj.groupDeleteMessage = GroupDeleteMessage.toJSON(message.message.groupDeleteMessage);
    }
    if (message.message?.$case === "userJoinedMessage") {
      obj.userJoinedMessage = UserJoinedMessage.toJSON(message.message.userJoinedMessage);
    }
    if (message.message?.$case === "userLeftMessage") {
      obj.userLeftMessage = UserLeftMessage.toJSON(message.message.userLeftMessage);
    }
    if (message.message?.$case === "itemEventMessage") {
      obj.itemEventMessage = ItemEventMessage.toJSON(message.message.itemEventMessage);
    }
    if (message.message?.$case === "emoteEventMessage") {
      obj.emoteEventMessage = EmoteEventMessage.toJSON(message.message.emoteEventMessage);
    }
    if (message.message?.$case === "variableMessage") {
      obj.variableMessage = VariableMessage.toJSON(message.message.variableMessage);
    }
    if (message.message?.$case === "errorMessage") {
      obj.errorMessage = ErrorMessage.toJSON(message.message.errorMessage);
    }
    if (message.message?.$case === "playerDetailsUpdatedMessage") {
      obj.playerDetailsUpdatedMessage = PlayerDetailsUpdatedMessage.toJSON(message.message.playerDetailsUpdatedMessage);
    }
    if (message.message?.$case === "pingMessage") {
      obj.pingMessage = PingMessage.toJSON(message.message.pingMessage);
    }
    if (message.message?.$case === "editMapCommandMessage") {
      obj.editMapCommandMessage = EditMapCommandMessage.toJSON(message.message.editMapCommandMessage);
    }
    if (message.message?.$case === "joinMucRoomMessage") {
      obj.joinMucRoomMessage = JoinMucRoomMessage.toJSON(message.message.joinMucRoomMessage);
    }
    if (message.message?.$case === "leaveMucRoomMessage") {
      obj.leaveMucRoomMessage = LeaveMucRoomMessage.toJSON(message.message.leaveMucRoomMessage);
    }
    if (message.message?.$case === "addSpaceUserMessage") {
      obj.addSpaceUserMessage = AddSpaceUserMessage.toJSON(message.message.addSpaceUserMessage);
    }
    if (message.message?.$case === "updateSpaceUserMessage") {
      obj.updateSpaceUserMessage = UpdateSpaceUserMessage.toJSON(message.message.updateSpaceUserMessage);
    }
    if (message.message?.$case === "removeSpaceUserMessage") {
      obj.removeSpaceUserMessage = RemoveSpaceUserMessage.toJSON(message.message.removeSpaceUserMessage);
    }
    if (message.message?.$case === "megaphoneSettingsMessage") {
      obj.megaphoneSettingsMessage = MegaphoneSettings.toJSON(message.message.megaphoneSettingsMessage);
    }
    if (message.message?.$case === "receivedEventMessage") {
      obj.receivedEventMessage = ReceivedEventMessage.toJSON(message.message.receivedEventMessage);
    }
    if (message.message?.$case === "updateSpaceMetadataMessage") {
      obj.updateSpaceMetadataMessage = UpdateSpaceMetadataMessage.toJSON(message.message.updateSpaceMetadataMessage);
    }
    if (message.message?.$case === "kickOffMessage") {
      obj.kickOffMessage = KickOffMessage.toJSON(message.message.kickOffMessage);
    }
    if (message.message?.$case === "muteMicrophoneMessage") {
      obj.muteMicrophoneMessage = MuteMicrophoneMessage.toJSON(message.message.muteMicrophoneMessage);
    }
    if (message.message?.$case === "muteVideoMessage") {
      obj.muteVideoMessage = MuteVideoMessage.toJSON(message.message.muteVideoMessage);
    }
    if (message.message?.$case === "muteMicrophoneEverybodyMessage") {
      obj.muteMicrophoneEverybodyMessage = MuteMicrophoneEverybodyMessage.toJSON(
        message.message.muteMicrophoneEverybodyMessage,
      );
    }
    if (message.message?.$case === "muteVideoEverybodyMessage") {
      obj.muteVideoEverybodyMessage = MuteVideoEverybodyMessage.toJSON(message.message.muteVideoEverybodyMessage);
    }
    if (message.message?.$case === "askMuteMicrophoneMessage") {
      obj.askMuteMicrophoneMessage = AskMuteMicrophoneMessage.toJSON(message.message.askMuteMicrophoneMessage);
    }
    if (message.message?.$case === "askMuteVideoMessage") {
      obj.askMuteVideoMessage = AskMuteVideoMessage.toJSON(message.message.askMuteVideoMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubMessage>, I>>(base?: I): SubMessage {
    return SubMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubMessage>, I>>(object: I): SubMessage {
    const message = createBaseSubMessage();
    if (
      object.message?.$case === "userMovedMessage" &&
      object.message?.userMovedMessage !== undefined &&
      object.message?.userMovedMessage !== null
    ) {
      message.message = {
        $case: "userMovedMessage",
        userMovedMessage: UserMovedMessage.fromPartial(object.message.userMovedMessage),
      };
    }
    if (
      object.message?.$case === "groupUpdateMessage" &&
      object.message?.groupUpdateMessage !== undefined &&
      object.message?.groupUpdateMessage !== null
    ) {
      message.message = {
        $case: "groupUpdateMessage",
        groupUpdateMessage: GroupUpdateMessage.fromPartial(object.message.groupUpdateMessage),
      };
    }
    if (
      object.message?.$case === "groupDeleteMessage" &&
      object.message?.groupDeleteMessage !== undefined &&
      object.message?.groupDeleteMessage !== null
    ) {
      message.message = {
        $case: "groupDeleteMessage",
        groupDeleteMessage: GroupDeleteMessage.fromPartial(object.message.groupDeleteMessage),
      };
    }
    if (
      object.message?.$case === "userJoinedMessage" &&
      object.message?.userJoinedMessage !== undefined &&
      object.message?.userJoinedMessage !== null
    ) {
      message.message = {
        $case: "userJoinedMessage",
        userJoinedMessage: UserJoinedMessage.fromPartial(object.message.userJoinedMessage),
      };
    }
    if (
      object.message?.$case === "userLeftMessage" &&
      object.message?.userLeftMessage !== undefined &&
      object.message?.userLeftMessage !== null
    ) {
      message.message = {
        $case: "userLeftMessage",
        userLeftMessage: UserLeftMessage.fromPartial(object.message.userLeftMessage),
      };
    }
    if (
      object.message?.$case === "itemEventMessage" &&
      object.message?.itemEventMessage !== undefined &&
      object.message?.itemEventMessage !== null
    ) {
      message.message = {
        $case: "itemEventMessage",
        itemEventMessage: ItemEventMessage.fromPartial(object.message.itemEventMessage),
      };
    }
    if (
      object.message?.$case === "emoteEventMessage" &&
      object.message?.emoteEventMessage !== undefined &&
      object.message?.emoteEventMessage !== null
    ) {
      message.message = {
        $case: "emoteEventMessage",
        emoteEventMessage: EmoteEventMessage.fromPartial(object.message.emoteEventMessage),
      };
    }
    if (
      object.message?.$case === "variableMessage" &&
      object.message?.variableMessage !== undefined &&
      object.message?.variableMessage !== null
    ) {
      message.message = {
        $case: "variableMessage",
        variableMessage: VariableMessage.fromPartial(object.message.variableMessage),
      };
    }
    if (
      object.message?.$case === "errorMessage" &&
      object.message?.errorMessage !== undefined &&
      object.message?.errorMessage !== null
    ) {
      message.message = { $case: "errorMessage", errorMessage: ErrorMessage.fromPartial(object.message.errorMessage) };
    }
    if (
      object.message?.$case === "playerDetailsUpdatedMessage" &&
      object.message?.playerDetailsUpdatedMessage !== undefined &&
      object.message?.playerDetailsUpdatedMessage !== null
    ) {
      message.message = {
        $case: "playerDetailsUpdatedMessage",
        playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage.fromPartial(
          object.message.playerDetailsUpdatedMessage,
        ),
      };
    }
    if (
      object.message?.$case === "pingMessage" &&
      object.message?.pingMessage !== undefined &&
      object.message?.pingMessage !== null
    ) {
      message.message = { $case: "pingMessage", pingMessage: PingMessage.fromPartial(object.message.pingMessage) };
    }
    if (
      object.message?.$case === "editMapCommandMessage" &&
      object.message?.editMapCommandMessage !== undefined &&
      object.message?.editMapCommandMessage !== null
    ) {
      message.message = {
        $case: "editMapCommandMessage",
        editMapCommandMessage: EditMapCommandMessage.fromPartial(object.message.editMapCommandMessage),
      };
    }
    if (
      object.message?.$case === "joinMucRoomMessage" &&
      object.message?.joinMucRoomMessage !== undefined &&
      object.message?.joinMucRoomMessage !== null
    ) {
      message.message = {
        $case: "joinMucRoomMessage",
        joinMucRoomMessage: JoinMucRoomMessage.fromPartial(object.message.joinMucRoomMessage),
      };
    }
    if (
      object.message?.$case === "leaveMucRoomMessage" &&
      object.message?.leaveMucRoomMessage !== undefined &&
      object.message?.leaveMucRoomMessage !== null
    ) {
      message.message = {
        $case: "leaveMucRoomMessage",
        leaveMucRoomMessage: LeaveMucRoomMessage.fromPartial(object.message.leaveMucRoomMessage),
      };
    }
    if (
      object.message?.$case === "addSpaceUserMessage" &&
      object.message?.addSpaceUserMessage !== undefined &&
      object.message?.addSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "addSpaceUserMessage",
        addSpaceUserMessage: AddSpaceUserMessage.fromPartial(object.message.addSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "updateSpaceUserMessage" &&
      object.message?.updateSpaceUserMessage !== undefined &&
      object.message?.updateSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceUserMessage",
        updateSpaceUserMessage: UpdateSpaceUserMessage.fromPartial(object.message.updateSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "removeSpaceUserMessage" &&
      object.message?.removeSpaceUserMessage !== undefined &&
      object.message?.removeSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "removeSpaceUserMessage",
        removeSpaceUserMessage: RemoveSpaceUserMessage.fromPartial(object.message.removeSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "megaphoneSettingsMessage" &&
      object.message?.megaphoneSettingsMessage !== undefined &&
      object.message?.megaphoneSettingsMessage !== null
    ) {
      message.message = {
        $case: "megaphoneSettingsMessage",
        megaphoneSettingsMessage: MegaphoneSettings.fromPartial(object.message.megaphoneSettingsMessage),
      };
    }
    if (
      object.message?.$case === "receivedEventMessage" &&
      object.message?.receivedEventMessage !== undefined &&
      object.message?.receivedEventMessage !== null
    ) {
      message.message = {
        $case: "receivedEventMessage",
        receivedEventMessage: ReceivedEventMessage.fromPartial(object.message.receivedEventMessage),
      };
    }
    if (
      object.message?.$case === "updateSpaceMetadataMessage" &&
      object.message?.updateSpaceMetadataMessage !== undefined &&
      object.message?.updateSpaceMetadataMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceMetadataMessage",
        updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromPartial(object.message.updateSpaceMetadataMessage),
      };
    }
    if (
      object.message?.$case === "kickOffMessage" &&
      object.message?.kickOffMessage !== undefined &&
      object.message?.kickOffMessage !== null
    ) {
      message.message = {
        $case: "kickOffMessage",
        kickOffMessage: KickOffMessage.fromPartial(object.message.kickOffMessage),
      };
    }
    if (
      object.message?.$case === "muteMicrophoneMessage" &&
      object.message?.muteMicrophoneMessage !== undefined &&
      object.message?.muteMicrophoneMessage !== null
    ) {
      message.message = {
        $case: "muteMicrophoneMessage",
        muteMicrophoneMessage: MuteMicrophoneMessage.fromPartial(object.message.muteMicrophoneMessage),
      };
    }
    if (
      object.message?.$case === "muteVideoMessage" &&
      object.message?.muteVideoMessage !== undefined &&
      object.message?.muteVideoMessage !== null
    ) {
      message.message = {
        $case: "muteVideoMessage",
        muteVideoMessage: MuteVideoMessage.fromPartial(object.message.muteVideoMessage),
      };
    }
    if (
      object.message?.$case === "muteMicrophoneEverybodyMessage" &&
      object.message?.muteMicrophoneEverybodyMessage !== undefined &&
      object.message?.muteMicrophoneEverybodyMessage !== null
    ) {
      message.message = {
        $case: "muteMicrophoneEverybodyMessage",
        muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.fromPartial(
          object.message.muteMicrophoneEverybodyMessage,
        ),
      };
    }
    if (
      object.message?.$case === "muteVideoEverybodyMessage" &&
      object.message?.muteVideoEverybodyMessage !== undefined &&
      object.message?.muteVideoEverybodyMessage !== null
    ) {
      message.message = {
        $case: "muteVideoEverybodyMessage",
        muteVideoEverybodyMessage: MuteVideoEverybodyMessage.fromPartial(object.message.muteVideoEverybodyMessage),
      };
    }
    if (
      object.message?.$case === "askMuteMicrophoneMessage" &&
      object.message?.askMuteMicrophoneMessage !== undefined &&
      object.message?.askMuteMicrophoneMessage !== null
    ) {
      message.message = {
        $case: "askMuteMicrophoneMessage",
        askMuteMicrophoneMessage: AskMuteMicrophoneMessage.fromPartial(object.message.askMuteMicrophoneMessage),
      };
    }
    if (
      object.message?.$case === "askMuteVideoMessage" &&
      object.message?.askMuteVideoMessage !== undefined &&
      object.message?.askMuteVideoMessage !== null
    ) {
      message.message = {
        $case: "askMuteVideoMessage",
        askMuteVideoMessage: AskMuteVideoMessage.fromPartial(object.message.askMuteVideoMessage),
      };
    }
    return message;
  },
};

function createBaseBatchMessage(): BatchMessage {
  return { event: "", payload: [] };
}

export const BatchMessage = {
  encode(message: BatchMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.event !== "") {
      writer.uint32(10).string(message.event);
    }
    for (const v of message.payload) {
      SubMessage.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.event = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.payload.push(SubMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchMessage {
    return {
      event: isSet(object.event) ? globalThis.String(object.event) : "",
      payload: globalThis.Array.isArray(object?.payload) ? object.payload.map((e: any) => SubMessage.fromJSON(e)) : [],
    };
  },

  toJSON(message: BatchMessage): unknown {
    const obj: any = {};
    if (message.event !== "") {
      obj.event = message.event;
    }
    if (message.payload?.length) {
      obj.payload = message.payload.map((e) => SubMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchMessage>, I>>(base?: I): BatchMessage {
    return BatchMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchMessage>, I>>(object: I): BatchMessage {
    const message = createBaseBatchMessage();
    message.event = object.event ?? "";
    message.payload = object.payload?.map((e) => SubMessage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGroupUpdateMessage(): GroupUpdateMessage {
  return { groupId: 0, position: undefined, groupSize: undefined, locked: undefined };
}

export const GroupUpdateMessage = {
  encode(message: GroupUpdateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== 0) {
      writer.uint32(8).int32(message.groupId);
    }
    if (message.position !== undefined) {
      PointMessage.encode(message.position, writer.uint32(18).fork()).ldelim();
    }
    if (message.groupSize !== undefined) {
      UInt32Value.encode({ value: message.groupSize! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.locked !== undefined) {
      BoolValue.encode({ value: message.locked! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupUpdateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupUpdateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.groupId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.position = PointMessage.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.groupSize = UInt32Value.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.locked = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupUpdateMessage {
    return {
      groupId: isSet(object.groupId) ? globalThis.Number(object.groupId) : 0,
      position: isSet(object.position) ? PointMessage.fromJSON(object.position) : undefined,
      groupSize: isSet(object.groupSize) ? Number(object.groupSize) : undefined,
      locked: isSet(object.locked) ? Boolean(object.locked) : undefined,
    };
  },

  toJSON(message: GroupUpdateMessage): unknown {
    const obj: any = {};
    if (message.groupId !== 0) {
      obj.groupId = Math.round(message.groupId);
    }
    if (message.position !== undefined) {
      obj.position = PointMessage.toJSON(message.position);
    }
    if (message.groupSize !== undefined) {
      obj.groupSize = message.groupSize;
    }
    if (message.locked !== undefined) {
      obj.locked = message.locked;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupUpdateMessage>, I>>(base?: I): GroupUpdateMessage {
    return GroupUpdateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupUpdateMessage>, I>>(object: I): GroupUpdateMessage {
    const message = createBaseGroupUpdateMessage();
    message.groupId = object.groupId ?? 0;
    message.position = (object.position !== undefined && object.position !== null)
      ? PointMessage.fromPartial(object.position)
      : undefined;
    message.groupSize = object.groupSize ?? undefined;
    message.locked = object.locked ?? undefined;
    return message;
  },
};

function createBaseGroupDeleteMessage(): GroupDeleteMessage {
  return { groupId: 0 };
}

export const GroupDeleteMessage = {
  encode(message: GroupDeleteMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== 0) {
      writer.uint32(8).int32(message.groupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupDeleteMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupDeleteMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.groupId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupDeleteMessage {
    return { groupId: isSet(object.groupId) ? globalThis.Number(object.groupId) : 0 };
  },

  toJSON(message: GroupDeleteMessage): unknown {
    const obj: any = {};
    if (message.groupId !== 0) {
      obj.groupId = Math.round(message.groupId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupDeleteMessage>, I>>(base?: I): GroupDeleteMessage {
    return GroupDeleteMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupDeleteMessage>, I>>(object: I): GroupDeleteMessage {
    const message = createBaseGroupDeleteMessage();
    message.groupId = object.groupId ?? 0;
    return message;
  },
};

function createBaseUserJoinedMessage(): UserJoinedMessage {
  return {
    userId: 0,
    userJid: "",
    name: "",
    characterTextures: [],
    position: undefined,
    companionTexture: undefined,
    visitCardUrl: "",
    userUuid: "",
    outlineColor: 0,
    hasOutline: false,
    availabilityStatus: 0,
    variables: {},
  };
}

export const UserJoinedMessage = {
  encode(message: UserJoinedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.userJid !== "") {
      writer.uint32(18).string(message.userJid);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.characterTextures) {
      CharacterTextureMessage.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.position !== undefined) {
      PositionMessage.encode(message.position, writer.uint32(42).fork()).ldelim();
    }
    if (message.companionTexture !== undefined) {
      CompanionTextureMessage.encode(message.companionTexture, writer.uint32(50).fork()).ldelim();
    }
    if (message.visitCardUrl !== "") {
      writer.uint32(58).string(message.visitCardUrl);
    }
    if (message.userUuid !== "") {
      writer.uint32(66).string(message.userUuid);
    }
    if (message.outlineColor !== 0) {
      writer.uint32(72).uint32(message.outlineColor);
    }
    if (message.hasOutline === true) {
      writer.uint32(80).bool(message.hasOutline);
    }
    if (message.availabilityStatus !== 0) {
      writer.uint32(88).int32(message.availabilityStatus);
    }
    Object.entries(message.variables).forEach(([key, value]) => {
      UserJoinedMessage_VariablesEntry.encode({ key: key as any, value }, writer.uint32(98).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserJoinedMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserJoinedMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userJid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.characterTextures.push(CharacterTextureMessage.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.position = PositionMessage.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.companionTexture = CompanionTextureMessage.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.visitCardUrl = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.userUuid = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.outlineColor = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.hasOutline = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.availabilityStatus = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          const entry12 = UserJoinedMessage_VariablesEntry.decode(reader, reader.uint32());
          if (entry12.value !== undefined) {
            message.variables[entry12.key] = entry12.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserJoinedMessage {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      userJid: isSet(object.userJid) ? globalThis.String(object.userJid) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      characterTextures: globalThis.Array.isArray(object?.characterTextures)
        ? object.characterTextures.map((e: any) => CharacterTextureMessage.fromJSON(e))
        : [],
      position: isSet(object.position) ? PositionMessage.fromJSON(object.position) : undefined,
      companionTexture: isSet(object.companionTexture)
        ? CompanionTextureMessage.fromJSON(object.companionTexture)
        : undefined,
      visitCardUrl: isSet(object.visitCardUrl) ? globalThis.String(object.visitCardUrl) : "",
      userUuid: isSet(object.userUuid) ? globalThis.String(object.userUuid) : "",
      outlineColor: isSet(object.outlineColor) ? globalThis.Number(object.outlineColor) : 0,
      hasOutline: isSet(object.hasOutline) ? globalThis.Boolean(object.hasOutline) : false,
      availabilityStatus: isSet(object.availabilityStatus) ? availabilityStatusFromJSON(object.availabilityStatus) : 0,
      variables: isObject(object.variables)
        ? Object.entries(object.variables).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: UserJoinedMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.userJid !== "") {
      obj.userJid = message.userJid;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.characterTextures?.length) {
      obj.characterTextures = message.characterTextures.map((e) => CharacterTextureMessage.toJSON(e));
    }
    if (message.position !== undefined) {
      obj.position = PositionMessage.toJSON(message.position);
    }
    if (message.companionTexture !== undefined) {
      obj.companionTexture = CompanionTextureMessage.toJSON(message.companionTexture);
    }
    if (message.visitCardUrl !== "") {
      obj.visitCardUrl = message.visitCardUrl;
    }
    if (message.userUuid !== "") {
      obj.userUuid = message.userUuid;
    }
    if (message.outlineColor !== 0) {
      obj.outlineColor = Math.round(message.outlineColor);
    }
    if (message.hasOutline === true) {
      obj.hasOutline = message.hasOutline;
    }
    if (message.availabilityStatus !== 0) {
      obj.availabilityStatus = availabilityStatusToJSON(message.availabilityStatus);
    }
    if (message.variables) {
      const entries = Object.entries(message.variables);
      if (entries.length > 0) {
        obj.variables = {};
        entries.forEach(([k, v]) => {
          obj.variables[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserJoinedMessage>, I>>(base?: I): UserJoinedMessage {
    return UserJoinedMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserJoinedMessage>, I>>(object: I): UserJoinedMessage {
    const message = createBaseUserJoinedMessage();
    message.userId = object.userId ?? 0;
    message.userJid = object.userJid ?? "";
    message.name = object.name ?? "";
    message.characterTextures = object.characterTextures?.map((e) => CharacterTextureMessage.fromPartial(e)) || [];
    message.position = (object.position !== undefined && object.position !== null)
      ? PositionMessage.fromPartial(object.position)
      : undefined;
    message.companionTexture = (object.companionTexture !== undefined && object.companionTexture !== null)
      ? CompanionTextureMessage.fromPartial(object.companionTexture)
      : undefined;
    message.visitCardUrl = object.visitCardUrl ?? "";
    message.userUuid = object.userUuid ?? "";
    message.outlineColor = object.outlineColor ?? 0;
    message.hasOutline = object.hasOutline ?? false;
    message.availabilityStatus = object.availabilityStatus ?? 0;
    message.variables = Object.entries(object.variables ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUserJoinedMessage_VariablesEntry(): UserJoinedMessage_VariablesEntry {
  return { key: "", value: "" };
}

export const UserJoinedMessage_VariablesEntry = {
  encode(message: UserJoinedMessage_VariablesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserJoinedMessage_VariablesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserJoinedMessage_VariablesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserJoinedMessage_VariablesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: UserJoinedMessage_VariablesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserJoinedMessage_VariablesEntry>, I>>(
    base?: I,
  ): UserJoinedMessage_VariablesEntry {
    return UserJoinedMessage_VariablesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserJoinedMessage_VariablesEntry>, I>>(
    object: I,
  ): UserJoinedMessage_VariablesEntry {
    const message = createBaseUserJoinedMessage_VariablesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUserLeftMessage(): UserLeftMessage {
  return { userId: 0 };
}

export const UserLeftMessage = {
  encode(message: UserLeftMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserLeftMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserLeftMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserLeftMessage {
    return { userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0 };
  },

  toJSON(message: UserLeftMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserLeftMessage>, I>>(base?: I): UserLeftMessage {
    return UserLeftMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserLeftMessage>, I>>(object: I): UserLeftMessage {
    const message = createBaseUserLeftMessage();
    message.userId = object.userId ?? 0;
    return message;
  },
};

function createBaseErrorMessage(): ErrorMessage {
  return { message: "" };
}

export const ErrorMessage = {
  encode(message: ErrorMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ErrorMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ErrorMessage {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: ErrorMessage): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ErrorMessage>, I>>(base?: I): ErrorMessage {
    return ErrorMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ErrorMessage>, I>>(object: I): ErrorMessage {
    const message = createBaseErrorMessage();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseErrorScreenMessage(): ErrorScreenMessage {
  return {
    type: "",
    code: undefined,
    title: undefined,
    subtitle: undefined,
    details: undefined,
    timeToRetry: undefined,
    canRetryManual: undefined,
    urlToRedirect: undefined,
    buttonTitle: undefined,
    image: undefined,
  };
}

export const ErrorScreenMessage = {
  encode(message: ErrorScreenMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.code !== undefined) {
      StringValue.encode({ value: message.code! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== undefined) {
      StringValue.encode({ value: message.title! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.subtitle !== undefined) {
      StringValue.encode({ value: message.subtitle! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.details !== undefined) {
      StringValue.encode({ value: message.details! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.timeToRetry !== undefined) {
      Int32Value.encode({ value: message.timeToRetry! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.canRetryManual !== undefined) {
      BoolValue.encode({ value: message.canRetryManual! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.urlToRedirect !== undefined) {
      StringValue.encode({ value: message.urlToRedirect! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.buttonTitle !== undefined) {
      StringValue.encode({ value: message.buttonTitle! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.image !== undefined) {
      StringValue.encode({ value: message.image! }, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ErrorScreenMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorScreenMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.subtitle = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.details = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.timeToRetry = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.canRetryManual = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.urlToRedirect = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.buttonTitle = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.image = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ErrorScreenMessage {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      code: isSet(object.code) ? String(object.code) : undefined,
      title: isSet(object.title) ? String(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : undefined,
      details: isSet(object.details) ? String(object.details) : undefined,
      timeToRetry: isSet(object.timeToRetry) ? Number(object.timeToRetry) : undefined,
      canRetryManual: isSet(object.canRetryManual) ? Boolean(object.canRetryManual) : undefined,
      urlToRedirect: isSet(object.urlToRedirect) ? String(object.urlToRedirect) : undefined,
      buttonTitle: isSet(object.buttonTitle) ? String(object.buttonTitle) : undefined,
      image: isSet(object.image) ? String(object.image) : undefined,
    };
  },

  toJSON(message: ErrorScreenMessage): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.code !== undefined) {
      obj.code = message.code;
    }
    if (message.title !== undefined) {
      obj.title = message.title;
    }
    if (message.subtitle !== undefined) {
      obj.subtitle = message.subtitle;
    }
    if (message.details !== undefined) {
      obj.details = message.details;
    }
    if (message.timeToRetry !== undefined) {
      obj.timeToRetry = message.timeToRetry;
    }
    if (message.canRetryManual !== undefined) {
      obj.canRetryManual = message.canRetryManual;
    }
    if (message.urlToRedirect !== undefined) {
      obj.urlToRedirect = message.urlToRedirect;
    }
    if (message.buttonTitle !== undefined) {
      obj.buttonTitle = message.buttonTitle;
    }
    if (message.image !== undefined) {
      obj.image = message.image;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ErrorScreenMessage>, I>>(base?: I): ErrorScreenMessage {
    return ErrorScreenMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ErrorScreenMessage>, I>>(object: I): ErrorScreenMessage {
    const message = createBaseErrorScreenMessage();
    message.type = object.type ?? "";
    message.code = object.code ?? undefined;
    message.title = object.title ?? undefined;
    message.subtitle = object.subtitle ?? undefined;
    message.details = object.details ?? undefined;
    message.timeToRetry = object.timeToRetry ?? undefined;
    message.canRetryManual = object.canRetryManual ?? undefined;
    message.urlToRedirect = object.urlToRedirect ?? undefined;
    message.buttonTitle = object.buttonTitle ?? undefined;
    message.image = object.image ?? undefined;
    return message;
  },
};

function createBaseItemStateMessage(): ItemStateMessage {
  return { itemId: 0, stateJson: "" };
}

export const ItemStateMessage = {
  encode(message: ItemStateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.itemId !== 0) {
      writer.uint32(8).int32(message.itemId);
    }
    if (message.stateJson !== "") {
      writer.uint32(18).string(message.stateJson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ItemStateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseItemStateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.itemId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stateJson = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ItemStateMessage {
    return {
      itemId: isSet(object.itemId) ? globalThis.Number(object.itemId) : 0,
      stateJson: isSet(object.stateJson) ? globalThis.String(object.stateJson) : "",
    };
  },

  toJSON(message: ItemStateMessage): unknown {
    const obj: any = {};
    if (message.itemId !== 0) {
      obj.itemId = Math.round(message.itemId);
    }
    if (message.stateJson !== "") {
      obj.stateJson = message.stateJson;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ItemStateMessage>, I>>(base?: I): ItemStateMessage {
    return ItemStateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ItemStateMessage>, I>>(object: I): ItemStateMessage {
    const message = createBaseItemStateMessage();
    message.itemId = object.itemId ?? 0;
    message.stateJson = object.stateJson ?? "";
    return message;
  },
};

function createBaseGroupUsersUpdateMessage(): GroupUsersUpdateMessage {
  return { groupId: 0, userIds: [] };
}

export const GroupUsersUpdateMessage = {
  encode(message: GroupUsersUpdateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== 0) {
      writer.uint32(8).int32(message.groupId);
    }
    writer.uint32(18).fork();
    for (const v of message.userIds) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupUsersUpdateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupUsersUpdateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.groupId = reader.int32();
          continue;
        case 2:
          if (tag === 16) {
            message.userIds.push(reader.int32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.userIds.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupUsersUpdateMessage {
    return {
      groupId: isSet(object.groupId) ? globalThis.Number(object.groupId) : 0,
      userIds: globalThis.Array.isArray(object?.userIds) ? object.userIds.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: GroupUsersUpdateMessage): unknown {
    const obj: any = {};
    if (message.groupId !== 0) {
      obj.groupId = Math.round(message.groupId);
    }
    if (message.userIds?.length) {
      obj.userIds = message.userIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupUsersUpdateMessage>, I>>(base?: I): GroupUsersUpdateMessage {
    return GroupUsersUpdateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupUsersUpdateMessage>, I>>(object: I): GroupUsersUpdateMessage {
    const message = createBaseGroupUsersUpdateMessage();
    message.groupId = object.groupId ?? 0;
    message.userIds = object.userIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseRoomJoinedMessage(): RoomJoinedMessage {
  return {
    item: [],
    currentUserId: 0,
    tag: [],
    variable: [],
    userRoomToken: "",
    characterTextures: [],
    activatedInviteUser: false,
    playerVariable: [],
    applications: [],
    userJid: "",
    editMapCommandsArrayMessage: undefined,
    webrtcUserName: "",
    webrtcPassword: "",
    canEdit: false,
    megaphoneSettings: undefined,
    companionTexture: undefined,
  };
}

export const RoomJoinedMessage = {
  encode(message: RoomJoinedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.item) {
      ItemStateMessage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.currentUserId !== 0) {
      writer.uint32(32).int32(message.currentUserId);
    }
    for (const v of message.tag) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.variable) {
      VariableMessage.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.userRoomToken !== "") {
      writer.uint32(58).string(message.userRoomToken);
    }
    for (const v of message.characterTextures) {
      CharacterTextureMessage.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.activatedInviteUser === true) {
      writer.uint32(72).bool(message.activatedInviteUser);
    }
    for (const v of message.playerVariable) {
      VariableMessage.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.applications) {
      ApplicationMessage.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.userJid !== "") {
      writer.uint32(98).string(message.userJid);
    }
    if (message.editMapCommandsArrayMessage !== undefined) {
      EditMapCommandsArrayMessage.encode(message.editMapCommandsArrayMessage, writer.uint32(106).fork()).ldelim();
    }
    if (message.webrtcUserName !== "") {
      writer.uint32(114).string(message.webrtcUserName);
    }
    if (message.webrtcPassword !== "") {
      writer.uint32(122).string(message.webrtcPassword);
    }
    if (message.canEdit === true) {
      writer.uint32(128).bool(message.canEdit);
    }
    if (message.megaphoneSettings !== undefined) {
      MegaphoneSettings.encode(message.megaphoneSettings, writer.uint32(138).fork()).ldelim();
    }
    if (message.companionTexture !== undefined) {
      CompanionTextureMessage.encode(message.companionTexture, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomJoinedMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomJoinedMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.item.push(ItemStateMessage.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.currentUserId = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tag.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.variable.push(VariableMessage.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.userRoomToken = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.characterTextures.push(CharacterTextureMessage.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.activatedInviteUser = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.playerVariable.push(VariableMessage.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.applications.push(ApplicationMessage.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.userJid = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.editMapCommandsArrayMessage = EditMapCommandsArrayMessage.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.webrtcUserName = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.webrtcPassword = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.canEdit = reader.bool();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.megaphoneSettings = MegaphoneSettings.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.companionTexture = CompanionTextureMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomJoinedMessage {
    return {
      item: globalThis.Array.isArray(object?.item) ? object.item.map((e: any) => ItemStateMessage.fromJSON(e)) : [],
      currentUserId: isSet(object.currentUserId) ? globalThis.Number(object.currentUserId) : 0,
      tag: globalThis.Array.isArray(object?.tag) ? object.tag.map((e: any) => globalThis.String(e)) : [],
      variable: globalThis.Array.isArray(object?.variable)
        ? object.variable.map((e: any) => VariableMessage.fromJSON(e))
        : [],
      userRoomToken: isSet(object.userRoomToken) ? globalThis.String(object.userRoomToken) : "",
      characterTextures: globalThis.Array.isArray(object?.characterTextures)
        ? object.characterTextures.map((e: any) => CharacterTextureMessage.fromJSON(e))
        : [],
      activatedInviteUser: isSet(object.activatedInviteUser) ? globalThis.Boolean(object.activatedInviteUser) : false,
      playerVariable: globalThis.Array.isArray(object?.playerVariable)
        ? object.playerVariable.map((e: any) => VariableMessage.fromJSON(e))
        : [],
      applications: globalThis.Array.isArray(object?.applications)
        ? object.applications.map((e: any) => ApplicationMessage.fromJSON(e))
        : [],
      userJid: isSet(object.userJid) ? globalThis.String(object.userJid) : "",
      editMapCommandsArrayMessage: isSet(object.editMapCommandsArrayMessage)
        ? EditMapCommandsArrayMessage.fromJSON(object.editMapCommandsArrayMessage)
        : undefined,
      webrtcUserName: isSet(object.webrtcUserName) ? globalThis.String(object.webrtcUserName) : "",
      webrtcPassword: isSet(object.webrtcPassword) ? globalThis.String(object.webrtcPassword) : "",
      canEdit: isSet(object.canEdit) ? globalThis.Boolean(object.canEdit) : false,
      megaphoneSettings: isSet(object.megaphoneSettings)
        ? MegaphoneSettings.fromJSON(object.megaphoneSettings)
        : undefined,
      companionTexture: isSet(object.companionTexture)
        ? CompanionTextureMessage.fromJSON(object.companionTexture)
        : undefined,
    };
  },

  toJSON(message: RoomJoinedMessage): unknown {
    const obj: any = {};
    if (message.item?.length) {
      obj.item = message.item.map((e) => ItemStateMessage.toJSON(e));
    }
    if (message.currentUserId !== 0) {
      obj.currentUserId = Math.round(message.currentUserId);
    }
    if (message.tag?.length) {
      obj.tag = message.tag;
    }
    if (message.variable?.length) {
      obj.variable = message.variable.map((e) => VariableMessage.toJSON(e));
    }
    if (message.userRoomToken !== "") {
      obj.userRoomToken = message.userRoomToken;
    }
    if (message.characterTextures?.length) {
      obj.characterTextures = message.characterTextures.map((e) => CharacterTextureMessage.toJSON(e));
    }
    if (message.activatedInviteUser === true) {
      obj.activatedInviteUser = message.activatedInviteUser;
    }
    if (message.playerVariable?.length) {
      obj.playerVariable = message.playerVariable.map((e) => VariableMessage.toJSON(e));
    }
    if (message.applications?.length) {
      obj.applications = message.applications.map((e) => ApplicationMessage.toJSON(e));
    }
    if (message.userJid !== "") {
      obj.userJid = message.userJid;
    }
    if (message.editMapCommandsArrayMessage !== undefined) {
      obj.editMapCommandsArrayMessage = EditMapCommandsArrayMessage.toJSON(message.editMapCommandsArrayMessage);
    }
    if (message.webrtcUserName !== "") {
      obj.webrtcUserName = message.webrtcUserName;
    }
    if (message.webrtcPassword !== "") {
      obj.webrtcPassword = message.webrtcPassword;
    }
    if (message.canEdit === true) {
      obj.canEdit = message.canEdit;
    }
    if (message.megaphoneSettings !== undefined) {
      obj.megaphoneSettings = MegaphoneSettings.toJSON(message.megaphoneSettings);
    }
    if (message.companionTexture !== undefined) {
      obj.companionTexture = CompanionTextureMessage.toJSON(message.companionTexture);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomJoinedMessage>, I>>(base?: I): RoomJoinedMessage {
    return RoomJoinedMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomJoinedMessage>, I>>(object: I): RoomJoinedMessage {
    const message = createBaseRoomJoinedMessage();
    message.item = object.item?.map((e) => ItemStateMessage.fromPartial(e)) || [];
    message.currentUserId = object.currentUserId ?? 0;
    message.tag = object.tag?.map((e) => e) || [];
    message.variable = object.variable?.map((e) => VariableMessage.fromPartial(e)) || [];
    message.userRoomToken = object.userRoomToken ?? "";
    message.characterTextures = object.characterTextures?.map((e) => CharacterTextureMessage.fromPartial(e)) || [];
    message.activatedInviteUser = object.activatedInviteUser ?? false;
    message.playerVariable = object.playerVariable?.map((e) => VariableMessage.fromPartial(e)) || [];
    message.applications = object.applications?.map((e) => ApplicationMessage.fromPartial(e)) || [];
    message.userJid = object.userJid ?? "";
    message.editMapCommandsArrayMessage =
      (object.editMapCommandsArrayMessage !== undefined && object.editMapCommandsArrayMessage !== null)
        ? EditMapCommandsArrayMessage.fromPartial(object.editMapCommandsArrayMessage)
        : undefined;
    message.webrtcUserName = object.webrtcUserName ?? "";
    message.webrtcPassword = object.webrtcPassword ?? "";
    message.canEdit = object.canEdit ?? false;
    message.megaphoneSettings = (object.megaphoneSettings !== undefined && object.megaphoneSettings !== null)
      ? MegaphoneSettings.fromPartial(object.megaphoneSettings)
      : undefined;
    message.companionTexture = (object.companionTexture !== undefined && object.companionTexture !== null)
      ? CompanionTextureMessage.fromPartial(object.companionTexture)
      : undefined;
    return message;
  },
};

function createBaseMegaphoneSettings(): MegaphoneSettings {
  return { enabled: false, url: undefined };
}

export const MegaphoneSettings = {
  encode(message: MegaphoneSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled === true) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.url !== undefined) {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MegaphoneSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMegaphoneSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MegaphoneSettings {
    return {
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      url: isSet(object.url) ? globalThis.String(object.url) : undefined,
    };
  },

  toJSON(message: MegaphoneSettings): unknown {
    const obj: any = {};
    if (message.enabled === true) {
      obj.enabled = message.enabled;
    }
    if (message.url !== undefined) {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MegaphoneSettings>, I>>(base?: I): MegaphoneSettings {
    return MegaphoneSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MegaphoneSettings>, I>>(object: I): MegaphoneSettings {
    const message = createBaseMegaphoneSettings();
    message.enabled = object.enabled ?? false;
    message.url = object.url ?? undefined;
    return message;
  },
};

function createBaseWebRtcStartMessage(): WebRtcStartMessage {
  return { userId: 0, initiator: false, webrtcUserName: "", webrtcPassword: "" };
}

export const WebRtcStartMessage = {
  encode(message: WebRtcStartMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.initiator === true) {
      writer.uint32(24).bool(message.initiator);
    }
    if (message.webrtcUserName !== "") {
      writer.uint32(34).string(message.webrtcUserName);
    }
    if (message.webrtcPassword !== "") {
      writer.uint32(42).string(message.webrtcPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebRtcStartMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebRtcStartMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.initiator = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.webrtcUserName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.webrtcPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebRtcStartMessage {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      initiator: isSet(object.initiator) ? globalThis.Boolean(object.initiator) : false,
      webrtcUserName: isSet(object.webrtcUserName) ? globalThis.String(object.webrtcUserName) : "",
      webrtcPassword: isSet(object.webrtcPassword) ? globalThis.String(object.webrtcPassword) : "",
    };
  },

  toJSON(message: WebRtcStartMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.initiator === true) {
      obj.initiator = message.initiator;
    }
    if (message.webrtcUserName !== "") {
      obj.webrtcUserName = message.webrtcUserName;
    }
    if (message.webrtcPassword !== "") {
      obj.webrtcPassword = message.webrtcPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebRtcStartMessage>, I>>(base?: I): WebRtcStartMessage {
    return WebRtcStartMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebRtcStartMessage>, I>>(object: I): WebRtcStartMessage {
    const message = createBaseWebRtcStartMessage();
    message.userId = object.userId ?? 0;
    message.initiator = object.initiator ?? false;
    message.webrtcUserName = object.webrtcUserName ?? "";
    message.webrtcPassword = object.webrtcPassword ?? "";
    return message;
  },
};

function createBaseWebRtcDisconnectMessage(): WebRtcDisconnectMessage {
  return { userId: 0 };
}

export const WebRtcDisconnectMessage = {
  encode(message: WebRtcDisconnectMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebRtcDisconnectMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebRtcDisconnectMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebRtcDisconnectMessage {
    return { userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0 };
  },

  toJSON(message: WebRtcDisconnectMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebRtcDisconnectMessage>, I>>(base?: I): WebRtcDisconnectMessage {
    return WebRtcDisconnectMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebRtcDisconnectMessage>, I>>(object: I): WebRtcDisconnectMessage {
    const message = createBaseWebRtcDisconnectMessage();
    message.userId = object.userId ?? 0;
    return message;
  },
};

function createBaseWebRtcSignalToClientMessage(): WebRtcSignalToClientMessage {
  return { userId: 0, signal: "", webrtcUserName: "", webrtcPassword: "" };
}

export const WebRtcSignalToClientMessage = {
  encode(message: WebRtcSignalToClientMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.signal !== "") {
      writer.uint32(18).string(message.signal);
    }
    if (message.webrtcUserName !== "") {
      writer.uint32(34).string(message.webrtcUserName);
    }
    if (message.webrtcPassword !== "") {
      writer.uint32(42).string(message.webrtcPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebRtcSignalToClientMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebRtcSignalToClientMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signal = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.webrtcUserName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.webrtcPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebRtcSignalToClientMessage {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      signal: isSet(object.signal) ? globalThis.String(object.signal) : "",
      webrtcUserName: isSet(object.webrtcUserName) ? globalThis.String(object.webrtcUserName) : "",
      webrtcPassword: isSet(object.webrtcPassword) ? globalThis.String(object.webrtcPassword) : "",
    };
  },

  toJSON(message: WebRtcSignalToClientMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.signal !== "") {
      obj.signal = message.signal;
    }
    if (message.webrtcUserName !== "") {
      obj.webrtcUserName = message.webrtcUserName;
    }
    if (message.webrtcPassword !== "") {
      obj.webrtcPassword = message.webrtcPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebRtcSignalToClientMessage>, I>>(base?: I): WebRtcSignalToClientMessage {
    return WebRtcSignalToClientMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebRtcSignalToClientMessage>, I>>(object: I): WebRtcSignalToClientMessage {
    const message = createBaseWebRtcSignalToClientMessage();
    message.userId = object.userId ?? 0;
    message.signal = object.signal ?? "";
    message.webrtcUserName = object.webrtcUserName ?? "";
    message.webrtcPassword = object.webrtcPassword ?? "";
    return message;
  },
};

function createBaseTeleportMessageMessage(): TeleportMessageMessage {
  return { map: "" };
}

export const TeleportMessageMessage = {
  encode(message: TeleportMessageMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.map !== "") {
      writer.uint32(10).string(message.map);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TeleportMessageMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTeleportMessageMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.map = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TeleportMessageMessage {
    return { map: isSet(object.map) ? globalThis.String(object.map) : "" };
  },

  toJSON(message: TeleportMessageMessage): unknown {
    const obj: any = {};
    if (message.map !== "") {
      obj.map = message.map;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TeleportMessageMessage>, I>>(base?: I): TeleportMessageMessage {
    return TeleportMessageMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TeleportMessageMessage>, I>>(object: I): TeleportMessageMessage {
    const message = createBaseTeleportMessageMessage();
    message.map = object.map ?? "";
    return message;
  },
};

function createBaseSendUserMessage(): SendUserMessage {
  return { type: "", message: "" };
}

export const SendUserMessage = {
  encode(message: SendUserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendUserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendUserMessage {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: SendUserMessage): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendUserMessage>, I>>(base?: I): SendUserMessage {
    return SendUserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendUserMessage>, I>>(object: I): SendUserMessage {
    const message = createBaseSendUserMessage();
    message.type = object.type ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseWorldFullWarningMessage(): WorldFullWarningMessage {
  return {};
}

export const WorldFullWarningMessage = {
  encode(_: WorldFullWarningMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorldFullWarningMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorldFullWarningMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): WorldFullWarningMessage {
    return {};
  },

  toJSON(_: WorldFullWarningMessage): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<WorldFullWarningMessage>, I>>(base?: I): WorldFullWarningMessage {
    return WorldFullWarningMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorldFullWarningMessage>, I>>(_: I): WorldFullWarningMessage {
    const message = createBaseWorldFullWarningMessage();
    return message;
  },
};

function createBaseWorldFullWarningToRoomMessage(): WorldFullWarningToRoomMessage {
  return { roomId: "" };
}

export const WorldFullWarningToRoomMessage = {
  encode(message: WorldFullWarningToRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorldFullWarningToRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorldFullWarningToRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorldFullWarningToRoomMessage {
    return { roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "" };
  },

  toJSON(message: WorldFullWarningToRoomMessage): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorldFullWarningToRoomMessage>, I>>(base?: I): WorldFullWarningToRoomMessage {
    return WorldFullWarningToRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorldFullWarningToRoomMessage>, I>>(
    object: I,
  ): WorldFullWarningToRoomMessage {
    const message = createBaseWorldFullWarningToRoomMessage();
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBaseRefreshRoomPromptMessage(): RefreshRoomPromptMessage {
  return { roomId: "" };
}

export const RefreshRoomPromptMessage = {
  encode(message: RefreshRoomPromptMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshRoomPromptMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshRoomPromptMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshRoomPromptMessage {
    return { roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "" };
  },

  toJSON(message: RefreshRoomPromptMessage): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshRoomPromptMessage>, I>>(base?: I): RefreshRoomPromptMessage {
    return RefreshRoomPromptMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshRoomPromptMessage>, I>>(object: I): RefreshRoomPromptMessage {
    const message = createBaseRefreshRoomPromptMessage();
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBaseRefreshRoomMessage(): RefreshRoomMessage {
  return { roomId: "", versionNumber: 0, timeToRefresh: undefined };
}

export const RefreshRoomMessage = {
  encode(message: RefreshRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    if (message.versionNumber !== 0) {
      writer.uint32(16).int32(message.versionNumber);
    }
    if (message.timeToRefresh !== undefined) {
      writer.uint32(32).int32(message.timeToRefresh);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.versionNumber = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timeToRefresh = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshRoomMessage {
    return {
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      versionNumber: isSet(object.versionNumber) ? globalThis.Number(object.versionNumber) : 0,
      timeToRefresh: isSet(object.timeToRefresh) ? globalThis.Number(object.timeToRefresh) : undefined,
    };
  },

  toJSON(message: RefreshRoomMessage): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.versionNumber !== 0) {
      obj.versionNumber = Math.round(message.versionNumber);
    }
    if (message.timeToRefresh !== undefined) {
      obj.timeToRefresh = Math.round(message.timeToRefresh);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshRoomMessage>, I>>(base?: I): RefreshRoomMessage {
    return RefreshRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshRoomMessage>, I>>(object: I): RefreshRoomMessage {
    const message = createBaseRefreshRoomMessage();
    message.roomId = object.roomId ?? "";
    message.versionNumber = object.versionNumber ?? 0;
    message.timeToRefresh = object.timeToRefresh ?? undefined;
    return message;
  },
};

function createBaseWorldFullMessage(): WorldFullMessage {
  return {};
}

export const WorldFullMessage = {
  encode(_: WorldFullMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorldFullMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorldFullMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): WorldFullMessage {
    return {};
  },

  toJSON(_: WorldFullMessage): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<WorldFullMessage>, I>>(base?: I): WorldFullMessage {
    return WorldFullMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorldFullMessage>, I>>(_: I): WorldFullMessage {
    const message = createBaseWorldFullMessage();
    return message;
  },
};

function createBaseTokenExpiredMessage(): TokenExpiredMessage {
  return {};
}

export const TokenExpiredMessage = {
  encode(_: TokenExpiredMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TokenExpiredMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTokenExpiredMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TokenExpiredMessage {
    return {};
  },

  toJSON(_: TokenExpiredMessage): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TokenExpiredMessage>, I>>(base?: I): TokenExpiredMessage {
    return TokenExpiredMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TokenExpiredMessage>, I>>(_: I): TokenExpiredMessage {
    const message = createBaseTokenExpiredMessage();
    return message;
  },
};

function createBaseInvalidCharacterTextureMessage(): InvalidCharacterTextureMessage {
  return { message: "" };
}

export const InvalidCharacterTextureMessage = {
  encode(message: InvalidCharacterTextureMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvalidCharacterTextureMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvalidCharacterTextureMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvalidCharacterTextureMessage {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: InvalidCharacterTextureMessage): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InvalidCharacterTextureMessage>, I>>(base?: I): InvalidCharacterTextureMessage {
    return InvalidCharacterTextureMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InvalidCharacterTextureMessage>, I>>(
    object: I,
  ): InvalidCharacterTextureMessage {
    const message = createBaseInvalidCharacterTextureMessage();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseInvalidCompanionTextureMessage(): InvalidCompanionTextureMessage {
  return { message: "" };
}

export const InvalidCompanionTextureMessage = {
  encode(message: InvalidCompanionTextureMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvalidCompanionTextureMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvalidCompanionTextureMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvalidCompanionTextureMessage {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: InvalidCompanionTextureMessage): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InvalidCompanionTextureMessage>, I>>(base?: I): InvalidCompanionTextureMessage {
    return InvalidCompanionTextureMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InvalidCompanionTextureMessage>, I>>(
    object: I,
  ): InvalidCompanionTextureMessage {
    const message = createBaseInvalidCompanionTextureMessage();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseWorldConnectionMessage(): WorldConnectionMessage {
  return { message: "" };
}

export const WorldConnectionMessage = {
  encode(message: WorldConnectionMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorldConnectionMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorldConnectionMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorldConnectionMessage {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: WorldConnectionMessage): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorldConnectionMessage>, I>>(base?: I): WorldConnectionMessage {
    return WorldConnectionMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorldConnectionMessage>, I>>(object: I): WorldConnectionMessage {
    const message = createBaseWorldConnectionMessage();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseBanUserMessage(): BanUserMessage {
  return { type: "", message: "" };
}

export const BanUserMessage = {
  encode(message: BanUserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BanUserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBanUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BanUserMessage {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: BanUserMessage): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BanUserMessage>, I>>(base?: I): BanUserMessage {
    return BanUserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BanUserMessage>, I>>(object: I): BanUserMessage {
    const message = createBaseBanUserMessage();
    message.type = object.type ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseAskPositionMessage(): AskPositionMessage {
  return { userIdentifier: "", playUri: "" };
}

export const AskPositionMessage = {
  encode(message: AskPositionMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userIdentifier !== "") {
      writer.uint32(10).string(message.userIdentifier);
    }
    if (message.playUri !== "") {
      writer.uint32(18).string(message.playUri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AskPositionMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAskPositionMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.playUri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AskPositionMessage {
    return {
      userIdentifier: isSet(object.userIdentifier) ? globalThis.String(object.userIdentifier) : "",
      playUri: isSet(object.playUri) ? globalThis.String(object.playUri) : "",
    };
  },

  toJSON(message: AskPositionMessage): unknown {
    const obj: any = {};
    if (message.userIdentifier !== "") {
      obj.userIdentifier = message.userIdentifier;
    }
    if (message.playUri !== "") {
      obj.playUri = message.playUri;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AskPositionMessage>, I>>(base?: I): AskPositionMessage {
    return AskPositionMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AskPositionMessage>, I>>(object: I): AskPositionMessage {
    const message = createBaseAskPositionMessage();
    message.userIdentifier = object.userIdentifier ?? "";
    message.playUri = object.playUri ?? "";
    return message;
  },
};

function createBaseCameraStateMessage(): CameraStateMessage {
  return { value: false };
}

export const CameraStateMessage = {
  encode(message: CameraStateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value === true) {
      writer.uint32(8).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraStateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraStateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraStateMessage {
    return { value: isSet(object.value) ? globalThis.Boolean(object.value) : false };
  },

  toJSON(message: CameraStateMessage): unknown {
    const obj: any = {};
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraStateMessage>, I>>(base?: I): CameraStateMessage {
    return CameraStateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraStateMessage>, I>>(object: I): CameraStateMessage {
    const message = createBaseCameraStateMessage();
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseMicrophoneStateMessage(): MicrophoneStateMessage {
  return { value: false };
}

export const MicrophoneStateMessage = {
  encode(message: MicrophoneStateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value === true) {
      writer.uint32(8).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MicrophoneStateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMicrophoneStateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MicrophoneStateMessage {
    return { value: isSet(object.value) ? globalThis.Boolean(object.value) : false };
  },

  toJSON(message: MicrophoneStateMessage): unknown {
    const obj: any = {};
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MicrophoneStateMessage>, I>>(base?: I): MicrophoneStateMessage {
    return MicrophoneStateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MicrophoneStateMessage>, I>>(object: I): MicrophoneStateMessage {
    const message = createBaseMicrophoneStateMessage();
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseScreenSharingStateMessage(): ScreenSharingStateMessage {
  return { value: false };
}

export const ScreenSharingStateMessage = {
  encode(message: ScreenSharingStateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value === true) {
      writer.uint32(8).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenSharingStateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenSharingStateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenSharingStateMessage {
    return { value: isSet(object.value) ? globalThis.Boolean(object.value) : false };
  },

  toJSON(message: ScreenSharingStateMessage): unknown {
    const obj: any = {};
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenSharingStateMessage>, I>>(base?: I): ScreenSharingStateMessage {
    return ScreenSharingStateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScreenSharingStateMessage>, I>>(object: I): ScreenSharingStateMessage {
    const message = createBaseScreenSharingStateMessage();
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseMegaphoneStateMessage(): MegaphoneStateMessage {
  return { value: false, spaceName: undefined };
}

export const MegaphoneStateMessage = {
  encode(message: MegaphoneStateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value === true) {
      writer.uint32(8).bool(message.value);
    }
    if (message.spaceName !== undefined) {
      writer.uint32(18).string(message.spaceName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MegaphoneStateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMegaphoneStateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spaceName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MegaphoneStateMessage {
    return {
      value: isSet(object.value) ? globalThis.Boolean(object.value) : false,
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : undefined,
    };
  },

  toJSON(message: MegaphoneStateMessage): unknown {
    const obj: any = {};
    if (message.value === true) {
      obj.value = message.value;
    }
    if (message.spaceName !== undefined) {
      obj.spaceName = message.spaceName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MegaphoneStateMessage>, I>>(base?: I): MegaphoneStateMessage {
    return MegaphoneStateMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MegaphoneStateMessage>, I>>(object: I): MegaphoneStateMessage {
    const message = createBaseMegaphoneStateMessage();
    message.value = object.value ?? false;
    message.spaceName = object.spaceName ?? undefined;
    return message;
  },
};

function createBaseJitsiParticipantIdSpaceMessage(): JitsiParticipantIdSpaceMessage {
  return { spaceName: "", value: "" };
}

export const JitsiParticipantIdSpaceMessage = {
  encode(message: JitsiParticipantIdSpaceMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JitsiParticipantIdSpaceMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJitsiParticipantIdSpaceMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JitsiParticipantIdSpaceMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: JitsiParticipantIdSpaceMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JitsiParticipantIdSpaceMessage>, I>>(base?: I): JitsiParticipantIdSpaceMessage {
    return JitsiParticipantIdSpaceMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JitsiParticipantIdSpaceMessage>, I>>(
    object: I,
  ): JitsiParticipantIdSpaceMessage {
    const message = createBaseJitsiParticipantIdSpaceMessage();
    message.spaceName = object.spaceName ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseMegaphoneEnabledMessage(): MegaphoneEnabledMessage {
  return { value: false };
}

export const MegaphoneEnabledMessage = {
  encode(message: MegaphoneEnabledMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value === true) {
      writer.uint32(8).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MegaphoneEnabledMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMegaphoneEnabledMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MegaphoneEnabledMessage {
    return { value: isSet(object.value) ? globalThis.Boolean(object.value) : false };
  },

  toJSON(message: MegaphoneEnabledMessage): unknown {
    const obj: any = {};
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MegaphoneEnabledMessage>, I>>(base?: I): MegaphoneEnabledMessage {
    return MegaphoneEnabledMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MegaphoneEnabledMessage>, I>>(object: I): MegaphoneEnabledMessage {
    const message = createBaseMegaphoneEnabledMessage();
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseMuteParticipantIdMessage(): MuteParticipantIdMessage {
  return { spaceName: "", mutedUserUuid: "" };
}

export const MuteParticipantIdMessage = {
  encode(message: MuteParticipantIdMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.mutedUserUuid !== "") {
      writer.uint32(18).string(message.mutedUserUuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteParticipantIdMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteParticipantIdMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mutedUserUuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteParticipantIdMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      mutedUserUuid: isSet(object.mutedUserUuid) ? globalThis.String(object.mutedUserUuid) : "",
    };
  },

  toJSON(message: MuteParticipantIdMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.mutedUserUuid !== "") {
      obj.mutedUserUuid = message.mutedUserUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteParticipantIdMessage>, I>>(base?: I): MuteParticipantIdMessage {
    return MuteParticipantIdMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteParticipantIdMessage>, I>>(object: I): MuteParticipantIdMessage {
    const message = createBaseMuteParticipantIdMessage();
    message.spaceName = object.spaceName ?? "";
    message.mutedUserUuid = object.mutedUserUuid ?? "";
    return message;
  },
};

function createBaseMuteEveryBodyParticipantMessage(): MuteEveryBodyParticipantMessage {
  return { spaceName: "", senderUserId: "" };
}

export const MuteEveryBodyParticipantMessage = {
  encode(message: MuteEveryBodyParticipantMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.senderUserId !== "") {
      writer.uint32(18).string(message.senderUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteEveryBodyParticipantMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteEveryBodyParticipantMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.senderUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteEveryBodyParticipantMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      senderUserId: isSet(object.senderUserId) ? globalThis.String(object.senderUserId) : "",
    };
  },

  toJSON(message: MuteEveryBodyParticipantMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.senderUserId !== "") {
      obj.senderUserId = message.senderUserId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteEveryBodyParticipantMessage>, I>>(base?: I): MuteEveryBodyParticipantMessage {
    return MuteEveryBodyParticipantMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteEveryBodyParticipantMessage>, I>>(
    object: I,
  ): MuteEveryBodyParticipantMessage {
    const message = createBaseMuteEveryBodyParticipantMessage();
    message.spaceName = object.spaceName ?? "";
    message.senderUserId = object.senderUserId ?? "";
    return message;
  },
};

function createBaseMutedMessage(): MutedMessage {
  return { userUuid: "", message: "" };
}

export const MutedMessage = {
  encode(message: MutedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userUuid !== "") {
      writer.uint32(10).string(message.userUuid);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MutedMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMutedMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userUuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MutedMessage {
    return {
      userUuid: isSet(object.userUuid) ? globalThis.String(object.userUuid) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: MutedMessage): unknown {
    const obj: any = {};
    if (message.userUuid !== "") {
      obj.userUuid = message.userUuid;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MutedMessage>, I>>(base?: I): MutedMessage {
    return MutedMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MutedMessage>, I>>(object: I): MutedMessage {
    const message = createBaseMutedMessage();
    message.userUuid = object.userUuid ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseMuteVideoParticipantIdMessage(): MuteVideoParticipantIdMessage {
  return { spaceName: "", mutedUserUuid: "" };
}

export const MuteVideoParticipantIdMessage = {
  encode(message: MuteVideoParticipantIdMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.mutedUserUuid !== "") {
      writer.uint32(18).string(message.mutedUserUuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteVideoParticipantIdMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteVideoParticipantIdMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mutedUserUuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteVideoParticipantIdMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      mutedUserUuid: isSet(object.mutedUserUuid) ? globalThis.String(object.mutedUserUuid) : "",
    };
  },

  toJSON(message: MuteVideoParticipantIdMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.mutedUserUuid !== "") {
      obj.mutedUserUuid = message.mutedUserUuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteVideoParticipantIdMessage>, I>>(base?: I): MuteVideoParticipantIdMessage {
    return MuteVideoParticipantIdMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteVideoParticipantIdMessage>, I>>(
    object: I,
  ): MuteVideoParticipantIdMessage {
    const message = createBaseMuteVideoParticipantIdMessage();
    message.spaceName = object.spaceName ?? "";
    message.mutedUserUuid = object.mutedUserUuid ?? "";
    return message;
  },
};

function createBaseMuteVideoEveryBodyParticipantMessage(): MuteVideoEveryBodyParticipantMessage {
  return { spaceName: "", userId: "" };
}

export const MuteVideoEveryBodyParticipantMessage = {
  encode(message: MuteVideoEveryBodyParticipantMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteVideoEveryBodyParticipantMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteVideoEveryBodyParticipantMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteVideoEveryBodyParticipantMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
    };
  },

  toJSON(message: MuteVideoEveryBodyParticipantMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteVideoEveryBodyParticipantMessage>, I>>(
    base?: I,
  ): MuteVideoEveryBodyParticipantMessage {
    return MuteVideoEveryBodyParticipantMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteVideoEveryBodyParticipantMessage>, I>>(
    object: I,
  ): MuteVideoEveryBodyParticipantMessage {
    const message = createBaseMuteVideoEveryBodyParticipantMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseMutedVideoMessage(): MutedVideoMessage {
  return { userUuid: "", message: "" };
}

export const MutedVideoMessage = {
  encode(message: MutedVideoMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userUuid !== "") {
      writer.uint32(10).string(message.userUuid);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MutedVideoMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMutedVideoMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userUuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MutedVideoMessage {
    return {
      userUuid: isSet(object.userUuid) ? globalThis.String(object.userUuid) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: MutedVideoMessage): unknown {
    const obj: any = {};
    if (message.userUuid !== "") {
      obj.userUuid = message.userUuid;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MutedVideoMessage>, I>>(base?: I): MutedVideoMessage {
    return MutedVideoMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MutedVideoMessage>, I>>(object: I): MutedVideoMessage {
    const message = createBaseMutedVideoMessage();
    message.userUuid = object.userUuid ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseAskMutedMessage(): AskMutedMessage {
  return { userUuid: "", message: "" };
}

export const AskMutedMessage = {
  encode(message: AskMutedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userUuid !== "") {
      writer.uint32(10).string(message.userUuid);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AskMutedMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAskMutedMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userUuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AskMutedMessage {
    return {
      userUuid: isSet(object.userUuid) ? globalThis.String(object.userUuid) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: AskMutedMessage): unknown {
    const obj: any = {};
    if (message.userUuid !== "") {
      obj.userUuid = message.userUuid;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AskMutedMessage>, I>>(base?: I): AskMutedMessage {
    return AskMutedMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AskMutedMessage>, I>>(object: I): AskMutedMessage {
    const message = createBaseAskMutedMessage();
    message.userUuid = object.userUuid ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseAskMutedVideoMessage(): AskMutedVideoMessage {
  return { userUuid: "", message: "" };
}

export const AskMutedVideoMessage = {
  encode(message: AskMutedVideoMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userUuid !== "") {
      writer.uint32(10).string(message.userUuid);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AskMutedVideoMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAskMutedVideoMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userUuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AskMutedVideoMessage {
    return {
      userUuid: isSet(object.userUuid) ? globalThis.String(object.userUuid) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: AskMutedVideoMessage): unknown {
    const obj: any = {};
    if (message.userUuid !== "") {
      obj.userUuid = message.userUuid;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AskMutedVideoMessage>, I>>(base?: I): AskMutedVideoMessage {
    return AskMutedVideoMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AskMutedVideoMessage>, I>>(object: I): AskMutedVideoMessage {
    const message = createBaseAskMutedVideoMessage();
    message.userUuid = object.userUuid ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseServerToClientMessage(): ServerToClientMessage {
  return { message: undefined };
}

export const ServerToClientMessage = {
  encode(message: ServerToClientMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "batchMessage":
        BatchMessage.encode(message.message.batchMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "errorMessage":
        ErrorMessage.encode(message.message.errorMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "roomJoinedMessage":
        RoomJoinedMessage.encode(message.message.roomJoinedMessage, writer.uint32(26).fork()).ldelim();
        break;
      case "webRtcStartMessage":
        WebRtcStartMessage.encode(message.message.webRtcStartMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "webRtcSignalToClientMessage":
        WebRtcSignalToClientMessage.encode(message.message.webRtcSignalToClientMessage, writer.uint32(42).fork())
          .ldelim();
        break;
      case "webRtcScreenSharingSignalToClientMessage":
        WebRtcSignalToClientMessage.encode(
          message.message.webRtcScreenSharingSignalToClientMessage,
          writer.uint32(50).fork(),
        ).ldelim();
        break;
      case "webRtcDisconnectMessage":
        WebRtcDisconnectMessage.encode(message.message.webRtcDisconnectMessage, writer.uint32(58).fork()).ldelim();
        break;
      case "teleportMessageMessage":
        TeleportMessageMessage.encode(message.message.teleportMessageMessage, writer.uint32(82).fork()).ldelim();
        break;
      case "sendUserMessage":
        SendUserMessage.encode(message.message.sendUserMessage, writer.uint32(98).fork()).ldelim();
        break;
      case "banUserMessage":
        BanUserMessage.encode(message.message.banUserMessage, writer.uint32(106).fork()).ldelim();
        break;
      case "worldFullWarningMessage":
        WorldFullWarningMessage.encode(message.message.worldFullWarningMessage, writer.uint32(122).fork()).ldelim();
        break;
      case "worldFullMessage":
        WorldFullMessage.encode(message.message.worldFullMessage, writer.uint32(130).fork()).ldelim();
        break;
      case "refreshRoomMessage":
        RefreshRoomMessage.encode(message.message.refreshRoomMessage, writer.uint32(138).fork()).ldelim();
        break;
      case "worldConnectionMessage":
        WorldConnectionMessage.encode(message.message.worldConnectionMessage, writer.uint32(146).fork()).ldelim();
        break;
      case "tokenExpiredMessage":
        TokenExpiredMessage.encode(message.message.tokenExpiredMessage, writer.uint32(162).fork()).ldelim();
        break;
      case "followRequestMessage":
        FollowRequestMessage.encode(message.message.followRequestMessage, writer.uint32(170).fork()).ldelim();
        break;
      case "followConfirmationMessage":
        FollowConfirmationMessage.encode(message.message.followConfirmationMessage, writer.uint32(178).fork()).ldelim();
        break;
      case "followAbortMessage":
        FollowAbortMessage.encode(message.message.followAbortMessage, writer.uint32(186).fork()).ldelim();
        break;
      case "invalidCharacterTextureMessage":
        InvalidCharacterTextureMessage.encode(message.message.invalidCharacterTextureMessage, writer.uint32(194).fork())
          .ldelim();
        break;
      case "groupUsersUpdateMessage":
        GroupUsersUpdateMessage.encode(message.message.groupUsersUpdateMessage, writer.uint32(202).fork()).ldelim();
        break;
      case "errorScreenMessage":
        ErrorScreenMessage.encode(message.message.errorScreenMessage, writer.uint32(210).fork()).ldelim();
        break;
      case "answerMessage":
        AnswerMessage.encode(message.message.answerMessage, writer.uint32(226).fork()).ldelim();
        break;
      case "xmppSettingsMessage":
        XmppSettingsMessage.encode(message.message.xmppSettingsMessage, writer.uint32(234).fork()).ldelim();
        break;
      case "moveToPositionMessage":
        MoveToPositionMessage.encode(message.message.moveToPositionMessage, writer.uint32(250).fork()).ldelim();
        break;
      case "invalidCompanionTextureMessage":
        InvalidCompanionTextureMessage.encode(message.message.invalidCompanionTextureMessage, writer.uint32(258).fork())
          .ldelim();
        break;
      case "mutedMessage":
        MutedMessage.encode(message.message.mutedMessage, writer.uint32(266).fork()).ldelim();
        break;
      case "mutedVideoMessage":
        MutedVideoMessage.encode(message.message.mutedVideoMessage, writer.uint32(274).fork()).ldelim();
        break;
      case "askMutedMessage":
        AskMutedMessage.encode(message.message.askMutedMessage, writer.uint32(282).fork()).ldelim();
        break;
      case "askMutedVideoMessage":
        AskMutedVideoMessage.encode(message.message.askMutedVideoMessage, writer.uint32(290).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerToClientMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerToClientMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = { $case: "batchMessage", batchMessage: BatchMessage.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = { $case: "errorMessage", errorMessage: ErrorMessage.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "roomJoinedMessage",
            roomJoinedMessage: RoomJoinedMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "webRtcStartMessage",
            webRtcStartMessage: WebRtcStartMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "webRtcSignalToClientMessage",
            webRtcSignalToClientMessage: WebRtcSignalToClientMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = {
            $case: "webRtcScreenSharingSignalToClientMessage",
            webRtcScreenSharingSignalToClientMessage: WebRtcSignalToClientMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "webRtcDisconnectMessage",
            webRtcDisconnectMessage: WebRtcDisconnectMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.message = {
            $case: "teleportMessageMessage",
            teleportMessageMessage: TeleportMessageMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.message = {
            $case: "sendUserMessage",
            sendUserMessage: SendUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.message = { $case: "banUserMessage", banUserMessage: BanUserMessage.decode(reader, reader.uint32()) };
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.message = {
            $case: "worldFullWarningMessage",
            worldFullWarningMessage: WorldFullWarningMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.message = {
            $case: "worldFullMessage",
            worldFullMessage: WorldFullMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.message = {
            $case: "refreshRoomMessage",
            refreshRoomMessage: RefreshRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.message = {
            $case: "worldConnectionMessage",
            worldConnectionMessage: WorldConnectionMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.message = {
            $case: "tokenExpiredMessage",
            tokenExpiredMessage: TokenExpiredMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.message = {
            $case: "followRequestMessage",
            followRequestMessage: FollowRequestMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.message = {
            $case: "followConfirmationMessage",
            followConfirmationMessage: FollowConfirmationMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.message = {
            $case: "followAbortMessage",
            followAbortMessage: FollowAbortMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.message = {
            $case: "invalidCharacterTextureMessage",
            invalidCharacterTextureMessage: InvalidCharacterTextureMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.message = {
            $case: "groupUsersUpdateMessage",
            groupUsersUpdateMessage: GroupUsersUpdateMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.message = {
            $case: "errorScreenMessage",
            errorScreenMessage: ErrorScreenMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.message = { $case: "answerMessage", answerMessage: AnswerMessage.decode(reader, reader.uint32()) };
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.message = {
            $case: "xmppSettingsMessage",
            xmppSettingsMessage: XmppSettingsMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.message = {
            $case: "moveToPositionMessage",
            moveToPositionMessage: MoveToPositionMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.message = {
            $case: "invalidCompanionTextureMessage",
            invalidCompanionTextureMessage: InvalidCompanionTextureMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.message = { $case: "mutedMessage", mutedMessage: MutedMessage.decode(reader, reader.uint32()) };
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.message = {
            $case: "mutedVideoMessage",
            mutedVideoMessage: MutedVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.message = {
            $case: "askMutedMessage",
            askMutedMessage: AskMutedMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.message = {
            $case: "askMutedVideoMessage",
            askMutedVideoMessage: AskMutedVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerToClientMessage {
    return {
      message: isSet(object.batchMessage)
        ? { $case: "batchMessage", batchMessage: BatchMessage.fromJSON(object.batchMessage) }
        : isSet(object.errorMessage)
        ? { $case: "errorMessage", errorMessage: ErrorMessage.fromJSON(object.errorMessage) }
        : isSet(object.roomJoinedMessage)
        ? { $case: "roomJoinedMessage", roomJoinedMessage: RoomJoinedMessage.fromJSON(object.roomJoinedMessage) }
        : isSet(object.webRtcStartMessage)
        ? { $case: "webRtcStartMessage", webRtcStartMessage: WebRtcStartMessage.fromJSON(object.webRtcStartMessage) }
        : isSet(object.webRtcSignalToClientMessage)
        ? {
          $case: "webRtcSignalToClientMessage",
          webRtcSignalToClientMessage: WebRtcSignalToClientMessage.fromJSON(object.webRtcSignalToClientMessage),
        }
        : isSet(object.webRtcScreenSharingSignalToClientMessage)
        ? {
          $case: "webRtcScreenSharingSignalToClientMessage",
          webRtcScreenSharingSignalToClientMessage: WebRtcSignalToClientMessage.fromJSON(
            object.webRtcScreenSharingSignalToClientMessage,
          ),
        }
        : isSet(object.webRtcDisconnectMessage)
        ? {
          $case: "webRtcDisconnectMessage",
          webRtcDisconnectMessage: WebRtcDisconnectMessage.fromJSON(object.webRtcDisconnectMessage),
        }
        : isSet(object.teleportMessageMessage)
        ? {
          $case: "teleportMessageMessage",
          teleportMessageMessage: TeleportMessageMessage.fromJSON(object.teleportMessageMessage),
        }
        : isSet(object.sendUserMessage)
        ? { $case: "sendUserMessage", sendUserMessage: SendUserMessage.fromJSON(object.sendUserMessage) }
        : isSet(object.banUserMessage)
        ? { $case: "banUserMessage", banUserMessage: BanUserMessage.fromJSON(object.banUserMessage) }
        : isSet(object.worldFullWarningMessage)
        ? {
          $case: "worldFullWarningMessage",
          worldFullWarningMessage: WorldFullWarningMessage.fromJSON(object.worldFullWarningMessage),
        }
        : isSet(object.worldFullMessage)
        ? { $case: "worldFullMessage", worldFullMessage: WorldFullMessage.fromJSON(object.worldFullMessage) }
        : isSet(object.refreshRoomMessage)
        ? { $case: "refreshRoomMessage", refreshRoomMessage: RefreshRoomMessage.fromJSON(object.refreshRoomMessage) }
        : isSet(object.worldConnectionMessage)
        ? {
          $case: "worldConnectionMessage",
          worldConnectionMessage: WorldConnectionMessage.fromJSON(object.worldConnectionMessage),
        }
        : isSet(object.tokenExpiredMessage)
        ? {
          $case: "tokenExpiredMessage",
          tokenExpiredMessage: TokenExpiredMessage.fromJSON(object.tokenExpiredMessage),
        }
        : isSet(object.followRequestMessage)
        ? {
          $case: "followRequestMessage",
          followRequestMessage: FollowRequestMessage.fromJSON(object.followRequestMessage),
        }
        : isSet(object.followConfirmationMessage)
        ? {
          $case: "followConfirmationMessage",
          followConfirmationMessage: FollowConfirmationMessage.fromJSON(object.followConfirmationMessage),
        }
        : isSet(object.followAbortMessage)
        ? { $case: "followAbortMessage", followAbortMessage: FollowAbortMessage.fromJSON(object.followAbortMessage) }
        : isSet(object.invalidCharacterTextureMessage)
        ? {
          $case: "invalidCharacterTextureMessage",
          invalidCharacterTextureMessage: InvalidCharacterTextureMessage.fromJSON(
            object.invalidCharacterTextureMessage,
          ),
        }
        : isSet(object.groupUsersUpdateMessage)
        ? {
          $case: "groupUsersUpdateMessage",
          groupUsersUpdateMessage: GroupUsersUpdateMessage.fromJSON(object.groupUsersUpdateMessage),
        }
        : isSet(object.errorScreenMessage)
        ? { $case: "errorScreenMessage", errorScreenMessage: ErrorScreenMessage.fromJSON(object.errorScreenMessage) }
        : isSet(object.answerMessage)
        ? { $case: "answerMessage", answerMessage: AnswerMessage.fromJSON(object.answerMessage) }
        : isSet(object.xmppSettingsMessage)
        ? {
          $case: "xmppSettingsMessage",
          xmppSettingsMessage: XmppSettingsMessage.fromJSON(object.xmppSettingsMessage),
        }
        : isSet(object.moveToPositionMessage)
        ? {
          $case: "moveToPositionMessage",
          moveToPositionMessage: MoveToPositionMessage.fromJSON(object.moveToPositionMessage),
        }
        : isSet(object.invalidCompanionTextureMessage)
        ? {
          $case: "invalidCompanionTextureMessage",
          invalidCompanionTextureMessage: InvalidCompanionTextureMessage.fromJSON(
            object.invalidCompanionTextureMessage,
          ),
        }
        : isSet(object.mutedMessage)
        ? { $case: "mutedMessage", mutedMessage: MutedMessage.fromJSON(object.mutedMessage) }
        : isSet(object.mutedVideoMessage)
        ? { $case: "mutedVideoMessage", mutedVideoMessage: MutedVideoMessage.fromJSON(object.mutedVideoMessage) }
        : isSet(object.askMutedMessage)
        ? { $case: "askMutedMessage", askMutedMessage: AskMutedMessage.fromJSON(object.askMutedMessage) }
        : isSet(object.askMutedVideoMessage)
        ? {
          $case: "askMutedVideoMessage",
          askMutedVideoMessage: AskMutedVideoMessage.fromJSON(object.askMutedVideoMessage),
        }
        : undefined,
    };
  },

  toJSON(message: ServerToClientMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "batchMessage") {
      obj.batchMessage = BatchMessage.toJSON(message.message.batchMessage);
    }
    if (message.message?.$case === "errorMessage") {
      obj.errorMessage = ErrorMessage.toJSON(message.message.errorMessage);
    }
    if (message.message?.$case === "roomJoinedMessage") {
      obj.roomJoinedMessage = RoomJoinedMessage.toJSON(message.message.roomJoinedMessage);
    }
    if (message.message?.$case === "webRtcStartMessage") {
      obj.webRtcStartMessage = WebRtcStartMessage.toJSON(message.message.webRtcStartMessage);
    }
    if (message.message?.$case === "webRtcSignalToClientMessage") {
      obj.webRtcSignalToClientMessage = WebRtcSignalToClientMessage.toJSON(message.message.webRtcSignalToClientMessage);
    }
    if (message.message?.$case === "webRtcScreenSharingSignalToClientMessage") {
      obj.webRtcScreenSharingSignalToClientMessage = WebRtcSignalToClientMessage.toJSON(
        message.message.webRtcScreenSharingSignalToClientMessage,
      );
    }
    if (message.message?.$case === "webRtcDisconnectMessage") {
      obj.webRtcDisconnectMessage = WebRtcDisconnectMessage.toJSON(message.message.webRtcDisconnectMessage);
    }
    if (message.message?.$case === "teleportMessageMessage") {
      obj.teleportMessageMessage = TeleportMessageMessage.toJSON(message.message.teleportMessageMessage);
    }
    if (message.message?.$case === "sendUserMessage") {
      obj.sendUserMessage = SendUserMessage.toJSON(message.message.sendUserMessage);
    }
    if (message.message?.$case === "banUserMessage") {
      obj.banUserMessage = BanUserMessage.toJSON(message.message.banUserMessage);
    }
    if (message.message?.$case === "worldFullWarningMessage") {
      obj.worldFullWarningMessage = WorldFullWarningMessage.toJSON(message.message.worldFullWarningMessage);
    }
    if (message.message?.$case === "worldFullMessage") {
      obj.worldFullMessage = WorldFullMessage.toJSON(message.message.worldFullMessage);
    }
    if (message.message?.$case === "refreshRoomMessage") {
      obj.refreshRoomMessage = RefreshRoomMessage.toJSON(message.message.refreshRoomMessage);
    }
    if (message.message?.$case === "worldConnectionMessage") {
      obj.worldConnectionMessage = WorldConnectionMessage.toJSON(message.message.worldConnectionMessage);
    }
    if (message.message?.$case === "tokenExpiredMessage") {
      obj.tokenExpiredMessage = TokenExpiredMessage.toJSON(message.message.tokenExpiredMessage);
    }
    if (message.message?.$case === "followRequestMessage") {
      obj.followRequestMessage = FollowRequestMessage.toJSON(message.message.followRequestMessage);
    }
    if (message.message?.$case === "followConfirmationMessage") {
      obj.followConfirmationMessage = FollowConfirmationMessage.toJSON(message.message.followConfirmationMessage);
    }
    if (message.message?.$case === "followAbortMessage") {
      obj.followAbortMessage = FollowAbortMessage.toJSON(message.message.followAbortMessage);
    }
    if (message.message?.$case === "invalidCharacterTextureMessage") {
      obj.invalidCharacterTextureMessage = InvalidCharacterTextureMessage.toJSON(
        message.message.invalidCharacterTextureMessage,
      );
    }
    if (message.message?.$case === "groupUsersUpdateMessage") {
      obj.groupUsersUpdateMessage = GroupUsersUpdateMessage.toJSON(message.message.groupUsersUpdateMessage);
    }
    if (message.message?.$case === "errorScreenMessage") {
      obj.errorScreenMessage = ErrorScreenMessage.toJSON(message.message.errorScreenMessage);
    }
    if (message.message?.$case === "answerMessage") {
      obj.answerMessage = AnswerMessage.toJSON(message.message.answerMessage);
    }
    if (message.message?.$case === "xmppSettingsMessage") {
      obj.xmppSettingsMessage = XmppSettingsMessage.toJSON(message.message.xmppSettingsMessage);
    }
    if (message.message?.$case === "moveToPositionMessage") {
      obj.moveToPositionMessage = MoveToPositionMessage.toJSON(message.message.moveToPositionMessage);
    }
    if (message.message?.$case === "invalidCompanionTextureMessage") {
      obj.invalidCompanionTextureMessage = InvalidCompanionTextureMessage.toJSON(
        message.message.invalidCompanionTextureMessage,
      );
    }
    if (message.message?.$case === "mutedMessage") {
      obj.mutedMessage = MutedMessage.toJSON(message.message.mutedMessage);
    }
    if (message.message?.$case === "mutedVideoMessage") {
      obj.mutedVideoMessage = MutedVideoMessage.toJSON(message.message.mutedVideoMessage);
    }
    if (message.message?.$case === "askMutedMessage") {
      obj.askMutedMessage = AskMutedMessage.toJSON(message.message.askMutedMessage);
    }
    if (message.message?.$case === "askMutedVideoMessage") {
      obj.askMutedVideoMessage = AskMutedVideoMessage.toJSON(message.message.askMutedVideoMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerToClientMessage>, I>>(base?: I): ServerToClientMessage {
    return ServerToClientMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerToClientMessage>, I>>(object: I): ServerToClientMessage {
    const message = createBaseServerToClientMessage();
    if (
      object.message?.$case === "batchMessage" &&
      object.message?.batchMessage !== undefined &&
      object.message?.batchMessage !== null
    ) {
      message.message = { $case: "batchMessage", batchMessage: BatchMessage.fromPartial(object.message.batchMessage) };
    }
    if (
      object.message?.$case === "errorMessage" &&
      object.message?.errorMessage !== undefined &&
      object.message?.errorMessage !== null
    ) {
      message.message = { $case: "errorMessage", errorMessage: ErrorMessage.fromPartial(object.message.errorMessage) };
    }
    if (
      object.message?.$case === "roomJoinedMessage" &&
      object.message?.roomJoinedMessage !== undefined &&
      object.message?.roomJoinedMessage !== null
    ) {
      message.message = {
        $case: "roomJoinedMessage",
        roomJoinedMessage: RoomJoinedMessage.fromPartial(object.message.roomJoinedMessage),
      };
    }
    if (
      object.message?.$case === "webRtcStartMessage" &&
      object.message?.webRtcStartMessage !== undefined &&
      object.message?.webRtcStartMessage !== null
    ) {
      message.message = {
        $case: "webRtcStartMessage",
        webRtcStartMessage: WebRtcStartMessage.fromPartial(object.message.webRtcStartMessage),
      };
    }
    if (
      object.message?.$case === "webRtcSignalToClientMessage" &&
      object.message?.webRtcSignalToClientMessage !== undefined &&
      object.message?.webRtcSignalToClientMessage !== null
    ) {
      message.message = {
        $case: "webRtcSignalToClientMessage",
        webRtcSignalToClientMessage: WebRtcSignalToClientMessage.fromPartial(
          object.message.webRtcSignalToClientMessage,
        ),
      };
    }
    if (
      object.message?.$case === "webRtcScreenSharingSignalToClientMessage" &&
      object.message?.webRtcScreenSharingSignalToClientMessage !== undefined &&
      object.message?.webRtcScreenSharingSignalToClientMessage !== null
    ) {
      message.message = {
        $case: "webRtcScreenSharingSignalToClientMessage",
        webRtcScreenSharingSignalToClientMessage: WebRtcSignalToClientMessage.fromPartial(
          object.message.webRtcScreenSharingSignalToClientMessage,
        ),
      };
    }
    if (
      object.message?.$case === "webRtcDisconnectMessage" &&
      object.message?.webRtcDisconnectMessage !== undefined &&
      object.message?.webRtcDisconnectMessage !== null
    ) {
      message.message = {
        $case: "webRtcDisconnectMessage",
        webRtcDisconnectMessage: WebRtcDisconnectMessage.fromPartial(object.message.webRtcDisconnectMessage),
      };
    }
    if (
      object.message?.$case === "teleportMessageMessage" &&
      object.message?.teleportMessageMessage !== undefined &&
      object.message?.teleportMessageMessage !== null
    ) {
      message.message = {
        $case: "teleportMessageMessage",
        teleportMessageMessage: TeleportMessageMessage.fromPartial(object.message.teleportMessageMessage),
      };
    }
    if (
      object.message?.$case === "sendUserMessage" &&
      object.message?.sendUserMessage !== undefined &&
      object.message?.sendUserMessage !== null
    ) {
      message.message = {
        $case: "sendUserMessage",
        sendUserMessage: SendUserMessage.fromPartial(object.message.sendUserMessage),
      };
    }
    if (
      object.message?.$case === "banUserMessage" &&
      object.message?.banUserMessage !== undefined &&
      object.message?.banUserMessage !== null
    ) {
      message.message = {
        $case: "banUserMessage",
        banUserMessage: BanUserMessage.fromPartial(object.message.banUserMessage),
      };
    }
    if (
      object.message?.$case === "worldFullWarningMessage" &&
      object.message?.worldFullWarningMessage !== undefined &&
      object.message?.worldFullWarningMessage !== null
    ) {
      message.message = {
        $case: "worldFullWarningMessage",
        worldFullWarningMessage: WorldFullWarningMessage.fromPartial(object.message.worldFullWarningMessage),
      };
    }
    if (
      object.message?.$case === "worldFullMessage" &&
      object.message?.worldFullMessage !== undefined &&
      object.message?.worldFullMessage !== null
    ) {
      message.message = {
        $case: "worldFullMessage",
        worldFullMessage: WorldFullMessage.fromPartial(object.message.worldFullMessage),
      };
    }
    if (
      object.message?.$case === "refreshRoomMessage" &&
      object.message?.refreshRoomMessage !== undefined &&
      object.message?.refreshRoomMessage !== null
    ) {
      message.message = {
        $case: "refreshRoomMessage",
        refreshRoomMessage: RefreshRoomMessage.fromPartial(object.message.refreshRoomMessage),
      };
    }
    if (
      object.message?.$case === "worldConnectionMessage" &&
      object.message?.worldConnectionMessage !== undefined &&
      object.message?.worldConnectionMessage !== null
    ) {
      message.message = {
        $case: "worldConnectionMessage",
        worldConnectionMessage: WorldConnectionMessage.fromPartial(object.message.worldConnectionMessage),
      };
    }
    if (
      object.message?.$case === "tokenExpiredMessage" &&
      object.message?.tokenExpiredMessage !== undefined &&
      object.message?.tokenExpiredMessage !== null
    ) {
      message.message = {
        $case: "tokenExpiredMessage",
        tokenExpiredMessage: TokenExpiredMessage.fromPartial(object.message.tokenExpiredMessage),
      };
    }
    if (
      object.message?.$case === "followRequestMessage" &&
      object.message?.followRequestMessage !== undefined &&
      object.message?.followRequestMessage !== null
    ) {
      message.message = {
        $case: "followRequestMessage",
        followRequestMessage: FollowRequestMessage.fromPartial(object.message.followRequestMessage),
      };
    }
    if (
      object.message?.$case === "followConfirmationMessage" &&
      object.message?.followConfirmationMessage !== undefined &&
      object.message?.followConfirmationMessage !== null
    ) {
      message.message = {
        $case: "followConfirmationMessage",
        followConfirmationMessage: FollowConfirmationMessage.fromPartial(object.message.followConfirmationMessage),
      };
    }
    if (
      object.message?.$case === "followAbortMessage" &&
      object.message?.followAbortMessage !== undefined &&
      object.message?.followAbortMessage !== null
    ) {
      message.message = {
        $case: "followAbortMessage",
        followAbortMessage: FollowAbortMessage.fromPartial(object.message.followAbortMessage),
      };
    }
    if (
      object.message?.$case === "invalidCharacterTextureMessage" &&
      object.message?.invalidCharacterTextureMessage !== undefined &&
      object.message?.invalidCharacterTextureMessage !== null
    ) {
      message.message = {
        $case: "invalidCharacterTextureMessage",
        invalidCharacterTextureMessage: InvalidCharacterTextureMessage.fromPartial(
          object.message.invalidCharacterTextureMessage,
        ),
      };
    }
    if (
      object.message?.$case === "groupUsersUpdateMessage" &&
      object.message?.groupUsersUpdateMessage !== undefined &&
      object.message?.groupUsersUpdateMessage !== null
    ) {
      message.message = {
        $case: "groupUsersUpdateMessage",
        groupUsersUpdateMessage: GroupUsersUpdateMessage.fromPartial(object.message.groupUsersUpdateMessage),
      };
    }
    if (
      object.message?.$case === "errorScreenMessage" &&
      object.message?.errorScreenMessage !== undefined &&
      object.message?.errorScreenMessage !== null
    ) {
      message.message = {
        $case: "errorScreenMessage",
        errorScreenMessage: ErrorScreenMessage.fromPartial(object.message.errorScreenMessage),
      };
    }
    if (
      object.message?.$case === "answerMessage" &&
      object.message?.answerMessage !== undefined &&
      object.message?.answerMessage !== null
    ) {
      message.message = {
        $case: "answerMessage",
        answerMessage: AnswerMessage.fromPartial(object.message.answerMessage),
      };
    }
    if (
      object.message?.$case === "xmppSettingsMessage" &&
      object.message?.xmppSettingsMessage !== undefined &&
      object.message?.xmppSettingsMessage !== null
    ) {
      message.message = {
        $case: "xmppSettingsMessage",
        xmppSettingsMessage: XmppSettingsMessage.fromPartial(object.message.xmppSettingsMessage),
      };
    }
    if (
      object.message?.$case === "moveToPositionMessage" &&
      object.message?.moveToPositionMessage !== undefined &&
      object.message?.moveToPositionMessage !== null
    ) {
      message.message = {
        $case: "moveToPositionMessage",
        moveToPositionMessage: MoveToPositionMessage.fromPartial(object.message.moveToPositionMessage),
      };
    }
    if (
      object.message?.$case === "invalidCompanionTextureMessage" &&
      object.message?.invalidCompanionTextureMessage !== undefined &&
      object.message?.invalidCompanionTextureMessage !== null
    ) {
      message.message = {
        $case: "invalidCompanionTextureMessage",
        invalidCompanionTextureMessage: InvalidCompanionTextureMessage.fromPartial(
          object.message.invalidCompanionTextureMessage,
        ),
      };
    }
    if (
      object.message?.$case === "mutedMessage" &&
      object.message?.mutedMessage !== undefined &&
      object.message?.mutedMessage !== null
    ) {
      message.message = { $case: "mutedMessage", mutedMessage: MutedMessage.fromPartial(object.message.mutedMessage) };
    }
    if (
      object.message?.$case === "mutedVideoMessage" &&
      object.message?.mutedVideoMessage !== undefined &&
      object.message?.mutedVideoMessage !== null
    ) {
      message.message = {
        $case: "mutedVideoMessage",
        mutedVideoMessage: MutedVideoMessage.fromPartial(object.message.mutedVideoMessage),
      };
    }
    if (
      object.message?.$case === "askMutedMessage" &&
      object.message?.askMutedMessage !== undefined &&
      object.message?.askMutedMessage !== null
    ) {
      message.message = {
        $case: "askMutedMessage",
        askMutedMessage: AskMutedMessage.fromPartial(object.message.askMutedMessage),
      };
    }
    if (
      object.message?.$case === "askMutedVideoMessage" &&
      object.message?.askMutedVideoMessage !== undefined &&
      object.message?.askMutedVideoMessage !== null
    ) {
      message.message = {
        $case: "askMutedVideoMessage",
        askMutedVideoMessage: AskMutedVideoMessage.fromPartial(object.message.askMutedVideoMessage),
      };
    }
    return message;
  },
};

function createBaseJoinRoomMessage(): JoinRoomMessage {
  return {
    positionMessage: undefined,
    name: "",
    characterTextures: [],
    userUuid: "",
    roomId: "",
    tag: [],
    IPAddress: "",
    companionTexture: undefined,
    visitCardUrl: undefined,
    userRoomToken: "",
    availabilityStatus: 0,
    activatedInviteUser: false,
    isLogged: false,
    applications: [],
    userJid: "",
    lastCommandId: undefined,
    canEdit: false,
  };
}

export const JoinRoomMessage = {
  encode(message: JoinRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.positionMessage !== undefined) {
      PositionMessage.encode(message.positionMessage, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.characterTextures) {
      CharacterTextureMessage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.userUuid !== "") {
      writer.uint32(34).string(message.userUuid);
    }
    if (message.roomId !== "") {
      writer.uint32(42).string(message.roomId);
    }
    for (const v of message.tag) {
      writer.uint32(50).string(v!);
    }
    if (message.IPAddress !== "") {
      writer.uint32(58).string(message.IPAddress);
    }
    if (message.companionTexture !== undefined) {
      CompanionTextureMessage.encode(message.companionTexture, writer.uint32(66).fork()).ldelim();
    }
    if (message.visitCardUrl !== undefined) {
      StringValue.encode({ value: message.visitCardUrl! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.userRoomToken !== "") {
      writer.uint32(82).string(message.userRoomToken);
    }
    if (message.availabilityStatus !== 0) {
      writer.uint32(88).int32(message.availabilityStatus);
    }
    if (message.activatedInviteUser === true) {
      writer.uint32(96).bool(message.activatedInviteUser);
    }
    if (message.isLogged === true) {
      writer.uint32(104).bool(message.isLogged);
    }
    for (const v of message.applications) {
      ApplicationMessage.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.userJid !== "") {
      writer.uint32(122).string(message.userJid);
    }
    if (message.lastCommandId !== undefined) {
      StringValue.encode({ value: message.lastCommandId! }, writer.uint32(130).fork()).ldelim();
    }
    if (message.canEdit === true) {
      writer.uint32(136).bool(message.canEdit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.positionMessage = PositionMessage.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.characterTextures.push(CharacterTextureMessage.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.userUuid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tag.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.IPAddress = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.companionTexture = CompanionTextureMessage.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.visitCardUrl = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.userRoomToken = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.availabilityStatus = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.activatedInviteUser = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isLogged = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.applications.push(ApplicationMessage.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.userJid = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.lastCommandId = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.canEdit = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinRoomMessage {
    return {
      positionMessage: isSet(object.positionMessage) ? PositionMessage.fromJSON(object.positionMessage) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      characterTextures: globalThis.Array.isArray(object?.characterTextures)
        ? object.characterTextures.map((e: any) => CharacterTextureMessage.fromJSON(e))
        : [],
      userUuid: isSet(object.userUuid) ? globalThis.String(object.userUuid) : "",
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      tag: globalThis.Array.isArray(object?.tag) ? object.tag.map((e: any) => globalThis.String(e)) : [],
      IPAddress: isSet(object.IPAddress) ? globalThis.String(object.IPAddress) : "",
      companionTexture: isSet(object.companionTexture)
        ? CompanionTextureMessage.fromJSON(object.companionTexture)
        : undefined,
      visitCardUrl: isSet(object.visitCardUrl) ? String(object.visitCardUrl) : undefined,
      userRoomToken: isSet(object.userRoomToken) ? globalThis.String(object.userRoomToken) : "",
      availabilityStatus: isSet(object.availabilityStatus) ? availabilityStatusFromJSON(object.availabilityStatus) : 0,
      activatedInviteUser: isSet(object.activatedInviteUser) ? globalThis.Boolean(object.activatedInviteUser) : false,
      isLogged: isSet(object.isLogged) ? globalThis.Boolean(object.isLogged) : false,
      applications: globalThis.Array.isArray(object?.applications)
        ? object.applications.map((e: any) => ApplicationMessage.fromJSON(e))
        : [],
      userJid: isSet(object.userJid) ? globalThis.String(object.userJid) : "",
      lastCommandId: isSet(object.lastCommandId) ? String(object.lastCommandId) : undefined,
      canEdit: isSet(object.canEdit) ? globalThis.Boolean(object.canEdit) : false,
    };
  },

  toJSON(message: JoinRoomMessage): unknown {
    const obj: any = {};
    if (message.positionMessage !== undefined) {
      obj.positionMessage = PositionMessage.toJSON(message.positionMessage);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.characterTextures?.length) {
      obj.characterTextures = message.characterTextures.map((e) => CharacterTextureMessage.toJSON(e));
    }
    if (message.userUuid !== "") {
      obj.userUuid = message.userUuid;
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.tag?.length) {
      obj.tag = message.tag;
    }
    if (message.IPAddress !== "") {
      obj.IPAddress = message.IPAddress;
    }
    if (message.companionTexture !== undefined) {
      obj.companionTexture = CompanionTextureMessage.toJSON(message.companionTexture);
    }
    if (message.visitCardUrl !== undefined) {
      obj.visitCardUrl = message.visitCardUrl;
    }
    if (message.userRoomToken !== "") {
      obj.userRoomToken = message.userRoomToken;
    }
    if (message.availabilityStatus !== 0) {
      obj.availabilityStatus = availabilityStatusToJSON(message.availabilityStatus);
    }
    if (message.activatedInviteUser === true) {
      obj.activatedInviteUser = message.activatedInviteUser;
    }
    if (message.isLogged === true) {
      obj.isLogged = message.isLogged;
    }
    if (message.applications?.length) {
      obj.applications = message.applications.map((e) => ApplicationMessage.toJSON(e));
    }
    if (message.userJid !== "") {
      obj.userJid = message.userJid;
    }
    if (message.lastCommandId !== undefined) {
      obj.lastCommandId = message.lastCommandId;
    }
    if (message.canEdit === true) {
      obj.canEdit = message.canEdit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinRoomMessage>, I>>(base?: I): JoinRoomMessage {
    return JoinRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinRoomMessage>, I>>(object: I): JoinRoomMessage {
    const message = createBaseJoinRoomMessage();
    message.positionMessage = (object.positionMessage !== undefined && object.positionMessage !== null)
      ? PositionMessage.fromPartial(object.positionMessage)
      : undefined;
    message.name = object.name ?? "";
    message.characterTextures = object.characterTextures?.map((e) => CharacterTextureMessage.fromPartial(e)) || [];
    message.userUuid = object.userUuid ?? "";
    message.roomId = object.roomId ?? "";
    message.tag = object.tag?.map((e) => e) || [];
    message.IPAddress = object.IPAddress ?? "";
    message.companionTexture = (object.companionTexture !== undefined && object.companionTexture !== null)
      ? CompanionTextureMessage.fromPartial(object.companionTexture)
      : undefined;
    message.visitCardUrl = object.visitCardUrl ?? undefined;
    message.userRoomToken = object.userRoomToken ?? "";
    message.availabilityStatus = object.availabilityStatus ?? 0;
    message.activatedInviteUser = object.activatedInviteUser ?? false;
    message.isLogged = object.isLogged ?? false;
    message.applications = object.applications?.map((e) => ApplicationMessage.fromPartial(e)) || [];
    message.userJid = object.userJid ?? "";
    message.lastCommandId = object.lastCommandId ?? undefined;
    message.canEdit = object.canEdit ?? false;
    return message;
  },
};

function createBaseUserJoinedZoneMessage(): UserJoinedZoneMessage {
  return {
    userId: 0,
    userJid: "",
    name: "",
    characterTextures: [],
    position: undefined,
    fromZone: undefined,
    companionTexture: undefined,
    visitCardUrl: "",
    userUuid: "",
    outlineColor: 0,
    hasOutline: false,
    availabilityStatus: 0,
    variables: {},
  };
}

export const UserJoinedZoneMessage = {
  encode(message: UserJoinedZoneMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.userJid !== "") {
      writer.uint32(18).string(message.userJid);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.characterTextures) {
      CharacterTextureMessage.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.position !== undefined) {
      PositionMessage.encode(message.position, writer.uint32(42).fork()).ldelim();
    }
    if (message.fromZone !== undefined) {
      Zone.encode(message.fromZone, writer.uint32(50).fork()).ldelim();
    }
    if (message.companionTexture !== undefined) {
      CompanionTextureMessage.encode(message.companionTexture, writer.uint32(58).fork()).ldelim();
    }
    if (message.visitCardUrl !== "") {
      writer.uint32(66).string(message.visitCardUrl);
    }
    if (message.userUuid !== "") {
      writer.uint32(74).string(message.userUuid);
    }
    if (message.outlineColor !== 0) {
      writer.uint32(80).uint32(message.outlineColor);
    }
    if (message.hasOutline === true) {
      writer.uint32(88).bool(message.hasOutline);
    }
    if (message.availabilityStatus !== 0) {
      writer.uint32(96).int32(message.availabilityStatus);
    }
    Object.entries(message.variables).forEach(([key, value]) => {
      UserJoinedZoneMessage_VariablesEntry.encode({ key: key as any, value }, writer.uint32(106).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserJoinedZoneMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserJoinedZoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userJid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.characterTextures.push(CharacterTextureMessage.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.position = PositionMessage.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fromZone = Zone.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.companionTexture = CompanionTextureMessage.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.visitCardUrl = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userUuid = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.outlineColor = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.hasOutline = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.availabilityStatus = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          const entry13 = UserJoinedZoneMessage_VariablesEntry.decode(reader, reader.uint32());
          if (entry13.value !== undefined) {
            message.variables[entry13.key] = entry13.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserJoinedZoneMessage {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      userJid: isSet(object.userJid) ? globalThis.String(object.userJid) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      characterTextures: globalThis.Array.isArray(object?.characterTextures)
        ? object.characterTextures.map((e: any) => CharacterTextureMessage.fromJSON(e))
        : [],
      position: isSet(object.position) ? PositionMessage.fromJSON(object.position) : undefined,
      fromZone: isSet(object.fromZone) ? Zone.fromJSON(object.fromZone) : undefined,
      companionTexture: isSet(object.companionTexture)
        ? CompanionTextureMessage.fromJSON(object.companionTexture)
        : undefined,
      visitCardUrl: isSet(object.visitCardUrl) ? globalThis.String(object.visitCardUrl) : "",
      userUuid: isSet(object.userUuid) ? globalThis.String(object.userUuid) : "",
      outlineColor: isSet(object.outlineColor) ? globalThis.Number(object.outlineColor) : 0,
      hasOutline: isSet(object.hasOutline) ? globalThis.Boolean(object.hasOutline) : false,
      availabilityStatus: isSet(object.availabilityStatus) ? availabilityStatusFromJSON(object.availabilityStatus) : 0,
      variables: isObject(object.variables)
        ? Object.entries(object.variables).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: UserJoinedZoneMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.userJid !== "") {
      obj.userJid = message.userJid;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.characterTextures?.length) {
      obj.characterTextures = message.characterTextures.map((e) => CharacterTextureMessage.toJSON(e));
    }
    if (message.position !== undefined) {
      obj.position = PositionMessage.toJSON(message.position);
    }
    if (message.fromZone !== undefined) {
      obj.fromZone = Zone.toJSON(message.fromZone);
    }
    if (message.companionTexture !== undefined) {
      obj.companionTexture = CompanionTextureMessage.toJSON(message.companionTexture);
    }
    if (message.visitCardUrl !== "") {
      obj.visitCardUrl = message.visitCardUrl;
    }
    if (message.userUuid !== "") {
      obj.userUuid = message.userUuid;
    }
    if (message.outlineColor !== 0) {
      obj.outlineColor = Math.round(message.outlineColor);
    }
    if (message.hasOutline === true) {
      obj.hasOutline = message.hasOutline;
    }
    if (message.availabilityStatus !== 0) {
      obj.availabilityStatus = availabilityStatusToJSON(message.availabilityStatus);
    }
    if (message.variables) {
      const entries = Object.entries(message.variables);
      if (entries.length > 0) {
        obj.variables = {};
        entries.forEach(([k, v]) => {
          obj.variables[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserJoinedZoneMessage>, I>>(base?: I): UserJoinedZoneMessage {
    return UserJoinedZoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserJoinedZoneMessage>, I>>(object: I): UserJoinedZoneMessage {
    const message = createBaseUserJoinedZoneMessage();
    message.userId = object.userId ?? 0;
    message.userJid = object.userJid ?? "";
    message.name = object.name ?? "";
    message.characterTextures = object.characterTextures?.map((e) => CharacterTextureMessage.fromPartial(e)) || [];
    message.position = (object.position !== undefined && object.position !== null)
      ? PositionMessage.fromPartial(object.position)
      : undefined;
    message.fromZone = (object.fromZone !== undefined && object.fromZone !== null)
      ? Zone.fromPartial(object.fromZone)
      : undefined;
    message.companionTexture = (object.companionTexture !== undefined && object.companionTexture !== null)
      ? CompanionTextureMessage.fromPartial(object.companionTexture)
      : undefined;
    message.visitCardUrl = object.visitCardUrl ?? "";
    message.userUuid = object.userUuid ?? "";
    message.outlineColor = object.outlineColor ?? 0;
    message.hasOutline = object.hasOutline ?? false;
    message.availabilityStatus = object.availabilityStatus ?? 0;
    message.variables = Object.entries(object.variables ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUserJoinedZoneMessage_VariablesEntry(): UserJoinedZoneMessage_VariablesEntry {
  return { key: "", value: "" };
}

export const UserJoinedZoneMessage_VariablesEntry = {
  encode(message: UserJoinedZoneMessage_VariablesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserJoinedZoneMessage_VariablesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserJoinedZoneMessage_VariablesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserJoinedZoneMessage_VariablesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: UserJoinedZoneMessage_VariablesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserJoinedZoneMessage_VariablesEntry>, I>>(
    base?: I,
  ): UserJoinedZoneMessage_VariablesEntry {
    return UserJoinedZoneMessage_VariablesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserJoinedZoneMessage_VariablesEntry>, I>>(
    object: I,
  ): UserJoinedZoneMessage_VariablesEntry {
    const message = createBaseUserJoinedZoneMessage_VariablesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUserLeftZoneMessage(): UserLeftZoneMessage {
  return { userId: 0, toZone: undefined };
}

export const UserLeftZoneMessage = {
  encode(message: UserLeftZoneMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.toZone !== undefined) {
      Zone.encode(message.toZone, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserLeftZoneMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserLeftZoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.toZone = Zone.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserLeftZoneMessage {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      toZone: isSet(object.toZone) ? Zone.fromJSON(object.toZone) : undefined,
    };
  },

  toJSON(message: UserLeftZoneMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.toZone !== undefined) {
      obj.toZone = Zone.toJSON(message.toZone);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserLeftZoneMessage>, I>>(base?: I): UserLeftZoneMessage {
    return UserLeftZoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserLeftZoneMessage>, I>>(object: I): UserLeftZoneMessage {
    const message = createBaseUserLeftZoneMessage();
    message.userId = object.userId ?? 0;
    message.toZone = (object.toZone !== undefined && object.toZone !== null)
      ? Zone.fromPartial(object.toZone)
      : undefined;
    return message;
  },
};

function createBaseGroupUpdateZoneMessage(): GroupUpdateZoneMessage {
  return { groupId: 0, position: undefined, groupSize: 0, fromZone: undefined, locked: false };
}

export const GroupUpdateZoneMessage = {
  encode(message: GroupUpdateZoneMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== 0) {
      writer.uint32(8).int32(message.groupId);
    }
    if (message.position !== undefined) {
      PointMessage.encode(message.position, writer.uint32(18).fork()).ldelim();
    }
    if (message.groupSize !== 0) {
      writer.uint32(24).int32(message.groupSize);
    }
    if (message.fromZone !== undefined) {
      Zone.encode(message.fromZone, writer.uint32(34).fork()).ldelim();
    }
    if (message.locked === true) {
      writer.uint32(40).bool(message.locked);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupUpdateZoneMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupUpdateZoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.groupId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.position = PointMessage.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.groupSize = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fromZone = Zone.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.locked = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupUpdateZoneMessage {
    return {
      groupId: isSet(object.groupId) ? globalThis.Number(object.groupId) : 0,
      position: isSet(object.position) ? PointMessage.fromJSON(object.position) : undefined,
      groupSize: isSet(object.groupSize) ? globalThis.Number(object.groupSize) : 0,
      fromZone: isSet(object.fromZone) ? Zone.fromJSON(object.fromZone) : undefined,
      locked: isSet(object.locked) ? globalThis.Boolean(object.locked) : false,
    };
  },

  toJSON(message: GroupUpdateZoneMessage): unknown {
    const obj: any = {};
    if (message.groupId !== 0) {
      obj.groupId = Math.round(message.groupId);
    }
    if (message.position !== undefined) {
      obj.position = PointMessage.toJSON(message.position);
    }
    if (message.groupSize !== 0) {
      obj.groupSize = Math.round(message.groupSize);
    }
    if (message.fromZone !== undefined) {
      obj.fromZone = Zone.toJSON(message.fromZone);
    }
    if (message.locked === true) {
      obj.locked = message.locked;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupUpdateZoneMessage>, I>>(base?: I): GroupUpdateZoneMessage {
    return GroupUpdateZoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupUpdateZoneMessage>, I>>(object: I): GroupUpdateZoneMessage {
    const message = createBaseGroupUpdateZoneMessage();
    message.groupId = object.groupId ?? 0;
    message.position = (object.position !== undefined && object.position !== null)
      ? PointMessage.fromPartial(object.position)
      : undefined;
    message.groupSize = object.groupSize ?? 0;
    message.fromZone = (object.fromZone !== undefined && object.fromZone !== null)
      ? Zone.fromPartial(object.fromZone)
      : undefined;
    message.locked = object.locked ?? false;
    return message;
  },
};

function createBaseGroupLeftZoneMessage(): GroupLeftZoneMessage {
  return { groupId: 0, toZone: undefined };
}

export const GroupLeftZoneMessage = {
  encode(message: GroupLeftZoneMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== 0) {
      writer.uint32(8).int32(message.groupId);
    }
    if (message.toZone !== undefined) {
      Zone.encode(message.toZone, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupLeftZoneMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupLeftZoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.groupId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.toZone = Zone.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupLeftZoneMessage {
    return {
      groupId: isSet(object.groupId) ? globalThis.Number(object.groupId) : 0,
      toZone: isSet(object.toZone) ? Zone.fromJSON(object.toZone) : undefined,
    };
  },

  toJSON(message: GroupLeftZoneMessage): unknown {
    const obj: any = {};
    if (message.groupId !== 0) {
      obj.groupId = Math.round(message.groupId);
    }
    if (message.toZone !== undefined) {
      obj.toZone = Zone.toJSON(message.toZone);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupLeftZoneMessage>, I>>(base?: I): GroupLeftZoneMessage {
    return GroupLeftZoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupLeftZoneMessage>, I>>(object: I): GroupLeftZoneMessage {
    const message = createBaseGroupLeftZoneMessage();
    message.groupId = object.groupId ?? 0;
    message.toZone = (object.toZone !== undefined && object.toZone !== null)
      ? Zone.fromPartial(object.toZone)
      : undefined;
    return message;
  },
};

function createBasePlayerDetailsUpdatedMessage(): PlayerDetailsUpdatedMessage {
  return { userId: 0, details: undefined };
}

export const PlayerDetailsUpdatedMessage = {
  encode(message: PlayerDetailsUpdatedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.details !== undefined) {
      SetPlayerDetailsMessage.encode(message.details, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PlayerDetailsUpdatedMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlayerDetailsUpdatedMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.details = SetPlayerDetailsMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlayerDetailsUpdatedMessage {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      details: isSet(object.details) ? SetPlayerDetailsMessage.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: PlayerDetailsUpdatedMessage): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.details !== undefined) {
      obj.details = SetPlayerDetailsMessage.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlayerDetailsUpdatedMessage>, I>>(base?: I): PlayerDetailsUpdatedMessage {
    return PlayerDetailsUpdatedMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlayerDetailsUpdatedMessage>, I>>(object: I): PlayerDetailsUpdatedMessage {
    const message = createBasePlayerDetailsUpdatedMessage();
    message.userId = object.userId ?? 0;
    message.details = (object.details !== undefined && object.details !== null)
      ? SetPlayerDetailsMessage.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseZone(): Zone {
  return { x: 0, y: 0 };
}

export const Zone = {
  encode(message: Zone, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(8).int32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(16).int32(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Zone {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZone();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.y = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Zone {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: Zone): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Zone>, I>>(base?: I): Zone {
    return Zone.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Zone>, I>>(object: I): Zone {
    const message = createBaseZone();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseZoneMessage(): ZoneMessage {
  return { roomId: "", x: 0, y: 0 };
}

export const ZoneMessage = {
  encode(message: ZoneMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    if (message.x !== 0) {
      writer.uint32(16).int32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(24).int32(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZoneMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.x = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.y = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZoneMessage {
    return {
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: ZoneMessage): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ZoneMessage>, I>>(base?: I): ZoneMessage {
    return ZoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ZoneMessage>, I>>(object: I): ZoneMessage {
    const message = createBaseZoneMessage();
    message.roomId = object.roomId ?? "";
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseRoomMessage(): RoomMessage {
  return { roomId: "" };
}

export const RoomMessage = {
  encode(message: RoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomMessage {
    return { roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "" };
  },

  toJSON(message: RoomMessage): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomMessage>, I>>(base?: I): RoomMessage {
    return RoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomMessage>, I>>(object: I): RoomMessage {
    const message = createBaseRoomMessage();
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBasePusherToBackMessage(): PusherToBackMessage {
  return { message: undefined };
}

export const PusherToBackMessage = {
  encode(message: PusherToBackMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "joinRoomMessage":
        JoinRoomMessage.encode(message.message.joinRoomMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "userMovesMessage":
        UserMovesMessage.encode(message.message.userMovesMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "itemEventMessage":
        ItemEventMessage.encode(message.message.itemEventMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "setPlayerDetailsMessage":
        SetPlayerDetailsMessage.encode(message.message.setPlayerDetailsMessage, writer.uint32(42).fork()).ldelim();
        break;
      case "webRtcSignalToServerMessage":
        WebRtcSignalToServerMessage.encode(message.message.webRtcSignalToServerMessage, writer.uint32(50).fork())
          .ldelim();
        break;
      case "webRtcScreenSharingSignalToServerMessage":
        WebRtcSignalToServerMessage.encode(
          message.message.webRtcScreenSharingSignalToServerMessage,
          writer.uint32(58).fork(),
        ).ldelim();
        break;
      case "sendUserMessage":
        SendUserMessage.encode(message.message.sendUserMessage, writer.uint32(98).fork()).ldelim();
        break;
      case "banUserMessage":
        BanUserMessage.encode(message.message.banUserMessage, writer.uint32(106).fork()).ldelim();
        break;
      case "emotePromptMessage":
        EmotePromptMessage.encode(message.message.emotePromptMessage, writer.uint32(114).fork()).ldelim();
        break;
      case "variableMessage":
        VariableMessage.encode(message.message.variableMessage, writer.uint32(122).fork()).ldelim();
        break;
      case "followRequestMessage":
        FollowRequestMessage.encode(message.message.followRequestMessage, writer.uint32(130).fork()).ldelim();
        break;
      case "followConfirmationMessage":
        FollowConfirmationMessage.encode(message.message.followConfirmationMessage, writer.uint32(138).fork()).ldelim();
        break;
      case "followAbortMessage":
        FollowAbortMessage.encode(message.message.followAbortMessage, writer.uint32(146).fork()).ldelim();
        break;
      case "lockGroupPromptMessage":
        LockGroupPromptMessage.encode(message.message.lockGroupPromptMessage, writer.uint32(154).fork()).ldelim();
        break;
      case "queryMessage":
        QueryMessage.encode(message.message.queryMessage, writer.uint32(170).fork()).ldelim();
        break;
      case "askPositionMessage":
        AskPositionMessage.encode(message.message.askPositionMessage, writer.uint32(178).fork()).ldelim();
        break;
      case "editMapCommandMessage":
        EditMapCommandMessage.encode(message.message.editMapCommandMessage, writer.uint32(186).fork()).ldelim();
        break;
      case "pingMessage":
        PingMessage.encode(message.message.pingMessage, writer.uint32(194).fork()).ldelim();
        break;
      case "kickOffUserMessage":
        KickOffUserMessage.encode(message.message.kickOffUserMessage, writer.uint32(202).fork()).ldelim();
        break;
      case "muteParticipantIdMessage":
        MuteParticipantIdMessage.encode(message.message.muteParticipantIdMessage, writer.uint32(210).fork()).ldelim();
        break;
      case "muteEveryBodyParticipantMessage":
        MuteEveryBodyParticipantMessage.encode(
          message.message.muteEveryBodyParticipantMessage,
          writer.uint32(218).fork(),
        ).ldelim();
        break;
      case "muteVideoParticipantIdMessage":
        MuteVideoParticipantIdMessage.encode(message.message.muteVideoParticipantIdMessage, writer.uint32(226).fork())
          .ldelim();
        break;
      case "muteVideoEveryBodyParticipantMessage":
        MuteVideoEveryBodyParticipantMessage.encode(
          message.message.muteVideoEveryBodyParticipantMessage,
          writer.uint32(234).fork(),
        ).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PusherToBackMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePusherToBackMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "joinRoomMessage",
            joinRoomMessage: JoinRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "userMovesMessage",
            userMovesMessage: UserMovesMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "itemEventMessage",
            itemEventMessage: ItemEventMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "setPlayerDetailsMessage",
            setPlayerDetailsMessage: SetPlayerDetailsMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = {
            $case: "webRtcSignalToServerMessage",
            webRtcSignalToServerMessage: WebRtcSignalToServerMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "webRtcScreenSharingSignalToServerMessage",
            webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.message = {
            $case: "sendUserMessage",
            sendUserMessage: SendUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.message = { $case: "banUserMessage", banUserMessage: BanUserMessage.decode(reader, reader.uint32()) };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.message = {
            $case: "emotePromptMessage",
            emotePromptMessage: EmotePromptMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.message = {
            $case: "variableMessage",
            variableMessage: VariableMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.message = {
            $case: "followRequestMessage",
            followRequestMessage: FollowRequestMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.message = {
            $case: "followConfirmationMessage",
            followConfirmationMessage: FollowConfirmationMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.message = {
            $case: "followAbortMessage",
            followAbortMessage: FollowAbortMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.message = {
            $case: "lockGroupPromptMessage",
            lockGroupPromptMessage: LockGroupPromptMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.message = { $case: "queryMessage", queryMessage: QueryMessage.decode(reader, reader.uint32()) };
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.message = {
            $case: "askPositionMessage",
            askPositionMessage: AskPositionMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.message = {
            $case: "editMapCommandMessage",
            editMapCommandMessage: EditMapCommandMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.message = { $case: "pingMessage", pingMessage: PingMessage.decode(reader, reader.uint32()) };
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.message = {
            $case: "kickOffUserMessage",
            kickOffUserMessage: KickOffUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.message = {
            $case: "muteParticipantIdMessage",
            muteParticipantIdMessage: MuteParticipantIdMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.message = {
            $case: "muteEveryBodyParticipantMessage",
            muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.message = {
            $case: "muteVideoParticipantIdMessage",
            muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.message = {
            $case: "muteVideoEveryBodyParticipantMessage",
            muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PusherToBackMessage {
    return {
      message: isSet(object.joinRoomMessage)
        ? { $case: "joinRoomMessage", joinRoomMessage: JoinRoomMessage.fromJSON(object.joinRoomMessage) }
        : isSet(object.userMovesMessage)
        ? { $case: "userMovesMessage", userMovesMessage: UserMovesMessage.fromJSON(object.userMovesMessage) }
        : isSet(object.itemEventMessage)
        ? { $case: "itemEventMessage", itemEventMessage: ItemEventMessage.fromJSON(object.itemEventMessage) }
        : isSet(object.setPlayerDetailsMessage)
        ? {
          $case: "setPlayerDetailsMessage",
          setPlayerDetailsMessage: SetPlayerDetailsMessage.fromJSON(object.setPlayerDetailsMessage),
        }
        : isSet(object.webRtcSignalToServerMessage)
        ? {
          $case: "webRtcSignalToServerMessage",
          webRtcSignalToServerMessage: WebRtcSignalToServerMessage.fromJSON(object.webRtcSignalToServerMessage),
        }
        : isSet(object.webRtcScreenSharingSignalToServerMessage)
        ? {
          $case: "webRtcScreenSharingSignalToServerMessage",
          webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage.fromJSON(
            object.webRtcScreenSharingSignalToServerMessage,
          ),
        }
        : isSet(object.sendUserMessage)
        ? { $case: "sendUserMessage", sendUserMessage: SendUserMessage.fromJSON(object.sendUserMessage) }
        : isSet(object.banUserMessage)
        ? { $case: "banUserMessage", banUserMessage: BanUserMessage.fromJSON(object.banUserMessage) }
        : isSet(object.emotePromptMessage)
        ? { $case: "emotePromptMessage", emotePromptMessage: EmotePromptMessage.fromJSON(object.emotePromptMessage) }
        : isSet(object.variableMessage)
        ? { $case: "variableMessage", variableMessage: VariableMessage.fromJSON(object.variableMessage) }
        : isSet(object.followRequestMessage)
        ? {
          $case: "followRequestMessage",
          followRequestMessage: FollowRequestMessage.fromJSON(object.followRequestMessage),
        }
        : isSet(object.followConfirmationMessage)
        ? {
          $case: "followConfirmationMessage",
          followConfirmationMessage: FollowConfirmationMessage.fromJSON(object.followConfirmationMessage),
        }
        : isSet(object.followAbortMessage)
        ? { $case: "followAbortMessage", followAbortMessage: FollowAbortMessage.fromJSON(object.followAbortMessage) }
        : isSet(object.lockGroupPromptMessage)
        ? {
          $case: "lockGroupPromptMessage",
          lockGroupPromptMessage: LockGroupPromptMessage.fromJSON(object.lockGroupPromptMessage),
        }
        : isSet(object.queryMessage)
        ? { $case: "queryMessage", queryMessage: QueryMessage.fromJSON(object.queryMessage) }
        : isSet(object.askPositionMessage)
        ? { $case: "askPositionMessage", askPositionMessage: AskPositionMessage.fromJSON(object.askPositionMessage) }
        : isSet(object.editMapCommandMessage)
        ? {
          $case: "editMapCommandMessage",
          editMapCommandMessage: EditMapCommandMessage.fromJSON(object.editMapCommandMessage),
        }
        : isSet(object.pingMessage)
        ? { $case: "pingMessage", pingMessage: PingMessage.fromJSON(object.pingMessage) }
        : isSet(object.kickOffUserMessage)
        ? { $case: "kickOffUserMessage", kickOffUserMessage: KickOffUserMessage.fromJSON(object.kickOffUserMessage) }
        : isSet(object.muteParticipantIdMessage)
        ? {
          $case: "muteParticipantIdMessage",
          muteParticipantIdMessage: MuteParticipantIdMessage.fromJSON(object.muteParticipantIdMessage),
        }
        : isSet(object.muteEveryBodyParticipantMessage)
        ? {
          $case: "muteEveryBodyParticipantMessage",
          muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage.fromJSON(
            object.muteEveryBodyParticipantMessage,
          ),
        }
        : isSet(object.muteVideoParticipantIdMessage)
        ? {
          $case: "muteVideoParticipantIdMessage",
          muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage.fromJSON(object.muteVideoParticipantIdMessage),
        }
        : isSet(object.muteVideoEveryBodyParticipantMessage)
        ? {
          $case: "muteVideoEveryBodyParticipantMessage",
          muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage.fromJSON(
            object.muteVideoEveryBodyParticipantMessage,
          ),
        }
        : undefined,
    };
  },

  toJSON(message: PusherToBackMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "joinRoomMessage") {
      obj.joinRoomMessage = JoinRoomMessage.toJSON(message.message.joinRoomMessage);
    }
    if (message.message?.$case === "userMovesMessage") {
      obj.userMovesMessage = UserMovesMessage.toJSON(message.message.userMovesMessage);
    }
    if (message.message?.$case === "itemEventMessage") {
      obj.itemEventMessage = ItemEventMessage.toJSON(message.message.itemEventMessage);
    }
    if (message.message?.$case === "setPlayerDetailsMessage") {
      obj.setPlayerDetailsMessage = SetPlayerDetailsMessage.toJSON(message.message.setPlayerDetailsMessage);
    }
    if (message.message?.$case === "webRtcSignalToServerMessage") {
      obj.webRtcSignalToServerMessage = WebRtcSignalToServerMessage.toJSON(message.message.webRtcSignalToServerMessage);
    }
    if (message.message?.$case === "webRtcScreenSharingSignalToServerMessage") {
      obj.webRtcScreenSharingSignalToServerMessage = WebRtcSignalToServerMessage.toJSON(
        message.message.webRtcScreenSharingSignalToServerMessage,
      );
    }
    if (message.message?.$case === "sendUserMessage") {
      obj.sendUserMessage = SendUserMessage.toJSON(message.message.sendUserMessage);
    }
    if (message.message?.$case === "banUserMessage") {
      obj.banUserMessage = BanUserMessage.toJSON(message.message.banUserMessage);
    }
    if (message.message?.$case === "emotePromptMessage") {
      obj.emotePromptMessage = EmotePromptMessage.toJSON(message.message.emotePromptMessage);
    }
    if (message.message?.$case === "variableMessage") {
      obj.variableMessage = VariableMessage.toJSON(message.message.variableMessage);
    }
    if (message.message?.$case === "followRequestMessage") {
      obj.followRequestMessage = FollowRequestMessage.toJSON(message.message.followRequestMessage);
    }
    if (message.message?.$case === "followConfirmationMessage") {
      obj.followConfirmationMessage = FollowConfirmationMessage.toJSON(message.message.followConfirmationMessage);
    }
    if (message.message?.$case === "followAbortMessage") {
      obj.followAbortMessage = FollowAbortMessage.toJSON(message.message.followAbortMessage);
    }
    if (message.message?.$case === "lockGroupPromptMessage") {
      obj.lockGroupPromptMessage = LockGroupPromptMessage.toJSON(message.message.lockGroupPromptMessage);
    }
    if (message.message?.$case === "queryMessage") {
      obj.queryMessage = QueryMessage.toJSON(message.message.queryMessage);
    }
    if (message.message?.$case === "askPositionMessage") {
      obj.askPositionMessage = AskPositionMessage.toJSON(message.message.askPositionMessage);
    }
    if (message.message?.$case === "editMapCommandMessage") {
      obj.editMapCommandMessage = EditMapCommandMessage.toJSON(message.message.editMapCommandMessage);
    }
    if (message.message?.$case === "pingMessage") {
      obj.pingMessage = PingMessage.toJSON(message.message.pingMessage);
    }
    if (message.message?.$case === "kickOffUserMessage") {
      obj.kickOffUserMessage = KickOffUserMessage.toJSON(message.message.kickOffUserMessage);
    }
    if (message.message?.$case === "muteParticipantIdMessage") {
      obj.muteParticipantIdMessage = MuteParticipantIdMessage.toJSON(message.message.muteParticipantIdMessage);
    }
    if (message.message?.$case === "muteEveryBodyParticipantMessage") {
      obj.muteEveryBodyParticipantMessage = MuteEveryBodyParticipantMessage.toJSON(
        message.message.muteEveryBodyParticipantMessage,
      );
    }
    if (message.message?.$case === "muteVideoParticipantIdMessage") {
      obj.muteVideoParticipantIdMessage = MuteVideoParticipantIdMessage.toJSON(
        message.message.muteVideoParticipantIdMessage,
      );
    }
    if (message.message?.$case === "muteVideoEveryBodyParticipantMessage") {
      obj.muteVideoEveryBodyParticipantMessage = MuteVideoEveryBodyParticipantMessage.toJSON(
        message.message.muteVideoEveryBodyParticipantMessage,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PusherToBackMessage>, I>>(base?: I): PusherToBackMessage {
    return PusherToBackMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PusherToBackMessage>, I>>(object: I): PusherToBackMessage {
    const message = createBasePusherToBackMessage();
    if (
      object.message?.$case === "joinRoomMessage" &&
      object.message?.joinRoomMessage !== undefined &&
      object.message?.joinRoomMessage !== null
    ) {
      message.message = {
        $case: "joinRoomMessage",
        joinRoomMessage: JoinRoomMessage.fromPartial(object.message.joinRoomMessage),
      };
    }
    if (
      object.message?.$case === "userMovesMessage" &&
      object.message?.userMovesMessage !== undefined &&
      object.message?.userMovesMessage !== null
    ) {
      message.message = {
        $case: "userMovesMessage",
        userMovesMessage: UserMovesMessage.fromPartial(object.message.userMovesMessage),
      };
    }
    if (
      object.message?.$case === "itemEventMessage" &&
      object.message?.itemEventMessage !== undefined &&
      object.message?.itemEventMessage !== null
    ) {
      message.message = {
        $case: "itemEventMessage",
        itemEventMessage: ItemEventMessage.fromPartial(object.message.itemEventMessage),
      };
    }
    if (
      object.message?.$case === "setPlayerDetailsMessage" &&
      object.message?.setPlayerDetailsMessage !== undefined &&
      object.message?.setPlayerDetailsMessage !== null
    ) {
      message.message = {
        $case: "setPlayerDetailsMessage",
        setPlayerDetailsMessage: SetPlayerDetailsMessage.fromPartial(object.message.setPlayerDetailsMessage),
      };
    }
    if (
      object.message?.$case === "webRtcSignalToServerMessage" &&
      object.message?.webRtcSignalToServerMessage !== undefined &&
      object.message?.webRtcSignalToServerMessage !== null
    ) {
      message.message = {
        $case: "webRtcSignalToServerMessage",
        webRtcSignalToServerMessage: WebRtcSignalToServerMessage.fromPartial(
          object.message.webRtcSignalToServerMessage,
        ),
      };
    }
    if (
      object.message?.$case === "webRtcScreenSharingSignalToServerMessage" &&
      object.message?.webRtcScreenSharingSignalToServerMessage !== undefined &&
      object.message?.webRtcScreenSharingSignalToServerMessage !== null
    ) {
      message.message = {
        $case: "webRtcScreenSharingSignalToServerMessage",
        webRtcScreenSharingSignalToServerMessage: WebRtcSignalToServerMessage.fromPartial(
          object.message.webRtcScreenSharingSignalToServerMessage,
        ),
      };
    }
    if (
      object.message?.$case === "sendUserMessage" &&
      object.message?.sendUserMessage !== undefined &&
      object.message?.sendUserMessage !== null
    ) {
      message.message = {
        $case: "sendUserMessage",
        sendUserMessage: SendUserMessage.fromPartial(object.message.sendUserMessage),
      };
    }
    if (
      object.message?.$case === "banUserMessage" &&
      object.message?.banUserMessage !== undefined &&
      object.message?.banUserMessage !== null
    ) {
      message.message = {
        $case: "banUserMessage",
        banUserMessage: BanUserMessage.fromPartial(object.message.banUserMessage),
      };
    }
    if (
      object.message?.$case === "emotePromptMessage" &&
      object.message?.emotePromptMessage !== undefined &&
      object.message?.emotePromptMessage !== null
    ) {
      message.message = {
        $case: "emotePromptMessage",
        emotePromptMessage: EmotePromptMessage.fromPartial(object.message.emotePromptMessage),
      };
    }
    if (
      object.message?.$case === "variableMessage" &&
      object.message?.variableMessage !== undefined &&
      object.message?.variableMessage !== null
    ) {
      message.message = {
        $case: "variableMessage",
        variableMessage: VariableMessage.fromPartial(object.message.variableMessage),
      };
    }
    if (
      object.message?.$case === "followRequestMessage" &&
      object.message?.followRequestMessage !== undefined &&
      object.message?.followRequestMessage !== null
    ) {
      message.message = {
        $case: "followRequestMessage",
        followRequestMessage: FollowRequestMessage.fromPartial(object.message.followRequestMessage),
      };
    }
    if (
      object.message?.$case === "followConfirmationMessage" &&
      object.message?.followConfirmationMessage !== undefined &&
      object.message?.followConfirmationMessage !== null
    ) {
      message.message = {
        $case: "followConfirmationMessage",
        followConfirmationMessage: FollowConfirmationMessage.fromPartial(object.message.followConfirmationMessage),
      };
    }
    if (
      object.message?.$case === "followAbortMessage" &&
      object.message?.followAbortMessage !== undefined &&
      object.message?.followAbortMessage !== null
    ) {
      message.message = {
        $case: "followAbortMessage",
        followAbortMessage: FollowAbortMessage.fromPartial(object.message.followAbortMessage),
      };
    }
    if (
      object.message?.$case === "lockGroupPromptMessage" &&
      object.message?.lockGroupPromptMessage !== undefined &&
      object.message?.lockGroupPromptMessage !== null
    ) {
      message.message = {
        $case: "lockGroupPromptMessage",
        lockGroupPromptMessage: LockGroupPromptMessage.fromPartial(object.message.lockGroupPromptMessage),
      };
    }
    if (
      object.message?.$case === "queryMessage" &&
      object.message?.queryMessage !== undefined &&
      object.message?.queryMessage !== null
    ) {
      message.message = { $case: "queryMessage", queryMessage: QueryMessage.fromPartial(object.message.queryMessage) };
    }
    if (
      object.message?.$case === "askPositionMessage" &&
      object.message?.askPositionMessage !== undefined &&
      object.message?.askPositionMessage !== null
    ) {
      message.message = {
        $case: "askPositionMessage",
        askPositionMessage: AskPositionMessage.fromPartial(object.message.askPositionMessage),
      };
    }
    if (
      object.message?.$case === "editMapCommandMessage" &&
      object.message?.editMapCommandMessage !== undefined &&
      object.message?.editMapCommandMessage !== null
    ) {
      message.message = {
        $case: "editMapCommandMessage",
        editMapCommandMessage: EditMapCommandMessage.fromPartial(object.message.editMapCommandMessage),
      };
    }
    if (
      object.message?.$case === "pingMessage" &&
      object.message?.pingMessage !== undefined &&
      object.message?.pingMessage !== null
    ) {
      message.message = { $case: "pingMessage", pingMessage: PingMessage.fromPartial(object.message.pingMessage) };
    }
    if (
      object.message?.$case === "kickOffUserMessage" &&
      object.message?.kickOffUserMessage !== undefined &&
      object.message?.kickOffUserMessage !== null
    ) {
      message.message = {
        $case: "kickOffUserMessage",
        kickOffUserMessage: KickOffUserMessage.fromPartial(object.message.kickOffUserMessage),
      };
    }
    if (
      object.message?.$case === "muteParticipantIdMessage" &&
      object.message?.muteParticipantIdMessage !== undefined &&
      object.message?.muteParticipantIdMessage !== null
    ) {
      message.message = {
        $case: "muteParticipantIdMessage",
        muteParticipantIdMessage: MuteParticipantIdMessage.fromPartial(object.message.muteParticipantIdMessage),
      };
    }
    if (
      object.message?.$case === "muteEveryBodyParticipantMessage" &&
      object.message?.muteEveryBodyParticipantMessage !== undefined &&
      object.message?.muteEveryBodyParticipantMessage !== null
    ) {
      message.message = {
        $case: "muteEveryBodyParticipantMessage",
        muteEveryBodyParticipantMessage: MuteEveryBodyParticipantMessage.fromPartial(
          object.message.muteEveryBodyParticipantMessage,
        ),
      };
    }
    if (
      object.message?.$case === "muteVideoParticipantIdMessage" &&
      object.message?.muteVideoParticipantIdMessage !== undefined &&
      object.message?.muteVideoParticipantIdMessage !== null
    ) {
      message.message = {
        $case: "muteVideoParticipantIdMessage",
        muteVideoParticipantIdMessage: MuteVideoParticipantIdMessage.fromPartial(
          object.message.muteVideoParticipantIdMessage,
        ),
      };
    }
    if (
      object.message?.$case === "muteVideoEveryBodyParticipantMessage" &&
      object.message?.muteVideoEveryBodyParticipantMessage !== undefined &&
      object.message?.muteVideoEveryBodyParticipantMessage !== null
    ) {
      message.message = {
        $case: "muteVideoEveryBodyParticipantMessage",
        muteVideoEveryBodyParticipantMessage: MuteVideoEveryBodyParticipantMessage.fromPartial(
          object.message.muteVideoEveryBodyParticipantMessage,
        ),
      };
    }
    return message;
  },
};

function createBaseBatchToPusherMessage(): BatchToPusherMessage {
  return { payload: [] };
}

export const BatchToPusherMessage = {
  encode(message: BatchToPusherMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.payload) {
      SubToPusherMessage.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchToPusherMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchToPusherMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.payload.push(SubToPusherMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchToPusherMessage {
    return {
      payload: globalThis.Array.isArray(object?.payload)
        ? object.payload.map((e: any) => SubToPusherMessage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchToPusherMessage): unknown {
    const obj: any = {};
    if (message.payload?.length) {
      obj.payload = message.payload.map((e) => SubToPusherMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchToPusherMessage>, I>>(base?: I): BatchToPusherMessage {
    return BatchToPusherMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchToPusherMessage>, I>>(object: I): BatchToPusherMessage {
    const message = createBaseBatchToPusherMessage();
    message.payload = object.payload?.map((e) => SubToPusherMessage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubToPusherMessage(): SubToPusherMessage {
  return { message: undefined };
}

export const SubToPusherMessage = {
  encode(message: SubToPusherMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "userJoinedZoneMessage":
        UserJoinedZoneMessage.encode(message.message.userJoinedZoneMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "groupUpdateZoneMessage":
        GroupUpdateZoneMessage.encode(message.message.groupUpdateZoneMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "userMovedMessage":
        UserMovedMessage.encode(message.message.userMovedMessage, writer.uint32(26).fork()).ldelim();
        break;
      case "groupLeftZoneMessage":
        GroupLeftZoneMessage.encode(message.message.groupLeftZoneMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "userLeftZoneMessage":
        UserLeftZoneMessage.encode(message.message.userLeftZoneMessage, writer.uint32(42).fork()).ldelim();
        break;
      case "itemEventMessage":
        ItemEventMessage.encode(message.message.itemEventMessage, writer.uint32(50).fork()).ldelim();
        break;
      case "sendUserMessage":
        SendUserMessage.encode(message.message.sendUserMessage, writer.uint32(58).fork()).ldelim();
        break;
      case "banUserMessage":
        BanUserMessage.encode(message.message.banUserMessage, writer.uint32(66).fork()).ldelim();
        break;
      case "emoteEventMessage":
        EmoteEventMessage.encode(message.message.emoteEventMessage, writer.uint32(74).fork()).ldelim();
        break;
      case "errorMessage":
        ErrorMessage.encode(message.message.errorMessage, writer.uint32(82).fork()).ldelim();
        break;
      case "playerDetailsUpdatedMessage":
        PlayerDetailsUpdatedMessage.encode(message.message.playerDetailsUpdatedMessage, writer.uint32(90).fork())
          .ldelim();
        break;
      case "editMapCommandMessage":
        EditMapCommandMessage.encode(message.message.editMapCommandMessage, writer.uint32(98).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubToPusherMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubToPusherMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "userJoinedZoneMessage",
            userJoinedZoneMessage: UserJoinedZoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "groupUpdateZoneMessage",
            groupUpdateZoneMessage: GroupUpdateZoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "userMovedMessage",
            userMovedMessage: UserMovedMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "groupLeftZoneMessage",
            groupLeftZoneMessage: GroupLeftZoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "userLeftZoneMessage",
            userLeftZoneMessage: UserLeftZoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = {
            $case: "itemEventMessage",
            itemEventMessage: ItemEventMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "sendUserMessage",
            sendUserMessage: SendUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.message = { $case: "banUserMessage", banUserMessage: BanUserMessage.decode(reader, reader.uint32()) };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.message = {
            $case: "emoteEventMessage",
            emoteEventMessage: EmoteEventMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.message = { $case: "errorMessage", errorMessage: ErrorMessage.decode(reader, reader.uint32()) };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.message = {
            $case: "playerDetailsUpdatedMessage",
            playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.message = {
            $case: "editMapCommandMessage",
            editMapCommandMessage: EditMapCommandMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubToPusherMessage {
    return {
      message: isSet(object.userJoinedZoneMessage)
        ? {
          $case: "userJoinedZoneMessage",
          userJoinedZoneMessage: UserJoinedZoneMessage.fromJSON(object.userJoinedZoneMessage),
        }
        : isSet(object.groupUpdateZoneMessage)
        ? {
          $case: "groupUpdateZoneMessage",
          groupUpdateZoneMessage: GroupUpdateZoneMessage.fromJSON(object.groupUpdateZoneMessage),
        }
        : isSet(object.userMovedMessage)
        ? { $case: "userMovedMessage", userMovedMessage: UserMovedMessage.fromJSON(object.userMovedMessage) }
        : isSet(object.groupLeftZoneMessage)
        ? {
          $case: "groupLeftZoneMessage",
          groupLeftZoneMessage: GroupLeftZoneMessage.fromJSON(object.groupLeftZoneMessage),
        }
        : isSet(object.userLeftZoneMessage)
        ? {
          $case: "userLeftZoneMessage",
          userLeftZoneMessage: UserLeftZoneMessage.fromJSON(object.userLeftZoneMessage),
        }
        : isSet(object.itemEventMessage)
        ? { $case: "itemEventMessage", itemEventMessage: ItemEventMessage.fromJSON(object.itemEventMessage) }
        : isSet(object.sendUserMessage)
        ? { $case: "sendUserMessage", sendUserMessage: SendUserMessage.fromJSON(object.sendUserMessage) }
        : isSet(object.banUserMessage)
        ? { $case: "banUserMessage", banUserMessage: BanUserMessage.fromJSON(object.banUserMessage) }
        : isSet(object.emoteEventMessage)
        ? { $case: "emoteEventMessage", emoteEventMessage: EmoteEventMessage.fromJSON(object.emoteEventMessage) }
        : isSet(object.errorMessage)
        ? { $case: "errorMessage", errorMessage: ErrorMessage.fromJSON(object.errorMessage) }
        : isSet(object.playerDetailsUpdatedMessage)
        ? {
          $case: "playerDetailsUpdatedMessage",
          playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage.fromJSON(object.playerDetailsUpdatedMessage),
        }
        : isSet(object.editMapCommandMessage)
        ? {
          $case: "editMapCommandMessage",
          editMapCommandMessage: EditMapCommandMessage.fromJSON(object.editMapCommandMessage),
        }
        : undefined,
    };
  },

  toJSON(message: SubToPusherMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "userJoinedZoneMessage") {
      obj.userJoinedZoneMessage = UserJoinedZoneMessage.toJSON(message.message.userJoinedZoneMessage);
    }
    if (message.message?.$case === "groupUpdateZoneMessage") {
      obj.groupUpdateZoneMessage = GroupUpdateZoneMessage.toJSON(message.message.groupUpdateZoneMessage);
    }
    if (message.message?.$case === "userMovedMessage") {
      obj.userMovedMessage = UserMovedMessage.toJSON(message.message.userMovedMessage);
    }
    if (message.message?.$case === "groupLeftZoneMessage") {
      obj.groupLeftZoneMessage = GroupLeftZoneMessage.toJSON(message.message.groupLeftZoneMessage);
    }
    if (message.message?.$case === "userLeftZoneMessage") {
      obj.userLeftZoneMessage = UserLeftZoneMessage.toJSON(message.message.userLeftZoneMessage);
    }
    if (message.message?.$case === "itemEventMessage") {
      obj.itemEventMessage = ItemEventMessage.toJSON(message.message.itemEventMessage);
    }
    if (message.message?.$case === "sendUserMessage") {
      obj.sendUserMessage = SendUserMessage.toJSON(message.message.sendUserMessage);
    }
    if (message.message?.$case === "banUserMessage") {
      obj.banUserMessage = BanUserMessage.toJSON(message.message.banUserMessage);
    }
    if (message.message?.$case === "emoteEventMessage") {
      obj.emoteEventMessage = EmoteEventMessage.toJSON(message.message.emoteEventMessage);
    }
    if (message.message?.$case === "errorMessage") {
      obj.errorMessage = ErrorMessage.toJSON(message.message.errorMessage);
    }
    if (message.message?.$case === "playerDetailsUpdatedMessage") {
      obj.playerDetailsUpdatedMessage = PlayerDetailsUpdatedMessage.toJSON(message.message.playerDetailsUpdatedMessage);
    }
    if (message.message?.$case === "editMapCommandMessage") {
      obj.editMapCommandMessage = EditMapCommandMessage.toJSON(message.message.editMapCommandMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubToPusherMessage>, I>>(base?: I): SubToPusherMessage {
    return SubToPusherMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubToPusherMessage>, I>>(object: I): SubToPusherMessage {
    const message = createBaseSubToPusherMessage();
    if (
      object.message?.$case === "userJoinedZoneMessage" &&
      object.message?.userJoinedZoneMessage !== undefined &&
      object.message?.userJoinedZoneMessage !== null
    ) {
      message.message = {
        $case: "userJoinedZoneMessage",
        userJoinedZoneMessage: UserJoinedZoneMessage.fromPartial(object.message.userJoinedZoneMessage),
      };
    }
    if (
      object.message?.$case === "groupUpdateZoneMessage" &&
      object.message?.groupUpdateZoneMessage !== undefined &&
      object.message?.groupUpdateZoneMessage !== null
    ) {
      message.message = {
        $case: "groupUpdateZoneMessage",
        groupUpdateZoneMessage: GroupUpdateZoneMessage.fromPartial(object.message.groupUpdateZoneMessage),
      };
    }
    if (
      object.message?.$case === "userMovedMessage" &&
      object.message?.userMovedMessage !== undefined &&
      object.message?.userMovedMessage !== null
    ) {
      message.message = {
        $case: "userMovedMessage",
        userMovedMessage: UserMovedMessage.fromPartial(object.message.userMovedMessage),
      };
    }
    if (
      object.message?.$case === "groupLeftZoneMessage" &&
      object.message?.groupLeftZoneMessage !== undefined &&
      object.message?.groupLeftZoneMessage !== null
    ) {
      message.message = {
        $case: "groupLeftZoneMessage",
        groupLeftZoneMessage: GroupLeftZoneMessage.fromPartial(object.message.groupLeftZoneMessage),
      };
    }
    if (
      object.message?.$case === "userLeftZoneMessage" &&
      object.message?.userLeftZoneMessage !== undefined &&
      object.message?.userLeftZoneMessage !== null
    ) {
      message.message = {
        $case: "userLeftZoneMessage",
        userLeftZoneMessage: UserLeftZoneMessage.fromPartial(object.message.userLeftZoneMessage),
      };
    }
    if (
      object.message?.$case === "itemEventMessage" &&
      object.message?.itemEventMessage !== undefined &&
      object.message?.itemEventMessage !== null
    ) {
      message.message = {
        $case: "itemEventMessage",
        itemEventMessage: ItemEventMessage.fromPartial(object.message.itemEventMessage),
      };
    }
    if (
      object.message?.$case === "sendUserMessage" &&
      object.message?.sendUserMessage !== undefined &&
      object.message?.sendUserMessage !== null
    ) {
      message.message = {
        $case: "sendUserMessage",
        sendUserMessage: SendUserMessage.fromPartial(object.message.sendUserMessage),
      };
    }
    if (
      object.message?.$case === "banUserMessage" &&
      object.message?.banUserMessage !== undefined &&
      object.message?.banUserMessage !== null
    ) {
      message.message = {
        $case: "banUserMessage",
        banUserMessage: BanUserMessage.fromPartial(object.message.banUserMessage),
      };
    }
    if (
      object.message?.$case === "emoteEventMessage" &&
      object.message?.emoteEventMessage !== undefined &&
      object.message?.emoteEventMessage !== null
    ) {
      message.message = {
        $case: "emoteEventMessage",
        emoteEventMessage: EmoteEventMessage.fromPartial(object.message.emoteEventMessage),
      };
    }
    if (
      object.message?.$case === "errorMessage" &&
      object.message?.errorMessage !== undefined &&
      object.message?.errorMessage !== null
    ) {
      message.message = { $case: "errorMessage", errorMessage: ErrorMessage.fromPartial(object.message.errorMessage) };
    }
    if (
      object.message?.$case === "playerDetailsUpdatedMessage" &&
      object.message?.playerDetailsUpdatedMessage !== undefined &&
      object.message?.playerDetailsUpdatedMessage !== null
    ) {
      message.message = {
        $case: "playerDetailsUpdatedMessage",
        playerDetailsUpdatedMessage: PlayerDetailsUpdatedMessage.fromPartial(
          object.message.playerDetailsUpdatedMessage,
        ),
      };
    }
    if (
      object.message?.$case === "editMapCommandMessage" &&
      object.message?.editMapCommandMessage !== undefined &&
      object.message?.editMapCommandMessage !== null
    ) {
      message.message = {
        $case: "editMapCommandMessage",
        editMapCommandMessage: EditMapCommandMessage.fromPartial(object.message.editMapCommandMessage),
      };
    }
    return message;
  },
};

function createBaseBatchToPusherRoomMessage(): BatchToPusherRoomMessage {
  return { payload: [] };
}

export const BatchToPusherRoomMessage = {
  encode(message: BatchToPusherRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.payload) {
      SubToPusherRoomMessage.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchToPusherRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchToPusherRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.payload.push(SubToPusherRoomMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchToPusherRoomMessage {
    return {
      payload: globalThis.Array.isArray(object?.payload)
        ? object.payload.map((e: any) => SubToPusherRoomMessage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchToPusherRoomMessage): unknown {
    const obj: any = {};
    if (message.payload?.length) {
      obj.payload = message.payload.map((e) => SubToPusherRoomMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchToPusherRoomMessage>, I>>(base?: I): BatchToPusherRoomMessage {
    return BatchToPusherRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchToPusherRoomMessage>, I>>(object: I): BatchToPusherRoomMessage {
    const message = createBaseBatchToPusherRoomMessage();
    message.payload = object.payload?.map((e) => SubToPusherRoomMessage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubToPusherRoomMessage(): SubToPusherRoomMessage {
  return { message: undefined };
}

export const SubToPusherRoomMessage = {
  encode(message: SubToPusherRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "variableMessage":
        VariableWithTagMessage.encode(message.message.variableMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "errorMessage":
        ErrorMessage.encode(message.message.errorMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "editMapCommandMessage":
        EditMapCommandMessage.encode(message.message.editMapCommandMessage, writer.uint32(26).fork()).ldelim();
        break;
      case "joinMucRoomMessage":
        JoinMucRoomMessage.encode(message.message.joinMucRoomMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "leaveMucRoomMessage":
        LeaveMucRoomMessage.encode(message.message.leaveMucRoomMessage, writer.uint32(42).fork()).ldelim();
        break;
      case "receivedEventMessage":
        ReceivedEventMessage.encode(message.message.receivedEventMessage, writer.uint32(50).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubToPusherRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubToPusherRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "variableMessage",
            variableMessage: VariableWithTagMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = { $case: "errorMessage", errorMessage: ErrorMessage.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "editMapCommandMessage",
            editMapCommandMessage: EditMapCommandMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "joinMucRoomMessage",
            joinMucRoomMessage: JoinMucRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "leaveMucRoomMessage",
            leaveMucRoomMessage: LeaveMucRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = {
            $case: "receivedEventMessage",
            receivedEventMessage: ReceivedEventMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubToPusherRoomMessage {
    return {
      message: isSet(object.variableMessage)
        ? { $case: "variableMessage", variableMessage: VariableWithTagMessage.fromJSON(object.variableMessage) }
        : isSet(object.errorMessage)
        ? { $case: "errorMessage", errorMessage: ErrorMessage.fromJSON(object.errorMessage) }
        : isSet(object.editMapCommandMessage)
        ? {
          $case: "editMapCommandMessage",
          editMapCommandMessage: EditMapCommandMessage.fromJSON(object.editMapCommandMessage),
        }
        : isSet(object.joinMucRoomMessage)
        ? { $case: "joinMucRoomMessage", joinMucRoomMessage: JoinMucRoomMessage.fromJSON(object.joinMucRoomMessage) }
        : isSet(object.leaveMucRoomMessage)
        ? {
          $case: "leaveMucRoomMessage",
          leaveMucRoomMessage: LeaveMucRoomMessage.fromJSON(object.leaveMucRoomMessage),
        }
        : isSet(object.receivedEventMessage)
        ? {
          $case: "receivedEventMessage",
          receivedEventMessage: ReceivedEventMessage.fromJSON(object.receivedEventMessage),
        }
        : undefined,
    };
  },

  toJSON(message: SubToPusherRoomMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "variableMessage") {
      obj.variableMessage = VariableWithTagMessage.toJSON(message.message.variableMessage);
    }
    if (message.message?.$case === "errorMessage") {
      obj.errorMessage = ErrorMessage.toJSON(message.message.errorMessage);
    }
    if (message.message?.$case === "editMapCommandMessage") {
      obj.editMapCommandMessage = EditMapCommandMessage.toJSON(message.message.editMapCommandMessage);
    }
    if (message.message?.$case === "joinMucRoomMessage") {
      obj.joinMucRoomMessage = JoinMucRoomMessage.toJSON(message.message.joinMucRoomMessage);
    }
    if (message.message?.$case === "leaveMucRoomMessage") {
      obj.leaveMucRoomMessage = LeaveMucRoomMessage.toJSON(message.message.leaveMucRoomMessage);
    }
    if (message.message?.$case === "receivedEventMessage") {
      obj.receivedEventMessage = ReceivedEventMessage.toJSON(message.message.receivedEventMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubToPusherRoomMessage>, I>>(base?: I): SubToPusherRoomMessage {
    return SubToPusherRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubToPusherRoomMessage>, I>>(object: I): SubToPusherRoomMessage {
    const message = createBaseSubToPusherRoomMessage();
    if (
      object.message?.$case === "variableMessage" &&
      object.message?.variableMessage !== undefined &&
      object.message?.variableMessage !== null
    ) {
      message.message = {
        $case: "variableMessage",
        variableMessage: VariableWithTagMessage.fromPartial(object.message.variableMessage),
      };
    }
    if (
      object.message?.$case === "errorMessage" &&
      object.message?.errorMessage !== undefined &&
      object.message?.errorMessage !== null
    ) {
      message.message = { $case: "errorMessage", errorMessage: ErrorMessage.fromPartial(object.message.errorMessage) };
    }
    if (
      object.message?.$case === "editMapCommandMessage" &&
      object.message?.editMapCommandMessage !== undefined &&
      object.message?.editMapCommandMessage !== null
    ) {
      message.message = {
        $case: "editMapCommandMessage",
        editMapCommandMessage: EditMapCommandMessage.fromPartial(object.message.editMapCommandMessage),
      };
    }
    if (
      object.message?.$case === "joinMucRoomMessage" &&
      object.message?.joinMucRoomMessage !== undefined &&
      object.message?.joinMucRoomMessage !== null
    ) {
      message.message = {
        $case: "joinMucRoomMessage",
        joinMucRoomMessage: JoinMucRoomMessage.fromPartial(object.message.joinMucRoomMessage),
      };
    }
    if (
      object.message?.$case === "leaveMucRoomMessage" &&
      object.message?.leaveMucRoomMessage !== undefined &&
      object.message?.leaveMucRoomMessage !== null
    ) {
      message.message = {
        $case: "leaveMucRoomMessage",
        leaveMucRoomMessage: LeaveMucRoomMessage.fromPartial(object.message.leaveMucRoomMessage),
      };
    }
    if (
      object.message?.$case === "receivedEventMessage" &&
      object.message?.receivedEventMessage !== undefined &&
      object.message?.receivedEventMessage !== null
    ) {
      message.message = {
        $case: "receivedEventMessage",
        receivedEventMessage: ReceivedEventMessage.fromPartial(object.message.receivedEventMessage),
      };
    }
    return message;
  },
};

function createBaseUserJoinedRoomMessage(): UserJoinedRoomMessage {
  return { uuid: "", ipAddress: "", name: "" };
}

export const UserJoinedRoomMessage = {
  encode(message: UserJoinedRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.ipAddress !== "") {
      writer.uint32(18).string(message.ipAddress);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserJoinedRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserJoinedRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ipAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserJoinedRoomMessage {
    return {
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
      ipAddress: isSet(object.ipAddress) ? globalThis.String(object.ipAddress) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: UserJoinedRoomMessage): unknown {
    const obj: any = {};
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.ipAddress !== "") {
      obj.ipAddress = message.ipAddress;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserJoinedRoomMessage>, I>>(base?: I): UserJoinedRoomMessage {
    return UserJoinedRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserJoinedRoomMessage>, I>>(object: I): UserJoinedRoomMessage {
    const message = createBaseUserJoinedRoomMessage();
    message.uuid = object.uuid ?? "";
    message.ipAddress = object.ipAddress ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseUserLeftRoomMessage(): UserLeftRoomMessage {
  return { uuid: "" };
}

export const UserLeftRoomMessage = {
  encode(message: UserLeftRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserLeftRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserLeftRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserLeftRoomMessage {
    return { uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "" };
  },

  toJSON(message: UserLeftRoomMessage): unknown {
    const obj: any = {};
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserLeftRoomMessage>, I>>(base?: I): UserLeftRoomMessage {
    return UserLeftRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserLeftRoomMessage>, I>>(object: I): UserLeftRoomMessage {
    const message = createBaseUserLeftRoomMessage();
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBaseServerToAdminClientMessage(): ServerToAdminClientMessage {
  return { message: undefined };
}

export const ServerToAdminClientMessage = {
  encode(message: ServerToAdminClientMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "userJoinedRoom":
        UserJoinedRoomMessage.encode(message.message.userJoinedRoom, writer.uint32(10).fork()).ldelim();
        break;
      case "userLeftRoom":
        UserLeftRoomMessage.encode(message.message.userLeftRoom, writer.uint32(18).fork()).ldelim();
        break;
      case "errorMessage":
        ErrorMessage.encode(message.message.errorMessage, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerToAdminClientMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerToAdminClientMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "userJoinedRoom",
            userJoinedRoom: UserJoinedRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "userLeftRoom",
            userLeftRoom: UserLeftRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = { $case: "errorMessage", errorMessage: ErrorMessage.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerToAdminClientMessage {
    return {
      message: isSet(object.userJoinedRoom)
        ? { $case: "userJoinedRoom", userJoinedRoom: UserJoinedRoomMessage.fromJSON(object.userJoinedRoom) }
        : isSet(object.userLeftRoom)
        ? { $case: "userLeftRoom", userLeftRoom: UserLeftRoomMessage.fromJSON(object.userLeftRoom) }
        : isSet(object.errorMessage)
        ? { $case: "errorMessage", errorMessage: ErrorMessage.fromJSON(object.errorMessage) }
        : undefined,
    };
  },

  toJSON(message: ServerToAdminClientMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "userJoinedRoom") {
      obj.userJoinedRoom = UserJoinedRoomMessage.toJSON(message.message.userJoinedRoom);
    }
    if (message.message?.$case === "userLeftRoom") {
      obj.userLeftRoom = UserLeftRoomMessage.toJSON(message.message.userLeftRoom);
    }
    if (message.message?.$case === "errorMessage") {
      obj.errorMessage = ErrorMessage.toJSON(message.message.errorMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerToAdminClientMessage>, I>>(base?: I): ServerToAdminClientMessage {
    return ServerToAdminClientMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerToAdminClientMessage>, I>>(object: I): ServerToAdminClientMessage {
    const message = createBaseServerToAdminClientMessage();
    if (
      object.message?.$case === "userJoinedRoom" &&
      object.message?.userJoinedRoom !== undefined &&
      object.message?.userJoinedRoom !== null
    ) {
      message.message = {
        $case: "userJoinedRoom",
        userJoinedRoom: UserJoinedRoomMessage.fromPartial(object.message.userJoinedRoom),
      };
    }
    if (
      object.message?.$case === "userLeftRoom" &&
      object.message?.userLeftRoom !== undefined &&
      object.message?.userLeftRoom !== null
    ) {
      message.message = {
        $case: "userLeftRoom",
        userLeftRoom: UserLeftRoomMessage.fromPartial(object.message.userLeftRoom),
      };
    }
    if (
      object.message?.$case === "errorMessage" &&
      object.message?.errorMessage !== undefined &&
      object.message?.errorMessage !== null
    ) {
      message.message = { $case: "errorMessage", errorMessage: ErrorMessage.fromPartial(object.message.errorMessage) };
    }
    return message;
  },
};

function createBaseAdminPusherToBackMessage(): AdminPusherToBackMessage {
  return { message: undefined };
}

export const AdminPusherToBackMessage = {
  encode(message: AdminPusherToBackMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "subscribeToRoom":
        writer.uint32(10).string(message.message.subscribeToRoom);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminPusherToBackMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminPusherToBackMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = { $case: "subscribeToRoom", subscribeToRoom: reader.string() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminPusherToBackMessage {
    return {
      message: isSet(object.subscribeToRoom)
        ? { $case: "subscribeToRoom", subscribeToRoom: globalThis.String(object.subscribeToRoom) }
        : undefined,
    };
  },

  toJSON(message: AdminPusherToBackMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "subscribeToRoom") {
      obj.subscribeToRoom = message.message.subscribeToRoom;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminPusherToBackMessage>, I>>(base?: I): AdminPusherToBackMessage {
    return AdminPusherToBackMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminPusherToBackMessage>, I>>(object: I): AdminPusherToBackMessage {
    const message = createBaseAdminPusherToBackMessage();
    if (
      object.message?.$case === "subscribeToRoom" &&
      object.message?.subscribeToRoom !== undefined &&
      object.message?.subscribeToRoom !== null
    ) {
      message.message = { $case: "subscribeToRoom", subscribeToRoom: object.message.subscribeToRoom };
    }
    return message;
  },
};

function createBaseMapStorageRefreshMessage(): MapStorageRefreshMessage {
  return { comment: "" };
}

export const MapStorageRefreshMessage = {
  encode(message: MapStorageRefreshMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.comment !== "") {
      writer.uint32(10).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapStorageRefreshMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapStorageRefreshMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MapStorageRefreshMessage {
    return { comment: isSet(object.comment) ? globalThis.String(object.comment) : "" };
  },

  toJSON(message: MapStorageRefreshMessage): unknown {
    const obj: any = {};
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MapStorageRefreshMessage>, I>>(base?: I): MapStorageRefreshMessage {
    return MapStorageRefreshMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MapStorageRefreshMessage>, I>>(object: I): MapStorageRefreshMessage {
    const message = createBaseMapStorageRefreshMessage();
    message.comment = object.comment ?? "";
    return message;
  },
};

function createBaseMapStorageToBackMessage(): MapStorageToBackMessage {
  return { message: undefined };
}

export const MapStorageToBackMessage = {
  encode(message: MapStorageToBackMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "mapStorageRefreshMessage":
        MapStorageRefreshMessage.encode(message.message.mapStorageRefreshMessage, writer.uint32(10).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapStorageToBackMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapStorageToBackMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "mapStorageRefreshMessage",
            mapStorageRefreshMessage: MapStorageRefreshMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MapStorageToBackMessage {
    return {
      message: isSet(object.mapStorageRefreshMessage)
        ? {
          $case: "mapStorageRefreshMessage",
          mapStorageRefreshMessage: MapStorageRefreshMessage.fromJSON(object.mapStorageRefreshMessage),
        }
        : undefined,
    };
  },

  toJSON(message: MapStorageToBackMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "mapStorageRefreshMessage") {
      obj.mapStorageRefreshMessage = MapStorageRefreshMessage.toJSON(message.message.mapStorageRefreshMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MapStorageToBackMessage>, I>>(base?: I): MapStorageToBackMessage {
    return MapStorageToBackMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MapStorageToBackMessage>, I>>(object: I): MapStorageToBackMessage {
    const message = createBaseMapStorageToBackMessage();
    if (
      object.message?.$case === "mapStorageRefreshMessage" &&
      object.message?.mapStorageRefreshMessage !== undefined &&
      object.message?.mapStorageRefreshMessage !== null
    ) {
      message.message = {
        $case: "mapStorageRefreshMessage",
        mapStorageRefreshMessage: MapStorageRefreshMessage.fromPartial(object.message.mapStorageRefreshMessage),
      };
    }
    return message;
  },
};

function createBaseMapStorageUrlMessage(): MapStorageUrlMessage {
  return { mapUrl: "", roomId: "" };
}

export const MapStorageUrlMessage = {
  encode(message: MapStorageUrlMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mapUrl !== "") {
      writer.uint32(10).string(message.mapUrl);
    }
    if (message.roomId !== "") {
      writer.uint32(18).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapStorageUrlMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapStorageUrlMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mapUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MapStorageUrlMessage {
    return {
      mapUrl: isSet(object.mapUrl) ? globalThis.String(object.mapUrl) : "",
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
    };
  },

  toJSON(message: MapStorageUrlMessage): unknown {
    const obj: any = {};
    if (message.mapUrl !== "") {
      obj.mapUrl = message.mapUrl;
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MapStorageUrlMessage>, I>>(base?: I): MapStorageUrlMessage {
    return MapStorageUrlMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MapStorageUrlMessage>, I>>(object: I): MapStorageUrlMessage {
    const message = createBaseMapStorageUrlMessage();
    message.mapUrl = object.mapUrl ?? "";
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBaseMapStorageClearAfterUploadMessage(): MapStorageClearAfterUploadMessage {
  return { wamUrl: "" };
}

export const MapStorageClearAfterUploadMessage = {
  encode(message: MapStorageClearAfterUploadMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wamUrl !== "") {
      writer.uint32(10).string(message.wamUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapStorageClearAfterUploadMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapStorageClearAfterUploadMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wamUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MapStorageClearAfterUploadMessage {
    return { wamUrl: isSet(object.wamUrl) ? globalThis.String(object.wamUrl) : "" };
  },

  toJSON(message: MapStorageClearAfterUploadMessage): unknown {
    const obj: any = {};
    if (message.wamUrl !== "") {
      obj.wamUrl = message.wamUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MapStorageClearAfterUploadMessage>, I>>(
    base?: I,
  ): MapStorageClearAfterUploadMessage {
    return MapStorageClearAfterUploadMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MapStorageClearAfterUploadMessage>, I>>(
    object: I,
  ): MapStorageClearAfterUploadMessage {
    const message = createBaseMapStorageClearAfterUploadMessage();
    message.wamUrl = object.wamUrl ?? "";
    return message;
  },
};

function createBaseAdminMessage(): AdminMessage {
  return { message: "", recipientUuid: "", roomId: "", type: "" };
}

export const AdminMessage = {
  encode(message: AdminMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    if (message.recipientUuid !== "") {
      writer.uint32(18).string(message.recipientUuid);
    }
    if (message.roomId !== "") {
      writer.uint32(26).string(message.roomId);
    }
    if (message.type !== "") {
      writer.uint32(34).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientUuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminMessage {
    return {
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      recipientUuid: isSet(object.recipientUuid) ? globalThis.String(object.recipientUuid) : "",
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: AdminMessage): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.recipientUuid !== "") {
      obj.recipientUuid = message.recipientUuid;
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminMessage>, I>>(base?: I): AdminMessage {
    return AdminMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminMessage>, I>>(object: I): AdminMessage {
    const message = createBaseAdminMessage();
    message.message = object.message ?? "";
    message.recipientUuid = object.recipientUuid ?? "";
    message.roomId = object.roomId ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseAdminRoomMessage(): AdminRoomMessage {
  return { message: "", roomId: "", type: "" };
}

export const AdminRoomMessage = {
  encode(message: AdminRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    if (message.roomId !== "") {
      writer.uint32(18).string(message.roomId);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminRoomMessage {
    return {
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: AdminRoomMessage): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminRoomMessage>, I>>(base?: I): AdminRoomMessage {
    return AdminRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminRoomMessage>, I>>(object: I): AdminRoomMessage {
    const message = createBaseAdminRoomMessage();
    message.message = object.message ?? "";
    message.roomId = object.roomId ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseAdminGlobalMessage(): AdminGlobalMessage {
  return { message: "" };
}

export const AdminGlobalMessage = {
  encode(message: AdminGlobalMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGlobalMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGlobalMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGlobalMessage {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: AdminGlobalMessage): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGlobalMessage>, I>>(base?: I): AdminGlobalMessage {
    return AdminGlobalMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdminGlobalMessage>, I>>(object: I): AdminGlobalMessage {
    const message = createBaseAdminGlobalMessage();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseBanMessage(): BanMessage {
  return { recipientUuid: "", roomId: "", type: "", message: "" };
}

export const BanMessage = {
  encode(message: BanMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.recipientUuid !== "") {
      writer.uint32(10).string(message.recipientUuid);
    }
    if (message.roomId !== "") {
      writer.uint32(18).string(message.roomId);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    if (message.message !== "") {
      writer.uint32(34).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BanMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBanMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientUuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BanMessage {
    return {
      recipientUuid: isSet(object.recipientUuid) ? globalThis.String(object.recipientUuid) : "",
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: BanMessage): unknown {
    const obj: any = {};
    if (message.recipientUuid !== "") {
      obj.recipientUuid = message.recipientUuid;
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BanMessage>, I>>(base?: I): BanMessage {
    return BanMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BanMessage>, I>>(object: I): BanMessage {
    const message = createBaseBanMessage();
    message.recipientUuid = object.recipientUuid ?? "";
    message.roomId = object.roomId ?? "";
    message.type = object.type ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseRoomDescription(): RoomDescription {
  return { roomId: "", nbUsers: 0 };
}

export const RoomDescription = {
  encode(message: RoomDescription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    if (message.nbUsers !== 0) {
      writer.uint32(16).int32(message.nbUsers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomDescription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomDescription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.nbUsers = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomDescription {
    return {
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      nbUsers: isSet(object.nbUsers) ? globalThis.Number(object.nbUsers) : 0,
    };
  },

  toJSON(message: RoomDescription): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.nbUsers !== 0) {
      obj.nbUsers = Math.round(message.nbUsers);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomDescription>, I>>(base?: I): RoomDescription {
    return RoomDescription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomDescription>, I>>(object: I): RoomDescription {
    const message = createBaseRoomDescription();
    message.roomId = object.roomId ?? "";
    message.nbUsers = object.nbUsers ?? 0;
    return message;
  },
};

function createBaseRoomsList(): RoomsList {
  return { roomDescription: [] };
}

export const RoomsList = {
  encode(message: RoomsList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roomDescription) {
      RoomDescription.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomsList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomsList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomDescription.push(RoomDescription.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomsList {
    return {
      roomDescription: globalThis.Array.isArray(object?.roomDescription)
        ? object.roomDescription.map((e: any) => RoomDescription.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RoomsList): unknown {
    const obj: any = {};
    if (message.roomDescription?.length) {
      obj.roomDescription = message.roomDescription.map((e) => RoomDescription.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomsList>, I>>(base?: I): RoomsList {
    return RoomsList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomsList>, I>>(object: I): RoomsList {
    const message = createBaseRoomsList();
    message.roomDescription = object.roomDescription?.map((e) => RoomDescription.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDispatchGlobalEventRequest(): DispatchGlobalEventRequest {
  return { name: "", value: undefined };
}

export const DispatchGlobalEventRequest = {
  encode(message: DispatchGlobalEventRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DispatchGlobalEventRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDispatchGlobalEventRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DispatchGlobalEventRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object?.value) ? object.value : undefined,
    };
  },

  toJSON(message: DispatchGlobalEventRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== undefined) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DispatchGlobalEventRequest>, I>>(base?: I): DispatchGlobalEventRequest {
    return DispatchGlobalEventRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DispatchGlobalEventRequest>, I>>(object: I): DispatchGlobalEventRequest {
    const message = createBaseDispatchGlobalEventRequest();
    message.name = object.name ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseChatMessagePrompt(): ChatMessagePrompt {
  return { roomId: "", message: undefined };
}

export const ChatMessagePrompt = {
  encode(message: ChatMessagePrompt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    switch (message.message?.$case) {
      case "joinMucRoomMessage":
        JoinMucRoomMessage.encode(message.message.joinMucRoomMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "leaveMucRoomMessage":
        LeaveMucRoomMessage.encode(message.message.leaveMucRoomMessage, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatMessagePrompt {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatMessagePrompt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "joinMucRoomMessage",
            joinMucRoomMessage: JoinMucRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "leaveMucRoomMessage",
            leaveMucRoomMessage: LeaveMucRoomMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatMessagePrompt {
    return {
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      message: isSet(object.joinMucRoomMessage)
        ? { $case: "joinMucRoomMessage", joinMucRoomMessage: JoinMucRoomMessage.fromJSON(object.joinMucRoomMessage) }
        : isSet(object.leaveMucRoomMessage)
        ? {
          $case: "leaveMucRoomMessage",
          leaveMucRoomMessage: LeaveMucRoomMessage.fromJSON(object.leaveMucRoomMessage),
        }
        : undefined,
    };
  },

  toJSON(message: ChatMessagePrompt): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.message?.$case === "joinMucRoomMessage") {
      obj.joinMucRoomMessage = JoinMucRoomMessage.toJSON(message.message.joinMucRoomMessage);
    }
    if (message.message?.$case === "leaveMucRoomMessage") {
      obj.leaveMucRoomMessage = LeaveMucRoomMessage.toJSON(message.message.leaveMucRoomMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatMessagePrompt>, I>>(base?: I): ChatMessagePrompt {
    return ChatMessagePrompt.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatMessagePrompt>, I>>(object: I): ChatMessagePrompt {
    const message = createBaseChatMessagePrompt();
    message.roomId = object.roomId ?? "";
    if (
      object.message?.$case === "joinMucRoomMessage" &&
      object.message?.joinMucRoomMessage !== undefined &&
      object.message?.joinMucRoomMessage !== null
    ) {
      message.message = {
        $case: "joinMucRoomMessage",
        joinMucRoomMessage: JoinMucRoomMessage.fromPartial(object.message.joinMucRoomMessage),
      };
    }
    if (
      object.message?.$case === "leaveMucRoomMessage" &&
      object.message?.leaveMucRoomMessage !== undefined &&
      object.message?.leaveMucRoomMessage !== null
    ) {
      message.message = {
        $case: "leaveMucRoomMessage",
        leaveMucRoomMessage: LeaveMucRoomMessage.fromPartial(object.message.leaveMucRoomMessage),
      };
    }
    return message;
  },
};

function createBaseJoinMucRoomMessage(): JoinMucRoomMessage {
  return { mucRoomDefinitionMessage: undefined };
}

export const JoinMucRoomMessage = {
  encode(message: JoinMucRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mucRoomDefinitionMessage !== undefined) {
      MucRoomDefinitionMessage.encode(message.mucRoomDefinitionMessage, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinMucRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinMucRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mucRoomDefinitionMessage = MucRoomDefinitionMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinMucRoomMessage {
    return {
      mucRoomDefinitionMessage: isSet(object.mucRoomDefinitionMessage)
        ? MucRoomDefinitionMessage.fromJSON(object.mucRoomDefinitionMessage)
        : undefined,
    };
  },

  toJSON(message: JoinMucRoomMessage): unknown {
    const obj: any = {};
    if (message.mucRoomDefinitionMessage !== undefined) {
      obj.mucRoomDefinitionMessage = MucRoomDefinitionMessage.toJSON(message.mucRoomDefinitionMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinMucRoomMessage>, I>>(base?: I): JoinMucRoomMessage {
    return JoinMucRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinMucRoomMessage>, I>>(object: I): JoinMucRoomMessage {
    const message = createBaseJoinMucRoomMessage();
    message.mucRoomDefinitionMessage =
      (object.mucRoomDefinitionMessage !== undefined && object.mucRoomDefinitionMessage !== null)
        ? MucRoomDefinitionMessage.fromPartial(object.mucRoomDefinitionMessage)
        : undefined;
    return message;
  },
};

function createBaseLeaveMucRoomMessage(): LeaveMucRoomMessage {
  return { url: "" };
}

export const LeaveMucRoomMessage = {
  encode(message: LeaveMucRoomMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LeaveMucRoomMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeaveMucRoomMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LeaveMucRoomMessage {
    return { url: isSet(object.url) ? globalThis.String(object.url) : "" };
  },

  toJSON(message: LeaveMucRoomMessage): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LeaveMucRoomMessage>, I>>(base?: I): LeaveMucRoomMessage {
    return LeaveMucRoomMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LeaveMucRoomMessage>, I>>(object: I): LeaveMucRoomMessage {
    const message = createBaseLeaveMucRoomMessage();
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseMucRoomDefinitionMessage(): MucRoomDefinitionMessage {
  return { url: "", name: "", type: "", subscribe: false };
}

export const MucRoomDefinitionMessage = {
  encode(message: MucRoomDefinitionMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    if (message.subscribe === true) {
      writer.uint32(32).bool(message.subscribe);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MucRoomDefinitionMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMucRoomDefinitionMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.subscribe = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MucRoomDefinitionMessage {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      subscribe: isSet(object.subscribe) ? globalThis.Boolean(object.subscribe) : false,
    };
  },

  toJSON(message: MucRoomDefinitionMessage): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.subscribe === true) {
      obj.subscribe = message.subscribe;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MucRoomDefinitionMessage>, I>>(base?: I): MucRoomDefinitionMessage {
    return MucRoomDefinitionMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MucRoomDefinitionMessage>, I>>(object: I): MucRoomDefinitionMessage {
    const message = createBaseMucRoomDefinitionMessage();
    message.url = object.url ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.subscribe = object.subscribe ?? false;
    return message;
  },
};

function createBaseXmppSettingsMessage(): XmppSettingsMessage {
  return { conferenceDomain: "", rooms: [], jabberId: "", jabberPassword: "" };
}

export const XmppSettingsMessage = {
  encode(message: XmppSettingsMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.conferenceDomain !== "") {
      writer.uint32(18).string(message.conferenceDomain);
    }
    for (const v of message.rooms) {
      MucRoomDefinitionMessage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.jabberId !== "") {
      writer.uint32(34).string(message.jabberId);
    }
    if (message.jabberPassword !== "") {
      writer.uint32(42).string(message.jabberPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): XmppSettingsMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseXmppSettingsMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.conferenceDomain = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rooms.push(MucRoomDefinitionMessage.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.jabberId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.jabberPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): XmppSettingsMessage {
    return {
      conferenceDomain: isSet(object.conferenceDomain) ? globalThis.String(object.conferenceDomain) : "",
      rooms: globalThis.Array.isArray(object?.rooms)
        ? object.rooms.map((e: any) => MucRoomDefinitionMessage.fromJSON(e))
        : [],
      jabberId: isSet(object.jabberId) ? globalThis.String(object.jabberId) : "",
      jabberPassword: isSet(object.jabberPassword) ? globalThis.String(object.jabberPassword) : "",
    };
  },

  toJSON(message: XmppSettingsMessage): unknown {
    const obj: any = {};
    if (message.conferenceDomain !== "") {
      obj.conferenceDomain = message.conferenceDomain;
    }
    if (message.rooms?.length) {
      obj.rooms = message.rooms.map((e) => MucRoomDefinitionMessage.toJSON(e));
    }
    if (message.jabberId !== "") {
      obj.jabberId = message.jabberId;
    }
    if (message.jabberPassword !== "") {
      obj.jabberPassword = message.jabberPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<XmppSettingsMessage>, I>>(base?: I): XmppSettingsMessage {
    return XmppSettingsMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<XmppSettingsMessage>, I>>(object: I): XmppSettingsMessage {
    const message = createBaseXmppSettingsMessage();
    message.conferenceDomain = object.conferenceDomain ?? "";
    message.rooms = object.rooms?.map((e) => MucRoomDefinitionMessage.fromPartial(e)) || [];
    message.jabberId = object.jabberId ?? "";
    message.jabberPassword = object.jabberPassword ?? "";
    return message;
  },
};

function createBasePusherToBackSpaceMessage(): PusherToBackSpaceMessage {
  return { message: undefined };
}

export const PusherToBackSpaceMessage = {
  encode(message: PusherToBackSpaceMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "watchSpaceMessage":
        WatchSpaceMessage.encode(message.message.watchSpaceMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "unwatchSpaceMessage":
        UnwatchSpaceMessage.encode(message.message.unwatchSpaceMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "addSpaceUserMessage":
        AddSpaceUserMessage.encode(message.message.addSpaceUserMessage, writer.uint32(26).fork()).ldelim();
        break;
      case "updateSpaceUserMessage":
        UpdateSpaceUserMessage.encode(message.message.updateSpaceUserMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "removeSpaceUserMessage":
        RemoveSpaceUserMessage.encode(message.message.removeSpaceUserMessage, writer.uint32(42).fork()).ldelim();
        break;
      case "pongMessage":
        PingMessage.encode(message.message.pongMessage, writer.uint32(50).fork()).ldelim();
        break;
      case "updateSpaceMetadataMessage":
        UpdateSpaceMetadataMessage.encode(message.message.updateSpaceMetadataMessage, writer.uint32(58).fork())
          .ldelim();
        break;
      case "kickOffMessage":
        KickOffMessage.encode(message.message.kickOffMessage, writer.uint32(66).fork()).ldelim();
        break;
      case "muteMicrophoneMessage":
        MuteMicrophoneMessage.encode(message.message.muteMicrophoneMessage, writer.uint32(74).fork()).ldelim();
        break;
      case "muteVideoMessage":
        MuteVideoMessage.encode(message.message.muteVideoMessage, writer.uint32(82).fork()).ldelim();
        break;
      case "muteMicrophoneEverybodyMessage":
        MuteMicrophoneEverybodyMessage.encode(message.message.muteMicrophoneEverybodyMessage, writer.uint32(90).fork())
          .ldelim();
        break;
      case "muteVideoEverybodyMessage":
        MuteVideoEverybodyMessage.encode(message.message.muteVideoEverybodyMessage, writer.uint32(98).fork()).ldelim();
        break;
      case "askMuteMicrophoneMessage":
        AskMuteMicrophoneMessage.encode(message.message.askMuteMicrophoneMessage, writer.uint32(106).fork()).ldelim();
        break;
      case "askMuteVideoMessage":
        AskMuteVideoMessage.encode(message.message.askMuteVideoMessage, writer.uint32(114).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PusherToBackSpaceMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePusherToBackSpaceMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "watchSpaceMessage",
            watchSpaceMessage: WatchSpaceMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "unwatchSpaceMessage",
            unwatchSpaceMessage: UnwatchSpaceMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "addSpaceUserMessage",
            addSpaceUserMessage: AddSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = {
            $case: "updateSpaceUserMessage",
            updateSpaceUserMessage: UpdateSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "removeSpaceUserMessage",
            removeSpaceUserMessage: RemoveSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = { $case: "pongMessage", pongMessage: PingMessage.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "updateSpaceMetadataMessage",
            updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.message = { $case: "kickOffMessage", kickOffMessage: KickOffMessage.decode(reader, reader.uint32()) };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.message = {
            $case: "muteMicrophoneMessage",
            muteMicrophoneMessage: MuteMicrophoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.message = {
            $case: "muteVideoMessage",
            muteVideoMessage: MuteVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.message = {
            $case: "muteMicrophoneEverybodyMessage",
            muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.message = {
            $case: "muteVideoEverybodyMessage",
            muteVideoEverybodyMessage: MuteVideoEverybodyMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.message = {
            $case: "askMuteMicrophoneMessage",
            askMuteMicrophoneMessage: AskMuteMicrophoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.message = {
            $case: "askMuteVideoMessage",
            askMuteVideoMessage: AskMuteVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PusherToBackSpaceMessage {
    return {
      message: isSet(object.watchSpaceMessage)
        ? { $case: "watchSpaceMessage", watchSpaceMessage: WatchSpaceMessage.fromJSON(object.watchSpaceMessage) }
        : isSet(object.unwatchSpaceMessage)
        ? {
          $case: "unwatchSpaceMessage",
          unwatchSpaceMessage: UnwatchSpaceMessage.fromJSON(object.unwatchSpaceMessage),
        }
        : isSet(object.addSpaceUserMessage)
        ? {
          $case: "addSpaceUserMessage",
          addSpaceUserMessage: AddSpaceUserMessage.fromJSON(object.addSpaceUserMessage),
        }
        : isSet(object.updateSpaceUserMessage)
        ? {
          $case: "updateSpaceUserMessage",
          updateSpaceUserMessage: UpdateSpaceUserMessage.fromJSON(object.updateSpaceUserMessage),
        }
        : isSet(object.removeSpaceUserMessage)
        ? {
          $case: "removeSpaceUserMessage",
          removeSpaceUserMessage: RemoveSpaceUserMessage.fromJSON(object.removeSpaceUserMessage),
        }
        : isSet(object.pongMessage)
        ? { $case: "pongMessage", pongMessage: PingMessage.fromJSON(object.pongMessage) }
        : isSet(object.updateSpaceMetadataMessage)
        ? {
          $case: "updateSpaceMetadataMessage",
          updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromJSON(object.updateSpaceMetadataMessage),
        }
        : isSet(object.kickOffMessage)
        ? { $case: "kickOffMessage", kickOffMessage: KickOffMessage.fromJSON(object.kickOffMessage) }
        : isSet(object.muteMicrophoneMessage)
        ? {
          $case: "muteMicrophoneMessage",
          muteMicrophoneMessage: MuteMicrophoneMessage.fromJSON(object.muteMicrophoneMessage),
        }
        : isSet(object.muteVideoMessage)
        ? { $case: "muteVideoMessage", muteVideoMessage: MuteVideoMessage.fromJSON(object.muteVideoMessage) }
        : isSet(object.muteMicrophoneEverybodyMessage)
        ? {
          $case: "muteMicrophoneEverybodyMessage",
          muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.fromJSON(
            object.muteMicrophoneEverybodyMessage,
          ),
        }
        : isSet(object.muteVideoEverybodyMessage)
        ? {
          $case: "muteVideoEverybodyMessage",
          muteVideoEverybodyMessage: MuteVideoEverybodyMessage.fromJSON(object.muteVideoEverybodyMessage),
        }
        : isSet(object.askMuteMicrophoneMessage)
        ? {
          $case: "askMuteMicrophoneMessage",
          askMuteMicrophoneMessage: AskMuteMicrophoneMessage.fromJSON(object.askMuteMicrophoneMessage),
        }
        : isSet(object.askMuteVideoMessage)
        ? {
          $case: "askMuteVideoMessage",
          askMuteVideoMessage: AskMuteVideoMessage.fromJSON(object.askMuteVideoMessage),
        }
        : undefined,
    };
  },

  toJSON(message: PusherToBackSpaceMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "watchSpaceMessage") {
      obj.watchSpaceMessage = WatchSpaceMessage.toJSON(message.message.watchSpaceMessage);
    }
    if (message.message?.$case === "unwatchSpaceMessage") {
      obj.unwatchSpaceMessage = UnwatchSpaceMessage.toJSON(message.message.unwatchSpaceMessage);
    }
    if (message.message?.$case === "addSpaceUserMessage") {
      obj.addSpaceUserMessage = AddSpaceUserMessage.toJSON(message.message.addSpaceUserMessage);
    }
    if (message.message?.$case === "updateSpaceUserMessage") {
      obj.updateSpaceUserMessage = UpdateSpaceUserMessage.toJSON(message.message.updateSpaceUserMessage);
    }
    if (message.message?.$case === "removeSpaceUserMessage") {
      obj.removeSpaceUserMessage = RemoveSpaceUserMessage.toJSON(message.message.removeSpaceUserMessage);
    }
    if (message.message?.$case === "pongMessage") {
      obj.pongMessage = PingMessage.toJSON(message.message.pongMessage);
    }
    if (message.message?.$case === "updateSpaceMetadataMessage") {
      obj.updateSpaceMetadataMessage = UpdateSpaceMetadataMessage.toJSON(message.message.updateSpaceMetadataMessage);
    }
    if (message.message?.$case === "kickOffMessage") {
      obj.kickOffMessage = KickOffMessage.toJSON(message.message.kickOffMessage);
    }
    if (message.message?.$case === "muteMicrophoneMessage") {
      obj.muteMicrophoneMessage = MuteMicrophoneMessage.toJSON(message.message.muteMicrophoneMessage);
    }
    if (message.message?.$case === "muteVideoMessage") {
      obj.muteVideoMessage = MuteVideoMessage.toJSON(message.message.muteVideoMessage);
    }
    if (message.message?.$case === "muteMicrophoneEverybodyMessage") {
      obj.muteMicrophoneEverybodyMessage = MuteMicrophoneEverybodyMessage.toJSON(
        message.message.muteMicrophoneEverybodyMessage,
      );
    }
    if (message.message?.$case === "muteVideoEverybodyMessage") {
      obj.muteVideoEverybodyMessage = MuteVideoEverybodyMessage.toJSON(message.message.muteVideoEverybodyMessage);
    }
    if (message.message?.$case === "askMuteMicrophoneMessage") {
      obj.askMuteMicrophoneMessage = AskMuteMicrophoneMessage.toJSON(message.message.askMuteMicrophoneMessage);
    }
    if (message.message?.$case === "askMuteVideoMessage") {
      obj.askMuteVideoMessage = AskMuteVideoMessage.toJSON(message.message.askMuteVideoMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PusherToBackSpaceMessage>, I>>(base?: I): PusherToBackSpaceMessage {
    return PusherToBackSpaceMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PusherToBackSpaceMessage>, I>>(object: I): PusherToBackSpaceMessage {
    const message = createBasePusherToBackSpaceMessage();
    if (
      object.message?.$case === "watchSpaceMessage" &&
      object.message?.watchSpaceMessage !== undefined &&
      object.message?.watchSpaceMessage !== null
    ) {
      message.message = {
        $case: "watchSpaceMessage",
        watchSpaceMessage: WatchSpaceMessage.fromPartial(object.message.watchSpaceMessage),
      };
    }
    if (
      object.message?.$case === "unwatchSpaceMessage" &&
      object.message?.unwatchSpaceMessage !== undefined &&
      object.message?.unwatchSpaceMessage !== null
    ) {
      message.message = {
        $case: "unwatchSpaceMessage",
        unwatchSpaceMessage: UnwatchSpaceMessage.fromPartial(object.message.unwatchSpaceMessage),
      };
    }
    if (
      object.message?.$case === "addSpaceUserMessage" &&
      object.message?.addSpaceUserMessage !== undefined &&
      object.message?.addSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "addSpaceUserMessage",
        addSpaceUserMessage: AddSpaceUserMessage.fromPartial(object.message.addSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "updateSpaceUserMessage" &&
      object.message?.updateSpaceUserMessage !== undefined &&
      object.message?.updateSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceUserMessage",
        updateSpaceUserMessage: UpdateSpaceUserMessage.fromPartial(object.message.updateSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "removeSpaceUserMessage" &&
      object.message?.removeSpaceUserMessage !== undefined &&
      object.message?.removeSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "removeSpaceUserMessage",
        removeSpaceUserMessage: RemoveSpaceUserMessage.fromPartial(object.message.removeSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "pongMessage" &&
      object.message?.pongMessage !== undefined &&
      object.message?.pongMessage !== null
    ) {
      message.message = { $case: "pongMessage", pongMessage: PingMessage.fromPartial(object.message.pongMessage) };
    }
    if (
      object.message?.$case === "updateSpaceMetadataMessage" &&
      object.message?.updateSpaceMetadataMessage !== undefined &&
      object.message?.updateSpaceMetadataMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceMetadataMessage",
        updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromPartial(object.message.updateSpaceMetadataMessage),
      };
    }
    if (
      object.message?.$case === "kickOffMessage" &&
      object.message?.kickOffMessage !== undefined &&
      object.message?.kickOffMessage !== null
    ) {
      message.message = {
        $case: "kickOffMessage",
        kickOffMessage: KickOffMessage.fromPartial(object.message.kickOffMessage),
      };
    }
    if (
      object.message?.$case === "muteMicrophoneMessage" &&
      object.message?.muteMicrophoneMessage !== undefined &&
      object.message?.muteMicrophoneMessage !== null
    ) {
      message.message = {
        $case: "muteMicrophoneMessage",
        muteMicrophoneMessage: MuteMicrophoneMessage.fromPartial(object.message.muteMicrophoneMessage),
      };
    }
    if (
      object.message?.$case === "muteVideoMessage" &&
      object.message?.muteVideoMessage !== undefined &&
      object.message?.muteVideoMessage !== null
    ) {
      message.message = {
        $case: "muteVideoMessage",
        muteVideoMessage: MuteVideoMessage.fromPartial(object.message.muteVideoMessage),
      };
    }
    if (
      object.message?.$case === "muteMicrophoneEverybodyMessage" &&
      object.message?.muteMicrophoneEverybodyMessage !== undefined &&
      object.message?.muteMicrophoneEverybodyMessage !== null
    ) {
      message.message = {
        $case: "muteMicrophoneEverybodyMessage",
        muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.fromPartial(
          object.message.muteMicrophoneEverybodyMessage,
        ),
      };
    }
    if (
      object.message?.$case === "muteVideoEverybodyMessage" &&
      object.message?.muteVideoEverybodyMessage !== undefined &&
      object.message?.muteVideoEverybodyMessage !== null
    ) {
      message.message = {
        $case: "muteVideoEverybodyMessage",
        muteVideoEverybodyMessage: MuteVideoEverybodyMessage.fromPartial(object.message.muteVideoEverybodyMessage),
      };
    }
    if (
      object.message?.$case === "askMuteMicrophoneMessage" &&
      object.message?.askMuteMicrophoneMessage !== undefined &&
      object.message?.askMuteMicrophoneMessage !== null
    ) {
      message.message = {
        $case: "askMuteMicrophoneMessage",
        askMuteMicrophoneMessage: AskMuteMicrophoneMessage.fromPartial(object.message.askMuteMicrophoneMessage),
      };
    }
    if (
      object.message?.$case === "askMuteVideoMessage" &&
      object.message?.askMuteVideoMessage !== undefined &&
      object.message?.askMuteVideoMessage !== null
    ) {
      message.message = {
        $case: "askMuteVideoMessage",
        askMuteVideoMessage: AskMuteVideoMessage.fromPartial(object.message.askMuteVideoMessage),
      };
    }
    return message;
  },
};

function createBaseBackToPusherSpaceMessage(): BackToPusherSpaceMessage {
  return { message: undefined };
}

export const BackToPusherSpaceMessage = {
  encode(message: BackToPusherSpaceMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.message?.$case) {
      case "addSpaceUserMessage":
        AddSpaceUserMessage.encode(message.message.addSpaceUserMessage, writer.uint32(10).fork()).ldelim();
        break;
      case "updateSpaceUserMessage":
        UpdateSpaceUserMessage.encode(message.message.updateSpaceUserMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "removeSpaceUserMessage":
        RemoveSpaceUserMessage.encode(message.message.removeSpaceUserMessage, writer.uint32(26).fork()).ldelim();
        break;
      case "pingMessage":
        PingMessage.encode(message.message.pingMessage, writer.uint32(34).fork()).ldelim();
        break;
      case "updateSpaceMetadataMessage":
        UpdateSpaceMetadataMessage.encode(message.message.updateSpaceMetadataMessage, writer.uint32(42).fork())
          .ldelim();
        break;
      case "kickOffMessage":
        KickOffMessage.encode(message.message.kickOffMessage, writer.uint32(50).fork()).ldelim();
        break;
      case "muteMicrophoneMessage":
        MuteMicrophoneMessage.encode(message.message.muteMicrophoneMessage, writer.uint32(58).fork()).ldelim();
        break;
      case "muteVideoMessage":
        MuteVideoMessage.encode(message.message.muteVideoMessage, writer.uint32(66).fork()).ldelim();
        break;
      case "muteMicrophoneEverybodyMessage":
        MuteMicrophoneEverybodyMessage.encode(message.message.muteMicrophoneEverybodyMessage, writer.uint32(74).fork())
          .ldelim();
        break;
      case "muteVideoEverybodyMessage":
        MuteVideoEverybodyMessage.encode(message.message.muteVideoEverybodyMessage, writer.uint32(82).fork()).ldelim();
        break;
      case "askMuteMicrophoneMessage":
        AskMuteMicrophoneMessage.encode(message.message.askMuteMicrophoneMessage, writer.uint32(90).fork()).ldelim();
        break;
      case "askMuteVideoMessage":
        AskMuteVideoMessage.encode(message.message.askMuteVideoMessage, writer.uint32(98).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BackToPusherSpaceMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackToPusherSpaceMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = {
            $case: "addSpaceUserMessage",
            addSpaceUserMessage: AddSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = {
            $case: "updateSpaceUserMessage",
            updateSpaceUserMessage: UpdateSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = {
            $case: "removeSpaceUserMessage",
            removeSpaceUserMessage: RemoveSpaceUserMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = { $case: "pingMessage", pingMessage: PingMessage.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = {
            $case: "updateSpaceMetadataMessage",
            updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.message = { $case: "kickOffMessage", kickOffMessage: KickOffMessage.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = {
            $case: "muteMicrophoneMessage",
            muteMicrophoneMessage: MuteMicrophoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.message = {
            $case: "muteVideoMessage",
            muteVideoMessage: MuteVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.message = {
            $case: "muteMicrophoneEverybodyMessage",
            muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.message = {
            $case: "muteVideoEverybodyMessage",
            muteVideoEverybodyMessage: MuteVideoEverybodyMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.message = {
            $case: "askMuteMicrophoneMessage",
            askMuteMicrophoneMessage: AskMuteMicrophoneMessage.decode(reader, reader.uint32()),
          };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.message = {
            $case: "askMuteVideoMessage",
            askMuteVideoMessage: AskMuteVideoMessage.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BackToPusherSpaceMessage {
    return {
      message: isSet(object.addSpaceUserMessage)
        ? {
          $case: "addSpaceUserMessage",
          addSpaceUserMessage: AddSpaceUserMessage.fromJSON(object.addSpaceUserMessage),
        }
        : isSet(object.updateSpaceUserMessage)
        ? {
          $case: "updateSpaceUserMessage",
          updateSpaceUserMessage: UpdateSpaceUserMessage.fromJSON(object.updateSpaceUserMessage),
        }
        : isSet(object.removeSpaceUserMessage)
        ? {
          $case: "removeSpaceUserMessage",
          removeSpaceUserMessage: RemoveSpaceUserMessage.fromJSON(object.removeSpaceUserMessage),
        }
        : isSet(object.pingMessage)
        ? { $case: "pingMessage", pingMessage: PingMessage.fromJSON(object.pingMessage) }
        : isSet(object.updateSpaceMetadataMessage)
        ? {
          $case: "updateSpaceMetadataMessage",
          updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromJSON(object.updateSpaceMetadataMessage),
        }
        : isSet(object.kickOffMessage)
        ? { $case: "kickOffMessage", kickOffMessage: KickOffMessage.fromJSON(object.kickOffMessage) }
        : isSet(object.muteMicrophoneMessage)
        ? {
          $case: "muteMicrophoneMessage",
          muteMicrophoneMessage: MuteMicrophoneMessage.fromJSON(object.muteMicrophoneMessage),
        }
        : isSet(object.muteVideoMessage)
        ? { $case: "muteVideoMessage", muteVideoMessage: MuteVideoMessage.fromJSON(object.muteVideoMessage) }
        : isSet(object.muteMicrophoneEverybodyMessage)
        ? {
          $case: "muteMicrophoneEverybodyMessage",
          muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.fromJSON(
            object.muteMicrophoneEverybodyMessage,
          ),
        }
        : isSet(object.muteVideoEverybodyMessage)
        ? {
          $case: "muteVideoEverybodyMessage",
          muteVideoEverybodyMessage: MuteVideoEverybodyMessage.fromJSON(object.muteVideoEverybodyMessage),
        }
        : isSet(object.askMuteMicrophoneMessage)
        ? {
          $case: "askMuteMicrophoneMessage",
          askMuteMicrophoneMessage: AskMuteMicrophoneMessage.fromJSON(object.askMuteMicrophoneMessage),
        }
        : isSet(object.askMuteVideoMessage)
        ? {
          $case: "askMuteVideoMessage",
          askMuteVideoMessage: AskMuteVideoMessage.fromJSON(object.askMuteVideoMessage),
        }
        : undefined,
    };
  },

  toJSON(message: BackToPusherSpaceMessage): unknown {
    const obj: any = {};
    if (message.message?.$case === "addSpaceUserMessage") {
      obj.addSpaceUserMessage = AddSpaceUserMessage.toJSON(message.message.addSpaceUserMessage);
    }
    if (message.message?.$case === "updateSpaceUserMessage") {
      obj.updateSpaceUserMessage = UpdateSpaceUserMessage.toJSON(message.message.updateSpaceUserMessage);
    }
    if (message.message?.$case === "removeSpaceUserMessage") {
      obj.removeSpaceUserMessage = RemoveSpaceUserMessage.toJSON(message.message.removeSpaceUserMessage);
    }
    if (message.message?.$case === "pingMessage") {
      obj.pingMessage = PingMessage.toJSON(message.message.pingMessage);
    }
    if (message.message?.$case === "updateSpaceMetadataMessage") {
      obj.updateSpaceMetadataMessage = UpdateSpaceMetadataMessage.toJSON(message.message.updateSpaceMetadataMessage);
    }
    if (message.message?.$case === "kickOffMessage") {
      obj.kickOffMessage = KickOffMessage.toJSON(message.message.kickOffMessage);
    }
    if (message.message?.$case === "muteMicrophoneMessage") {
      obj.muteMicrophoneMessage = MuteMicrophoneMessage.toJSON(message.message.muteMicrophoneMessage);
    }
    if (message.message?.$case === "muteVideoMessage") {
      obj.muteVideoMessage = MuteVideoMessage.toJSON(message.message.muteVideoMessage);
    }
    if (message.message?.$case === "muteMicrophoneEverybodyMessage") {
      obj.muteMicrophoneEverybodyMessage = MuteMicrophoneEverybodyMessage.toJSON(
        message.message.muteMicrophoneEverybodyMessage,
      );
    }
    if (message.message?.$case === "muteVideoEverybodyMessage") {
      obj.muteVideoEverybodyMessage = MuteVideoEverybodyMessage.toJSON(message.message.muteVideoEverybodyMessage);
    }
    if (message.message?.$case === "askMuteMicrophoneMessage") {
      obj.askMuteMicrophoneMessage = AskMuteMicrophoneMessage.toJSON(message.message.askMuteMicrophoneMessage);
    }
    if (message.message?.$case === "askMuteVideoMessage") {
      obj.askMuteVideoMessage = AskMuteVideoMessage.toJSON(message.message.askMuteVideoMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BackToPusherSpaceMessage>, I>>(base?: I): BackToPusherSpaceMessage {
    return BackToPusherSpaceMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BackToPusherSpaceMessage>, I>>(object: I): BackToPusherSpaceMessage {
    const message = createBaseBackToPusherSpaceMessage();
    if (
      object.message?.$case === "addSpaceUserMessage" &&
      object.message?.addSpaceUserMessage !== undefined &&
      object.message?.addSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "addSpaceUserMessage",
        addSpaceUserMessage: AddSpaceUserMessage.fromPartial(object.message.addSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "updateSpaceUserMessage" &&
      object.message?.updateSpaceUserMessage !== undefined &&
      object.message?.updateSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceUserMessage",
        updateSpaceUserMessage: UpdateSpaceUserMessage.fromPartial(object.message.updateSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "removeSpaceUserMessage" &&
      object.message?.removeSpaceUserMessage !== undefined &&
      object.message?.removeSpaceUserMessage !== null
    ) {
      message.message = {
        $case: "removeSpaceUserMessage",
        removeSpaceUserMessage: RemoveSpaceUserMessage.fromPartial(object.message.removeSpaceUserMessage),
      };
    }
    if (
      object.message?.$case === "pingMessage" &&
      object.message?.pingMessage !== undefined &&
      object.message?.pingMessage !== null
    ) {
      message.message = { $case: "pingMessage", pingMessage: PingMessage.fromPartial(object.message.pingMessage) };
    }
    if (
      object.message?.$case === "updateSpaceMetadataMessage" &&
      object.message?.updateSpaceMetadataMessage !== undefined &&
      object.message?.updateSpaceMetadataMessage !== null
    ) {
      message.message = {
        $case: "updateSpaceMetadataMessage",
        updateSpaceMetadataMessage: UpdateSpaceMetadataMessage.fromPartial(object.message.updateSpaceMetadataMessage),
      };
    }
    if (
      object.message?.$case === "kickOffMessage" &&
      object.message?.kickOffMessage !== undefined &&
      object.message?.kickOffMessage !== null
    ) {
      message.message = {
        $case: "kickOffMessage",
        kickOffMessage: KickOffMessage.fromPartial(object.message.kickOffMessage),
      };
    }
    if (
      object.message?.$case === "muteMicrophoneMessage" &&
      object.message?.muteMicrophoneMessage !== undefined &&
      object.message?.muteMicrophoneMessage !== null
    ) {
      message.message = {
        $case: "muteMicrophoneMessage",
        muteMicrophoneMessage: MuteMicrophoneMessage.fromPartial(object.message.muteMicrophoneMessage),
      };
    }
    if (
      object.message?.$case === "muteVideoMessage" &&
      object.message?.muteVideoMessage !== undefined &&
      object.message?.muteVideoMessage !== null
    ) {
      message.message = {
        $case: "muteVideoMessage",
        muteVideoMessage: MuteVideoMessage.fromPartial(object.message.muteVideoMessage),
      };
    }
    if (
      object.message?.$case === "muteMicrophoneEverybodyMessage" &&
      object.message?.muteMicrophoneEverybodyMessage !== undefined &&
      object.message?.muteMicrophoneEverybodyMessage !== null
    ) {
      message.message = {
        $case: "muteMicrophoneEverybodyMessage",
        muteMicrophoneEverybodyMessage: MuteMicrophoneEverybodyMessage.fromPartial(
          object.message.muteMicrophoneEverybodyMessage,
        ),
      };
    }
    if (
      object.message?.$case === "muteVideoEverybodyMessage" &&
      object.message?.muteVideoEverybodyMessage !== undefined &&
      object.message?.muteVideoEverybodyMessage !== null
    ) {
      message.message = {
        $case: "muteVideoEverybodyMessage",
        muteVideoEverybodyMessage: MuteVideoEverybodyMessage.fromPartial(object.message.muteVideoEverybodyMessage),
      };
    }
    if (
      object.message?.$case === "askMuteMicrophoneMessage" &&
      object.message?.askMuteMicrophoneMessage !== undefined &&
      object.message?.askMuteMicrophoneMessage !== null
    ) {
      message.message = {
        $case: "askMuteMicrophoneMessage",
        askMuteMicrophoneMessage: AskMuteMicrophoneMessage.fromPartial(object.message.askMuteMicrophoneMessage),
      };
    }
    if (
      object.message?.$case === "askMuteVideoMessage" &&
      object.message?.askMuteVideoMessage !== undefined &&
      object.message?.askMuteVideoMessage !== null
    ) {
      message.message = {
        $case: "askMuteVideoMessage",
        askMuteVideoMessage: AskMuteVideoMessage.fromPartial(object.message.askMuteVideoMessage),
      };
    }
    return message;
  },
};

function createBaseWatchSpaceMessage(): WatchSpaceMessage {
  return { spaceName: "", user: undefined, spaceFilter: undefined };
}

export const WatchSpaceMessage = {
  encode(message: WatchSpaceMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.user !== undefined) {
      SpaceUser.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.spaceFilter !== undefined) {
      SpaceFilterMessage.encode(message.spaceFilter, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WatchSpaceMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWatchSpaceMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = SpaceUser.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.spaceFilter = SpaceFilterMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WatchSpaceMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      user: isSet(object.user) ? SpaceUser.fromJSON(object.user) : undefined,
      spaceFilter: isSet(object.spaceFilter) ? SpaceFilterMessage.fromJSON(object.spaceFilter) : undefined,
    };
  },

  toJSON(message: WatchSpaceMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.user !== undefined) {
      obj.user = SpaceUser.toJSON(message.user);
    }
    if (message.spaceFilter !== undefined) {
      obj.spaceFilter = SpaceFilterMessage.toJSON(message.spaceFilter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WatchSpaceMessage>, I>>(base?: I): WatchSpaceMessage {
    return WatchSpaceMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WatchSpaceMessage>, I>>(object: I): WatchSpaceMessage {
    const message = createBaseWatchSpaceMessage();
    message.spaceName = object.spaceName ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? SpaceUser.fromPartial(object.user) : undefined;
    message.spaceFilter = (object.spaceFilter !== undefined && object.spaceFilter !== null)
      ? SpaceFilterMessage.fromPartial(object.spaceFilter)
      : undefined;
    return message;
  },
};

function createBaseUnwatchSpaceMessage(): UnwatchSpaceMessage {
  return { spaceName: "", userId: 0 };
}

export const UnwatchSpaceMessage = {
  encode(message: UnwatchSpaceMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== 0) {
      writer.uint32(16).int32(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnwatchSpaceMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnwatchSpaceMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnwatchSpaceMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
    };
  },

  toJSON(message: UnwatchSpaceMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnwatchSpaceMessage>, I>>(base?: I): UnwatchSpaceMessage {
    return UnwatchSpaceMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnwatchSpaceMessage>, I>>(object: I): UnwatchSpaceMessage {
    const message = createBaseUnwatchSpaceMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? 0;
    return message;
  },
};

function createBaseSpaceUser(): SpaceUser {
  return {
    id: 0,
    name: "",
    playUri: "",
    color: "",
    characterTextures: [],
    isLogged: false,
    availabilityStatus: 0,
    roomName: undefined,
    visitCardUrl: undefined,
    tags: [],
    cameraState: false,
    microphoneState: false,
    screenSharingState: false,
    megaphoneState: false,
    jitsiParticipantId: undefined,
    uuid: "",
  };
}

export const SpaceUser = {
  encode(message: SpaceUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.playUri !== "") {
      writer.uint32(26).string(message.playUri);
    }
    if (message.color !== "") {
      writer.uint32(34).string(message.color);
    }
    for (const v of message.characterTextures) {
      CharacterTextureMessage.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.isLogged === true) {
      writer.uint32(48).bool(message.isLogged);
    }
    if (message.availabilityStatus !== 0) {
      writer.uint32(56).int32(message.availabilityStatus);
    }
    if (message.roomName !== undefined) {
      StringValue.encode({ value: message.roomName! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.visitCardUrl !== undefined) {
      StringValue.encode({ value: message.visitCardUrl! }, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(82).string(v!);
    }
    if (message.cameraState === true) {
      writer.uint32(88).bool(message.cameraState);
    }
    if (message.microphoneState === true) {
      writer.uint32(96).bool(message.microphoneState);
    }
    if (message.screenSharingState === true) {
      writer.uint32(104).bool(message.screenSharingState);
    }
    if (message.megaphoneState === true) {
      writer.uint32(112).bool(message.megaphoneState);
    }
    if (message.jitsiParticipantId !== undefined) {
      StringValue.encode({ value: message.jitsiParticipantId! }, writer.uint32(122).fork()).ldelim();
    }
    if (message.uuid !== "") {
      writer.uint32(130).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpaceUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpaceUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.playUri = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.color = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.characterTextures.push(CharacterTextureMessage.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isLogged = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.availabilityStatus = reader.int32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.roomName = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.visitCardUrl = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.cameraState = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.microphoneState = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.screenSharingState = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.megaphoneState = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.jitsiParticipantId = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpaceUser {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      playUri: isSet(object.playUri) ? globalThis.String(object.playUri) : "",
      color: isSet(object.color) ? globalThis.String(object.color) : "",
      characterTextures: globalThis.Array.isArray(object?.characterTextures)
        ? object.characterTextures.map((e: any) => CharacterTextureMessage.fromJSON(e))
        : [],
      isLogged: isSet(object.isLogged) ? globalThis.Boolean(object.isLogged) : false,
      availabilityStatus: isSet(object.availabilityStatus) ? globalThis.Number(object.availabilityStatus) : 0,
      roomName: isSet(object.roomName) ? String(object.roomName) : undefined,
      visitCardUrl: isSet(object.visitCardUrl) ? String(object.visitCardUrl) : undefined,
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      cameraState: isSet(object.cameraState) ? globalThis.Boolean(object.cameraState) : false,
      microphoneState: isSet(object.microphoneState) ? globalThis.Boolean(object.microphoneState) : false,
      screenSharingState: isSet(object.screenSharingState) ? globalThis.Boolean(object.screenSharingState) : false,
      megaphoneState: isSet(object.megaphoneState) ? globalThis.Boolean(object.megaphoneState) : false,
      jitsiParticipantId: isSet(object.jitsiParticipantId) ? String(object.jitsiParticipantId) : undefined,
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
    };
  },

  toJSON(message: SpaceUser): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.playUri !== "") {
      obj.playUri = message.playUri;
    }
    if (message.color !== "") {
      obj.color = message.color;
    }
    if (message.characterTextures?.length) {
      obj.characterTextures = message.characterTextures.map((e) => CharacterTextureMessage.toJSON(e));
    }
    if (message.isLogged === true) {
      obj.isLogged = message.isLogged;
    }
    if (message.availabilityStatus !== 0) {
      obj.availabilityStatus = Math.round(message.availabilityStatus);
    }
    if (message.roomName !== undefined) {
      obj.roomName = message.roomName;
    }
    if (message.visitCardUrl !== undefined) {
      obj.visitCardUrl = message.visitCardUrl;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.cameraState === true) {
      obj.cameraState = message.cameraState;
    }
    if (message.microphoneState === true) {
      obj.microphoneState = message.microphoneState;
    }
    if (message.screenSharingState === true) {
      obj.screenSharingState = message.screenSharingState;
    }
    if (message.megaphoneState === true) {
      obj.megaphoneState = message.megaphoneState;
    }
    if (message.jitsiParticipantId !== undefined) {
      obj.jitsiParticipantId = message.jitsiParticipantId;
    }
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpaceUser>, I>>(base?: I): SpaceUser {
    return SpaceUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpaceUser>, I>>(object: I): SpaceUser {
    const message = createBaseSpaceUser();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.playUri = object.playUri ?? "";
    message.color = object.color ?? "";
    message.characterTextures = object.characterTextures?.map((e) => CharacterTextureMessage.fromPartial(e)) || [];
    message.isLogged = object.isLogged ?? false;
    message.availabilityStatus = object.availabilityStatus ?? 0;
    message.roomName = object.roomName ?? undefined;
    message.visitCardUrl = object.visitCardUrl ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.cameraState = object.cameraState ?? false;
    message.microphoneState = object.microphoneState ?? false;
    message.screenSharingState = object.screenSharingState ?? false;
    message.megaphoneState = object.megaphoneState ?? false;
    message.jitsiParticipantId = object.jitsiParticipantId ?? undefined;
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBasePartialSpaceUser(): PartialSpaceUser {
  return {
    id: 0,
    name: undefined,
    playUri: undefined,
    color: undefined,
    characterTextures: [],
    isLogged: undefined,
    availabilityStatus: undefined,
    roomName: undefined,
    visitCardUrl: undefined,
    tags: [],
    cameraState: undefined,
    microphoneState: undefined,
    screenSharingState: undefined,
    megaphoneState: undefined,
    jitsiParticipantId: undefined,
    uuid: "",
  };
}

export const PartialSpaceUser = {
  encode(message: PartialSpaceUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.playUri !== undefined) {
      StringValue.encode({ value: message.playUri! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.color !== undefined) {
      StringValue.encode({ value: message.color! }, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.characterTextures) {
      CharacterTextureMessage.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.isLogged !== undefined) {
      BoolValue.encode({ value: message.isLogged! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.availabilityStatus !== undefined) {
      Int32Value.encode({ value: message.availabilityStatus! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.roomName !== undefined) {
      StringValue.encode({ value: message.roomName! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.visitCardUrl !== undefined) {
      StringValue.encode({ value: message.visitCardUrl! }, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(82).string(v!);
    }
    if (message.cameraState !== undefined) {
      BoolValue.encode({ value: message.cameraState! }, writer.uint32(90).fork()).ldelim();
    }
    if (message.microphoneState !== undefined) {
      BoolValue.encode({ value: message.microphoneState! }, writer.uint32(98).fork()).ldelim();
    }
    if (message.screenSharingState !== undefined) {
      BoolValue.encode({ value: message.screenSharingState! }, writer.uint32(106).fork()).ldelim();
    }
    if (message.megaphoneState !== undefined) {
      BoolValue.encode({ value: message.megaphoneState! }, writer.uint32(114).fork()).ldelim();
    }
    if (message.jitsiParticipantId !== undefined) {
      StringValue.encode({ value: message.jitsiParticipantId! }, writer.uint32(122).fork()).ldelim();
    }
    if (message.uuid !== "") {
      writer.uint32(130).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PartialSpaceUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePartialSpaceUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.playUri = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.color = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.characterTextures.push(CharacterTextureMessage.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.isLogged = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.availabilityStatus = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.roomName = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.visitCardUrl = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.cameraState = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.microphoneState = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.screenSharingState = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.megaphoneState = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.jitsiParticipantId = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PartialSpaceUser {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : undefined,
      playUri: isSet(object.playUri) ? String(object.playUri) : undefined,
      color: isSet(object.color) ? String(object.color) : undefined,
      characterTextures: globalThis.Array.isArray(object?.characterTextures)
        ? object.characterTextures.map((e: any) => CharacterTextureMessage.fromJSON(e))
        : [],
      isLogged: isSet(object.isLogged) ? Boolean(object.isLogged) : undefined,
      availabilityStatus: isSet(object.availabilityStatus) ? Number(object.availabilityStatus) : undefined,
      roomName: isSet(object.roomName) ? String(object.roomName) : undefined,
      visitCardUrl: isSet(object.visitCardUrl) ? String(object.visitCardUrl) : undefined,
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      cameraState: isSet(object.cameraState) ? Boolean(object.cameraState) : undefined,
      microphoneState: isSet(object.microphoneState) ? Boolean(object.microphoneState) : undefined,
      screenSharingState: isSet(object.screenSharingState) ? Boolean(object.screenSharingState) : undefined,
      megaphoneState: isSet(object.megaphoneState) ? Boolean(object.megaphoneState) : undefined,
      jitsiParticipantId: isSet(object.jitsiParticipantId) ? String(object.jitsiParticipantId) : undefined,
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
    };
  },

  toJSON(message: PartialSpaceUser): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.playUri !== undefined) {
      obj.playUri = message.playUri;
    }
    if (message.color !== undefined) {
      obj.color = message.color;
    }
    if (message.characterTextures?.length) {
      obj.characterTextures = message.characterTextures.map((e) => CharacterTextureMessage.toJSON(e));
    }
    if (message.isLogged !== undefined) {
      obj.isLogged = message.isLogged;
    }
    if (message.availabilityStatus !== undefined) {
      obj.availabilityStatus = message.availabilityStatus;
    }
    if (message.roomName !== undefined) {
      obj.roomName = message.roomName;
    }
    if (message.visitCardUrl !== undefined) {
      obj.visitCardUrl = message.visitCardUrl;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.cameraState !== undefined) {
      obj.cameraState = message.cameraState;
    }
    if (message.microphoneState !== undefined) {
      obj.microphoneState = message.microphoneState;
    }
    if (message.screenSharingState !== undefined) {
      obj.screenSharingState = message.screenSharingState;
    }
    if (message.megaphoneState !== undefined) {
      obj.megaphoneState = message.megaphoneState;
    }
    if (message.jitsiParticipantId !== undefined) {
      obj.jitsiParticipantId = message.jitsiParticipantId;
    }
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PartialSpaceUser>, I>>(base?: I): PartialSpaceUser {
    return PartialSpaceUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PartialSpaceUser>, I>>(object: I): PartialSpaceUser {
    const message = createBasePartialSpaceUser();
    message.id = object.id ?? 0;
    message.name = object.name ?? undefined;
    message.playUri = object.playUri ?? undefined;
    message.color = object.color ?? undefined;
    message.characterTextures = object.characterTextures?.map((e) => CharacterTextureMessage.fromPartial(e)) || [];
    message.isLogged = object.isLogged ?? undefined;
    message.availabilityStatus = object.availabilityStatus ?? undefined;
    message.roomName = object.roomName ?? undefined;
    message.visitCardUrl = object.visitCardUrl ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.cameraState = object.cameraState ?? undefined;
    message.microphoneState = object.microphoneState ?? undefined;
    message.screenSharingState = object.screenSharingState ?? undefined;
    message.megaphoneState = object.megaphoneState ?? undefined;
    message.jitsiParticipantId = object.jitsiParticipantId ?? undefined;
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBaseAddSpaceUserMessage(): AddSpaceUserMessage {
  return { spaceName: "", user: undefined, filterName: undefined };
}

export const AddSpaceUserMessage = {
  encode(message: AddSpaceUserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.user !== undefined) {
      SpaceUser.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddSpaceUserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddSpaceUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = SpaceUser.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddSpaceUserMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      user: isSet(object.user) ? SpaceUser.fromJSON(object.user) : undefined,
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: AddSpaceUserMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.user !== undefined) {
      obj.user = SpaceUser.toJSON(message.user);
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddSpaceUserMessage>, I>>(base?: I): AddSpaceUserMessage {
    return AddSpaceUserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddSpaceUserMessage>, I>>(object: I): AddSpaceUserMessage {
    const message = createBaseAddSpaceUserMessage();
    message.spaceName = object.spaceName ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? SpaceUser.fromPartial(object.user) : undefined;
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseUpdateSpaceUserMessage(): UpdateSpaceUserMessage {
  return { spaceName: "", user: undefined, filterName: undefined };
}

export const UpdateSpaceUserMessage = {
  encode(message: UpdateSpaceUserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.user !== undefined) {
      PartialSpaceUser.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSpaceUserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSpaceUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = PartialSpaceUser.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSpaceUserMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      user: isSet(object.user) ? PartialSpaceUser.fromJSON(object.user) : undefined,
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: UpdateSpaceUserMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.user !== undefined) {
      obj.user = PartialSpaceUser.toJSON(message.user);
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSpaceUserMessage>, I>>(base?: I): UpdateSpaceUserMessage {
    return UpdateSpaceUserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSpaceUserMessage>, I>>(object: I): UpdateSpaceUserMessage {
    const message = createBaseUpdateSpaceUserMessage();
    message.spaceName = object.spaceName ?? "";
    message.user = (object.user !== undefined && object.user !== null)
      ? PartialSpaceUser.fromPartial(object.user)
      : undefined;
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseRemoveSpaceUserMessage(): RemoveSpaceUserMessage {
  return { spaceName: "", userId: 0, filterName: undefined };
}

export const RemoveSpaceUserMessage = {
  encode(message: RemoveSpaceUserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== 0) {
      writer.uint32(16).int32(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveSpaceUserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveSpaceUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userId = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveSpaceUserMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: RemoveSpaceUserMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveSpaceUserMessage>, I>>(base?: I): RemoveSpaceUserMessage {
    return RemoveSpaceUserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveSpaceUserMessage>, I>>(object: I): RemoveSpaceUserMessage {
    const message = createBaseRemoveSpaceUserMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? 0;
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseUpdateSpaceMetadataMessage(): UpdateSpaceMetadataMessage {
  return { spaceName: "", metadata: "", filterName: undefined };
}

export const UpdateSpaceMetadataMessage = {
  encode(message: UpdateSpaceMetadataMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.metadata !== "") {
      writer.uint32(18).string(message.metadata);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSpaceMetadataMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSpaceMetadataMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metadata = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSpaceMetadataMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      metadata: isSet(object.metadata) ? globalThis.String(object.metadata) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: UpdateSpaceMetadataMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.metadata !== "") {
      obj.metadata = message.metadata;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSpaceMetadataMessage>, I>>(base?: I): UpdateSpaceMetadataMessage {
    return UpdateSpaceMetadataMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSpaceMetadataMessage>, I>>(object: I): UpdateSpaceMetadataMessage {
    const message = createBaseUpdateSpaceMetadataMessage();
    message.spaceName = object.spaceName ?? "";
    message.metadata = object.metadata ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseKickOffMessage(): KickOffMessage {
  return { spaceName: "", userId: "", filterName: undefined };
}

export const KickOffMessage = {
  encode(message: KickOffMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KickOffMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKickOffMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KickOffMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: KickOffMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KickOffMessage>, I>>(base?: I): KickOffMessage {
    return KickOffMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KickOffMessage>, I>>(object: I): KickOffMessage {
    const message = createBaseKickOffMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseMuteMicrophoneMessage(): MuteMicrophoneMessage {
  return { spaceName: "", userId: "", filterName: undefined };
}

export const MuteMicrophoneMessage = {
  encode(message: MuteMicrophoneMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteMicrophoneMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteMicrophoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteMicrophoneMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: MuteMicrophoneMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteMicrophoneMessage>, I>>(base?: I): MuteMicrophoneMessage {
    return MuteMicrophoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteMicrophoneMessage>, I>>(object: I): MuteMicrophoneMessage {
    const message = createBaseMuteMicrophoneMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseMuteVideoMessage(): MuteVideoMessage {
  return { spaceName: "", userId: "", filterName: undefined };
}

export const MuteVideoMessage = {
  encode(message: MuteVideoMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteVideoMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteVideoMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteVideoMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: MuteVideoMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteVideoMessage>, I>>(base?: I): MuteVideoMessage {
    return MuteVideoMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteVideoMessage>, I>>(object: I): MuteVideoMessage {
    const message = createBaseMuteVideoMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseMuteMicrophoneEverybodyMessage(): MuteMicrophoneEverybodyMessage {
  return { spaceName: "", userId: "", filterName: undefined };
}

export const MuteMicrophoneEverybodyMessage = {
  encode(message: MuteMicrophoneEverybodyMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteMicrophoneEverybodyMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteMicrophoneEverybodyMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteMicrophoneEverybodyMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: MuteMicrophoneEverybodyMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteMicrophoneEverybodyMessage>, I>>(base?: I): MuteMicrophoneEverybodyMessage {
    return MuteMicrophoneEverybodyMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteMicrophoneEverybodyMessage>, I>>(
    object: I,
  ): MuteMicrophoneEverybodyMessage {
    const message = createBaseMuteMicrophoneEverybodyMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseMuteVideoEverybodyMessage(): MuteVideoEverybodyMessage {
  return { spaceName: "", userId: "", filterName: undefined };
}

export const MuteVideoEverybodyMessage = {
  encode(message: MuteVideoEverybodyMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MuteVideoEverybodyMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMuteVideoEverybodyMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MuteVideoEverybodyMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: MuteVideoEverybodyMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MuteVideoEverybodyMessage>, I>>(base?: I): MuteVideoEverybodyMessage {
    return MuteVideoEverybodyMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MuteVideoEverybodyMessage>, I>>(object: I): MuteVideoEverybodyMessage {
    const message = createBaseMuteVideoEverybodyMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseAskMuteMicrophoneMessage(): AskMuteMicrophoneMessage {
  return { spaceName: "", userId: "", filterName: undefined };
}

export const AskMuteMicrophoneMessage = {
  encode(message: AskMuteMicrophoneMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AskMuteMicrophoneMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAskMuteMicrophoneMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AskMuteMicrophoneMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: AskMuteMicrophoneMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AskMuteMicrophoneMessage>, I>>(base?: I): AskMuteMicrophoneMessage {
    return AskMuteMicrophoneMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AskMuteMicrophoneMessage>, I>>(object: I): AskMuteMicrophoneMessage {
    const message = createBaseAskMuteMicrophoneMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseAskMuteVideoMessage(): AskMuteVideoMessage {
  return { spaceName: "", userId: "", filterName: undefined };
}

export const AskMuteVideoMessage = {
  encode(message: AskMuteVideoMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceName !== "") {
      writer.uint32(10).string(message.spaceName);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.filterName !== undefined) {
      StringValue.encode({ value: message.filterName! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AskMuteVideoMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAskMuteVideoMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterName = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AskMuteVideoMessage {
    return {
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      filterName: isSet(object.filterName) ? String(object.filterName) : undefined,
    };
  },

  toJSON(message: AskMuteVideoMessage): unknown {
    const obj: any = {};
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.filterName !== undefined) {
      obj.filterName = message.filterName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AskMuteVideoMessage>, I>>(base?: I): AskMuteVideoMessage {
    return AskMuteVideoMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AskMuteVideoMessage>, I>>(object: I): AskMuteVideoMessage {
    const message = createBaseAskMuteVideoMessage();
    message.spaceName = object.spaceName ?? "";
    message.userId = object.userId ?? "";
    message.filterName = object.filterName ?? undefined;
    return message;
  },
};

function createBaseAddSpaceFilterMessage(): AddSpaceFilterMessage {
  return { spaceFilterMessage: undefined };
}

export const AddSpaceFilterMessage = {
  encode(message: AddSpaceFilterMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceFilterMessage !== undefined) {
      SpaceFilterMessage.encode(message.spaceFilterMessage, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddSpaceFilterMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddSpaceFilterMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceFilterMessage = SpaceFilterMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddSpaceFilterMessage {
    return {
      spaceFilterMessage: isSet(object.spaceFilterMessage)
        ? SpaceFilterMessage.fromJSON(object.spaceFilterMessage)
        : undefined,
    };
  },

  toJSON(message: AddSpaceFilterMessage): unknown {
    const obj: any = {};
    if (message.spaceFilterMessage !== undefined) {
      obj.spaceFilterMessage = SpaceFilterMessage.toJSON(message.spaceFilterMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddSpaceFilterMessage>, I>>(base?: I): AddSpaceFilterMessage {
    return AddSpaceFilterMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddSpaceFilterMessage>, I>>(object: I): AddSpaceFilterMessage {
    const message = createBaseAddSpaceFilterMessage();
    message.spaceFilterMessage = (object.spaceFilterMessage !== undefined && object.spaceFilterMessage !== null)
      ? SpaceFilterMessage.fromPartial(object.spaceFilterMessage)
      : undefined;
    return message;
  },
};

function createBaseUpdateSpaceFilterMessage(): UpdateSpaceFilterMessage {
  return { spaceFilterMessage: undefined };
}

export const UpdateSpaceFilterMessage = {
  encode(message: UpdateSpaceFilterMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceFilterMessage !== undefined) {
      SpaceFilterMessage.encode(message.spaceFilterMessage, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSpaceFilterMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSpaceFilterMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceFilterMessage = SpaceFilterMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSpaceFilterMessage {
    return {
      spaceFilterMessage: isSet(object.spaceFilterMessage)
        ? SpaceFilterMessage.fromJSON(object.spaceFilterMessage)
        : undefined,
    };
  },

  toJSON(message: UpdateSpaceFilterMessage): unknown {
    const obj: any = {};
    if (message.spaceFilterMessage !== undefined) {
      obj.spaceFilterMessage = SpaceFilterMessage.toJSON(message.spaceFilterMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSpaceFilterMessage>, I>>(base?: I): UpdateSpaceFilterMessage {
    return UpdateSpaceFilterMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSpaceFilterMessage>, I>>(object: I): UpdateSpaceFilterMessage {
    const message = createBaseUpdateSpaceFilterMessage();
    message.spaceFilterMessage = (object.spaceFilterMessage !== undefined && object.spaceFilterMessage !== null)
      ? SpaceFilterMessage.fromPartial(object.spaceFilterMessage)
      : undefined;
    return message;
  },
};

function createBaseRemoveSpaceFilterMessage(): RemoveSpaceFilterMessage {
  return { spaceFilterMessage: undefined };
}

export const RemoveSpaceFilterMessage = {
  encode(message: RemoveSpaceFilterMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceFilterMessage !== undefined) {
      SpaceFilterMessage.encode(message.spaceFilterMessage, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveSpaceFilterMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveSpaceFilterMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceFilterMessage = SpaceFilterMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveSpaceFilterMessage {
    return {
      spaceFilterMessage: isSet(object.spaceFilterMessage)
        ? SpaceFilterMessage.fromJSON(object.spaceFilterMessage)
        : undefined,
    };
  },

  toJSON(message: RemoveSpaceFilterMessage): unknown {
    const obj: any = {};
    if (message.spaceFilterMessage !== undefined) {
      obj.spaceFilterMessage = SpaceFilterMessage.toJSON(message.spaceFilterMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveSpaceFilterMessage>, I>>(base?: I): RemoveSpaceFilterMessage {
    return RemoveSpaceFilterMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveSpaceFilterMessage>, I>>(object: I): RemoveSpaceFilterMessage {
    const message = createBaseRemoveSpaceFilterMessage();
    message.spaceFilterMessage = (object.spaceFilterMessage !== undefined && object.spaceFilterMessage !== null)
      ? SpaceFilterMessage.fromPartial(object.spaceFilterMessage)
      : undefined;
    return message;
  },
};

function createBaseSpaceFilterMessage(): SpaceFilterMessage {
  return { filterName: "", spaceName: "", filter: undefined };
}

export const SpaceFilterMessage = {
  encode(message: SpaceFilterMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filterName !== "") {
      writer.uint32(10).string(message.filterName);
    }
    if (message.spaceName !== "") {
      writer.uint32(18).string(message.spaceName);
    }
    switch (message.filter?.$case) {
      case "spaceFilterEverybody":
        SpaceFilterEverybody.encode(message.filter.spaceFilterEverybody, writer.uint32(26).fork()).ldelim();
        break;
      case "spaceFilterContainName":
        SpaceFilterContainName.encode(message.filter.spaceFilterContainName, writer.uint32(34).fork()).ldelim();
        break;
      case "spaceFilterLiveStreaming":
        SpaceFilterLiveStreaming.encode(message.filter.spaceFilterLiveStreaming, writer.uint32(42).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpaceFilterMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpaceFilterMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filterName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spaceName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filter = {
            $case: "spaceFilterEverybody",
            spaceFilterEverybody: SpaceFilterEverybody.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filter = {
            $case: "spaceFilterContainName",
            spaceFilterContainName: SpaceFilterContainName.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filter = {
            $case: "spaceFilterLiveStreaming",
            spaceFilterLiveStreaming: SpaceFilterLiveStreaming.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpaceFilterMessage {
    return {
      filterName: isSet(object.filterName) ? globalThis.String(object.filterName) : "",
      spaceName: isSet(object.spaceName) ? globalThis.String(object.spaceName) : "",
      filter: isSet(object.spaceFilterEverybody)
        ? {
          $case: "spaceFilterEverybody",
          spaceFilterEverybody: SpaceFilterEverybody.fromJSON(object.spaceFilterEverybody),
        }
        : isSet(object.spaceFilterContainName)
        ? {
          $case: "spaceFilterContainName",
          spaceFilterContainName: SpaceFilterContainName.fromJSON(object.spaceFilterContainName),
        }
        : isSet(object.spaceFilterLiveStreaming)
        ? {
          $case: "spaceFilterLiveStreaming",
          spaceFilterLiveStreaming: SpaceFilterLiveStreaming.fromJSON(object.spaceFilterLiveStreaming),
        }
        : undefined,
    };
  },

  toJSON(message: SpaceFilterMessage): unknown {
    const obj: any = {};
    if (message.filterName !== "") {
      obj.filterName = message.filterName;
    }
    if (message.spaceName !== "") {
      obj.spaceName = message.spaceName;
    }
    if (message.filter?.$case === "spaceFilterEverybody") {
      obj.spaceFilterEverybody = SpaceFilterEverybody.toJSON(message.filter.spaceFilterEverybody);
    }
    if (message.filter?.$case === "spaceFilterContainName") {
      obj.spaceFilterContainName = SpaceFilterContainName.toJSON(message.filter.spaceFilterContainName);
    }
    if (message.filter?.$case === "spaceFilterLiveStreaming") {
      obj.spaceFilterLiveStreaming = SpaceFilterLiveStreaming.toJSON(message.filter.spaceFilterLiveStreaming);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpaceFilterMessage>, I>>(base?: I): SpaceFilterMessage {
    return SpaceFilterMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpaceFilterMessage>, I>>(object: I): SpaceFilterMessage {
    const message = createBaseSpaceFilterMessage();
    message.filterName = object.filterName ?? "";
    message.spaceName = object.spaceName ?? "";
    if (
      object.filter?.$case === "spaceFilterEverybody" &&
      object.filter?.spaceFilterEverybody !== undefined &&
      object.filter?.spaceFilterEverybody !== null
    ) {
      message.filter = {
        $case: "spaceFilterEverybody",
        spaceFilterEverybody: SpaceFilterEverybody.fromPartial(object.filter.spaceFilterEverybody),
      };
    }
    if (
      object.filter?.$case === "spaceFilterContainName" &&
      object.filter?.spaceFilterContainName !== undefined &&
      object.filter?.spaceFilterContainName !== null
    ) {
      message.filter = {
        $case: "spaceFilterContainName",
        spaceFilterContainName: SpaceFilterContainName.fromPartial(object.filter.spaceFilterContainName),
      };
    }
    if (
      object.filter?.$case === "spaceFilterLiveStreaming" &&
      object.filter?.spaceFilterLiveStreaming !== undefined &&
      object.filter?.spaceFilterLiveStreaming !== null
    ) {
      message.filter = {
        $case: "spaceFilterLiveStreaming",
        spaceFilterLiveStreaming: SpaceFilterLiveStreaming.fromPartial(object.filter.spaceFilterLiveStreaming),
      };
    }
    return message;
  },
};

function createBaseSpaceFilterContainName(): SpaceFilterContainName {
  return { value: "" };
}

export const SpaceFilterContainName = {
  encode(message: SpaceFilterContainName, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpaceFilterContainName {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpaceFilterContainName();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpaceFilterContainName {
    return { value: isSet(object.value) ? globalThis.String(object.value) : "" };
  },

  toJSON(message: SpaceFilterContainName): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpaceFilterContainName>, I>>(base?: I): SpaceFilterContainName {
    return SpaceFilterContainName.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpaceFilterContainName>, I>>(object: I): SpaceFilterContainName {
    const message = createBaseSpaceFilterContainName();
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseSpaceFilterLiveStreaming(): SpaceFilterLiveStreaming {
  return {};
}

export const SpaceFilterLiveStreaming = {
  encode(_: SpaceFilterLiveStreaming, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpaceFilterLiveStreaming {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpaceFilterLiveStreaming();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SpaceFilterLiveStreaming {
    return {};
  },

  toJSON(_: SpaceFilterLiveStreaming): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SpaceFilterLiveStreaming>, I>>(base?: I): SpaceFilterLiveStreaming {
    return SpaceFilterLiveStreaming.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpaceFilterLiveStreaming>, I>>(_: I): SpaceFilterLiveStreaming {
    const message = createBaseSpaceFilterLiveStreaming();
    return message;
  },
};

function createBaseSpaceFilterEverybody(): SpaceFilterEverybody {
  return {};
}

export const SpaceFilterEverybody = {
  encode(_: SpaceFilterEverybody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpaceFilterEverybody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpaceFilterEverybody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SpaceFilterEverybody {
    return {};
  },

  toJSON(_: SpaceFilterEverybody): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SpaceFilterEverybody>, I>>(base?: I): SpaceFilterEverybody {
    return SpaceFilterEverybody.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpaceFilterEverybody>, I>>(_: I): SpaceFilterEverybody {
    const message = createBaseSpaceFilterEverybody();
    return message;
  },
};

function createBaseVariableRequest(): VariableRequest {
  return { room: "", name: "" };
}

export const VariableRequest = {
  encode(message: VariableRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.room !== "") {
      writer.uint32(10).string(message.room);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariableRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVariableRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.room = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VariableRequest {
    return {
      room: isSet(object.room) ? globalThis.String(object.room) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: VariableRequest): unknown {
    const obj: any = {};
    if (message.room !== "") {
      obj.room = message.room;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VariableRequest>, I>>(base?: I): VariableRequest {
    return VariableRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VariableRequest>, I>>(object: I): VariableRequest {
    const message = createBaseVariableRequest();
    message.room = object.room ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseSaveVariableRequest(): SaveVariableRequest {
  return { room: "", name: "", value: undefined };
}

export const SaveVariableRequest = {
  encode(message: SaveVariableRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.room !== "") {
      writer.uint32(10).string(message.room);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SaveVariableRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveVariableRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.room = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SaveVariableRequest {
    return {
      room: isSet(object.room) ? globalThis.String(object.room) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object?.value) ? object.value : undefined,
    };
  },

  toJSON(message: SaveVariableRequest): unknown {
    const obj: any = {};
    if (message.room !== "") {
      obj.room = message.room;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== undefined) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SaveVariableRequest>, I>>(base?: I): SaveVariableRequest {
    return SaveVariableRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SaveVariableRequest>, I>>(object: I): SaveVariableRequest {
    const message = createBaseSaveVariableRequest();
    message.room = object.room ?? "";
    message.name = object.name ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseEventRequest(): EventRequest {
  return { room: "", name: "" };
}

export const EventRequest = {
  encode(message: EventRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.room !== "") {
      writer.uint32(10).string(message.room);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.room = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventRequest {
    return {
      room: isSet(object.room) ? globalThis.String(object.room) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: EventRequest): unknown {
    const obj: any = {};
    if (message.room !== "") {
      obj.room = message.room;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventRequest>, I>>(base?: I): EventRequest {
    return EventRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventRequest>, I>>(object: I): EventRequest {
    const message = createBaseEventRequest();
    message.room = object.room ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseEventResponse(): EventResponse {
  return { data: undefined, senderId: undefined };
}

export const EventResponse = {
  encode(message: EventResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      Value.encode(Value.wrap(message.data), writer.uint32(10).fork()).ldelim();
    }
    if (message.senderId !== undefined) {
      writer.uint32(16).int32(message.senderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.senderId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventResponse {
    return {
      data: isSet(object?.data) ? object.data : undefined,
      senderId: isSet(object.senderId) ? globalThis.Number(object.senderId) : undefined,
    };
  },

  toJSON(message: EventResponse): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = message.data;
    }
    if (message.senderId !== undefined) {
      obj.senderId = Math.round(message.senderId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventResponse>, I>>(base?: I): EventResponse {
    return EventResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventResponse>, I>>(object: I): EventResponse {
    const message = createBaseEventResponse();
    message.data = object.data ?? undefined;
    message.senderId = object.senderId ?? undefined;
    return message;
  },
};

function createBaseDispatchEventRequest(): DispatchEventRequest {
  return { room: "", name: "", data: undefined, targetUserIds: [] };
}

export const DispatchEventRequest = {
  encode(message: DispatchEventRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.room !== "") {
      writer.uint32(10).string(message.room);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.data !== undefined) {
      Value.encode(Value.wrap(message.data), writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.targetUserIds) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DispatchEventRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDispatchEventRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.room = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag === 32) {
            message.targetUserIds.push(reader.int32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.targetUserIds.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DispatchEventRequest {
    return {
      room: isSet(object.room) ? globalThis.String(object.room) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      data: isSet(object?.data) ? object.data : undefined,
      targetUserIds: globalThis.Array.isArray(object?.targetUserIds)
        ? object.targetUserIds.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: DispatchEventRequest): unknown {
    const obj: any = {};
    if (message.room !== "") {
      obj.room = message.room;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.data !== undefined) {
      obj.data = message.data;
    }
    if (message.targetUserIds?.length) {
      obj.targetUserIds = message.targetUserIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DispatchEventRequest>, I>>(base?: I): DispatchEventRequest {
    return DispatchEventRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DispatchEventRequest>, I>>(object: I): DispatchEventRequest {
    const message = createBaseDispatchEventRequest();
    message.room = object.room ?? "";
    message.name = object.name ?? "";
    message.data = object.data ?? undefined;
    message.targetUserIds = object.targetUserIds?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
